export class RecordModeIridium {
    public static Off = 'Off';
    public static On = 'On';
    public static getModes() {
        return [
            this.Off, // 0
            this.On, // 1
        ];
    }
    public static byIdx(i: number) {
        return [
            this.Off, // 0
            this.On, // 1
        ][i];
    }
    public static toType(i: number) {
        return [
            'off', // 0
            'on', // 1
        ][i];
    }
    public static toString(s: string) {
        const idx = [
            'off', // 0
            'on', // 1
        ].indexOf(s);
        if (idx !== -1) {
            return this.byIdx(idx);
        } else {
            return '';
        }
    }
    public static intToString(mode: number) {
        if (mode === 0) {
            return this.Off;
        }
        if (mode === 1) {
            return this.On;
        }
        return '';
    }
}
