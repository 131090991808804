<template>
    <div v-show="showDeviceTable">
        <q-card square flat bordered class="my-card bg-grey-1">
            <!--
                Mitch:
                    - added
                         :filter-method="customFilter"
             -->
            <q-card-section style="padding: 0px;">
                <q-table
                title="Devices"
                :data="this.$store.state.devices"
                :columns="columns"
                row-key="name"
                dense
                :visible-columns="visibleColumns"
                :filter="filter"
                :filter-method="customFilter"
                :pagination.sync="pagination"
                >
                    <template v-slot:body-cell="props">
                        <q-td :props="props" @click.native="clickRow(props.row)">
                            <div>{{ props.value }}</div>
                        </q-td>
                    </template>
                    <template v-slot:top="props">
                        <div class="col-2 q-table__title text-grey-brand text-bold" style="width: 150px">Devices</div>
                        <q-space />
                        <q-select
                        v-model="visibleColumns"
                        multiple
                        borderless
                        dense
                        options-dense
                        emit-value
                        map-options
                        :display-value="$q.lang.table.columns"
                        :options="columns"
                        option-value="name"
                        style="min-width: 50px"
                        />
                        <q-input dense debounce="300" color="primary" v-model="filter">
                            <template v-slot:append>
                                <q-icon name="search" />
                            </template>
                        </q-input>                        

                    </template>   
                    <!-- <template v-slot:bottom="props">
                    </template> -->
                </q-table>
            </q-card-section>
        </q-card>
    </div>
</template>

<script>
// This is intentionally not TS, there appears to be a bug within Quasar select btn
export default {
    data() {
        return {
            pagination: {
              rowsPerPage: 15,
            },
            visibleColumns: ['uid', 'lastPowerMode'],
            columns: [
                { name: 'uid', label: 'UID', field: 'uid', required: true, disable: true },
                { name: 'lastHeartbeat', label: 'Heartbeat', field: 'lastHeartbeat' },
                { name: 'lastPowerMode', label: 'Mode', field: 'lastPowerMode' },
                { name: 'schedule', label: 'Schedule', field: 'schedule' },
            ],

            // Set the initial filter text for the uid search box to be an empty string.
            filter: '',
        };
    },
    methods: {
        clickRow(row)
        {
            // If the user is a member of the viewer role, only dispatch the setSelectedDevicePlusZoom action if the authenticated user clicked a uid value that is
            // participating in an active pig run that they have a notification subscription for.
            //
            // The web api call to get the list of uid values being used in active pig runs is dispatched in Default.vue (getActivePigRunUidListForUserWithNotification).
            if (this.$store.state.user.role == 'viewer')
            {
                // If the activePigRunUidArrayWithNotification store state member has any data.
                if (this.$store.state.activePigRunUidArrayWithNotification != undefined && this.$store.state.activePigRunUidArrayWithNotification.length > 0)
                {
                    if (this.$store.state.activePigRunUidArrayWithNotification.findIndex(element => element == row.uid) != -1)
                    {
                        this.$store.dispatch('setSelectedDevicePlusZoom', row.uid);
                    }
                }
            }
            else
            {
                this.$store.dispatch('setSelectedDevicePlusZoom', row.uid);     
            }

            // Mitch - previous impl. (start)
            // this.$store.dispatch('setSelectedDevicePlusZoom', row.uid);
            // Mitch - previous impl. (end)
        },

        // customFilter.
        customFilter(rows, terms)
        {
            // console.log('DeviceTable.vue. rows.length = ' + rows.length);
            // console.log('DeviceTable.vue. terms = ' + terms);

            const filteredRows = rows.filter( (row, i) =>
            {
                if (row.uid == terms)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            });

            return filteredRows;
        },
    },
    computed: {
        showDeviceTable() {
            return this.$store.state.showDeviceTable;
        },
    },
};
</script>


<!-- <script lang="ts"> 
// import { Component, Prop, Vue } from 'vue-property-decorator';

// export default class DeviceTable extends Vue {
//     public visibleColumns = ['uid', 'status', 'heartbeat'];
//     public selectedRow = [];
//     public columns = [
//         { name: 'uid', label: 'UID', field: 'uid', required: true, disable: true },
//         { name: 'status', label: 'Status', field: 'status' },
//         { name: 'heartbeat', label: 'Heartbeat', field: 'heartbeat' },
//         { name: 'mode', label: 'Mode', field: 'mode' },
//         { name: 'schedule', label: 'Schedule', field: 'schedule' },
//     ];
//     public data = this.$store.state.devices;

//     public clickRow(id: any) {
//         console.log(id);
//     }
// }
// </script>
-->