// DownloadRecordedPassageDataRequest.
//
// Class representing a download recorded passage data request.
export class DownloadRecordedPassageDataRequest
{
    // Launch date.
    public launchDate: Date;

    // Receive date.
    public receiveDate: Date;

    // Uid array.
    public uidList: number[]; 

    // Constructor.
    public constructor(launchDate: Date, receiveDate: Date, uidList: number[])
    {
        this.launchDate = launchDate;
        this.receiveDate = receiveDate;
        this.uidList = uidList;
    }
}