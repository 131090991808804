
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '../models/Device';
import { Passage } from '../models/Passage';
import { PassageSize } from '../models/PassageSize';
import { PassageState } from '../models/PassageState';
import { date } from 'quasar';
import PigRunCommon from '../utils/PigRunCommon';
import L from 'leaflet';
import { RecordModeIridium } from '../models/RecordModeIridium';
import { RecordMode } from '../models/RecordMode';
import { PowerMode } from '../models/PowerMode';
import DeviceService from '../services/DeviceService';
import { PigRun } from '@/models/PigRun';
import { MarkerPassage } from '../models/MarkerPassage';
import { Pig } from '@/models/Pig';

@Component
export default class DeviceHistory extends Vue {

public ErrorDialog:boolean = false;
public ErrorMsg:string = '';
public onDismissErrorMsg()
{
    this.ErrorMsg = '';
    this.ErrorDialog = false;
}

// ------------------------------------------------------------------------------------------------
// Passage section - start
// ------------------------------------------------------------------------------------------------

// Computed property - bound to the passage q-table.
get passageColumnsToDisplay() : any
{
    let passageColumns = [
        { name: 'passageTimeStamp', label: 'Timestamp', field: 'passageTimeStamp', sortable: false, required: true },
        { name: 'detectionCount', label: 'Count', field: 'detectionCount', sortable: false },
        { name: 'passageDetectedFrequency', label: 'Frequency', field: 'passageDetectedFrequency', sortable: false },
        { name: 'graph-data', field: 'passageId', label: 'Data' },                                                     // changed to graph btn in slot
        { name: 'download-data', field: 'passageId', label: '' },                                                      // changed to dl btn in slot
        { name: 'verify-data', field: 'passageId', label: 'Verified' },                                                // changed to check btn in slot
        { name: 'delete-data', field: 'passageId', label: 'Delete' },                                                  // changed to delete btn in slot
        { name: 'pigRunName', field: 'pigRunName', label: 'Pig Run' },
        { name: 'toolName', field: 'toolName', label: 'Tool' },
        { name: 'markers', field: 'markerName', label: 'Marker' },
        { name: 'clear-data', field: 'passageId', label: 'Run Info' }, 
        { name: 'view-me', field: 'markerName', label: 'Passage Location' },
        { name: 'isOffline', field: 'isOffline', label: 'State' }
    ];

    return passageColumns;
}

// Computed property - bound to the passage q-table.
get passagePagination() : any
{
    return this.$store.state.passageDataPagination;
}

// Computed property setter.
//
// Note: This setter is not used. However, if it is not specified here the browser console will display red warning text that the
// passagePagination getter has no corresponding setter.
set passagePagination(passagePagination: any)
{
}

// onRequestPassage.
//
// Implementation of the @request QTable event handler for passages.
//
// Note: When rowsPerPage is changed in the QTable using the GUI, page gets reset to 1.
public onRequestPassage(props: any)
{
    // Create the action payload.
    let actionPayload: any = { uid: this.device.uid, page: props.pagination.page, rowsPerPage: props.pagination.rowsPerPage, sortBy: props.pagination.sortBy, descending: props.pagination.descending };

    // Invoke the action.
    this.$store.dispatch('getPassageHistoricData', actionPayload);
    
    // Note: The pagination properties must be reset each time this event handler is invoked. The relevant values
    //       are passed to the getPassageHistoricData action, then passed to the SET_HISTORIC_DATA mutation
    //       defined in index.ts. The SET_HISTORIC_DATA mutation then sets the passageDataPagination state member.
}

// Computed property - bound to the passage q-table.
get passageData(): any
{
    var data = this.$store.state.passageData;

    this.ToolNamesV2.length =  this.$store.state.passageData.length;
    this.MarkerNamesV2.length = this.$store.state.passageData.length;

    this.tempSelectedToolName = "";
    this.RunDropDownChange = false;

    this.temprowindex = -1;
    this.ToolDropDownChange = false;
    this.MarkerDropDownChange = false;

    this.GetRunNames();
        
    return data;
}

public showChart(props: any, type: string)
{
    console.log('DeviceHistory.vue. showChart. Enter method.');

    // get passageIds for timestamp update
    this.$store.dispatch('setPassageDialogSource', 'historic_data');

    const passageIdsToUpdate = [];
    if (props.row.hasOwnProperty('geoId'))
    {
        if (props.row.geoId != null)
        {
            passageIdsToUpdate.push(props.row.geoId);
        }
    }

    if (props.row.hasOwnProperty('elfId'))
    {
        if (props.row.elfId != null)
        {
            passageIdsToUpdate.push(props.row.elfId);
        }
    }

    if (props.row.hasOwnProperty('magId'))
    {
        if (props.row.magId != null)
        {
            passageIdsToUpdate.push(props.row.magId);
        }
    }

    this.$store.dispatch('addPassageIdsToUpdate', passageIdsToUpdate);

    if (type === 'GEO')
    {
        const passageCopy =  {...props.row};
        passageCopy.passageId = passageCopy.geoId;

        this.$store.dispatch('passageChartShow', passageCopy);
    }
    else if (type === 'MAG')
    {
        const passageCopy =  {...props.row};
        passageCopy.passageId = passageCopy.magId;

        this.$store.dispatch('passageChartShow', passageCopy);
    }
    else
    {
        this.$store.dispatch('passageChartShow', props.row);
    }
}

public downloadPassage(props: any, type: string)
{
    console.log('DeviceHistory.vue. downloadPassage. Enter method.');

    if (type === 'ELF')
    {
        this.$store.dispatch('passageDownload', { uid: props.row.uid, passageId: props.row.elfId, detectionCount: props.row.detectionCount, passageTimeStamp: props.row.passageTimeStamp });
    }

    if (type === 'GEO')
    {
        this.$store.dispatch('passageDownload', { uid: props.row.uid, passageId: props.row.geoId, detectionCount: props.row.detectionCount, passageTimeStamp: props.row.passageTimeStamp });
    }

    if (type === 'MAG')
    {
        this.$store.dispatch('passageDownload', { uid: props.row.uid, passageId: props.row.magId, detectionCount: props.row.detectionCount, passageTimeStamp: props.row.passageTimeStamp });
    }
}

// onDeleteConfirm.
//
// Confirmation dialog to delete a passage.
public onDeleteConfirm()
{
    console.log('DeviceHistory.vue. onDeleteConfirm. Enter method.');
    this.$store.dispatch('deletePassage', this.deleteIds);
}

// onDeletePassage.
public onDeletePassage(passage: Passage)
{
    this.confirmDeleteDialog = true;

    const idsToDelete = [];

    if (passage.geoId)
    {
        idsToDelete.push(passage.geoId);
    }

    if (passage.elfId)
    {
        idsToDelete.push(passage.elfId);
    }

    if (passage.magId)
    {
        idsToDelete.push(passage.magId);
    }

    this.deleteIds = idsToDelete;
}

// toProperWord
//
// Method used to convert camel case to proper case.
public toProperWord(text: string)
{
    // camel case to proper words
    return text.charAt(0).toUpperCase() + text.replace( /([A-Z])/g, ' $1' ).slice(1);
}

// onVerifyPassage
//
// Implementation of the @click q-btn event handler associated with the passage body-cell-verify-data slot.
public onVerifyPassage(passage: Passage)
{
    console.log('DeviceHistory: onVerifyPassage. Enter method.');
    if(!(this.getuser() == 'admin' || this.getuser() == 'superadmin'))
    {
        console.log('DeviceHistory: onVerifyPassage. Access Denied.');
        return;
    }



    if(passage.isVerified == false && passage.RunId!= null && passage.MarkerName != null)
    {
        // Get the PigRun
        var PigRun = this.GetMatchingPigRun(passage.RunId);

        if(PigRun != null)
        {
            if(this.CheckifMarkerHasPsg(PigRun,passage.MarkerName) == true)
            {
            // Error
                console.log('DeviceHistory: This marker has previously verified passage')
                this.ErrorMsg = ' Marker has a previously verified passage !';
                this.ErrorDialog = true;
                return;
            }
            // Check 2: Check to see if this.EditManualPsgTimestamp is before launch time
            if(this.CheckifBeforeLaunch(PigRun,passage.passageTimeStamp + ' GMT') == true)
            {
                // Error
                console.log('DeviceHistory: Verify passage Time is before Launch Time')
                this.ErrorMsg =  'Passage Time is before Launch Time !';
                this.ErrorDialog = true;
                return;
            }
            // Check 3: Check to see if this.EditManualPsgTimestamp is in the future
            if(this.CheckifinFuture(passage.passageTimeStamp + ' GMT') == true)
            {
                // Error
                console.log('DeviceHistory: Verify passage Time is in the future')
                this.ErrorMsg = 'Passage Time is in the Future !';
                this.ErrorDialog = true;
                return;
            }
            // Check 4: Check to see if this.EditManualPsgTimestamp is a time that occured before any previous verified passages

            if(this.CheckifBeforePreviousVerifiedPsg(PigRun,passage.MarkerName,passage.passageTimeStamp + ' GMT') == true)
            {
                // Error
                console.log('DeviceHistory: Manual passage Time is Invalid')
                this.ErrorMsg = 'Passage time is before a previous Verified Psg !';
                this.ErrorDialog = true;
                return;
            }
                    
                    
            // Check 5: Check to see if this.EditManualPsgTimestamp is a time that occured after the next verified passage location
            if(this.CheckifAfterNextVerifiedPsg(PigRun,passage.MarkerName,passage.passageTimeStamp + ' GMT',) == true)
            {
                // Error
                console.log('DeviceHistory: Manual passage Time is Invalid')
                this.ErrorMsg = 'Passage time is ahead of a further Verified Psg !';
                this.ErrorDialog = true;
                return;
            }

        }

    }

    this.$store.dispatch('verifyPassage', passage);
    this.$store.state.pigRun.activePigRuns.forEach((pigrun:PigRun) => {
        if(pigrun.passages)
        {
            var index = -1;
            if(index == -1 && passage.elfId)
            {
                index = pigrun.passages.findIndex((psg: any) => psg.passageId === passage.elfId);
                //result = pigrun.passages.filter((psg: any,index) => psg.passageId === passage.elfId)
            }
            if(index == -1 && passage.geoId)
            {
                index = pigrun.passages.findIndex((psg: any) => psg.passageId === passage.geoId)
            }
            if(index == -1 &&  passage.magId)
            {
                index = pigrun.passages.findIndex((psg: any) => psg.passageId === passage.magId)
            }

            if(index != -1)
            {
                pigrun.passages[index].isVerified = passage.isVerified;
                this.$store.dispatch('upsertPigRun', pigrun);
                return;
            }
        }
    });

    this.$store.state.pigRun.completedPigRuns.forEach((pigrun:PigRun) => {
        if(pigrun.passages)
        {
            var index = -1;
            if(index == -1 && passage.elfId)
            {
                index = pigrun.passages.findIndex((psg: any,index) => psg.passageId === passage.elfId)
            }
            if(index == -1 && passage.geoId)
            {
                index = pigrun.passages.findIndex((psg: any,index) => psg.passageId === passage.geoId)
            }
            if(index == -1 && passage.magId)
            {
                index = pigrun.passages.findIndex((psg: any,index) => psg.passageId === passage.magId)
            }

            if(index != -1)
            {
                pigrun.passages[index].isVerified = passage.isVerified;
                this.$store.dispatch('upsertFinishedPigRun', pigrun);
            }
        }
    });
}

public GetMatchingPigRun(RunId:number|null)
{
    const Idx = this.$store.state.pigRun.activePigRuns.findIndex((pigRun: PigRun) => pigRun.runId === RunId);
    const Idx2 = this.$store.state.pigRun.completedPigRuns.findIndex((pigRun: PigRun) => pigRun.runId === RunId);
    if(Idx>=0)
    {
        return this.$store.state.pigRun.activePigRuns[Idx];
    }
    if(Idx2>=0)
    {
        return this.$store.state.pigRun.completedPigRuns[Idx];
    }
    return null;
}

// latlontoString.
//
// This function is called by the passage tab template slot named body-cell-view-me to create the passage location label. It was modified
// to display the altitude of the passage (if available) in meters provided the value is at least -1000 mm relative to sea level.
public latlontoString(lat:any, lon:any, alt:any)
{
    var lat_decimal = 0;var lon_decimal = 0;

    lat_decimal = lat.toString().split(".")[1].length || 0; 
    lon_decimal = lon.toString().split(".")[1].length || 0; 

    if(lat_decimal >8)
    {
        lat = Math.round(lat * 1000000) / 1000000
    }

    if(lon_decimal >8)
    {
        lon = Math.round(lon * 1000000) / 1000000
    }

    // Altitude
    //
    // If the input alt is not undefined and greater than -1000, display the altitude in meters.
    var altitudeAsString = '';

    if (alt != undefined)
    {
        let altitudeAsNumber = parseInt(alt, 10);

        if (altitudeAsNumber > -1000)
        {
            let altitudeInMeters = altitudeAsNumber / 1000;
            altitudeAsString = altitudeAsString.concat(',' + altitudeInMeters.toString());
        }
    }

    return (lat + ',' + lon + altitudeAsString)
}

// Mitch - previous implementation (start)
//public latlontoString(lat:any,lon:any)
//{
//    var lat_decimal = 0;var lon_decimal = 0;
//
//    lat_decimal = lat.toString().split(".")[1].length || 0; 
//    lon_decimal = lon.toString().split(".")[1].length || 0; 
//
//  if(lat_decimal >8)
//    {
//        lat = Math.round(lat * 1000000) / 1000000
//    }
//    if(lon_decimal >8)
//    {
//        lon = Math.round(lon * 1000000) / 1000000
//    }
//
//    return (lat + ',' + lon)
//}
// Mitch - previous implementation (end)

public CheckifMarkerHasPsg(pigRun:PigRun,selectedmarkerName:string)
{
    var index = -1;
    //var selectedmarkerName = this.passageMarker;
    
    if(pigRun.passages)
    {
        index = pigRun.passages.findIndex( (passage: MarkerPassage) => {
            if (passage.markerName == selectedmarkerName) {
                if(passage.isVerified == true)
                {
                    return true;
                }
            }
        });

        if(index <0)
        {
            return false;
        }
        else
        {
            return true;
        }

    }
    return false;
}

public CheckifBeforeLaunch(pigRun:PigRun,psgtime_GMT:string)
{
    if(pigRun.launchTime)
    {
        //var psgtime_GMT  = this.EditManualPsgTimestamp + ' GMT';
        var launchTime = new Date(pigRun.launchTime.toUTCString());
        var NewPsgTime = new Date(psgtime_GMT);
        var diff = NewPsgTime.getTime() - launchTime.getTime();
        if(diff <= 0)
        {
            // Error Passage Time is before Launch Time
            return true;
        }
    }
    return false;
}


    public CheckifinFuture(psgtime_GMT:string)
    {
        var NowDate:Date;
        NowDate = new Date();
        var NewPsgTime = new Date(psgtime_GMT);
        var diff = NewPsgTime.getTime() - NowDate.getTime();
        if(diff > 0)
        {
            return true;
        }
        
        return false;
    }




public CheckifBeforePreviousVerifiedPsg(pigRun:PigRun,selectedmarkerName:string,psgtime_GMT:string)
{
    if(pigRun.passages)
    {
        // In this case we should only look at passages that correspond to locations between launch and current location (no future points)
            var current_marker_index = -1;

            if(pigRun.markers)
            {
                current_marker_index = pigRun.markers?.findIndex(x =>
                {
                    if(x.name === selectedmarkerName)
                    {   
                        return true;
                    }
                });

                if(current_marker_index<0)
                {
                    return false; // selected marker name does not exist in the list
                                // We are not going to proceed checking
                }
                else
                {
                    // It is in the list and we know the index => current_marker_index
                    for(var i = 0;i<current_marker_index;i++ )
                    {
                        if(pigRun.markers[i].state == "TRACK")
                        {
                            for(var j = 0;j<pigRun.passages.length;j++)
                            {
                                if(pigRun.markers[i].name == pigRun.passages[j].markerName)
                                {
                                    // We have a match between TRACK marker name and a passage in list
                                    if(pigRun.passages[j].isVerified == true)
                                    {
                                        // Now we make the comparison
                                        var psgTime = new Date(pigRun.passages[j].passageTimeStamp + ' GMT');
                                        var EditManualPsgTime = new Date(psgtime_GMT);
                                        var diff = EditManualPsgTime.getTime() - psgTime.getTime();

                                        if(diff <= 0) // if edited manual psg time is less than a verieifed psg time then error
                                        {
                                            return true; /// An error case exist
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    }

    return false;
}


public CheckifAfterNextVerifiedPsg(pigRun:PigRun,selectedmarkerName:string,psgtime_GMT:string)
{
    if(pigRun && pigRun.passages)
    {
        var index = -1;var current_marker_index = -1;
        console.log(selectedmarkerName);
        // First step we need to find where this passage in the list
        if(pigRun.markers)
        {
            current_marker_index = pigRun.markers?.findIndex(x =>
            {
                if(x.name === selectedmarkerName)
                {   
                    return true;
                }
            });
            if(current_marker_index<0)
            {
                return false; // selected marker name does not exist in the list
                                // We are not going to proceed checking
            }
            else
            {
                // It is in the list and we know the index => current_marker_index
                for(var i = current_marker_index + 1;i<pigRun.markers.length;i++ )
                {
                    if(pigRun.markers[i].state == "TRACK")
                    {
                        for(var j = 0;j<pigRun.passages.length;j++)
                        {
                            if(pigRun.markers[i].name == pigRun.passages[j].markerName)
                            {
                                // We have a match between TRACK marker name and a passage in list
                                if(pigRun.passages[j].isVerified == true)
                                {
                                    // Now we make the comparison
                                    var psgTime = new Date(pigRun.passages[j].passageTimeStamp + ' GMT');
                                    var EditManualPsgTime = new Date(psgtime_GMT);
                                    var diff = psgTime.getTime() - EditManualPsgTime.getTime();
                                    if(diff <= 0) // if edited manual psg time is greater than a verieifed psg time then error
                                    {
                                        return true; /// An error case exist
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }

    return false;
}



// onViewPassageLocation
//
// Method to view the passage location.
public onViewPassageLocation(props: any)
{
    console.log('DeviceHistory.vue. onViewPassageLocation. Enter method.');
    if ((props.row.lat != null) && (props.row.lon != null))
    {
        if ((props.row.lat != 0) && (props.row.lon != 0))
        {
            const tempLocation = L.marker(L.latLng(props.row.lat, props.row.lon), {icon: PigRunCommon.psgIcon, opacity: 0.8})
                .addTo(this.map); 

            this.map.flyTo(tempLocation.getLatLng(), 16);

            setTimeout(() =>
            {
                tempLocation.removeFrom(this.map);
            }, 7000);
        }
    }
}
public UpdatePassageRunInfoV2: boolean = false;
public UpdatePassageRunInfo: boolean = false;
public PsgInfoMsg: string = '';
public PsgInfoMsgV2: string  = 'Are you sure you want to Update Passage Run Info?';
public OldPigRunName: string = '';
public OldPigRunId: number = -1;
public OldToolName: string = '';
public OldMarkerName: string = '';

public NewPigRunName: string = '';
public NewPigRunId: number = -1;
public NewToolName: string = '';
public NewMarkerName: string = '';

public OldpsgInfo: any = null;

public onCancelUpdatePsgInfo()
{
   this.RunDropDownChange = false;
   this.ToolDropDownChange = false;
   this.MarkerDropDownChange = false; 

   this.temprowindex = -1;
   this.tempRunlabelindex = -1;
}

public onUpdatePsgInfo() 
{
    console.log('Updating Passage Info');

    var a = [];
    a[0] = this.OldpsgInfo;
    a[1] = this.NewPigRunId;
    a[2] = this.NewPigRunName;
    a[3] = this.NewToolName;
    a[4] = this.NewMarkerName;

    this.$store.dispatch('EditPassageRunInfo', a);    
    //this.onRefreshTab();
}

public RunNames =  [] as any;

public RunDropDownChange = false;
public temprowindex = -1;
public tempRunlabelindex = -1;

public GetRunNames()
{
    this.RunNames = [];

    for (var i = 0; i < this.$store.state.pigRun.activePigRuns.length; i++)
    {
        var RunId = new Array(0),RunName = new Array(0);

        RunId = this.$store.state.pigRun.activePigRuns[i].runId;
        RunName = this.$store.state.pigRun.activePigRuns[i].pigPath.pigRunName;
        
        var input = {RunId,RunName}

        this.RunNames.push(input);
        
        console.log(this.RunNames[0].RunId);
        console.log(this.RunNames[0].RunName);
    }

    for (var i = 0; i < this.$store.state.pigRun.completedPigRuns.length; i++)
    {
        var RunId = new Array(0),RunName = new Array(0);

        RunId = this.$store.state.pigRun.completedPigRuns[i].runId;
        RunName = this.$store.state.pigRun.completedPigRuns[i].pigPath.pigRunName;
        
        var input = {RunId,RunName}
        this.RunNames.push(input);
    }
}

public DisplayPsgRunName(passageinfo:any)
{        
    if(passageinfo.PigRunName == null)
    {
        return true;
    }


    /*debugger
    var exist = false;
    exist = this.$store.state.pigRun.activePigRuns.filter(function(elem:any){
        if(elem.pigPath.pigRunName == passageinfo.PigRunName) return true;
    });
    return exist;*/



    for (var i = 0; i < this.$store.state.pigRun.activePigRuns.length; i++)
    {
        if (this.$store.state.pigRun.activePigRuns[i].pigPath.pigRunName == passageinfo.PigRunName)
        {
            return true;
        }
    }

    for (var i = 0; i < this.$store.state.pigRun.completedPigRuns.length; i++)
    {
        if (this.$store.state.pigRun.completedPigRuns[i].pigPath.pigRunName == passageinfo.PigRunName)
        {
            return true;
        }
    }

    return false;
}

public GetCurrentPigRunName(passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);

    if (passageinfo.PigRunName == null && this.RunDropDownChange == false)
    {
        return '';
    }
    
    if (this.RunDropDownChange == true)
    {
        // Need to return Temp item Name
        if (this.temprowindex == rowindex)
        {
            this.NewPigRunName = this.RunNames[this.tempRunlabelindex].RunName;
            this.NewPigRunId = this.RunNames[this.tempRunlabelindex].RunId;

            return (this.RunNames[this.tempRunlabelindex].RunName + "_" + this.RunNames[this.tempRunlabelindex].RunId);
        }
    }

    var a = -1;
    for (var i = 0; i < this.RunNames.length; i++)
    {
        if ((this.RunNames[i].RunName == passageinfo.PigRunName) && (this.RunNames[i].RunId == passageinfo.RunId))
        {
            a = i;
            break;
        }
    }
    
    if (a != -1)
    {
        this.GetRunToolAndMarkerNames(this.RunNames[a].RunName,this.RunNames[a].RunId,rowindex);

        return (this.RunNames[a].RunName + "_" + this.RunNames[a].RunId);
    }

    return "";
}

public PigRunNameChange(label_index: number, passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);

    this.RunDropDownChange = true;
    this.temprowindex = rowindex;
    this.tempRunlabelindex = label_index;

    this.ToolDropDownChange = false;
    this.tempToollabelindex = -1;

    this.MarkerDropDownChange = false;
    this.tempMarkerlabelindex = -1;

    console.log("Selected New PigRun = " + this.RunNames[label_index].RunName + "_" + this.RunNames[label_index].RunId);

    if (passageinfo.ToolName == null && passageinfo.MarkerName == null && passageinfo.PigRunName == null)
    {
        this.GetRunToolAndMarkerNames(this.RunNames[label_index].RunName,this.RunNames[label_index].RunId,rowindex);
    }
}

public ToolDropDownChange = false;

public DisplayPsgToolName(passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);

    if (passageinfo.ToolName == null)
    {
        if (this.RunDropDownChange == true && this.temprowindex == rowindex)
        {
            return true;
        }

        return false;
    }

    if (this.ToolDropDownChange == false)
    {
        for (var i = 0; i < this.$store.state.pigRun.activePigRuns.length; i++)
        {
            for(var j = 0; j < this.$store.state.pigRun.activePigRuns[i].pigPath.pigs.length; j++)
            {
                if (passageinfo.ToolName)
                {
                    if (this.$store.state.pigRun.activePigRuns[i].pigPath.pigs[j] == passageinfo.ToolName)
                    {
                        this.SelectedToolName = passageinfo.ToolName;

                        return true; 
                    }
                }
            }
        }

        for (var i = 0; i < this.$store.state.pigRun.completedPigRuns.length; i++)
        {
            for (var j = 0; j < this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs.length; j++)
            {
                if (passageinfo.ToolName)
                {
                    if (this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs[j] == passageinfo.ToolName)
                    {
                        this.SelectedToolName = passageinfo.ToolName;
                        
                        return true; 
                    }
                }
            }        
        }
    }
    else
    {
        if (passageinfo.ToolName)
        {
            return true;
        }
    }

    return false;
}

public GetCurrentToolName(passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);
        
    if (this.RunDropDownChange == false && passageinfo.ToolName == null)
    {
        return '';
    }
    
    if (this.ToolDropDownChange == true )
    {    
        if (this.temprowindex == rowindex)
        {
            if (this.RunDropDownChange == false)
            {
                // find based on passageinfo.PigRunName
                for(var i = 0; i < this.ToolNamesV2.length; i++)
                {
                    if (this.ToolNamesV2[i])
                    {
                        if (this.ToolNamesV2[i][0] == passageinfo.PigRunName)
                        {
                            this.NewToolName = this.ToolNamesV2[i][this.tempToollabelindex + 1];

                            return this.ToolNamesV2[i][this.tempToollabelindex + 1];
                        }
                    }
                }
            }
            else
            {
                // find based on ToolNamesV2
                //this.tempRunlabelindex is with respect to this.RunNames
                var localindex = -1;

                for (var i = 0; i < this.ToolNamesV2.length; i++)
                {
                    if (this.ToolNamesV2[i])
                    {
                        if (this.RunNames[this.tempRunlabelindex].RunName == this.ToolNamesV2[i][0])
                        {
                            localindex = i;

                            break;
                        }
                    }
                }

                this.NewToolName = this.ToolNamesV2[localindex][this.tempToollabelindex + 1];

                return this.ToolNamesV2[localindex][this.tempToollabelindex + 1];
            }

            return "unknown";
        }
    }
    else
    {
        if (this.RunDropDownChange == true && (this.temprowindex == rowindex))
        {
            return "";
        }
    }

    var a = -1;
    
    if (passageinfo.ToolName)
    {
       a = this.ToolNamesV2[rowindex].indexOf(passageinfo.ToolName); 
    }

    if (a > -1)
    {
        return this.ToolNamesV2[rowindex][a];
    }

    return "";
}

public GetToolDropDownLength(passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);
    var currentindex = 0;

    if (this.ToolDropDownChange == true)
    {
        if (this.RunDropDownChange == false)
        {
            for (var i = 0; i < this.ToolNamesV2.length; i++)
            {
                if (this.ToolNamesV2[i])
                {
                    if (this.ToolNamesV2[i][0] == passageinfo.PigRunName)
                    {
                        return (this.ToolNamesV2[i].length - 1);
                    }
                }
            }
        }
        else
        {
            // find based on ToolNamesV2
            var localindex = -1;

            for (var i = 0; i < this.ToolNamesV2.length; i++)
            {
                if (this.ToolNamesV2[i])
                {
                    if (this.RunNames[this.tempRunlabelindex].RunName == this.ToolNamesV2[i][0])
                    {
                        localindex = i;

                        break;
                    }
                }
            }

            return this.ToolNamesV2[localindex].length - 1;
        }
    }
    else
    {
        if (this.RunDropDownChange == true && (this.temprowindex == rowindex))
        {
            // The selected Run name is this.RunNames[this.templabelindex]
            var a  = this.FindRunindexinToolNames(this.tempRunlabelindex);

            if(a != -1)
            {
                return this.ToolNamesV2[a].length - 1;
            }
        }

        if (passageinfo.ToolName == null && this.RunDropDownChange == false)
        {
            return 0;
        }

        currentindex = this.$store.state.passageData.indexOf(passageinfo);
    }

    return (this.ToolNamesV2[currentindex].length - 1);
}

public GetDropItemToolName(index: any, passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);
    var currentindex = 0;

    if (this.ToolDropDownChange == true)
    {
        if (this.RunDropDownChange == false)
        {
            var localindex = -1;

            for (var i = 0; i < this.ToolNamesV2.length; i++)
            {
                if (this.ToolNamesV2[i])
                {
                    if (this.ToolNamesV2[i][0] == passageinfo.PigRunName)
                    {
                        localindex = i;

                        break;
                    }
                }
            }
            
            if (localindex == -1)
            {
                return "";
            }

            return this.ToolNamesV2[localindex][index + 1];
        }
        else
        {
            // find based on ToolNamesV2
            var localindex = -1;

            for (var i = 0; i < this.ToolNamesV2.length; i++)
            {
                if (this.ToolNamesV2[i])
                {
                    if (this.RunNames[this.tempRunlabelindex].RunName == this.ToolNamesV2[i][0])
                    {
                        localindex = i;

                        break;
                    }
                }

            }

            return this.ToolNamesV2[localindex][index + 1];
        }
    }
    else
    {
        if (this.RunDropDownChange == true && (this.temprowindex == rowindex))
        {
            // The selected Run name is this.RunNames[this.templabelindex]
            var a  = this.FindRunindexinToolNames(this.tempRunlabelindex);

            if (a != -1)
            {
                return this.ToolNamesV2[a][index + 1];
            }
        }

        currentindex = this.$store.state.passageData.indexOf(passageinfo);
    }
    
    return this.ToolNamesV2[currentindex][index + 1];
}

public FindRunindexinToolNames(labelindex: number)
{
    for (var i = 0; i < this.ToolNamesV2.length; i++)
    {
        if (this.ToolNamesV2[i])
        {
            if (this.ToolNamesV2[i][0] == this.RunNames[labelindex].RunName)
            {
                return i;
            }
        }
    }

    return -1;
}

public tempToollabelindex = -1;

public ToolNameChange(label_index: number, passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);

    this.ToolDropDownChange = true;
    this.temprowindex = rowindex;
    this.tempToollabelindex = label_index;

    this.MarkerDropDownChange = false;
    this.tempMarkerlabelindex = -1;
}

public MarkerDropDownChange = false;

public DisplayPsgMarkerName(passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);

    if (passageinfo.MarkerName == null)
    {
        if (this.RunDropDownChange == true && this.ToolDropDownChange == true && this.temprowindex == rowindex)
        {
            return true
        }
        
        return false;
    }

    if (this.MarkerDropDownChange == false)
    {
        for (var i = 0; i < this.$store.state.pigRun.activePigRuns.length; i++)
        {
            for(var j = 0; j < this.$store.state.pigRun.activePigRuns[i].markers.length; j++)
            {
                if (passageinfo.MarkerName)
                {
                    if (this.$store.state.pigRun.activePigRuns[i].markers[j].name == passageinfo.MarkerName)
                    {
                        return true; 
                    }
                }

            }
        }

        for (var i = 0; i < this.$store.state.pigRun.completedPigRuns.length; i++)
        {
            for (var j = 0; j < this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs.length; j++)
            {
                if (passageinfo.ToolName)
                {
                    if (this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs[j] == passageinfo.ToolName)
                    {
                        return true; 
                    }
                }
            }        
        }
    }
    else
    {
        if (passageinfo.ToolName)
        {
            return true;
        }
    }

    return false;
}

public GetCurrentMarkerName(passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);

    if (this.MarkerDropDownChange == false && passageinfo.ToolName == null)
    {
        return '';
    }

    if (this.MarkerDropDownChange == true)
    {
        if (this.temprowindex == rowindex)
        {
            if (this.RunDropDownChange == false)
            {
                // case 1 changing Marker name only
                // find based on passageinfo.PigRunName
                for (var i = 0; i < this.MarkerNamesV2.length; i++)
                {
                    if (this.MarkerNamesV2[i])
                    {
                        if (this.MarkerNamesV2[i][0] == passageinfo.PigRunName)
                        {
                            this.NewMarkerName = this.MarkerNamesV2[i][this.tempMarkerlabelindex + 1];
                            this.OldpsgInfo = passageinfo;

                            return this.MarkerNamesV2[i][this.tempMarkerlabelindex + 1];
                        }
                    }
                }
            }
            else
            {
                // case 3 changing Run Name,ToolName and marker Name
                var localindex = -1;

                for (var i = 0; i < this.MarkerNamesV2.length; i++)
                {
                    if (this.MarkerNamesV2[i])
                    {
                        if (this.RunNames[this.tempRunlabelindex].RunName == this.MarkerNamesV2[i][0])
                        {
                            localindex = i;

                            break;
                        }
                    }
                }

                this.NewMarkerName = this.MarkerNamesV2[localindex][this.tempMarkerlabelindex + 1]; 
                this.OldpsgInfo = passageinfo;

                return this.MarkerNamesV2[localindex][this.tempMarkerlabelindex + 1]; 
            }
        }
        else
        {

        }
    }
    else
    {
        if ((this.RunDropDownChange == true || this.ToolDropDownChange == true) && (this.temprowindex == rowindex))
        {
            return "";
        }
    }

    var a = -1;

    if (passageinfo.MarkerName)
    {
        a = this.MarkerNamesV2[rowindex].indexOf(passageinfo.MarkerName);
    }

    if (a > -1)
    {
        return this.MarkerNamesV2[rowindex][a];
    }

    return "";
}

public GetMarkerDropDownLength(passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);
    var currentindex = 0;

    if (this.MarkerDropDownChange == true)
    {
        if (this.RunDropDownChange == false)
        {
            for (var i = 0; i < this.MarkerNamesV2.length; i++)
            {
                if (this.MarkerNamesV2[i])
                {
                    if (this.MarkerNamesV2[i][0] == passageinfo.PigRunName)
                    {
                        return (this.MarkerNamesV2[i].length - 1);
                    }
                }
            }
        }
        else
        {
            // find based on ToolNamesV2
            var localindex = -1;

            for (var i = 0; i < this.ToolNamesV2.length; i++)
            {
                if (this.ToolNamesV2[i])
                {
                    if (this.RunNames[this.tempRunlabelindex].RunName == this.ToolNamesV2[i][0])
                    {
                        localindex = i;

                        break;
                    }
                }
            }
                
            return this.MarkerNamesV2[localindex].length - 1;
        }
    }
    else
    {
        if (this.RunDropDownChange == true && (this.temprowindex == rowindex))
        {
            // The selected Run name is this.RunNames[this.templabelindex]
            var a  = this.FindRunindexinToolNames(this.tempRunlabelindex);

            if(a != -1)
            {
                return this.MarkerNamesV2[a].length - 1;
            }
        }

        if (passageinfo.MarkerName == null)
        {
            return 0;
        }

        currentindex = this.$store.state.passageData.indexOf(passageinfo);
    }

    return (this.MarkerNamesV2[currentindex].length - 1);
}

public GetDropItemMarkerName(index: any, passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);
    var currentindex = 0;

    if (this.MarkerDropDownChange == true)
    {
        if (this.RunDropDownChange == false)
        {
            var localindex = -1;

            for (var i = 0; i < this.MarkerNamesV2.length; i++)
            {
                if (this.MarkerNamesV2[i])
                {
                    if (this.MarkerNamesV2[i][0] == passageinfo.PigRunName)
                    {
                        localindex = i;

                        break;
                    }
                }

            }

            return this.MarkerNamesV2[localindex][index + 1];
        }
        else
        {
            // find based on ToolNamesV2
            var localindex = -1;

            for (var i = 0; i < this.MarkerNamesV2.length; i++)
            {
                if (this.MarkerNamesV2[i])
                {
                    if (this.RunNames[this.tempRunlabelindex].RunName == this.MarkerNamesV2[i][0])
                    {
                        localindex = i;

                        break;
                    }
                }
            }

            return this.MarkerNamesV2[localindex][index + 1];
        }
    }
    else
    {
        if (this.RunDropDownChange == true && (this.temprowindex == rowindex))
        {
            // The selected Run name is this.RunNames[this.templabelindex]
            var a  = this.FindRunindexinToolNames(this.tempRunlabelindex);

            if(a != -1)
            {
                return this.MarkerNamesV2[a][index + 1];
            }
        }

        currentindex = this.$store.state.passageData.indexOf(passageinfo);
    }
    
    return this.MarkerNamesV2[currentindex][index + 1];
}

public tempMarkerlabelindex = -1;

public MarkerNameChange(label_index: number, passageinfo: any)
{
    var rowindex = this.$store.state.passageData.indexOf(passageinfo);

    this.MarkerDropDownChange = true;
    this.temprowindex = rowindex;
    this.tempMarkerlabelindex = label_index;
    this.UpdatePassageRunInfo = true;
    
    this.PsgInfoMsg = 'Are you sure you want to Update Passage Run Info?';

    this.OldPigRunName = (passageinfo.PigRunName + "_" +  passageinfo.RunId);
    this.OldToolName = passageinfo.ToolName;
    this.OldMarkerName = passageinfo.markerName;

    if (this.RunDropDownChange == false)
    {
        this.NewPigRunName = passageinfo.PigRunName;
        this.NewPigRunId = passageinfo.RunId;
    }
    
    if (this.ToolDropDownChange == false)
    {
        this.NewToolName = passageinfo.ToolName;
    }
}

public GetRunToolAndMarkerNames(RunName: any, RunId: any, currentindex: any)
{
    var temparray = [""];
    var markers_temparray = [""];
    temparray = [];
    markers_temparray = [];
    
    for (var i = 0; i < this.$store.state.pigRun.activePigRuns.length; i++)
    {  
        if ((this.$store.state.pigRun.activePigRuns[i].pigPath.pigRunName == RunName) && (this.$store.state.pigRun.activePigRuns[i].runId == RunId))
        {
            for (var j = 0; j < this.$store.state.pigRun.activePigRuns[i].pigPath.pigs.length; j++)
            {
                if (temparray.indexOf(this.$store.state.pigRun.activePigRuns[i].pigPath.pigs[j]) < 0)
                {
                    temparray.push(this.$store.state.pigRun.activePigRuns[i].pigPath.pigs[j]);
                }
            }

            for (var j = 0; j < this.$store.state.pigRun.activePigRuns[i].markers.length; j++)
            {
                if (markers_temparray.indexOf(this.$store.state.pigRun.activePigRuns[i].markers[j]) < 0)
                {
                    markers_temparray.push(this.$store.state.pigRun.activePigRuns[i].markers[j].name);
                }
            }
        }
    }

    for (var i = 0; i < this.$store.state.pigRun.completedPigRuns.length; i++)
    {  
        if (this.$store.state.pigRun.completedPigRuns[i].pigPath.pigRunName == RunName && (this.$store.state.pigRun.completedPigRuns[i].runId == RunId))
        {
            for (var j = 0; j < this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs.length; j++)
            {
                if (temparray.indexOf(this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs[j]) < 0)
                {
                    temparray.push(this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs[j]);
                }
            }

            for(var j = 0; j < this.$store.state.pigRun.completedPigRuns[i].markers.length; j++)
            {
                if (markers_temparray.indexOf(this.$store.state.pigRun.completedPigRuns[i].markers[j]) < 0)
                {
                    markers_temparray.push(this.$store.state.pigRun.completedPigRuns[i].markers[j].name);
                }
            }
        }
    }
    
    if (currentindex < this.ToolNamesV2.length)
    {
        temparray.unshift(RunName);
        this.ToolNamesV2[currentindex] = temparray;    // this method does not force to re-render
    }

    if (currentindex < this.MarkerNamesV2.length)
    {
        markers_temparray.unshift(RunName);
        this.MarkerNamesV2[currentindex] = markers_temparray;    // this method does not force to re-render     
    }
}

public onClearPassageInfo(passageinfo: any)
{
    // use this to clear Passage PigRun Information
    // this will trigger logic to remove passage from passage.PigRunName
    this.OldPigRunName = "None";
    this.OldToolName = "None";
    this.OldMarkerName = "None";

    this.NewPigRunName = "";
    this.NewToolName = "";
    this.NewMarkerName = "";



    if (passageinfo.PigRunName != null)
    {
        // We need to dispatch an action that will delete passage from pigrun
        this.$store.dispatch('ClearPassageRunInfo', passageinfo); 
    }
}

public onEditPassageRunInfo(passageinfo: any)
{
    // use this to clear Passage PigRun Information
    // this will trigger logic to remove passage from passage.PigRunName
    // 1- Get Passage Run Id
    this.RunIdV2 = passageinfo.RunId;

    // 2- Set Old and New Labels

    this.OldPigRunName = "None";
    this.OldToolName = "None";
    this.OldMarkerName = "None";

    this.NewPigRunName = "";
    this.NewToolName = "";
    this.NewMarkerName = "";

    this.RunNamesV2 = [];
    this.ToolNamesV3 = [];
    this.MarkerNamesV3 = [];
    this.OldpsgInfo = passageinfo;
    
    this.GetRunNamesV2(passageinfo);

    if(passageinfo.RunId != undefined && passageinfo.PigRunName != undefined && passageinfo.ToolName != undefined && passageinfo.MarkerName != undefined)
    {
        this.OldPigRunName = passageinfo.PigRunName;
        this.NewPigRunName = passageinfo.PigRunName;
        this.NewPigRunId = passageinfo.RunId;

        this.OldToolName = passageinfo.ToolName;
        this.NewToolName = passageinfo.ToolName;

        this.OldMarkerName = passageinfo.MarkerName;
        this.NewMarkerName = passageinfo.MarkerName;

                //4- Get Tool Names For RunId
        this.GetToolNamesV3();
        // 5- Get MarkerNames for RunId
        this.GetMarkerNamesV3();
    }
    
    this.UpdatePassageRunInfoV2 = true;
}
public RunIdV2:any;
public RunNamesV2 =  [] as any;
public GetRunNamesV2(passageinfo: any)
{
    for (var i = 0; i < this.$store.state.pigRun.activePigRuns.length; i++)
    {
        var RunId = new Array(0),RunName = new Array(0);
        var psgdate: Date = new Date(passageinfo.passageTimeStamp + ' GMT');
        var launchdate: Date = this.$store.state.pigRun.activePigRuns[i].launchTime;
        if(psgdate>this.$store.state.pigRun.activePigRuns[i].launchTime)
        {       
            RunId = this.$store.state.pigRun.activePigRuns[i].runId;
            RunName = this.$store.state.pigRun.activePigRuns[i].pigPath.pigRunName;
            
            var input = {RunId,RunName}
            this.RunNamesV2.push(input);
        }
    }

    for (var i = 0; i < this.$store.state.pigRun.completedPigRuns.length; i++)
    {
        var RunId = new Array(0),RunName = new Array(0);
        var psgdate: Date = new Date(passageinfo.passageTimeStamp + ' GMT');
        var launchdate: Date = this.$store.state.pigRun.completedPigRuns[i].launchTime;
        var receivedate: Date = this.$store.state.pigRun.completedPigRuns[i].recieveTime;
        if(psgdate>this.$store.state.pigRun.completedPigRuns[i].launchTime && psgdate<this.$store.state.pigRun.completedPigRuns[i].recieveTime)
        {
            RunId = this.$store.state.pigRun.completedPigRuns[i].runId;
            RunName = this.$store.state.pigRun.completedPigRuns[i].pigPath.pigRunName;
        
            var input = {RunId,RunName}
            this.RunNamesV2.push(input);
        }
    }
}

public ToolNamesV3 =  [] as any;
public GetToolNamesV3()
{
    // Need check if RunId exist in activeRuns or completed Runs
    // If it exist then we need to get all tool name

    var valObj = this.$store.state.pigRun.activePigRuns.filter((pigRun: any) => pigRun.runId == this.RunIdV2 );
    if(valObj.length != 1)
    {
        valObj = this.$store.state.pigRun.completedPigRuns.filter((pigRun: any) => pigRun.runId == this.RunIdV2 );
        if(valObj.length != 1)
        {
            return;
        }
    }

    valObj[0].pigPath.pigs.forEach((pig:any) => {
        this.ToolNamesV3.push(pig);
    });

}
public MarkerNamesV3 =  [] as any;
public GetMarkerNamesV3()
{
    debugger
    // Need to look in for all tools that exist in RunId
    var valObj = this.$store.state.pigRun.activePigRuns.filter((pigRun: any) => pigRun.runId == this.RunIdV2 );
    if(valObj.length != 1)
    {
        valObj = this.$store.state.pigRun.completedPigRuns.filter((pigRun: any) => pigRun.runId == this.RunIdV2 );
        if(valObj.length != 1)
        {
            return;
        }
    }

    valObj[0].markers.forEach((marker:any) => {
        //if(marker.state == "TRACK")
        //{
        //    this.MarkerNamesV3.push(marker.name);
        //}

        if(marker.description == "AGM")
        {
            this.MarkerNamesV3.push(marker.name);
        }
        else if(marker.state == "TRACK")
        {
            this.MarkerNamesV3.push(marker.name);
        }
    });
}


public PigRunNameChangeV2(index:number)
{
    this.NewPigRunName = this.RunNamesV2[index].RunName;
    this.RunIdV2 = this.RunNamesV2[index].RunId;
    this.NewToolName = "";
    this.NewMarkerName = "";
    this.ToolNamesV3 =  [];
    this.MarkerNamesV3 = [];
    this.GetToolNamesV3();
    this.GetMarkerNamesV3();
}


public ToolNameChangeV2(index:number)
{
    this.NewToolName = this.ToolNamesV3[index];
    this.NewMarkerName = "";
    this.MarkerNamesV3 = [];
    this.GetMarkerNamesV3();
}



public MarkerNameChangeV2(index:number)
{
    this.NewMarkerName = this.MarkerNamesV3[index];
}

public onUpdatePsgInfoV2()
{
    console.log('Updating Passage Info');
    var a = [];

    a[0] = this.OldpsgInfo;
    a[1] = this.RunIdV2;
    a[2] = this.NewPigRunName;
    a[3] = this.NewToolName;
    a[4] = this.NewMarkerName;

    this.$store.dispatch('EditPassageRunInfo', a); 
}



// Handle Tool Name Dropdown
public ToolNames =  [""];
public SelectedToolName = "";
public tempSelectedToolName = "";
public ToolNamesV2 =  [[""]];
public tempSelectedMarkerName = "";
public MarkerNamesV2 =  [[""]];
public tempToolList = [""];

public GetToolNames()
{
    this.ToolNames = [];

    for (var i = 0; i < this.$store.state.pigRun.activePigRuns.length; i++)
    {
        for(var j = 0; j < this.$store.state.pigRun.activePigRuns[i].pigPath.pigs.length; j++)
        {
            if (this.ToolNames.indexOf(this.$store.state.pigRun.activePigRuns[i].pigPath.pigs[j]) < 0)
            {
                this.ToolNames.push(this.$store.state.pigRun.activePigRuns[i].pigPath.pigs[j]);
            }
        }
    }

    for(var i = 0; i < this.$store.state.pigRun.completedPigRuns.length; i++)
    {
        for(var j = 0; j < this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs.length; j++)
        {
            if (this.ToolNames.indexOf(this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs[j]) < 0)
            {
                this.ToolNames.push(this.$store.state.pigRun.completedPigRuns[i].pigPath.pigs[j]);
            }
        }
    }
}

public MarkerNames =  [""];
public SelectedMarkerName = "";
public tempMarkerList = [""];

public GetMarkerNames()
{
    this.MarkerNames = [];

    for (var i = 0; i < this.$store.state.pigRun.activePigRuns.length; i++)
    {
        for(var j = 0; j < this.$store.state.pigRun.activePigRuns[i].markers.length; j++)
        {
            if (this.MarkerNames.indexOf(this.$store.state.pigRun.activePigRuns[i].markers[j].name) < 0)
            {
                this.MarkerNames.push(this.$store.state.pigRun.activePigRuns[i].markers[j].name);
            }
        }
    }

    for(var i = 0; i < this.$store.state.pigRun.completedPigRuns.length; i++)
    {
        for(var j = 0; j < this.$store.state.pigRun.completedPigRuns[i].markers.length; j++)
        {
            if (this.MarkerNames.indexOf(this.$store.state.pigRun.completedPigRuns[i].markers[j].name) < 0)
            {
                this.MarkerNames.push(this.$store.state.pigRun.completedPigRuns[i].markers[j].name);
            }
        }
    }
}

public GetCurrentPigRunNameV2()
{
    if(this.RunIdV2 == undefined)
    {
        return "";
    }
    return (this.NewPigRunName + "_" + this.RunIdV2);
}


// ------------------------------------------------------------------------------------------------
// Passage section - end
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
// Heartbeat section - start
// ------------------------------------------------------------------------------------------------

// Computed property - bound to the heartbeat q-table.
get heartbeatColumnsToDisplay(): any
{
    let heartbeatColumns = [
        { name: 'heartbeatTimeStamp', label: 'Timestamp', field: 'heartbeatTimeStamp', sortable: true, required: true },
        { name: 'satelliteCount', label: 'Satellite Count', field: 'satelliteCount', sortable: false, required: true },
        { name: 'lat', label: 'Latitude', field: 'lat', sortable: false, required: true },
        { name: 'lon', label: 'Longitude', field: 'lon', sortable: false, required: true },
        { name: 'altitude', label: 'Altitude', field: 'altitude', sortable: false, required: true },
        { name: 'signalStrength', label: 'Signal Strength', field: 'signalStrength', sortable: false, format: (val: any, row: any) => (row.signalStrength * -1) },
        { name: 'battery', label: 'Battery', field: 'battery', sortable: false, required: true },
        { name: 'errorFlag', label: 'Error Flag', field: 'errorFlag', sortable: false, required: true },
        { name: 'numberOfflineFiles', label: '# Offline', field: 'numberOfflineFiles', sortable: false, required: true },
        { name: 'numberOnlineFiles', label: '# Online', field: 'numberOnlineFiles', sortable: false, required: true },
        { name: 'numberIridiumOfflineFiles', label: '# IROffline', field: 'numberIridiumOfflineFiles', sortable: false, required: true },
        { name: 'powerSupplyState', label: 'Power Supply', field: 'powerSupplyState', sortable: false, format: (val: any, row: any) => row.powerSupplyState == 0 ? 'Battery' : 'External' },
        { name: 'geophoneConnected', label: 'Geo?', field: 'geophoneConnected', sortable: false, required: true },
        { name: 'externalAntennaConnected', label: 'Ext Ant?', field: 'externalAntennaConnected', sortable: false, required: true },
        { name: 'internalAntennaFoldState', label: 'Fold Ant?', field: 'internalAntennaFoldState', sortable: false, required: true, format: (val: any, row: any) => row.internalAntennaFoldState == 0 ? 'Folded in' : 'Folded out' }
    ];

    let heartbeatColumnsLegacy = [
        { name: 'heartbeatTimeStamp', label: 'Timestamp', field: 'heartbeatTimeStamp', sortable: true, required: true },
        { name: 'satelliteCount', label: 'Satellite Count', field: 'satelliteCount', sortable: false, required: true },
        { name: 'lat', label: 'Latitude', field: 'lat', sortable: false, required: true },
        { name: 'lon', label: 'Longitude', field: 'lon', sortable: false, required: true },
        { name: 'signalStrength', label: 'Signal Strength', field: 'signalStrength', sortable: false, format: (val: any, row: any) => (row.signalStrength * -1) },
        { name: 'battery', label: 'Battery', field: 'battery', sortable: false, required: true },
        { name: 'errorFlag', label: 'Error Flag', field: 'errorFlag', sortable: false, required: true },
        { name: 'numberOfflineFiles', label: '# Offline', field: 'numberOfflineFiles', sortable: false, required: true }
    ];

    if (this.device.firmwareVersion)
    {
        if (this.device.firmwareVersion > 2.4)
        {
            return heartbeatColumns;
        }
        else
        {
            return heartbeatColumnsLegacy;
        }
    }
    else
    {
        return heartbeatColumnsLegacy;
    }
}

// Computed property - bound to the heartbeat q-table.
get heartbeatData(): any
{
    return this.$store.state.heartbeatData;
}

// Computed property - bound to the heartbeat q-table.
get heartbeatPagination() : any
{
    return this.$store.state.heartbeatDataPagination;
}

// Computed property setter.
//
// Note: This setter is not used. However, if it is not specified here the browser console will display red warning text that the
// heartbeatPagination getter has no corresponding setter.
set heartbeatPagination(heartbeatPagination: any)
{
}

// onRequestHeartbeat.
//
// Implementation of the @request QTable event handler for heartbeats.
//
// Note: When rowsPerPage is changed in the QTable using the GUI, page gets reset to 1.
public onRequestHeartbeat(props: any)
{
    // Create the action payload.
    let actionPayload: any = { uid: this.device.uid, page: props.pagination.page, rowsPerPage: props.pagination.rowsPerPage, sortBy: props.pagination.sortBy, descending: props.pagination.descending };

    // Invoke the action.
    this.$store.dispatch('getHeartbeatHistoricData', actionPayload);

    // Note: The pagination properties must be reset each time this event handler is invoked. The relevant values
    //       are passed to the getHeartbeatHistoricData action, then passed to the SET_HISTORIC_DATA mutation
    //       defined in index.ts. The SET_HISTORIC_DATA mutation then sets the heartbeatDataPagination state member.
}

// ------------------------------------------------------------------------------------------------
// Heartbeat section - end
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
// DeviceInformationResponse section - start
// ------------------------------------------------------------------------------------------------

// Computed property - bound to the deviceInfo q-table.
get deviceInformationResponseColumnsToDisplay() : any
{
    let deviceInformationResponseColumns = [
        { name: 'timeStampAck', label: 'Timestamp', field: 'timeStampAck', sortable: true, required: false },
        { name: 'firmwareMajorVersion', label: 'Firmware Major Version', field: 'firmwareMajorVersion', sortable: false, required: false },
        { name: 'firmwareMinorVersion', label: 'Firmware Minor Version', field: 'firmwareMinorVersion', sortable: false, required: false },
        { name: 'protocolVersion', label: 'Protocol Version', field: 'protocolVersion', sortable: false, required: false },
        { name: 'hardwareMajorVersion', label: 'Hardware Major Version', field: 'hardwareMajorVersion', sortable: false, required: false },
        { name: 'hardwareMinorVersion', label: 'Hardware Minor Version', field: 'hardwareMinorVersion', sortable: false, required: false },
        { name: 'productNumber', label: 'Product Number', field: 'productNumber', sortable: false, required: false }
    ];

    // Note:
    //
    // A Date column can be formatted if needed. For example:
    // {name: 'timeStampAck', label: 'Timestamp', field: 'timeStampAck', sortable: false, required: true, format: (val: string|number|Date|undefined) => date.formatDate(val, 'MMM-DD-YYYY HH:mm:ss') }

    return deviceInformationResponseColumns;
}

// Computed property - bound to the deviceInfo q-table.
get deviceInformationResponseData() : any
{
    return this.$store.state.deviceInformationResponseData;
}

// Computed property - bound to the deviceInfo q-table.
get deviceInformationResponsePagination() : any
{
    return this.$store.state.deviceInformationResponseDataPagination;
}

// Property setter.
//
// Note: This setter is not used. However, if it is not specified here the browser console will display red warning text that the
// deviceInformationResponsePagination getter has no corresponding setter.
set deviceInformationResponsePagination(deviceInformationResponsePagination: any)
{
}

// onRequestDeviceInformationResponse.
//
// Implementation of the @request QTable event handler for device information response.
//
// Note: When rowsPerPage is changed in the QTable using the GUI, page gets reset to 1.
public onRequestDeviceInformationResponse(props: any)
{
    // Create the action payload.
    let actionPayload: any = { uid: this.device.uid, page: props.pagination.page, rowsPerPage: props.pagination.rowsPerPage, sortBy: props.pagination.sortBy, descending: props.pagination.descending };

    // Invoke the action.
    this.$store.dispatch('getDeviceInformationResponseHistoricData', actionPayload);

    // Note: The pagination properties must be reset each time this event handler is invoked. The relevant values
    //       are passed to the getDeviceInformationResponseHistoricData action, then passed to the SET_HISTORIC_DATA mutation
    //       defined in index.ts. The SET_HISTORIC_DATA mutation then sets the deviceInformationResponseDataPagination state member.
}

// ------------------------------------------------------------------------------------------------
// DeviceInformationResponse section - end
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
// Control Settings Response section - start
// ------------------------------------------------------------------------------------------------

// Computed property getter - bound to the controlResponse q-table.
get controlSettingsResponseColumnsToDisplay(): any
{
    let controlSettingsResponseColumns = [
        { name: 'timeStampAck', label: 'Timestamp', field: 'timeStampAck', sortable: true, required: true },
        { name: 'frequency0Setting', label: 'Frequency 0', field: 'frequency0Setting', sortable: false, format: (val: any, row: any) => this.enableText(row.enable0) + ' / ' + this.getFrequencySettingText(row.frequency0Setting) + ' / ' + this.sensitivityText(row.sensitivity0) },
        { name: 'frequency1Setting', label: 'Frequency 1', field: 'frequency1Setting', sortable: false, format: (val: any, row: any) => this.enableText(row.enable1) + ' / ' + this.getFrequencySettingText(row.frequency1Setting) + ' / ' + this.sensitivityText(row.sensitivity1) },
        { name: 'frequency2Setting', label: 'Frequency 2', field: 'frequency2Setting', sortable: false, format: (val: any, row: any) => this.enableText(row.enable2) + ' / ' + this.getFrequencySettingText(row.frequency2Setting) + ' / ' + this.sensitivityText(row.sensitivity2) },
        { name: 'frequency3Setting', label: 'Frequency 3', field: 'frequency3Setting', sortable: false, format: (val: any, row: any) => this.enableText(row.enable3) + ' / ' + this.getFrequencySettingText(row.frequency3Setting) + ' / ' + this.sensitivityText(row.sensitivity3) },
        { name: 'recordMode', label: 'Record Mode', field: 'recordMode', sortable: false, format: (val: any, row: any) => this.device.isIridium ? RecordModeIridium.intToString(row.recordMode) : RecordMode.intToString(row.recordMode) },
        { name: 'powerMode', label: 'Power Mode', field: 'powerMode', sortable: false },
        { name: 'connectionSchedule', label: 'Cnx Schedule', field: 'connectionSchedule', sortable: false, format: (val: any, row: any) => {if(row.powerMode == 'Active'){return "-";}else{return row.connectionSchedule;}}},
        { name: 'GeoGain', label: 'Geo Gain', field: 'geoMode', sortable: false, format: (val: any, row: any) => this.getGeoGainText(row.geoMode) },
        { name: 'Fold Ant', label: 'Fold Ant', field: 'Fold Ant', sortable: false, format: (val: any, row: any) => this.getFoldAntText(row.foldAntDetectorState) },
        { name: 'Sat Auto Dwn', label: 'Sat Auto Dwn', field: 'offlineDownloadState', sortable: false, format: (val: any, row: any) => this.getIROFFDwnText(row.offlineDownloadState) },
        { name: 'otherSettings', label: 'Other Settings', field: 'otherSettings', sortable: false, format: (val: any, row: any) => this.otherSettingsText(row) }
    ];

    return controlSettingsResponseColumns;
}

// Computed property - bound to the controlResponse q-table.
get controlSettingsResponseData(): any
{
    return this.$store.state.controlSettingsResponseData;
}

// Computed property - bound to the controlResponse q-table.
get controlSettingsResponsePagination() : any
{
    return this.$store.state.controlSettingsResponseDataPagination;
}

// Computed property setter.
//
// Note: This setter is not used. However, if it is not specified here the browser console will display red warning text that the
// controlSettingsResponsePagination getter has no corresponding setter.
set controlSettingsResponsePagination(controlSettingsResponsePagination: any)
{
}

// onRequestControlSettingsResponse.
//
// Implementation of the @request QTable event handler for control settings response.
//
// Note: When rowsPerPage is changed in the QTable using the GUI, page gets reset to 1.
public onRequestControlSettingsResponse(props: any)
{
    // Create the action payload.
    let actionPayload: any = { uid: this.device.uid, page: props.pagination.page, rowsPerPage: props.pagination.rowsPerPage, sortBy: props.pagination.sortBy, descending: props.pagination.descending };

    // Invoke the action.
    this.$store.dispatch('getControlSettingsResponseHistoricData', actionPayload);

    // Note: The pagination properties must be reset each time this event handler is invoked. The relevant values
    //       are passed to the getControlSettingsResponseHistoricData action, then passed to the SET_HISTORIC_DATA mutation
    //       defined in index.ts. The SET_HISTORIC_DATA mutation then sets the controlSettingsResponseDataPagination state member.
}

// enableText
//
// A custom formatting method applied to each row; called from the control settings response columns definition.
public enableText(isEnabled: boolean)
{
    if (isEnabled)
    {
        return 'on';
    }

    return 'off';
}

// getFrequencySettingText.
//
// A custom formatting method applied to each row; called from the control settings response columns definition.
public getFrequencySettingText(setting: number)
{
    if (setting === 0)
    {
        return 'Mag';
    }

    return setting + 'Hz';
}

// sensitivityText.
//
// A custom formatting method applied to each row; called from the control settings response columns definition.
public sensitivityText(sensitivity: number)
{
    if (sensitivity < 0 || sensitivity > 6)
    {
        return '';
    }

    let allSensitivitesAsText: string[] = ['UL', 'VL', 'L', 'M', 'H', 'VH', 'UH'];

    return allSensitivitesAsText[sensitivity];
}

// getGeoGainText
//
// A custom formatting method applied to each row; called from the control settings response columns definition.
public getGeoGainText(setting: number)
{
    if (setting < 0 || setting > 3)
    {
        return '';
    }

    let allGeoGainText: string[] = ['Low', 'Medium', 'High', 'Very High'];

    return allGeoGainText[setting];
}

// getFoldAntText
//
// A custom formatting method applied to each row; called from the control settings response columns definition.
public getFoldAntText(setting: number)
{
    if (setting === 0)
    {
        return 'Disabled';
    }

    if (setting === 1)
    {
        return 'Enabled';
    }
}

// getIROFFDwnText
//
// A custom formatting method applied to each row; called from the control settings reponse columns definition.
public getIROFFDwnText(setting: number)
{
    if (setting === 0)
    {
        return 'Disabled';
    }

    if (setting === 1)
    {
        return 'Enabled';
    }
}

// otherSettingsText
//
// A custom formatting method applied to each row; called from the control settings response columns definition.
public otherSettingsText(row: any)
{
    // For the Control Settings tab we will rename Stream Enable and push it to be the last column.
    //
    // Other Settings will basically be a field where we can display all the complimentary settings (streaming state, psg state, psg size)
    // In the case of cellular, the item in Other Settings will be either Streaming ON or OFF.
    //
    // In the case of iridium, the content will be PSG Size and PSG State values.
    if (row.psgState !== undefined && row.psgSize !== undefined)
    {
        return 'Passages ' + PassageSize.byIdx(row.psgSize) + ' / '  + PassageState.byIdx(row.psgState);
    }
    else
    {
        if (row.streamEnable)
        {
            return 'Streaming ' + row.streamEnable;
        }
    }

    return '';
}

// ------------------------------------------------------------------------------------------------
// Control Settings Response section - end
// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
// DeviceHistory section - start
// ------------------------------------------------------------------------------------------------
public closeDialog()
{
    console.log('DeviceHistory.vue. closeDialog. Enter method.');

    this.historyDialog = false;
}

// Computed property getter.
get map()
{
    return this.$store.state.map;
}

// Computed property getter.
//
// Method used to get the user role.
public UserRole:String = '';
public getuser()
{
    this.UserRole = this.$store.state.user.role

    return this.UserRole;
}

// mounted.
//
// Vue lifecycle hook called when this component is added to the document object model.
public mounted()
{
    console.log('DeviceHistory.vue. mounted.');
}

// onRefreshTab.
//
// Method used to refresh the data associated with the selected tab.
public onRefreshTab()
{
    console.log('DeviceHistory.vue. onRefreshTab. Enter method.');

    // If the passage tab has been selected.
    if (this.tab == 'passage')
    {
        console.log('DeviceHistory.vue. onRefreshTab. Refreshing the passage tab.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'passageTimeStamp', descending: true };

        this.$store.dispatch('getPassageHistoricData', actionPayload);
    }

    // If the heartbeat tab has been selected.
    if (this.tab == 'heartbeat')
    {
        console.log('DeviceHistory.vue. onRefreshTab. Refreshing the heartbeat tab.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'heartbeatTimeStamp', descending: true };

        this.$store.dispatch('getHeartbeatHistoricData', actionPayload);
    }

    // If the device info tab has been selected.
    if (this.tab == 'deviceInfo')
    {
        console.log('DeviceHistory.vue. onRefreshTab. Refreshing the device info tab.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'timeStampAck', descending: true };

        this.$store.dispatch('getDeviceInformationResponseHistoricData', actionPayload);
    }

    // If the settings tab has been selected.
    if (this.tab == 'controlResponse')
    {
        console.log('DeviceHistory.vue. onRefreshTab. Refreshing the settings tab.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'timeStampAck', descending: true };

        this.$store.dispatch('getControlSettingsResponseHistoricData', actionPayload);
    }
}

public showDialog()
{
    console.log('DeviceHistory.vue. showDialog. Enter method.');
    this.unfilteredData = [];
    this.filterVisible = false;
    //let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'passageTimeStamp', descending: true };

    //this.$store.dispatch('getPassageHistoricData', actionPayload);
    let uid: number = this.device.uid;

    // If the passage tab has been selected.
    if (this.tab == 'passage')
    {
        console.log('DeviceHistory.vue. tabChanged. Passage tab selected.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'passageTimeStamp', descending: true };

        this.$store.dispatch('getPassageHistoricData', actionPayload);
    }

    // If the heartbeat tab has been selected.
    if (this.tab == 'heartbeat')
    {
        console.log('DeviceHistory.vue. tabChanged. Heartbeat tab selected.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'heartbeatTimeStamp', descending: true };

        this.$store.dispatch('getHeartbeatHistoricData', actionPayload);
    }

    // If the device info tab has been selected.
    if (this.tab == 'deviceInfo')
    {
        console.log('DeviceHistory.vue. tabChanged. Device info tab selected.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'timeStampAck', descending: true };

        this.$store.dispatch('getDeviceInformationResponseHistoricData', actionPayload);
    }

    // If the settings tab has been selected.
    if (this.tab == 'controlResponse')
    {
        console.log('DeviceHistory.vue. tabChanged. Settings tab selected.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'timeStampAck', descending: true };

        this.$store.dispatch('getControlSettingsResponseHistoricData', actionPayload);
    }



    this.historyDialog = true;
}

public tabChanged()
{
    console.log('DeviceHistory.vue. tabChanged. Enter method.');
    this.unfilteredData = [];
    let uid: number = this.device.uid;

    // If the passage tab has been selected.
    if (this.tab == 'passage')
    {
        console.log('DeviceHistory.vue. tabChanged. Passage tab selected.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'passageTimeStamp', descending: true };

        this.$store.dispatch('getPassageHistoricData', actionPayload);
    }

    // If the heartbeat tab has been selected.
    if (this.tab == 'heartbeat')
    {
        console.log('DeviceHistory.vue. tabChanged. Heartbeat tab selected.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'heartbeatTimeStamp', descending: true };

        this.$store.dispatch('getHeartbeatHistoricData', actionPayload);
    }

    // If the device info tab has been selected.
    if (this.tab == 'deviceInfo')
    {
        console.log('DeviceHistory.vue. tabChanged. Device info tab selected.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'timeStampAck', descending: true };

        this.$store.dispatch('getDeviceInformationResponseHistoricData', actionPayload);
    }

    // If the settings tab has been selected.
    if (this.tab == 'controlResponse')
    {
        console.log('DeviceHistory.vue. tabChanged. Settings tab selected.');

        let actionPayload: any = { uid: this.device.uid, page: 1, rowsPerPage: 15, sortBy: 'timeStampAck', descending: true };

        this.$store.dispatch('getControlSettingsResponseHistoricData', actionPayload);
    }
}

// ------------------------------------------------------------------------------------------------
// DeviceHistory section - end
// ------------------------------------------------------------------------------------------------

// The code below is largely related to filtering and exporting and needs to be modified when these features are
// re-enabled.

    public historyDialog = false;
    public confirmDeleteAllDialog = false;
    public confirmDeleteDialog = false;
    public deleteIds: number[] = [];
    public tab: string = 'passage';

    public filteredData: any[] = [];
    public unfilteredData: any[] = [];
    public filterFromDate: Date = new Date('');
    public filterToDate: Date = new Date('');
    public filterVisible = false;

    /*public passageColumnsExport = [
        'passageId',
        'uid',
        'passageTimeStamp',
        'passageTimeEndOfSnip',
        'detectionCount',
        'isVerified',isBluetooth
        'passageDetectedFrequency',
        // 'packetComplexInformation',
    ];*/

    public passageColumnsExport = [
        'passageId',
        'uid',
        'passageTimeStamp',
        'passageTimeEndOfSnip',
        'detectionCount',
        'isVerified',
        'passageDetectedFrequency',
        'packetComplexInformation',
        'isOFFLINE',
        'isBluetooth',
        'Lat',
        'Lon',
        'geoId',
        'mAGId',
    ];

    public heartbeatColumnsExport = [
        'uid',
        'TimeStamp',
        'Satellite Count',
        'Latitude',
        'Longitude',
        'Altitude',
        'Signal Strength',
        'Battery (mV)',
        'Error Flag',
        '# Offline',
        '# Online',
        '# IROffline',
        'Power Supply',
        'Folded out?',
        'Geo?',
        'ExtAnt?'

        // 'ONLINE',
        // 'IROFFLINE',
        // 'Power Supply',
        // 'Geo',
        // 'Ext Ant',
        // 'Folded out'
    ];


   /*public deviceInfoColumnsExport = [
        'uid',
        'protocolVersion',
        'firmwareMajorVersion',
        'firmwareMinorVersion',
        'productNumber',
        'hardwareRevision',
        'unitNumber',
        'timeStampSent',
        'timeStampAck',
    ];*/


    public deviceInfoColumnsExport = [
        'uid',
        'protocolVersion',
        'firmwareMajorVersion',
        'firmwareMinorVersion',
        'productNumber',
        'hardware Major',
        'hardware Minor',
        'unitNumber',
        'timeStampSent',
        'timeStampAck',
    ];

    /*public controlResponseColumnsExport = [
        'uid',
        'sensitivity0',
        'sensitivity1',
        'sensitivity2',
        'sensitivity3',
        'enable0',
        'enable1',
        'enable2',
        'enable3',
        'frequency0Setting',
        'frequency1Setting',
        'frequency2Setting',
        'frequency3Setting',
        'recordMode',
        // 'streamEnable',
        'powerMode',
        // 'gpsMode',
        'connectionSchedule',
        'timeStampSent',
        'timeStampAck',
        'otherSettings',
    ];*/

    public controlResponseColumnsExport = [
        'uid',
        'sensitivity0',
        'sensitivity1',
        'sensitivity2',
        'sensitivity3',
        'frequency0Setting',
        'frequency1Setting',
        'frequency2Setting',
        'frequency3Setting',
        'enable0',
        'enable1',
        'enable2',
        'enable3',

        'recordMode',
        // 'streamEnable',
        'otherSettings',
        'powerMode',
        'gpsMode',
        'connectionSchedule',
        'timeStampSent',
        'timeStampAck',
        'geo mode',
        'sat autodwn',
        'foldant'
        
    ];

    public gpsFixColumns = [
        { name: 'timeStampSent', label: 'Timestamp Sent', field: 'timeStampSent', sortable: true, required: true},
        { name: 'timeStampAck', label: 'Timestamp Acknowledged', field: 'timeStampAck', sortable: true},
        { name: 'satelliteCount', label: 'Satellite Count', field: 'satelliteCount', sortable: true},
        { name: 'lat', label: 'Latitude', field: 'lat', sortable: true},
        { name: 'lon', label: 'Longitude', field: 'lon', sortable: true},
        { name: 'altitude', label: 'Altitude', field: 'altitude', sortable: true},
    ];

    public gpsFixColumnsExport = [
        'uid',
        'satelliteCount',
        'lat',
        'lon',
        'altitude',
        'timeStampSent',
        'timeStampAck',
    ];

    public errorColumns = [
        { name: 'errorTimeStamp', label: 'Timestamp', field: 'errorTimeStamp', sortable: true, required: true},
        { name: 'errorId', label: 'Error ID', field: 'errorId', sortable: true},
    ];

    public errorColumnsExport = [
        'uid',
        'errorId',
        'errorTimeStamp',
    ];

    public offlineColumns = [
        { name: 'offlineTimeStamp', label: 'Timestamp', field: 'offlineTimeStamp', sortable: true, required: true},
        { name: 'count', label: 'Count', field: 'count', sortable: true},
        { name: 'frequency', label: 'Frequency', field: 'frequency', sortable: true},
        { name: 'timeStampSent', label: 'Timestamp Sent', field: 'timeStampSent', sortable: true, required: true},
        { name: 'timeStampAck', label: 'Timestamp Acknowledged', field: 'timeStampAck', sortable: true},
    ];

    public offlineColumnsExport = [
        'uid',
        'offlineTimeStamp',
        'count',
        'frequency',
        'timeStampSent',
        'timeStampAck',
    ];

    /*
    get gpsFixData(): any {
        return this.$store.state.gpsFixData;
    }

    get errorData(): any {
        return this.$store.state.errorData;
    }

    get offlineData(): any {
        return this.$store.state.offlineData;
    }
    */

    get device(): Device {
        return this.$store.state.selectedDevice;
    }

    public unsubscribe = this.$store.subscribeAction((action, state) => {
        if (action.type === 'historicDataShow') {
            this.showDialog();
        }
        if (action.type === 'historicDataClose') {
            this.closeDialog();
        }
        if (action.type === 'passageExportCsv') {
            this.onPassageExport();
        }
        if(action.type === 'historicDataRefresh')
        {
            this.onRefreshTab();
        }
    });

    // Mitch - this does not appear to be used.
    //         commented on January 17, 2022
    /*
    public connectionScheduleText(connectionSchedule: string) {
       
       if (this.device.isIridium) {
            // iriduium needs to be shifted by 1 to account for the spec mismatch
            if(this.device.firmwareVersion && (this.device.firmwareVersion > 2.4))
            {
               console.log(connectionSchedule);
                return connectionSchedule;
            }
            
            if (connectionSchedule === 'Every30mins') {
                return '';
            }
            if (connectionSchedule === 'Every1hrs') {
                return 'Every30mins';
            }
            if (connectionSchedule === 'Every2hrs') {
                return 'Every1hrs';
            }
            if (connectionSchedule === 'Every4hrs') {
                return 'Every2hrs';
            }
            if (connectionSchedule === 'Every8hrs') {
                return 'Every4hrs';
            }
            if (connectionSchedule === 'Every24hrs') {
                return 'Every8hrs';
            }
            if (connectionSchedule === 'Every72hrs') {
                return 'Every24hrs';
            }
            if (connectionSchedule === 'Unknown') {
                return 'Every72hrs';
            }
        }

        return connectionSchedule;
    }
    */

    public onDelete() {
        this.confirmDeleteAllDialog = true;
    }

    public onDeleteAllConfirm() {
        console.log('DeviceHistory: onDeleteAllConfirm()');
        this.$store.dispatch('deleteHistory', this.tab);
    }

    public onToggleFilter() {
        this.filterVisible = !this.filterVisible;
    }

    public filterData() {
        console.log('DeviceHistory: filterData()');
        // the tabledata and field to apply the filters depends on the current tab

        const tableData = {
            // passage: this.passageData, Mitch - filtering related, comment out for now.
            passage: this.passageData,
            heartbeat: this.heartbeatData
            // deviceInfo: this.deviceInfoData, Mitch - filtering related, comment out for now.
            // controlResponse: this.controlResponseData, Mitch - filtering related, comment out for now.
            // gpsFix: this.gpsFixData, Mitch - filtering related, comment out for now.
            // error: this.errorData, Mitch - filtering related, comment out for now.
            // offline: this.offlineData, Mitch - filtering related, comment out for now.
        };

        const filterField = {
            passage: 'passageTimeStamp',
            heartbeat: 'heartbeatTimeStamp',
            deviceInfo: 'timeStampAck',
            controlResponse: 'timeStampAck',
            gpsFix: 'timeStampAck',
            error: 'errorTimeStamp',
            offline: 'offlineTimeStamp',
        };

        if (this.filterFromDate.valueOf() && this.filterToDate.valueOf()) {
            // NOTE: the date picker isn't createing true Date types
            const filterFromDateReal: Date = new Date(this.filterFromDate);

            var filterToDateReal: Date = new Date(this.filterToDate);
            filterToDateReal.setHours(23,59,59);


            this.filteredData = [];
            // check for previous filter
            if (this.unfilteredData.length > 0) {
                tableData[this.tab] = this.unfilteredData;
            }
            tableData[this.tab].forEach((row: any) => {
                // TODO this should be a date type eventually
                const rowDate = new Date(row[filterField[this.tab]]);
                // if (rowDate >= filterFromDateReal && rowDate <= filterToDateReal) {
                if (date.isBetweenDates(rowDate, filterFromDateReal, filterToDateReal, { inclusiveFrom: true, inclusiveTo: true })) {
                    this.filteredData.push(row);
                }
            });
            this.unfilteredData = tableData[this.tab];
            this.$store.dispatch('setFilteredHistory', {type: this.tab, data: this.filteredData});
        }
    }

    public filterClear() {
        console.log('DeviceHistory: filterClear()');
        this.filterFromDate = new Date();
        this.filterToDate = new Date();
        this.$store.dispatch('setFilteredHistory', {type: this.tab, data: this.unfilteredData});
        this.filteredData = [];
    }

    public onPassageExport() {
        // after passagge data has been retireved/selected
        const csvData = [];
        let dateOffset = 64 * 500;
        const passageEndTime = this.$store.state.selectedPassageEndTimestamp;
        const us = passageEndTime.slice(-3);
        const endDate = new Date(passageEndTime);
        for (let i = 0; i < 500; i++) {
            csvData.push({
                date: date.formatDate(endDate.getTime() - dateOffset, 'YYYY-MM-DD HH:mm:ss.SSS' + us),
                value: this.$store.state.selectedPassageData[i],
            });
            dateOffset -= 64;
        }

        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += [
            ['Time', 'Hz'],
            ...csvData.map((item: any) => Object.values(item).join(',')),
        ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');

        const data = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', 'passage_' + this.device.uid + '_' + this.$store.state.selectedPassageTimestamp + '.csv');
        link.click();
    }

    // onCsvExport.
    //
    // Function used to export the historic data for a particular tab.
    public onCsvExport()
    {
        let csvData: any;
        let csvContent = 'data:text/csv;charset=utf-8,';

        if (this.tab === 'heartbeat')
        {
            csvData = this.heartbeatData;

            if(csvData.length == 0)
            {
                return;
            }

            csvContent += 'UID' + ',' + 'Time Stamp' + ',' + 'Sat Count' + ',' + 'Latitude' + ',' + 'Longitude' + ',' + 'Altitude (m)' + ',' + 'Signal Strength' + ',' + 'Battery' + ',' + 'Error Flag' + ',' + 'OFFLINE' + ',' + 'ONLINE' + ','+ 'IROFFLINE' + ',' + 'Power Supply' + ',' + 'Geophone Connected?' + ',' + 'Ext Antenna Connected?' + ',' + 'Fold Ant?' + '\n';
        
            for (let i = 0; i < this.heartbeatData.length; i++)
            {
                csvData[i].heartbeatTimeStamp = " " + csvData[i].heartbeatTimeStamp.toString();
                
                csvContent += csvData[i].uid + ',' + csvData[i].heartbeatTimeStamp + ',' + csvData[i].satelliteCount + ',' + csvData[i].lat + ',' + csvData[i].lon + ',' + csvData[i].altitude + ',' + csvData[i].signalStrength + ',' + csvData[i].battery + ',' + csvData[i].errorFlag + ',' + csvData[i].numberOfflineFiles + ',' + csvData[i].numberOnlineFiles + ',' + csvData[i].numberIridiumOfflineFiles + ',';

                if (csvData[i].powerSupplyState != null || csvData[i].powerSupplyState != undefined)
                {
                    if (csvData[i].powerSupplyState == 0)
                    {
                        csvContent += 'Battery,';
                    }
                    else
                    {
                        csvContent += 'External,';
                    }
                }
                else
                {
                    csvContent += ',';
                }

                csvContent += csvData[i].geophoneConnected + ',' + csvData[i].externalAntennaConnected + ',';

                if (csvData[i].internalAntennaFoldState != null || csvData[i].internalAntennaFoldState  != undefined)
                {
                    if (csvData[i].internalAntennaFoldState == 0)
                    {
                        csvContent += 'Folded in,';
                    }
                    else
                    {
                        csvContent += 'Folded out,';
                    }
                }

                csvContent += '\n';    
            }
        }
        else if (this.tab === 'deviceInfo')
        {
            return;
            // Mitch - export related
            //         comment for now
            /*
            csvData = this.deviceInfoData;


                for(let i=0; i<this.deviceInfoData.length; i++)
            {
                csvData[i].timeStampAck = " " + csvData[i].timeStampAck.toString();
                csvData[i].timeStampSent = " " + csvData[i].timeStampSent.toString();            
            }
            */
        }
        else if (this.tab === 'controlResponse')
        {
            return;
            // Mitch - export related
            //         comment for now
            /*
            csvColumns = this.controlResponseColumnsExport;
            let csvData2: any= this.controlResponseData;
            
            for(let i=0; i<this.controlResponseData.length; i++)
            {
                csvData2[i].sensitivity0 = this.Update_Exported_SensVal(csvData2[i].sensitivity0);
                csvData2[i].sensitivity1 = this.Update_Exported_SensVal(csvData2[i].sensitivity1);
                csvData2[i].sensitivity2 = this.Update_Exported_SensVal(csvData2[i].sensitivity2);
                csvData2[i].sensitivity3 = this.Update_Exported_SensVal(csvData2[i].sensitivity3);
                csvData2[i].recordMode = this.Update_Exported_RecMode(csvData2[i].recordMode);
                csvData2[i].frequency0Setting = "MAG";
                csvData2[i].timeStampAck = " " + csvData2[i].timeStampAck.toString();
                csvData2[i].timeStampSent = " " + csvData2[i].timeStampSent.toString();
            }
            csvData = csvData2;
            csvData = this.controlResponseData;
            */
        }
        else if(this.tab === 'passage')
        {
            csvData = this.passageData;

            if (csvData.length == 0)
            {
                return;
            }

            csvContent += 'UID' + ',' + 'Passage Time' + ',' + 'Detection Count' + ',' + 'isVerified' + ',' + 'Detected Frequency' + ',' + 'Lat' + ',' + 'Lon' + ',' + 'Altitude (m)' + ',' + 'Pig Run Name' + ',' + 'Tool Name' + ',' + 'Marker Name' + '\n';

            for (var i = 0; i < this.passageData.length; i++)
            {
                // Mitch - updated implementation.
                csvData[i].passageTimeStamp = " " + csvData[i].passageTimeStamp.toString();
                csvData[i].passageTimeEndOfSnip = " " + csvData[i].passageTimeEndOfSnip.toString();

                csvContent += csvData[i].uid + ',';
                csvContent += csvData[i].passageTimeStamp + ',';
                csvContent += csvData[i].detectionCount + ',';

                if (csvData[i].isVerified != undefined)
                {
                    csvContent += csvData[i].isVerified + ',';
                }
                else
                {
                    csvContent += 'false,';
                }

                if (csvData[i].passageDetectedFrequency == 0)
                {
                    csvContent += 'MAG,';
                }
                else
                {
                    csvContent += csvData[i].passageDetectedFrequency + ' Hz,';
                }

                if (csvData[i].lat)
                {
                    csvContent += csvData[i].lat + ',';
                }
                else
                {
                    csvContent += ',';
                }
                
                if (csvData[i].lon)
                {
                    csvContent += csvData[i].lon + ',';
                }
                else
                {
                    csvContent += ',';
                }

                if (csvData[i].alt != undefined)
                {
                    csvContent += csvData[i].alt + ',';
                }
                else
                {
                    csvContent += ',';
                }

                if (csvData[i].PigRunName)
                {
                    csvContent += csvData[i].PigRunName + ',';
                }
                else
                {
                    csvContent += ',';
                }

                if (csvData[i].ToolName)
                {
                    csvContent += csvData[i].ToolName + ',';
                }
                else
                {
                    csvContent += ',';
                }

                if (csvData[i].MarkerName)
                {
                    csvContent += csvData[i].MarkerName + '\n';
                }
                else
                {
                    csvContent += '\n';
                }

                // Mitch - previous implementation.
                // csvData[i].passageTimeStamp = " " + csvData[i].passageTimeStamp.toString();
                // csvData[i].passageTimeEndOfSnip = " " + csvData[i].passageTimeEndOfSnip.toString();
                // csvContent += csvData[i].uid+","+ csvData[i].passageTimeStamp + ","+csvData[i].detectionCount+","+csvData[i].isVerified+',';
                // if(csvData[i].passageDetectedFrequency == 0){csvContent += "MAG,"}else{csvContent += csvData[i].passageDetectedFrequency + " Hz,";}
                // if(csvData[i].lat){csvContent += csvData[i].lat + ",";}
                // else{csvContent += ",";}if(csvData[i].lon){csvContent += csvData[i].lon + ",";}
                // else{csvContent += ",";}
                // if(csvData[i].PigRunName){csvContent += csvData[i].PigRunName + ",";}
                // else{csvContent += ",";}
                // if(csvData[i].ToolName){csvContent += csvData[i].ToolName + ",";}else{csvContent += ",";}
                // if(csvData[i].MarkerName){csvContent += csvData[i].MarkerName + '\n';}else{csvContent += '\n';}
            }    // end for (var i = 0; i < this.passageData.length; i++)
        }

        /*csvContent += [
            csvColumns.join(','),
            ...csvData.map((item: any) => Object.values(item).join(',')),
        ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');*/

        const data = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', this.device.uid + '_' + this.tab + '_' + Date.now() + '.csv');
        link.click();
    }

    public Update_Exported_SensVal(val:number ): string
    {
        if (val == 0)
        {
            return 'Ultra Low';
        }
        else if (val == 1)
        {
            return 'Very Low';
        }
        else if (val == 2)
        {
            return 'Low';
        }
        else if (val == 3)
        {
            return 'Medium';
        }
        else if (val == 4)
        {
            return 'High';
        }
        else if (val == 5)
        {
            return 'Very High';
        }
        else if (val == 6)
        {
            return 'Ultra High';
        }
        else if (val == 7)
        {
            return 'Ultra Low';
        }

        return '';
    }

    public Update_Exported_RecMode(val:number ): string
    {
        if (val == 0 )
        {
            return 'OFF';
        }
        else if (val == 1)
        {
            return '';
        }
        else if (val == 2)
        {
            return 'ON';
        }
        else if (val == 3)
        {
            return 'Download';
        }

        return '';
    }
}

