import Worker from 'worker-loader!./TrAgmAudioWorker.js';

export {
    TraaInit,
    TraaEnable, /* Enable AGM Audio processing and streaming. */
    TraaDisable, /* Disable AGM Audio. Input data is ignored when disabled. */
    TraaAgmData, /* AGM Data Input. This is expected to be an array of numbers in the range of -32768 to 32767 with a data rate of 1KSPS. */
    TraaSetGaindB, /* User setable gain in dB. This can be called in realtime. */
    TraaIsEnabled,
    TraaIsPlaying,
    MuteAudio,
    TerminateAudio
}
/* Tr Agm Audio Processing and Streaming Module (TRAA)

This module accepts raw agm stream data and plays audio.

 */










/* TRAA Const Defs */
const INPUT_BLOCK_SIZE = 254; //The expected size of data to arrive in bursts. For AGM data, this should be 254.

/* The expected timing jitter of the input data bursts. (Jitter meaning data arriving early/later than expected).
 A larger number helps reduce the chances of the stream pausing (due to waiting or dropping data), but increases the max latency.
  */
const INPUT_BLOCK_TIMING_JITTER_TOLERANCE_MS = 5000
const LATE_BLOCK_TOLERANCE_MS = INPUT_BLOCK_TIMING_JITTER_TOLERANCE_MS;
const EARLY_BLOCK_TOLERANCE_MS = INPUT_BLOCK_TIMING_JITTER_TOLERANCE_MS;

/* This sets the block size of a audio buffer (@1KSPS). The audio buffer size is used to determine the size of data blocks the processing works on.
A larger number reduces processing overhead, but increases the post-process latency and the overall max latency.
For best performance, make sure that AGMRATE_AUDIOBUFFER_SAMPLESIZE*48 is a multiple of 128. */
const AUDIOBUFFER_SAMPLECNT_AGM = 40;

/* This sets the number of processed audio buffers that are being played and queued to be played.
A larger number helps deal with CPU busy bursts, but increases the post-process latency and the overall max latency.
Minimum value is 2. */
const AUDIOBUFFER_QUEUE_MAX_CNT = 1000;//80;//40


const AGM_SAMPLE_RATE = 1000; //Fixed sample rate of the AGM input data.
const AGM_TO_AUDIO_RATIO = 4;//48; //Sets the audio sample rate. See TRAA_AUDIO_SAMPLE_RATE.
const AUDIO_SAMPLE_RATE = AGM_SAMPLE_RATE * AGM_TO_AUDIO_RATIO; //Make sure this is a value supported by target browsers.
const AUDIOBUFFER_SAMPLECNT_AUDIO = AUDIOBUFFER_SAMPLECNT_AGM * AGM_TO_AUDIO_RATIO;

const DBGPRINT = false;
const LATENCY_SECONDS = 10;


import
{
    TrFifo
} from '../tools/trfifo.js';



let FifoAudio;





/* TRAA Vars */
let queuedAudioBuffers = 0;
let audioContext;
let nextAudioBufferStartTime = 0;
let en;
let maxInputBufferLength;
let worker;
let audioBufferTime;
let requestedAudioBuffers = 0;
let gain;
//let statsTimer;
let startDelay;
let maxStartDelay;
let buffObj;
let buff;

let audioPing = new Array(AUDIO_SAMPLE_RATE*LATENCY_SECONDS);
let AudioReadyPing = true;
let mutestate = true;

function TraaInit(){
    console.log("Traa: Initializing...");

    gain = 1;
    mutestate = true;
    // need to import the web worker with the webpack plungin worker-loader, otherwise we get mime type errors
    // worker = new Worker('./TrAgmAudioWorker.js', {type: 'module'});
    worker = new Worker();
    FifoAudio = new TrFifo(AUDIO_SAMPLE_RATE*LATENCY_SECONDS)
    worker.onmessage = function(e){
        let data = e.data;
        switch(data.type){
            case 'audiodata': ReceiveAudioBuffer(data.output); return;
        }

        console.warn('Traa: Unhandled worker message');
    }



    worker.postMessage({'type': 'init'});
}

let buffill = false;
function TraaEnable(){
    console.log('Traa: Enabling AGM audio streaming.');

    let msg = 'Traa Parameters:';

    audioContext = new AudioContext();
   
    buffObj = audioContext.createBuffer(1, audioPing.length, AUDIO_SAMPLE_RATE);
    buff = buffObj.getChannelData(0);

    msg += '\nAudio Context Default Sample Rate:' + audioContext.sampleRate;

    queuedAudioBuffers = 0;
    requestedAudioBuffers = 0;

    //startDelay = LATE_BLOCK_TOLERANCE_MS / 1000 + AUDIOBUFFER_SAMPLECNT_AGM / AGM_SAMPLE_RATE;
    maxStartDelay = LATE_BLOCK_TOLERANCE_MS / 1000 + AUDIOBUFFER_SAMPLECNT_AGM / AGM_SAMPLE_RATE;
    startDelay = 2;
    maxInputBufferLength = INPUT_BLOCK_SIZE;
    maxInputBufferLength += (EARLY_BLOCK_TOLERANCE_MS + LATE_BLOCK_TOLERANCE_MS) * AGM_SAMPLE_RATE / 1000;
    maxInputBufferLength -= AUDIOBUFFER_SAMPLECNT_AGM*AUDIOBUFFER_QUEUE_MAX_CNT;
    if(maxInputBufferLength < (INPUT_BLOCK_SIZE+AUDIOBUFFER_SAMPLECNT_AGM)) maxInputBufferLength = INPUT_BLOCK_SIZE+AUDIOBUFFER_SAMPLECNT_AGM;
    maxInputBufferLength = Math.round(maxInputBufferLength);
    audioBufferTime = AUDIOBUFFER_SAMPLECNT_AGM / AGM_SAMPLE_RATE;

    msg += '\nInput Early Block Tolerance: ' + EARLY_BLOCK_TOLERANCE_MS + ' ms';
    msg += '\nInput Late Block Tolerance: ' + LATE_BLOCK_TOLERANCE_MS + ' ms';
    msg += '\nMax Input Buffer Limit: ' + maxInputBufferLength;

    //This is the initial latency when audio start streaming.
    msg += '\nStart Latency: ' + startDelay * 1000 + ' ms';

    //This is the latency from the time data is received, to the time data is sent to the audio driver.
    msg += '\nMax Latency: ' + (EARLY_BLOCK_TOLERANCE_MS+LATE_BLOCK_TOLERANCE_MS+(AUDIOBUFFER_SAMPLECNT_AGM*AUDIOBUFFER_QUEUE_MAX_CNT*AGM_SAMPLE_RATE/1000))  + ' ms';

    msg += '\nAudio Buffer Size: ' + AUDIOBUFFER_SAMPLECNT_AGM;
    msg += '\nAudio Buffer Time: ' + audioBufferTime * 1000 + ' ms';
    msg += '\nAudio Queue Max: ' + AUDIOBUFFER_QUEUE_MAX_CNT;

    console.log(msg);

    worker.postMessage({'type': 'settings', 'inputBufferMaxAgmSamples': maxInputBufferLength, 'BaseRateBlockSampleCnt': AUDIOBUFFER_SAMPLECNT_AGM, 'gain': gain});

    en = true;
    buffill = false;
    gain = 1;
    //statsTimer = setInterval(function(){worker.postMessage({'type': 'printstats'});}, 3000);
}

function TraaDisable(){
    console.log('Traa: Disabling AGM audio streaming.');
    //window.clearTimeout(statsTimer);
    en = false;
    worker.postMessage({'type': 'reset'});

    audioContext.close();
    audioContext = null;
    FifoAudio.Clear();
}

let dbgPrevTime = 0;

function TraaAgmData(input){

    if(!en){
        return;
    }

    if(DBGPRINT){
        let dbgCurrentTime = audioContext.currentTime;
        console.log('Data Block Arrival:' + dbgCurrentTime);
        console.log('DELTA ' + (dbgCurrentTime - dbgPrevTime) );
        dbgPrevTime = dbgCurrentTime;
    }

    worker.postMessage({'type': 'agmdata', 'input': input});
    //CheckAudioBufferRequest();
}

function sleep(ms) 
{
    return new Promise(resolve => setTimeout(resolve, ms));
}

//Deals with audio buffer received from the worker.
var startTime = 0;

function ReceiveAudioBuffer(data)
{
    FifoAudio.Put(data);

    while(FifoAudio.HasData())
    {          
        audioPing = FifoAudio.Take(); 
        if(mutestate == false)
        {
            let srcPing = new AudioBufferSourceNode(audioContext);
            srcPing.onended = () => 
            {
                AudioReadyPing = true;
            }

            srcPing.buffer = buffObj;
            srcPing.connect(audioContext.destination);


            if(startTime < audioContext.currentTime)
            {
                startTime = audioContext.currentTime;
            }
            
            buff.set(audioPing);

            srcPing.start(startTime);// start playinging ping audio
            AudioReadyPing = false;
            startTime += srcPing.buffer.duration;
        }
        // {

        //     let srcPing = new AudioBufferSourceNode(audioContext);
        //     srcPing.onended = () => 
        //     {
        //         AudioReadyPing = true;
        //     }
        //     srcPing.buffer = buffObj;
        //     srcPing.connect(audioContext.destination);


        //     if(startTime < audioContext.currentTime)
        //     {
        //         startTime = audioContext.currentTime;
        //     }
            
        //     buff.set(audioPing);

        //     srcPing.start(startTime);// start playinging ping audio
        //     AudioReadyPing = false;
        //     startTime += srcPing.buffer.duration;
        // } 
    }
}

//Sends the worker requests for audio buffers as needed.
function CheckAudioBufferRequest(){
    while(AUDIOBUFFER_QUEUE_MAX_CNT - queuedAudioBuffers - requestedAudioBuffers > 0){
        
        worker.postMessage({'type': 'rqstaudiobuff'});
        requestedAudioBuffers++;

        if(DBGPRINT){
            console.log('Need buffer, total requested: ' + requestedAudioBuffers);
        }
    }
}

function TraaSetGaindB(gaindB){
    gain = Math.pow(10,gaindB/20);
    worker.postMessage({'type': 'gain', 'gain': gain});
}

function TraaIsEnabled(){return en;}
function TraaIsPlaying(){return queuedAudioBuffers > 0;}


function MuteAudio(data)
{
    mutestate = data;
    if(data == false)
    {
        // Reset Buffers
        FifoAudio.Clear();
    }
}

function TerminateAudio()
{
    startTime = 0;
    if(worker)
    {
        worker.terminate();
    }
}