
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '../models/Device';

@Component
export default class DeviceSearch extends Vue {

    public options: string[] = [];

    get deviceUids() {
        return this.$store.getters.deviceUids;
    }

    public selectedUid = '';
    public filterFn(inputVal: string, update: any, abort: any) {
        // if (inputVal.length < 3) {
        //     abort();
        //     return;
        // }
        update(() => {
            const needle = inputVal.toLowerCase();
            this.options = this.deviceUids.filter((v: string) => v.toLowerCase().indexOf(needle) > -1);
        });
    }

    public setSelectedDevice(uid: string) {
        if (this.selectedUid) {
            // Careful! Cast uid back to number
            this.$store.dispatch('setSelectedDevicePlusZoom', Number(this.selectedUid));
        } else {
            this.$store.dispatch('clearSelection');
        }
    }


}

