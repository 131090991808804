export interface IDatabaseServerUtcDate
{
    currentDatabaseServerUtcDate: Date;
}

export class DatabaseServerUtcDate implements IDatabaseServerUtcDate
{
    constructor(public currentDatabaseServerUtcDate: Date)
    {
    }
}
