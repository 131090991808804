
import { mapGetters } from 'vuex';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LoginService from '../services/LoginService';
import { User } from '../models/User';

@Component
export default class AuthHandler extends Vue {

    public errorMessage = '';

    get user() {
        return this.$store.state.user;
    }

    @Watch('user', { immediate: false, deep: true })
    public userChanged(value: User, oldValue: User) {
        console.log('userChanged');
        if (this.$store.state.user) {
            this.$router.push({ name: 'home' });
        } else {
            this.errorMessage = 'Something bad happened';
        }
    }

    public getParameterFromHash(name: string) {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(this.$route.hash.replace('#', '?'));
        if (!results) {
            return '';
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    public mounted() {
        console.log('AuthHandler: mounted()');

        const token = this.getParameterFromHash('access_token');
        const scope = this.getParameterFromHash('scope');
        const expiresIn = Date.now() + (parseInt(this.getParameterFromHash('expires_in'), 10) * 1000); // s to ms!

        let role = '';
        if (scope && scope.indexOf('admin:propipe') !== -1) {
            role = 'superadmin';
        } else if (scope && scope.indexOf('admin:company') !== -1) {
            role = 'admin';
        } else if (scope && scope.indexOf('user:company') !== -1) {
            role = 'viewer';
        } else {
            this.errorMessage = 'No role configured for user';
            return;
        }

        if (token) {
            LoginService.getUserInfo(token).then( (userInfo: any) => {
                console.log(userInfo);
                const newLogin = new User(userInfo.sub, userInfo.name, role, token, expiresIn);
                this.$store.dispatch('login', newLogin);
            });
        }
    }
}

