export class CommandType {
    public static DeviceInfo = 'deviceInfo'; // no payload
    public static ControlSettings = 'controlSettings'; // no payload
    public static GpsFix = 'gpsFix'; // no payload
    public static PowerMode = 'powerMode';
    public static RecordMode = 'recordMode';
    public static FrequencySelect = 'frequencySelect';
    public static StreamEnable = 'streamEnable';
    public static DetectionCountReset = 'detectionCountReset'; // no payload
    public static FactoryReset = 'factoryReset'; // no payload
    public static DeviceRestart = 'deviceResart'; // no payload
    public static AddressChange = 'addressChange';
    public static IridiumSettings = 'iridiumSettings';
    public static IridiumOfflineDownload = 'iridiumofflinedownload';
    public static IridiumExitOfflineDownload = 'iridiumexitofflinedownload';
    public static RecordModee = 'recordModee';
    public static SdMode = 'SdMode';
    public static GeoMode = 'GeoMode';
    public static AGMShutDwn = 'agmshutdwn'; // no payload
    public static FoldState = 'foldstate'; //
    public static CellOfflineDownloadState = 'cellofflinedownloadstate'; //
    public static DownloadRange = 'downloadrange'; //

    public static isValid(name: string) {
        if ([
            this.DeviceInfo,
            this.ControlSettings,
            this.GpsFix,
            this.PowerMode,
            this.RecordMode,
            this.FrequencySelect,
            this.StreamEnable,
            this.DetectionCountReset,
            this.FactoryReset,
            this.DeviceRestart,
            this.AddressChange,
            this.IridiumSettings,
            this.IridiumOfflineDownload,
            this.IridiumExitOfflineDownload,
            this.SdMode,
            this.GeoMode,
            this.AGMShutDwn,
            this.FoldState,
            this.CellOfflineDownloadState,
            this.DownloadRange
        ].indexOf(name)) {
            return true;
        }
        return false;
    }
    public static byName(name: string) {
        if (name === this.DeviceInfo) {
          return this.DeviceInfo;
        } else if (name === this.ControlSettings) {
            return this.ControlSettings;
        } else if (name === this.GpsFix) {
            return this.GpsFix;
        } else if (name === this.PowerMode) {
            return this.PowerMode;
        } else if (name === this.RecordMode) {
            return this.RecordMode;
        } else if (name === this.FrequencySelect) {
            return this.FrequencySelect;
        } else if (name === this.StreamEnable) {
            return this.StreamEnable;
        } else if (name === this.DetectionCountReset) {
            return this.DetectionCountReset;
        } else if (name === this.FactoryReset) {
            return this.FactoryReset;
        } else if (name === this.DeviceRestart) {
            return this.DeviceRestart;
        } else if (name === this.AddressChange) {
            return this.AddressChange;
        } else if (name === this.IridiumSettings) {
            return this.IridiumSettings;
        }else if (name === this.IridiumOfflineDownload) {
            return this.IridiumOfflineDownload;
        }else if (name === this.IridiumExitOfflineDownload) {
            return this.IridiumExitOfflineDownload;
        }
         else if (name === this.SdMode) {
            return this.SdMode;
        }
        else if(name === this.GeoMode)
        {
            return this.GeoMode;
        }
        else if(name === this.AGMShutDwn)
        {
            return this.AGMShutDwn;
        }
        else if(name === this.FoldState)
        {
            return this.FoldState;
        }
        else if(name === this.CellOfflineDownloadState)
        {
            return this.CellOfflineDownloadState;
        }
        else if(name === this.DownloadRange)
        {
            return this.DownloadRange;
        }
        return '';
    }
}
