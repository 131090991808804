export class ConnectionSchedule {
    public static AlwaysConnected = 'Always Connected';
    public static ConnectEvery30Minutes = 'Connect Every 30 Minutes';
    public static ConnectEvery1Hour = 'Connect Every 1 Hour';
    public static ConnectEvery2Hours = 'Connect Every 2 Hours';
    public static ConnectEvery4Hours = 'Connect Every 4 Hours';
    public static ConnectEvery8Hours = 'Connect Every 8 Hours';
    public static ConnectEvery12Hours = 'Connect Every 12 Hours';
    public static ConnectEvery16Hours = 'Connect Every 16 Hours';
    public static ConnectEvery24Hours = 'Connect Every 24 Hours';
    public static getModes() {
        return [
            this.AlwaysConnected, // 0
            this.ConnectEvery30Minutes, // 1
            this.ConnectEvery1Hour, // 2
            this.ConnectEvery2Hours, // 3
            this.ConnectEvery4Hours, // 4
            this.ConnectEvery8Hours, // 5
            this.ConnectEvery12Hours, // 6
            this.ConnectEvery16Hours, // 7
            this.ConnectEvery24Hours, // 8
        ];
    }
    public static byIdx(i: number) {
        return [
            this.AlwaysConnected,
            this.ConnectEvery30Minutes,
            this.ConnectEvery1Hour,
            this.ConnectEvery2Hours,
            this.ConnectEvery4Hours,
            this.ConnectEvery8Hours,
            this.ConnectEvery12Hours,
            this.ConnectEvery16Hours,
            this.ConnectEvery24Hours,
        ][i];
    }
    public static toType(i: number) {
        return [
            'alwaysConnected', // 0
            'every30mins', // 1
            'every1hrs', // 2
            'every2hrs', // 3
            'every4hrs', // 4
            'every8hrs', // 5
            'every12hrs', // 6
            'every16hrs', // 7
            'every24hrs', // 8
        ][i];
    }
    public static toString(s: string) {
        const idx = [
            'alwaysConnected', // 0
            'every30mins', // 1
            'every1hrs', // 2
            'every2hrs', // 3
            'every4hrs', // 4
            'every8hrs', // 5
            'every12hrs', // 6
            'every16hrs', // 7
            'every24hrs', // 8
        ].indexOf(s);
        if (idx !== -1) {
            return this.byIdx(idx);
        } else {
            return '';
        }
    }
}
