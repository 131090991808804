import axios from 'axios';
import store from '../store';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  withCredentials: false, // This is the default
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

let token = '';

export default {

    // Web api service call used to get a DeviceUid data contract response given a Passage.Aid input parameter value.
    async getDeviceUid(passageId: number)
    {
        token = store.getters.token;

        return await apiClient.get('/apexagm/GetDeviceUidForPassageId/?passageId=' + passageId, {headers: { Authorization: `Bearer ${token}` }});
    },

  getControlSettingsResponseHistoricData(uid: number, page: number, rowsPerPage: number, sortBy: string, descending: boolean) {
    token = store.getters.token;
    return apiClient.get('/apexagm/' + uid + '/getControlSettingsResponseHistoricData/?page=' + page + '&rowsPerPage=' + rowsPerPage + '&sortBy=' + sortBy + '&descending=' + descending, { headers: {Authorization: `Bearer ${token}`} }) 
  },
  getDeviceInformationResponseHistoricData(uid: number, page: number, rowsPerPage: number, sortBy: string, descending: boolean) {
    token = store.getters.token;
    return apiClient.get('/apexagm/' + uid + '/getDeviceInformationResponseHistoricData/?page=' + page + '&rowsPerPage=' + rowsPerPage + '&sortBy=' + sortBy + '&descending=' + descending, { headers: {Authorization: `Bearer ${token}`} }) 
  },
  getHeartbeatHistoricData(uid: number, page: number, rowsPerPage: number, sortBy: string, descending: boolean) {
    token = store.getters.token;
    return apiClient.get('/apexagm/' + uid + '/getHeartbeatHistoricData/?page=' + page + '&rowsPerPage=' + rowsPerPage + '&sortBy=' + sortBy + '&descending=' + descending, { headers: {Authorization: `Bearer ${token}`} }) 
  },
  getPassageHistoricData(uid: number, page: number, rowsPerPage: number, sortBy: string, descending: boolean) {
    token = store.getters.token;
    return apiClient.get('/apexagm/' + uid + '/getPassageHistoricData/?page=' + page + '&rowsPerPage=' + rowsPerPage + '&sortBy=' + sortBy + '&descending=' + descending, { headers: {Authorization: `Bearer ${token}`} }) 
  },
  getDevices() {
    token = store.getters.token;
    return apiClient.get('/apexagm', { headers: {Authorization: `Bearer ${token}`} });
  },
  getDeviceHistory(uid: number, type: string) {
    token = store.getters.token;
    return apiClient.get('/apexagm/' + uid + '/' + type, { headers: {Authorization: `Bearer ${token}`} });
  },
  getLastPassageData(uid: number) {
    token = store.getters.token;
    return apiClient.get('/apexagm/lastPassage/' + uid, { headers: {Authorization: `Bearer ${token}`} });
  },
  getPassageData(uid: number, passageId: number, type?: number) {
    token = store.getters.token;
    if (type) {
      return apiClient.get('/apexagm/passage/' + uid + '/' + passageId + '/' + type, { headers: {Authorization: `Bearer ${token}`} });
    }
    return apiClient.get('/apexagm/passage/' + uid + '/' + passageId, { headers: {Authorization: `Bearer ${token}`} });
  },
  deletePassage(uid: number, passageId: number) {
    token = store.getters.token;
    return apiClient.delete('/apexagm/passage/' + uid + '/' + passageId, { headers: {Authorization: `Bearer ${token}`} });
  },
  verifyPassage(uid: number, passageId: number) {
    token = store.getters.token;
    return apiClient.get('/apexagm/verifyPassage/' + uid + '/' + passageId, { headers: {Authorization: `Bearer ${token}`} });
  },
  setPassageVerification(uid: number, passageId: number, isVerified: boolean) {
    token = store.getters.token;
    return apiClient.get('/apexagm/verifyPassage/' + uid + '/' + passageId + '/' + isVerified, { headers: {Authorization: `Bearer ${token}`} });
  },
  EditPassageRunInfo(payload:any) 
  {
    var aa = [];
    aa[0] = payload[0].RunId;// Old Run Id
    aa[1] = payload[1];// New Run Id
    aa[2] = payload [2];//New RunId
    aa[3] = payload [3];//New RunName
    aa[4] = payload [4];// New MarkerName

    token = store.getters.token;
    return apiClient.post('/apexagm/passage/' + payload[0].uid + '/' + payload[0].passageId + '/updateruninfo',  JSON.stringify(aa), { headers: {Authorization: `Bearer ${token}`} });
   },
   ClearPassageRunInfo(payload:any)
   {
      token = store.getters.token;
      if(payload.geoId  == undefined){payload.geoId = -1;}
      if(payload.magId  == undefined){payload.magId = -1;}
      if(payload.elfId  == undefined){payload.elfId = -1;}
      return apiClient.post('/apexagm/passage/' + payload.uid + '/' + payload.geoId + '/' + payload.magId +'/' + payload.elfId  + '/clearruninfo', null,{ headers: {Authorization: `Bearer ${token}`} });
    },
  deletePassages(uid: number) {
    token = store.getters.token;
    return apiClient.delete('/apexagm/' + uid + '/passage', { headers: {Authorization: `Bearer ${token}`} });
  },
  deleteHeartbeats(uid: number) {
    token = store.getters.token;
    return apiClient.delete('/apexagm/' + uid + '/heatbeat', { headers: {Authorization: `Bearer ${token}`} });
  },
  deleteErrors(uid: number) {
    token = store.getters.token;
    return apiClient.delete('/apexagm/' + uid + '/error', { headers: {Authorization: `Bearer ${token}`} });
  },
  deleteDeviceInfo(uid: number) {
    token = store.getters.token;
    return apiClient.delete('/apexagm/' + uid + '/deviceInfo', { headers: {Authorization: `Bearer ${token}`} });
  },
  deleteControlResponse(uid: number) {
    token = store.getters.token;
    return apiClient.delete('/apexagm/' + uid + '/controlResponse', { headers: {Authorization: `Bearer ${token}`} });
  },
  deleteGpsFix(uid: number) {
    token = store.getters.token;
    return apiClient.delete('/apexagm/' + uid + '/gpsFix', { headers: {Authorization: `Bearer ${token}`} });
  },
  // Mitch. This does not appear to be used. November 25, 2022.
  // deleteOffline(uid: number) {
  // token = store.getters.token;
  // return apiClient.delete('/apexagm/' + uid + '/offline', { headers: {Authorization: `Bearer ${token}`} });
  // },
  autoDownload(uid: number, isAutoDownload: boolean) {
    token = store.getters.token;
    return apiClient.post('/apexagm/' + uid + '/autodownload', JSON.stringify(isAutoDownload), { headers: {Authorization: `Bearer ${token}`} });
  },
  updateTimeStampByPassageId(uid: number, passageId: number, newPassageTime: Date) {
    token = store.getters.token;
    const postBody = {newPassageTime};
    return apiClient.post('/apexagm/passage/' + uid + '/' + passageId + '/updatetimestamp',  postBody, { headers: {Authorization: `Bearer ${token}`} });
  },
  getIhabsDevice(uid: number) {
    token = store.getters.token;
    return apiClient.get('/apexagm/ihab/' + uid, { headers: {Authorization: `Bearer ${token}`} });
  },

};
