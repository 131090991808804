// BroadcastCommandsRequest.
//
// Class representing a broadcast commands request.
//
// Note:
// This class is only used for the broadcast commands main menu item and operates independently from the broadcast commands
// section related to configuring a pig run.
export class BroadcastCommandsRequest
{
    // The array of commands.
    public commandList: Array<string>;

    // Request for download end date.
    public requestForDownloadEndDate: Date | undefined;

    // Request for download start date.
    public requestForDownloadStartDate: Date | undefined;

    // The array of devices.
    public uidList: Array<number>;

    // Constructor 2.
    public constructor(uidList: Array<number>, commandList: Array<string>, requestForDownloadStartDate: Date | undefined, requestForDownloadEndDate: Date | undefined)
    {
        this.uidList = uidList;
        this.commandList = commandList;
        this.requestForDownloadStartDate = requestForDownloadStartDate;
        this.requestForDownloadEndDate = requestForDownloadEndDate;
    }
}
