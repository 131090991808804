// ReportDataForDevicesRequest.
//
// Class representing a report data request given selected devices.
export class ReportDataForDevicesRequest
{
    // q-table pagination property: descending. Not currently used by the underlying web api.
    public descending: boolean;

    // Passage.PassageTimeStamp end.
    public endDate: Date | undefined;

    // Include verified passages only.
    public includeVerifiedPassagesOnly: boolean;

    // q-table pagination property: page.
    public page: number;

    // q-table pagination property: rowsPerPage.
    public rowsPerPage: number;

    // q-table pagination property: sortBy. Not currently used by the underlying web api.
    public sortBy: string;

    // Passage.PassageTimeStamp start.
    public startDate: Date | undefined;

    // List of uid value(s).
    public uidList: number[] | undefined;

    // Constructor.
    public constructor()
    {
        this.page = -1;
        this.rowsPerPage = -1;
        this.sortBy = '';
        this.descending = false;

        this.includeVerifiedPassagesOnly = false;
    }
}