export interface IUserNotificaion {
    name: string;
    userId: string;
    launch: boolean;
    receive: boolean;
    medium: string;
    passageType: string; // "all" | "verified"
    manualPassage: boolean;
    allMarkers: boolean;
    markers?: string[];
}

export class UserNotification implements IUserNotificaion {
    constructor(
        public name: string,
        public userId: string,
        public launch: boolean,
        public receive: boolean,
        public medium: string,
        public passageType: string,
        public manualPassage: boolean,
        public allMarkers: boolean,
        public markers?: string[],
    ) { }
}
