// DeleteManualPassageRequest.
//
// Class representing a request to delete a manual passage for the specified PigRun.RunId value.
export class DeleteManualPassageRequest
{
    // Manual passage time stamp.
    public passageTimeStamp: Date;

    // PigRun.RunId.
    public runId: number;

    // Constructor.
    public constructor(runId: number, passageTimeStamp: Date)
    {
        this.runId = runId;
        this.passageTimeStamp = passageTimeStamp;
    }
}