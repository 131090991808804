
import { colors } from 'quasar';
import { Component, Vue } from 'vue-property-decorator';
import LoginService from '../services/LoginService';

@Component
export default class Login extends Vue {

    public errorMessage: string = '';
    public username: string = '';
    public password: string = '';
    public redirect: string = '';

    public mounted() {
      // maintiain query params through the login redirects
      if (this.$route.query) {
        this.$store.dispatch('setQueryParams', this.$route.query);
      }
      colors.setBrand('negative', '#f26921');
      if ('redirect' in this.$route.params) {
        this.redirect = this.$route.params.redirect;
      }
      if (!(this.$q.platform.is.chrome || this.$q.platform.is.firefox)) {
        this.$q.dialog({
          title: 'Unsupported Browser',
          message: 'An unsupported browser has been detected. This application may not function as intended.',
        }).onOk(() => {
          //
        }).onCancel(() => {
          //
        }).onDismiss(() => {
          //
        });
      }
    }

    public onReset() {
        this.username = '';
        this.password = '';
        this.errorMessage = '';
    }

    public onSubmit() {
      console.log('Login: onSubmit()');
      LoginService.login(this.username, this.password).then(() => {
        // won't get here, resolve will be redirected to AuthHandler
      }).catch((error) => {
        console.error(error);
        this.errorMessage = error.description;
      });

      // Fake login...
      // if (this.username === 'propipe' && this.password === 'pr0p1pe@gm') {
      //   this.$store.dispatch('login', 'some-token-value');
      //   if (this.redirect.length > 0) {
      //     this.$router.push({name: this.redirect});
      //   } else {
      //     this.$router.push({name: 'home'});
      //   }
      // } else {
      //   this.onReset();
      // }
    }

}
