import axios from 'axios';
import store from '../store';
import { Group } from '../models/Group';
import { UserMgmt } from '../models/UserMgmt';
import { DeviceMgmt } from '../models/DeviceMgmt';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  withCredentials: false, // This is the default
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

let token = '';

export default {
  getGroups() {
    token = store.getters.token;
    return apiClient.get('/admin/group', { headers: {Authorization: `Bearer ${token}`} });
  },
  postGroup(group: Group) {
    token = store.getters.token;
    return apiClient.post('/admin/group', group, { headers: {Authorization: `Bearer ${token}`} });
  },
  deleteGroup(groupId: number) {
    token = store.getters.token;
    return apiClient.delete('/admin/group/' + groupId, { headers: {Authorization: `Bearer ${token}`} });
  },

  getAgms(groupId: number) {
    token = store.getters.token;
    return apiClient.get('/admin/apexagm/' + groupId, { headers: {Authorization: `Bearer ${token}`} });
  },
  addAgm(agm: DeviceMgmt) {
    token = store.getters.token;
    return apiClient.post('/admin/apexagm/add', agm, { headers: {Authorization: `Bearer ${token}`} });
  },
  deleteAgm(uid: number) {
    token = store.getters.token;
    return apiClient.delete('/admin/apexagm/' + uid, { headers: {Authorization: `Bearer ${token}`} });
  },

  getUsers(groupId: number) {
    token = store.getters.token;
    return apiClient.get('/admin/user/' + groupId,  { headers: {Authorization: `Bearer ${token}`} });
  },
  postUser(user: UserMgmt) {
    token = store.getters.token;
    return apiClient.post('/admin/user', user, { headers: {Authorization: `Bearer ${token}`} });
  },
  deleteUser(userId: string) {
    token = store.getters.token;
    return apiClient.delete('/admin/user/' + userId, { headers: {Authorization: `Bearer ${token}`} });
  },

};
