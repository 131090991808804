

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '../models/Device';
import {
        UpdateRSSIFreq,
// @ts-ignore - no type defs!
} from '../realtimeplotworkers/TrAgmRTManager.js';


@Component
export default class RealTimeOptions extends Vue {
    public OptionsDialog = false;
    public newcommandDisplay = false;
    public isGeoAutoClicked = false;
    public GeoSpeedCalcAvgSelect = "2 Welds Avg";
    public selectedPowerMode: string = '';
    public fsOne: string = "22";
    public enabledMAG: boolean = false;
    public enabledELF: boolean = false;
    public enabledGeo: boolean = false;
    public enabledSendPassage: boolean = false;
    public enabledSound: boolean = false;
    public SecondsToPause: string = "15";
    public GeoAvgOption = 2;
    public SystemUnits = "Imperial";
    public SystemState = "Use Raw";
    public WeldSpace = 20;
    public WeldDesc = "Weld Spacing (ft)";

    public EmRawGraph: boolean = true;
    public EmMAGGraph: boolean = true;
    public EmELFGraph: boolean = true;
    public EmScaleOption:string = "extents";
    public RSSIScaleOption:string = "extents";
    public GeoScaleOption:string = "extents";

    get device(): Device {
        return this.$store.state.selectedDevice;
    }
    
   public showDialog() {
        this.OptionsDialog = true;
    //debugger
        // clear any previous values
        //this.selectedPowerMode = '';        
    }

    public closeDialog() {
        //debugger
       
        this.OptionsDialog = false;
    }
    public UserRole:String = '';
    public getuser()
    {
        this.UserRole = this.$store.state.user.role
        return this.UserRole;
    }
    public UpdateRTSettings()
    {
        let t,f;

        t = parseInt(this.SecondsToPause);
        f = parseInt(this.fsOne);
        this.$store.commit("SET_RT_MAG_DETECT", this.enabledMAG);
        this.$store.commit("SET_RT_ELF_DETECT", this.enabledELF);
        this.$store.commit("SET_RT_GEO_DETECT", this.enabledGeo);
        this.$store.commit("SET_RT_SEND_TO_DB", this.enabledSendPassage);
        this.$store.commit("SET_RT_PASSAGE_SOUND", this.enabledSound)
        this.$store.commit("SET_RT_PAUSE_TIME", t);
        this.$store.commit("SET_RT_FREQ1", f);
        UpdateRSSIFreq(f);
    }

    public unsubscribe = this.$store.subscribeAction((action, state) => {
       
        if (action.type === 'RealTimeOptionsShow') 
        {
            this.EmScaleOption = action.payload[0];
            this.RSSIScaleOption  = action.payload[1];
            this.GeoScaleOption  = action.payload[2];
            this.showDialog();
            console.log('got action test')
        }
    });

    public onGeoAutoSelect(input:number)
    {
        if(input == 1)
        {
            this.GeoSpeedCalcAvgSelect = "2 Welds Avg";
        }
        else if(input == 2)
        {
            this.GeoSpeedCalcAvgSelect = "5 Welds Avg";
        }
        else if(input == 3)
        {
            this.GeoSpeedCalcAvgSelect = "10 Welds Avg";
        }
        this.GeoAvgOption = input;
    }

    public GeoAutoClicked()
    {
        if(this.isGeoAutoClicked == true)
        {
            //this.AutoGeoThreshLine.isHidden = false;
            //UpdateGeoAutoState(this.isGeoAutoClicked);
            console.log('Clicked!');
        }
        else
        {
            console.log('GeoAutoUNClicked!');
            //this.isGeoAutoClicked = false;
            //this.AutoGeoThreshLine.isSelected = false;
            //setTimeout(() => {
            //    this.AutoGeoThreshLine.isSelected = false;
            //    this.AutoGeoThreshLine.isHidden = true;
            //    }, 100);
        }
    }

    public onSystemUnitsSelect(input:number)
    {
        if(input == 1)
        {
            this.SystemUnits = "Metric";
            this.WeldDesc = "Weld Spacing (m)"
        }
        else if(input == 2)
        {
            this.SystemUnits = "Imperial";
            this.WeldDesc = "Weld Spacing (ft)"
        }
    }
     public onSystemStateSelect(input:number)
    {
        if(input == 1)
        {
            this.SystemState = "Use Raw";
        }
        else if(input == 2)
        {
            this.SystemState = "Use Filtered";
        }
    }

    public UpdateGeoSpeedSettings()
    {
        this.$store.commit("SET_GEO_SPEED_CALC",this.isGeoAutoClicked);
        this.$store.commit("SET_GEO_WELD_SPACE",this.WeldSpace)
        this.$store.commit("SET_GEO_SPEED_NUMAVG",this.GeoAvgOption)
        if(this.SystemUnits == "Metric")
        {
            this.$store.commit("SET_GEO_SPEED_METRIC",true)
        }
        else
        {
            this.$store.commit("SET_GEO_SPEED_METRIC",false)
        }

        if(this.SystemState == "Use Raw")
        {
            this.$store.commit("SET_GEO_WELD_SOURCE",true)
        }
        else
        {
            this.$store.commit("SET_GEO_WELD_SOURCE",false)
        }



        this.$store.dispatch('UpdateGeoSpeedParams');
        console.log("update geo speed");
    }
    
    public UpdateGraphingSettings()
    {
        //this.$store.commit("SET_EM_RAW",this.EmRawGraph) 
        //this.$store.commit("SET_EM_MAG",this.EmMAGGraph)   
        //this.$store.commit("SET_EM_ELF",this.EmELFGraph)   
        this.$store.commit("SET_EM_SCALE_EXTENTS",this.EmScaleOption)   
        this.$store.commit("SET_RSSI_SCALE_EXTENTS",this.RSSIScaleOption)   
        this.$store.commit("SET_GEO_SCALE_EXTENTS",this.GeoScaleOption)  
        this.$store.dispatch('UpdateGraphing');
        console.log("update graph settings");
    }
    
}

