import StreamingHub from '../../services/StreamingHub';
import MessageHub from '../../services/MessageHub';
import { Device } from '../../models/Device';
import { PigRun } from '../../models/PigRun';

export default {
    state: {
    },
    mutations: {
    },
    actions: {
        sendPigRunUpdateMsg(context: any, runId: number) {
            const connection = MessageHub.getConnection();
            connection.send('SendPigRunUpdate', runId).then(() => {
                 console.log('SendPigRunUpdate');
            }).catch((err) => {
                console.error(err);
            });
        },
        initSignalR(context: any) {
            MessageHub.init();
            StreamingHub.init();

            const connection = MessageHub.getConnection();
            const recievedPassageTimestamps: string[] =  [];
            const isUidInGroup = (uid: number) => {
                const groupUids = context.rootState.devices.map((d: Device) => d.uid);
                if (groupUids.indexOf(uid) !== -1) {
                    return true;
                }
                return false;
            };

            const isRunIdActive = (runId: number) => {
                const activeRunIds = context.rootState.pigRun.activePigRuns.map((pr: PigRun) => pr.runId);
                if (activeRunIds.indexOf(runId) !== -1) {
                    return true;
                }
                return false;
            };

            const shouldRunBeLaunched = (runId: number) => {
                if (context.rootState.pigRun.completedPigRuns && context.rootState.pigRun.completedPigRuns.find((completedRun: PigRun) => completedRun.runId === runId)) {
                    // run was completed, don't relaunch
                    return false;
                }
                if (context.rootState.pigRun.activePigRuns && context.rootState.pigRun.activePigRuns.length < 1) {
                    // no pig runs active, this may be a signal to launch
                    return true;
                }
                return false;
            };

            connection.on('ReceiveCellularCarrierInfo', (data: any) =>
            {
                // Refer to the ProPipe.Common.Hub.CellularCarrierInfoMsg class for the data members available here. The
                // members deserialize as follows:
                //
                // data.cellularCarrierInfoId
                // data.uid
                // data.createdDate
                // data.primaryCarrier
                // data.activeCarrier
                // data.cellActiveFirmware
                // data.registrationStatus
                //
                // Alternatively, the object key names can be displayed using console.log(Object.keys(data)).
                if (isUidInGroup(data.uid))
                {
                    // console.log('ReceiveCellularCarrierInfo before dispatching setCellularCarrierInfo action');
                    context.dispatch('setCellularCarrierInfo', data);
                }
            }),

            // ReceiveDownloadRecordedPassageDataDownloadingState SignalR event handler.
            connection.on('ReceiveDownloadRecordedPassageDataDownloadingState', (downloadRecordedPassageDataDownloadingState: any) =>
            {
                console.log('Hubs.ts. SignalR event = [ReceiveDownloadRecordedPassageDataDownloadingState]. Enter method.');

                context.dispatch('setDownloadRecordedPassageDataDownloadingState', downloadRecordedPassageDataDownloadingState);
            }),

            // ReceiveDownloadRecordedPassageDataPassageReceivedState SignalR event handler.
            connection.on('ReceiveDownloadRecordedPassageDataPassageReceivedState', (downloadRecordedPassageDataPassageReceivedState: any) =>
            {
                console.log('Hubs.ts. SignalR event = [ReceiveDownloadRecordedPassageDataPassageReceivedState]. Enter method.');

                context.dispatch('setDownloadRecordedPassageDataPassageReceivedState', downloadRecordedPassageDataPassageReceivedState);
            }),

            // ReceiveDownloadRecordedPassageDataDownloadCompleteState SignalR event handler.
            connection.on('ReceiveDownloadRecordedPassageDataDownloadCompleteState', (downloadRecordedPassageDataDownloadCompleteState: any) =>
            {
                console.log('Hubs.ts. SignalR event = [ReceiveDownloadRecordedPassageDataDownloadCompleteState]. Enter method.');

                context.dispatch('setDownloadRecordedPassageDataDownloadCompleteState', downloadRecordedPassageDataDownloadCompleteState);
            }),

            // ReceiveDownloadRecordedRawDataDownloadingState SignalR event handler.
            connection.on('ReceiveDownloadRecordedRawDataDownloadingState', (downloadRecordedRawDataDownloadingState: any) =>
            {
                console.log('Hubs.ts. SignalR event = [ReceiveDownloadRecordedRawDataDownloadingState]. Enter method.');
                console.log(downloadRecordedRawDataDownloadingState);
            }),

            // ReceiveDownloadRecordedRawDataPacketReceivedState SignalR event handler.
            connection.on('ReceiveDownloadRecordedRawDataPacketReceivedState', (downloadRecordedRawDataPacketReceivedState: any) =>
            {
                console.log('Hubs.ts. SignalR event = [ReceiveDownloadRecordedRawDataPacketReceivedState]. Enter method.');
                console.log(downloadRecordedRawDataPacketReceivedState);
            }),

            // ReceiveDownloadRecordedRawDataDownloadCompleteState SignalR event handler.
            connection.on('ReceiveDownloadRecordedRawDataDownloadCompleteState', (downloadRecordedRawDataDownloadCompleteState: any) =>
            {
                console.log('Hubs.ts. SignalR event = [ReceiveDownloadRecordedRawDataDownloadCompleteState]. Enter method.');
                console.log(downloadRecordedRawDataDownloadCompleteState);
            }),

            // ReceiveDiskUse SignalR event handler.
            connection.on('ReceiveDiskUse', (diskUseMsg: any) => 
            {
                console.log('Hubs.ts. SignalR event = [ReceiveDiskUse]. Enter method.');
                console.log(diskUseMsg);

                context.dispatch('setDiskUse', diskUseMsg);
            }),

            connection.on('ReceivePigRunUpdate', (data: any) => {
                if (isRunIdActive(data.runId)) {
                    if(data.messagegName == "UpdateLocation")
                    {
                        var h = [data.runId,data.lat,data.lon,data.serverTime,data.etAtoNextString,data.etAtoNextTrackString,data.etAtoEndString,data.speed];
                        context.dispatch('updatePigLoc', h);
                    }
                    else if(data.messagegName == "UpdateLocationV2")
                    {
                        var h = [data.runId,data.lat,data.lon,data.serverTime,data.etAtoNextV2,data.etAtoNextTrackV2,data.etAtoEndV2,data.speed];
                        context.dispatch('updatePigLocV2', h);
                    }

                    else
                    {
                        console.log('[' + new Date().toISOString() + '] ReceivePigRunUpdate ' + data.runId);
                        context.dispatch('refreshPigRun', data.runId);
                    }

                } else if (shouldRunBeLaunched(data.runId)) {
                    console.log('[' + new Date().toISOString() + '] ReceivePigRunUpdate NOT ACTIVE');
                    context.dispatch('restorePigData');
                } else {
                    //console.log('[' + new Date().toISOString() + '] ReceivePigRunUpdate IGNORE');
                    //May need to refresh a completed Run
                    context.dispatch('refreshcompPigRun', data.runId);
                }
            }),
            connection.on('ReceiveDownloadMode', (data: any) => {
                // IMPORTANT - this message was named incorectly, its really the record mode that it is updating
                if (isUidInGroup(data.uid)) {
                    console.log('[' + new Date().toISOString() + '] ReceiveDownloadMode ' + data.uid + '/' + data.isAutoDownload);
                    context.dispatch('updateStatus', { uid: data.uid, isConnected: true });
                    context.dispatch('setRecordMode', data);
                }
            }),
            connection.on('ReceiveCommTypeChange', (data: any) => {
                if (isUidInGroup(data.uid)) {
                    //console.log('[' + new Date().toISOString() + '] ReceiveCommTypeChange ' + data.uid + '/' + data.isIridium);
                    context.dispatch('setIsIridium', data);
                }
            }),
            connection.on('ReceiveHeartbeat', (data: any) => {
                if (isUidInGroup(data.uid)) {
                    console.log('[' + new Date().toISOString() + '] ReceiveHeartbeat ' + data.uid + '/' + data.lat + '/' + data.lon);
                    if (data.lat !== 0 && data.lon !== 0) {
                        context.dispatch('updateLocation', data);
                        context.dispatch('showHeartbeat', data.uid);
                    }
                    if (context.rootState.notifications.heartbeat) {
                        context.dispatch('notify', 'Recieved Heartbeat on UID ' + data.uid);
                    }
                    if (context.rootState.notifications.heartbeatSound && context.rootState.notifications.heartbeatSound !== '') {
                        context.dispatch('notifySound', context.rootState.notifications.heartbeatSound);
                    }
                    context.dispatch('getDevice', data.uid);
                }
            });
            connection.on('ReceiveConnectionUpdate', (data: any) => {
                if (isUidInGroup(data.uid)) {
                    //console.log('[' + new Date().toISOString() + '] ReceiveConnectionUpdate ' + data.uid + '/' + data.isConnected);
                    context.dispatch('updateStatus', data);
                }
            });
            connection.on('ReceivePassage', (data: any) =>
            {
                console.log('Hubs.ts. connection.on(ReceivePassage). Enter method.');

                if (isUidInGroup(data.uid))
                {
                    console.log('[' + new Date().toISOString() + '] ReceivePassage ' + data.uid + '/' + data.detectionCount);
                    console.log(data);

                    console.log('Hubs.ts. connection.on(ReceivePassage). Before dispatch action = [updateStatus].');

                    // Dispatch action = [updateStatus].
                    context.dispatch('updateStatus', { uid: data.uid, isConnected: true });

                    console.log('Hubs.ts. connection.on(ReceivePassage). After dispatch action = [updateStatus].');
                    console.log('Hubs.ts. connection.on(ReceivePassage). Before dispatch action = [passageRecieved].');

                    // Dispatch action = [passageRecieved].
                    context.dispatch('passageRecieved', data);

                    console.log('Hubs.ts. connection.on(ReceivePassage). After dispatch action = [passageRecieved].');
                    console.log('Hubs.ts. connection.on(ReceivePassage). Before dispatch action = [updateDetectionCount].');

                    // Dispatch action = [updateDetectionCount].
                    context.dispatch('updateDetectionCount', data);

                    console.log('Hubs.ts. connection.on(ReceivePassage). After dispatch action = [updateDetectionCount].');                    

                    // ELF/GEO/MAG (packetComplexInformation) are treated as seperate passages so we want to avoid double notifications
                    if (context.rootState.notifications.passage && recievedPassageTimestamps.indexOf(data.passageTimeStamp) === -1)
                    {
                        console.log('Hubs.ts. connection.on(ReceivePassage). Before dispatch action = [notify].');

                        // Dispatch action = [notify].
                        context.dispatch('notify', 'Recieved Passage  ' + data.detectionCount + ' on UID ' + data.uid);

                        console.log('Hubs.ts. connection.on(ReceivePassage). After dispatch action = [notify].');
                    }

                    if (context.rootState.notifications.passageSound && context.rootState.notifications.passageSound !== '' && recievedPassageTimestamps.indexOf(data.passageTimeStamp) === -1)
                    {
                        console.log('Hubs.ts. connection.on(ReceivePassage). Before dispatch action = [notifySound].');

                        // Dispatch action = [notifySound].
                        context.dispatch('notifySound', context.rootState.notifications.passageSound);

                        console.log('Hubs.ts. connection.on(ReceivePassage). After dispatch action = [notifySound].');
                    }

                    if (context.rootState.selectedDevice.uid === data.uid)
                    {
                        console.log('Hubs.ts. connection.on(ReceivePassage). Before dispatch action = [getDevice].');

                        // Dispatch action = [getDevice].
                        context.dispatch('getDevice', data.uid);

                        console.log('Hubs.ts. connection.on(ReceivePassage). After dispatch action = [getDevice].');
                    }

                    recievedPassageTimestamps.push(data.passageTimeStamp);
                }
            });
            connection.on('ReceiveCommandResponse', (data: any) => {
                if (isUidInGroup(data.uid)) {
                    console.log('[' + new Date().toISOString() + '] ReceiveCommandResponse ' + data.uid + '/' + data.command);

                    // If we are receiving command responses the device is connected.
                    context.dispatch('updateStatus', { uid: data.uid, isConnected: true });

                    if (data.command === 'CommandResponseNotReceived')
                    {
                        context.dispatch('notify', 'Command response not received. Deleting command.');
                    }

                    if (data.command === 'deviceInfo') {
                        if (context.rootState.notifications.deviceInfo) {
                            context.dispatch('notify', 'Received Device Info command on UID ' + data.uid);
                        }
                        if (context.rootState.notifications.deviceInfoSound && context.rootState.notifications.deviceInfoSound !== '') {
                            context.dispatch('notifySound', context.rootState.notifications.deviceInfoSound);
                        }
                    }
                    if (data.command === 'controlSettings') {
                        context.dispatch('getDevice', data.uid);
                        if (context.rootState.notifications.controlSetting) {
                            context.dispatch('notify', 'Received Control Setting command on UID ' + data.uid);
                        }
                        if (context.rootState.notifications.controlSettingSound && context.rootState.notifications.controlSettingSound !== '') {
                            context.dispatch('notifySound', context.rootState.notifications.controlSettingSound);
                        }
                    }
                    if (data.command === 'gpsFix') {
                        if (context.rootState.notifications.gpsFix) {
                            context.dispatch('notify', 'Received GPS Fix command on UID ' + data.uid);
                        }
                        if (context.rootState.notifications.gpsFixSound && context.rootState.notifications.gpsFixSound !== '') {
                            context.dispatch('notifySound', context.rootState.notifications.gpsFixSound);
                        }
                    }
                    if (data.command === 'powerMode') {
                        context.dispatch('getDevice', data.uid);
                        if (context.rootState.notifications.powerMode) {
                            context.dispatch('notify', 'Received Power Mode command on UID ' + data.uid);
                        }
                        if (context.rootState.notifications.powerModeSound && context.rootState.notifications.powerModeSound !== '') {
                            context.dispatch('notifySound', context.rootState.notifications.powerModeSound);
                        }
                    }
                    if (data.command === 'frequencySelect') {
                        context.dispatch('getDevice', data.uid);
                        if (context.rootState.notifications.frequencySelect) {
                            context.dispatch('notify', 'Received Frequency Select command on UID ' + data.uid);
                        }
                        if (context.rootState.notifications.frequencySelectSound && context.rootState.notifications.frequencySelectSound !== '') {
                            context.dispatch('notifySound', context.rootState.notifications.frequencySelectSound);
                        }
                    }
                    if (data.command === 'streamEnable') {
                        context.dispatch('getDevice', data.uid);
                        if (context.rootState.notifications.streamEnable) {
                            context.dispatch('notify', 'Received Stream Enable command on UID ' + data.uid);
                        }
                        if (context.rootState.notifications.streamEnableSound && context.rootState.notifications.streamEnableSound !== '') {
                            context.dispatch('notifySound', context.rootState.notifications.streamEnableSound);
                        }
                    }
                    if (data.command === 'recordMode') {
                        context.dispatch('getDevice', data.uid);
                        if (context.rootState.notifications.recordMode) {
                            context.dispatch('notify', 'Received Record Mode command on UID ' + data.uid);
                        }
                        if (context.rootState.notifications.recordModeSound && context.rootState.notifications.recordModeSound !== '') {
                            context.dispatch('notifySound', context.rootState.notifications.recordModeSound);
                        }
                    }
                    if (data.command === 'detectCountReset') {
                        context.dispatch('getDevice', data.uid);
                        if (context.rootState.notifications.detectionCount) {
                            context.dispatch('notify', 'Received Detection Count Reset command on UID ' + data.uid);
                        }
                        if (context.rootState.notifications.detectionCountSound && context.rootState.notifications.detectionCountSound !== '') {
                            context.dispatch('notifySound', context.rootState.notifications.detectionCountSound);
                        }
                    }
                    // TODO: expose commands for error, offline, shutdown, sleep
                    // if (data.command === 'factoryReset') {
                    //   // not an option yet
                    // }
                    // if (data.command === 'deviceResart') {
                    //   // not an option yet
                    // }
                    // if (data.command === 'addressChange') {
                    //   // not an option yet
                    // }
                    if (context.rootState.selectedDevice.uid === data.uid) {
                        context.dispatch('getCommands', data.uid);
                    }
                }
            });
        },
    },
};
