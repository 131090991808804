
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '../models/Device';

@Component
export default class ViewerDeviceActions extends Vue {

    get device(): Device {
        return this.$store.state.selectedDevice;
    }


    public onHistoricData() {
        console.log('ViewerDeviceActions.vue. onHistoricData.');

        this.$store.dispatch('historicDataShow');
    }
}

