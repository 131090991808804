
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AgmMarker } from '../models/AgmMarker';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

@Component
export default class MarkerActions extends Vue {


public UserRole:String = '';
public RunId:Number|null = null;
public getuser()
{
    //use this method to get user role. So that certain things get displayed/hidden based on role.
    this.UserRole = this.$store.state.user.role
    //console.log(this.$store.state.user)
    return this.UserRole;
}



    // get showMarkerInfo(): boolean {
    //     debugger
    //     return this.$store.state.showMarkerInfo;
    // }

    get marker(): AgmMarker {
        return this.$store.state.selectedMarker;
    }

    get etaFormated() {
        if (this.eta < 0) {
            return 'passed';
        } else if (this.eta === 0) {
            return 'now';
        }
        return dayjs.default().startOf('day').second(this.eta).format('H:mm:ss');
    }

    public isOnline: boolean = true;
    public eta = 0;
    public refreshFrequency = 1000; // ms
    public timerId: number = -1;
    public markerDescriptions = [
        'AGM',
        'Tap',
        'Magnet',
        'Bend',
        'Weld',
        'Attachment',
        'Tee',
        'Pipe',
        'Other',
    ];

    public unsubscribe = this.$store.subscribeAction((action: any, state: any) => {
        
        if (action.type === 'refreshMarkerEta') {
            console.log('MarkerActions: refreshMarkerEta');
            this.setMarkerEta(action.payload);
        }
        else if(action.type === 'passRunId')
        {
            this.SetRunId(action.payload);
        }
    });

    @Watch('marker', { immediate: false, deep: false })
    public markerChanged(value: AgmMarker, oldValue: AgmMarker) {
        // need to communicate with PigProgressMarker for accurate ETAs
        // it responds with refreshMarkerEta
        this.$store.dispatch('calculateMarkerEta', value.name);
    }


    public SetRunId(id: number)
    {

        this.RunId = id;
    }

    public setMarkerEta(newEta: number) {
        this.eta = newEta;
        clearInterval(this.timerId);
        this.timerId = setInterval(() => {
            if (this.eta > 0) {
                this.eta--;
            }
        }, this.refreshFrequency) as any;
    }

    public mounted() {
        console.log('MarkerActions: mounted()');
        this.watchOnlineStatus();
    }

    public beforeDestroy() {
        this.unsubscribe();
        clearInterval(this.timerId);
    }

    public onChangeDescription(description: string) {
        console.log('onChangeDescription');
        const payload = {
            markerName: this.marker.name,
            description,
        };
        this.$store.dispatch('changeMarkerDescription', payload);
    }

    public onChangeState() {
        var h = [];
        h.push(this.RunId);
        h.push(this.marker.name);
        console.log('onChangeState');
        this.$store.dispatch('changeMarkerState', h);
        //this.$store.dispatch('changeMarkerState', this.marker.name);
    }

    public onAssignUid() {
        console.log('onAssignUid');
        this.$store.dispatch('assignMarkerUid', this.marker.name);
    }

    public onUnlinkUid() {
        console.log('onUnlinkUid');
        this.$store.dispatch('unlinkMarkerUid', this.marker.name);
    }

    public onAddNote(currentNote: any) {
        console.log('onAddNote');
        this.$store.dispatch('addMarkerNote', this.marker.name);
    }

    public onDeleteMarker()
    {
        // This should dispatch an event to be picked up by PigProgressMarker.
        // As a result of this it will display a dialog box to the user asking them if they want to delete marker
        // If they do then it will have to delete marker from run and cleanly delete pigrun name from passages if any
    }

    public onAddPassage() {
        console.log('onAddPassage');
        var h = [];
        h.push(this.RunId);
        h.push(this.marker.name);
        this.$store.dispatch('addManualMarkerPassage', h);
        // this.$store.dispatch('addManualMarkerPassage', this.marker.name);
    }

    public onVerifyPassage() {
        console.log('onVerifyPassage');
        var h = [];
        h.push(this.RunId);
        h.push(this.marker.name);
        this.$store.dispatch('verifyMarkerPassage', h);
        // this.$store.dispatch('verifyMarkerPassage', this.marker.name);
    }

    public setOnline() {
        setTimeout(() => {
            this.isOnline = true;
        }, 5000);

    }

    public setOffline() {
        this.isOnline = false;
    }

    public visibilityChange() {
        let hidden = '';
        let visibilityChange = '';
        if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
            hidden = 'hidden';
            visibilityChange = 'visibilitychange';
        // @ts-ignore
        } else if (typeof document.msHidden !== 'undefined') {
            hidden = 'msHidden';
            visibilityChange = 'msvisibilitychange';
        // @ts-ignore
        } else if (typeof document.webkitHidden !== 'undefined') {
            hidden = 'webkitHidden';
            visibilityChange = 'webkitvisibilitychange';
        }
        if (document[hidden]) {
            this.setOffline();
        } else {
            this.setOnline();
        }
    }


    public watchOnlineStatus() {
        window.addEventListener('offline', this.setOffline);
        window.addEventListener('online', this.setOnline);
        window.addEventListener('visibilitychange', this.visibilityChange, false);
    }

    public stopWatching() {
        window.removeEventListener('offline', this.setOffline, false);
        window.removeEventListener('online', this.setOnline, false);
        window.removeEventListener('visibilitychange', this.visibilityChange, false);
    }

}

