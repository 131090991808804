import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import DefaultLayout from './layouts/Default.vue';
import Login from './views/Login.vue';
import AuthHandler from './views/AuthHandler.vue';
import ResetPassword from './views/ResetPassword.vue';
import UserManagementView from './views/UserManagementView.vue';
import { User } from './models/User';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      component: DefaultLayout,
      name: 'home',
      beforeEnter: async (to, from, next) => {
        if (to.hash) {
          // check if this is the authentication callback
          // this avoids the need for extra server config to support history mode
          next({ name: 'authenticated', hash: to.hash, query: to.query });
        } else if (store.state.user && store.state.user.hasOwnProperty('token')) {
          if (!(store.state.user instanceof User)) {
            // we already have session info in local storage
            // deserialize and attempt to refresh the token
            const user = new User(
              store.state.user.id,
              store.state.user.name,
              store.state.user.role,
              store.state.user.token,
              store.state.user.tokenExpiry,
            );
            user.refreshToken(); // it may kick back to login if token has expired
          }
          next();
        } else {
          next({ name: 'loginRedirect', params: { redirect: 'home' }, query: to.query});
        }
      },
    },
    {
      path: '/authenticated',
      name: 'authenticated',
      component: AuthHandler,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/reset',
      name: 'reset',
      component: ResetPassword,
    },
    {
      path: '/login/:redirect',
      name: 'loginRedirect',
      component: Login,
    },
    {
      path: '/users',
      name: 'userManagement',
      component: UserManagementView,
      beforeEnter: async (to, from, next) => {
        if (to.hash) {
          // check if this is the authentication callback
          // this avoids the need for extra server config to support history mode
          next({ name: 'authenticated', hash: to.hash });
        } else if (store.state.user && store.state.user.hasOwnProperty('token')) {
          if (!(store.state.user instanceof User)) {
            // we already have session info in local storage
            // deserialize and attempt to refresh the token
            const user = new User(
              store.state.user.id,
              store.state.user.name,
              store.state.user.role,
              store.state.user.token,
              store.state.user.tokenExpiry,
            );
            user.refreshToken(); // it may kick back to login if token has expired
          }
          next();
        } else {
          next({ name: 'loginRedirect', params: { redirect: 'userManagement' } });
        }
      },
    },
  ],
});

