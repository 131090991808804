import LoginService from '@/services/LoginService';
import store from '../store';
import router from '../router';

export interface IUser {
    id: string;
    name: string;
    role: string;
    token: string;
    tokenExpiry: number;
}

export class User implements IUser {
    constructor(
        public id: string,
        public name: string,
        public role: string,
        public token: string,
        public tokenExpiry: number,
    ) {}

    public isAuthenticated() {
        if (this.tokenExpiry < Date.now()) {
            return false;
        }
        return true;
    }

    public refreshToken() {
        console.log('User: refreshToken()');
        if (this.isAuthenticated()) {
            LoginService.refreshToken().then((result: any) => {
                if (result && result.hasOwnProperty('accessToken') && result.hasOwnProperty('expiresIn')) {
                    this.token = result.accessToken;
                    this.tokenExpiry = Date.now() + (result.expiresIn * 1000);
                    store.dispatch('login', this);
                }
            }).catch((err) => {
                store.dispatch('login', null);
                router.push({name: 'login'});
            });
        } else {
            store.dispatch('login', null);
            router.push({name: 'login'});
        }
    }
}
