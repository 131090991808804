// DownloadRecordedPassageDataFirmwareValidationRequest
//
// Class representing a download recorded passage data firmware validation request.
export class DownloadRecordedPassageDataFirmwareValidationRequest
{
    // Uid array.
    public uidList: number[];

    // Constructor.
    public constructor(uidList: number[])
    {
        this.uidList = uidList;
    }
}