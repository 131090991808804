
 export{
    TrFifo,
    TrBlockShiftRegister
}
 
 
class TrFifo
{
    constructor(blocksize)
    {
        this.m_blocksize = blocksize;
        this.data = [];
    }
   
    Put(input)
    {
        return(this.data.push(...input));// returns the new length of the array
    }

    Take()
    {
        if(this.data.length >= this.m_blocksize)
        {
            let output = [];
            output = this.data.splice(0,this.m_blocksize);
            return(output)
        }
        else
        {
            return(null)
        }
    }

    NumElements()
    {
        return(this.data.length);
    }
   
    HasData()
    {
        if(this.data.length >= this.m_blocksize)
        {
            return(true)
        }
        else
        {
            return(false)
        }
    }

    Clear()
    {
        this.data = [];
    }
}


class TrBlockShiftRegister
{
    constructor(inputblocksize, numblocks)
    {
        this.m_inputblocksize = inputblocksize;
        this.m_outputblocksize = numblocks*inputblocksize;
        this.data = [];
        this.output = []
        for(let i=0;i<this.m_outputblocksize;i++)
        {
            this.data[i] = 0.0;
            this.output[i] = 0.0;
        }
    }

    Put(input)
    { 
        if( (input.length != this.m_inputblocksize) || input.length == null )
        {
            return(null);
        }
        else
        {
            this.data.splice(0,this.m_inputblocksize);
            this.data.push(...input)
            /*this.data.splice(this.data.length - 32,32);
            this.data.unshift(...input);*/
            
            return(1);
        }
    }

    Take()
    {
        for(let i = 0; i< this.m_outputblocksize; i++)
        {
            this.output[i] = this.data[i];
        }
        return(this.output);
    }

    Clear()
    {
        this.data = [];
    }
}