
import * as signalR from '@aspnet/signalr';

const signalRConnection = new signalR.HubConnectionBuilder()
    .withUrl(process.env.VUE_APP_API_ROOT + '/streamhub')
    // .configureLogging(signalR.LogLevel.Critical) // TODO uncomment this in prod so all reconnects aren't logged
    .build();

signalRConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 60; // Three minutes
signalRConnection.serverTimeoutInMilliseconds = 1000 * 60 * 60; // Six minutes

async function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
async function connect(conn: any) {
    conn.start()
    .catch( (e: any) => {
        // these errors are NOT timeouts
        console.error(e);
        setTimeout(() => {
            console.log('Reconnecting Stream Socket');
            connect(conn);
        }, 5000);
        // sleep(5000); // progressivley increase this?
    });
}

// reconnect on close/timeout
signalRConnection.onclose((err: any) => {
    connect(signalRConnection);
});

export default {
    init() {
        connect(signalRConnection);
    },
    getConnection() {
        return signalRConnection;
    },
};
