import Vue from 'vue';
import { Command } from '../../models/Command';
import { CommandType } from '../../models/CommandType';
import CommandService from '../../services/CommandService';

export default {
    state: {
        commands: [],
    },
    mutations: {
        SET_COMMANDS(state: any, commands: Command[]) {
            state.commands = commands;
        },
        DELETE_COMMAND(state: any, commandId: number) {
            state.commands = state.commands.filter( (el: Command) => el.commandId !== commandId);
        },
    },
    actions: {
        getCommands(context: any, uid: number) {
            console.log('Action: getCommands()');
            CommandService.getCommands(uid)
            .then((response) => {
                const commands: Command[] = [];
                response.data.forEach((d: any) => {
                    commands.push(new Command(d.uid, CommandType.byName(d.commandType), d.timeStampSent, d.parameters, d.commandId));
                });
                context.commit('SET_COMMANDS', commands);
            })
            .catch((error) => {
                console.error(error);
                context.dispatch('notify', error);
            });
        },
        deleteCommand(context: any, commandId: number) {
            console.log('Action: deleteCommand()');
            CommandService.deleteCommand(commandId)
            .then((response) => {
                // TODO should I be checking for response 200?
                context.commit('DELETE_COMMAND', commandId);
            })
            .catch((error) => {
                console.error(error);
                context.dispatch('notify', error);
            });
        },
        postCommand(context: any, command: Command) {
            
            CommandService.postCommand(command)
            .then((response) => {
                // get the latest commands, it should have been given a commandId
                context.dispatch('getCommands', command.uid);
                context.dispatch('notify', 'Queued command');
                // do we want to close the command dialog now?
                context.dispatch('queueCommandsClose');
            })
            .catch((error) => {
                console.error(error);
                context.dispatch('notify', error);
            });
        },
        postCommands(context: any, commands: Command[]) {
            CommandService.postCommands(commands)
            .then((response) => {
                context.dispatch('notify', 'Queued commands');
            })
            .catch((error) => {
                console.error(error);
                context.dispatch('notify', error);
            });
        },
        queueCommandsShow(context: any) {
            // no state/mutations, this is only used to subscribe to actions in other components
        },
        queueCommandsIridiumShow(context: any) {
            // no state/mutations, this is only used to subscribe to actions in other components
        },
        queueCommandsClose(context: any) {
            // no state/mutations, this is only used to subscribe to actions in other components
        },
    },
};
