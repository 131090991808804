export class StreamEnable {
    public static Off = 'Off';
    public static On = 'On';
    public static getModes() {
        return [
            this.Off, // 0
            this.On, // 1
        ];
    }
    public static byIdx(i: number) {
        return [
            this.Off, // 0
            this.On, // 1
        ][i];
    }
    public static toString(s: string) {
        const idx = [
            'off', // 0
            'on', // 1
        ].indexOf(s);
        if (idx !== -1) {
            return this.byIdx(idx);
        } else {
            return '';
        }
    }
}
