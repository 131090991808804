export default class DateCommon
{
    // ConvertDateAsStringToDate.
    //
    // Function used to construct and return a TypeScript Date object based on the inputDateAsString parameter.
    //
    // Valid format = yyyy-MM-dd HH:mm:ss where the date is based on a 24-hour UTC clock.
    //
    // Constructing a TypeScript Date object will accept out-of-range parameter values and the
    // final Date object value will roll accordingly (strange but true). For example, November 31, 2022 (an invalid date)
    // will translate to December 1, 2022 etc.
    //
    // Note: This function is intended to be called after IsDateAsStringValid, provided it returned true.
    public static ConvertDateAsStringToDate(inputDateAsString : string) : Date
    {
        // Strip out colons.
        let compressedDateAsString : string = inputDateAsString.split(':').join('');

        // Strip out hyphens.
        compressedDateAsString = compressedDateAsString.split('-').join('');

        // Strip out spaces.
        compressedDateAsString = compressedDateAsString.split(' ').join('');

        // Year
        let yearAsString = compressedDateAsString.substring(0, 4);
        let yearAsInt = parseInt(yearAsString, 10);

        // Month
        let monthAsString = compressedDateAsString.substring(4, 6);
        let monthAsInt = parseInt(monthAsString, 10);
        
        // Day
        let dayAsString = compressedDateAsString.substring(6, 8);
        let dayAsInt = parseInt(dayAsString, 10);

        // Hour
        let hourAsString = compressedDateAsString.substring(8, 10);
        let hourAsInt = parseInt(hourAsString, 10);

        // Minute
        let minuteAsString = compressedDateAsString.substring(10, 12);
        let minuteAsInt = parseInt(minuteAsString, 10);

        // Second
        let secondAsString = compressedDateAsString.substring(12, 14);
        let secondAsInt = parseInt(secondAsString, 10);

        // At this point, each date element has been parsed from the inputDateAsString parameter. Construct a Date object
        // based on each element (year, month, hour, etc.) and return it.
        let dateObject = new Date();
        dateObject.setUTCFullYear(yearAsInt);
        dateObject.setUTCMonth(monthAsInt - 1);
        dateObject.setUTCDate(dayAsInt);
        dateObject.setUTCHours(hourAsInt);
        dateObject.setUTCMinutes(minuteAsInt);    
        dateObject.setUTCSeconds(secondAsInt);
        dateObject.setUTCMilliseconds(0);

        return dateObject;
    }

    // IsDateAsStringValid.
    //
    // Function used to determine if an input date as a string type has a valid format and value.
    //
    // Valid format = yyyy-MM-dd HH:mm:ss where the date is based on a 24-hour UTC clock.
    //
    // Constructing a TypeScript Date object will accept out-of-range parameter values and the
    // final Date object value will roll accordingly (strange but true). For example, November 31, 2022 (an invalid date)
    // will translate to December 1, 2022 etc.
    public static IsDateAsStringValid(inputDateAsString : string) : boolean
    {
        // Strip out colons.
        let compressedDateAsString : string = inputDateAsString.split(':').join('');

        // Strip out hyphens.
        compressedDateAsString = compressedDateAsString.split('-').join('');

        // Strip out spaces.
        compressedDateAsString = compressedDateAsString.split(' ').join('');

        if (compressedDateAsString.length != 14)
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. Compressed length != 14. Returning false.');

            return false;
        }

        // With the colons, hyphens, and spaces stripped out, verify that each remaining character
        // in the string is a single digit between 0 and 9.
        //
        // Note:
        // The / and / characters are used to start and end a regular expression.
        // The ^ character marks the beginning of the string input, and the $ character marks the end of it.
        // The \d pattern matches any digit (0 – 9) in the string.
        // Adding the + character after the \d makes the regex match one or more occurrences of the \d pattern.
        let regularExpressionTestResult : boolean = /^\d+$/.test(compressedDateAsString);
        
        if (!regularExpressionTestResult)
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. Regular expression test. Returning false.');

            return false;
        }

        // Year
        let yearAsString = compressedDateAsString.substring(0, 4);

        let yearAsInt = parseInt(yearAsString, 10);

        if (Number.isNaN(yearAsInt))
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. Year not valid. Returning false.');

            return false;
        }

        // Month
        let monthAsString = compressedDateAsString.substring(4, 6);

        let monthAsInt = parseInt(monthAsString, 10);
        
        if (Number.isNaN(monthAsInt))
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. Month not valid. Returning false.');

            return false;
        }

        // Day
        let dayAsString = compressedDateAsString.substring(6, 8);

        let dayAsInt = parseInt(dayAsString, 10);

        if (Number.isNaN(dayAsInt))
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. Day not valid. Returning false.');

            return false;
        }

        // Hour
        let hourAsString = compressedDateAsString.substring(8, 10);

        let hourAsInt = parseInt(hourAsString, 10);

        if (Number.isNaN(hourAsInt))
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. Hour not valid. Returning false.');

            return false;
        }

        // Minute
        let minuteAsString = compressedDateAsString.substring(10, 12);

        let minuteAsInt = parseInt(minuteAsString, 10);

        if (Number.isNaN(minuteAsInt))
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. Minute not valid. Returning false.');

            return false;
        }

        // Second
        let secondAsString = compressedDateAsString.substring(12, 14);

        let secondAsInt = parseInt(secondAsString, 10);

        if (Number.isNaN(secondAsInt))
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. Second not valid. Returning false.');

            return false;
        }

        // At this point, each date element has been parsed from the input string argument. Construct a Date object
        // based on each element (year, month, hour, etc.) and verify out-of-bounds elements have not been rolled
        // over.
        let dateObject = new Date();
        dateObject.setUTCFullYear(yearAsInt);
        dateObject.setUTCMonth(monthAsInt - 1);
        dateObject.setUTCDate(dayAsInt);
        dateObject.setUTCHours(hourAsInt);
        dateObject.setUTCMinutes(minuteAsInt);    
        dateObject.setUTCSeconds(secondAsInt);
        dateObject.setUTCMilliseconds(0);

        // Year
        if (dateObject.getUTCFullYear() != yearAsInt)
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. The year has rolled. Returning false.');

            return false;
        }

        // Month
        if (dateObject.getUTCMonth() != monthAsInt - 1)
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. The month has rolled. Returning false.');

            return false;
        }

        // Day
        if (dateObject.getUTCDate() != dayAsInt)
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. The day has rolled. Returning false.');

            return false;
        }

        // Hour
        if (dateObject.getUTCHours() != hourAsInt)
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. The hour has rolled. Returning false.');

            return false;
        }

        // Minute
        if (dateObject.getUTCMinutes() != minuteAsInt)
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. The minute has rolled. Returning false.');

            return false;
        }

        // Second
        if (dateObject.getUTCSeconds() != secondAsInt)
        {
            console.log('DateCommon.ts. IsDateAsStringValid. The inputDateAsString parameter is not a valid date. The second has rolled. Returning false.');

            return false;
        }

        return true;
    }

    public static sqlServerToDate(sqlServerDate: string)
    {
        if (sqlServerDate == undefined)
        {
            return undefined;
        }
    
        if (sqlServerDate == null)
        {
            return undefined;
        }
    
        if (sqlServerDate.length !== 26)
        {
            console.error('Unknown date format: ' + sqlServerDate);
    
            return undefined;
        }
    
        return new Date(sqlServerDate.replace(' ', 'T').substring(0, 23) + 'Z');
    }

    public static utcTime(date: Date) {
        return date.getTime() + (date.getTimezoneOffset() * 60 * 1000);
    }
}
