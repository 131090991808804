import GWorker from 'worker-loader!./TrAgmGeoWorker.js';
import EWorker from 'worker-loader!./TrAgmEmWorker.js';
import SpWorker from 'worker-loader!./TrAgmSpectWorker.js';
import RssiWorker from 'worker-loader!./TrAgmRSSIWorker.js';
import
{
    TrFifo
} from '../tools/trfifo';


export {
    InitWorkers,
    PassWorkersRaw, /* AGM Geophone Data Input */
    GetGeoData,  /* AGM Geophone Data Output to be plotted */
    GetFilteredGeoData,  /* AGM Filtered Geophone Data Output to be plotted */
    GetEMData,
    GetMAGData,
    GetMAGData16_67Hz,
    GetMAGN,
    GetELFData,
    GetELFN,
    GetELFimagData,
    GetRSSIData,
    UpdateTimeSpan,
    ClearGeoBuffers,
    GetMatrix,
    GetSpectMax,
    GetSpectMin,
    UpdateRSSIFreq,

    UpdateWeldThresh,
    UpdateGeoAutoState,
    UpdateWeldCount,
    UpdateGeoPassageThresh,
    SetMaxCnt,
    SetMinCnt,
    GetDetectout,
    GeoPassageFifoTakeData,
    GeoPassageFifoHasData,
    SetDetectuseRaw
}

let Geoworker,EMworker,Specworker,RSSIworker;
let NOutput = new Array(6)
let ELFNOutput = new Array(6);

let GeoT1 = new Array(0); // Array for timespan 5 sec
let GeoT2 = new Array(0); // Array for timespan 30 sec
let GeoT3 = new Array(0); // Array for timespan 60 sec
let GeoT4 = new Array(0); // Array for timespan 300 sec
let GeoT5 = new Array(0); // Array for timespan 600 sec
let GeoT6 = new Array(0); // Array for timespan 1800 sec

let HPGeoT1 = new Array(0); // Array for timespan 5 sec
let HPGeoT2 = new Array(0); // Array for timespan 30 sec
let HPGeoT3 = new Array(0); // Array for timespan 60 sec
let HPGeoT4 = new Array(0); // Array for timespan 300 sec
let HPGeoT5 = new Array(0); // Array for timespan 600 sec
let HPGeoT6 = new Array(0); // Array for timespan 1800 sec



let EMT1 = new Array(0); // Array for timespan 5 sec
let EMT2 = new Array(0); // Array for timespan 30 sec
let EMT3 = new Array(0); // Array for timespan 60 sec
let EMT4 = new Array(0); // Array for timespan 300 sec
let EMT5 = new Array(0); // Array for timespan 600 sec
let EMT6 = new Array(0); // Array for timespan 1800 sec

let MAGT1 = new Array(0); // Array for timespan 5 sec
let MAGT2 = new Array(0); // Array for timespan 30 sec
let MAGT3 = new Array(0); // Array for timespan 60 sec
let MAGT4 = new Array(0); // Array for timespan 300 sec
let MAGT5 = new Array(0); // Array for timespan 600 sec
let MAGT6 = new Array(0); // Array for timespan 1800 sec

let ELFT1 = new Array(0); // Array for timespan 5 sec
let ELFT2 = new Array(0); // Array for timespan 30 sec
let ELFT3 = new Array(0); // Array for timespan 60 sec
let ELFT4 = new Array(0); // Array for timespan 300 sec
let ELFT5 = new Array(0); // Array for timespan 600 sec
let ELFT6 = new Array(0); // Array for timespan 1800 sec

let ELFT1imag = new Array(0); // Array for timespan 5 sec
let ELFT2imag = new Array(0); // Array for timespan 30 sec
let ELFT3imag = new Array(0); // Array for timespan 60 sec
let ELFT4imag = new Array(0); // Array for timespan 300 sec
let ELFT5imag = new Array(0); // Array for timespan 600 sec
let ELFT6imag = new Array(0); // Array for timespan 1800 sec

let MAGT3PERSIST = new Array(0); // Array for timespan 60 sec

let RSSIT1 = new Array(0); // Array for timespan 5 sec
let RSSIT2 = new Array(0); // Array for timespan 30 sec
let RSSIT3 = new Array(0); // Array for timespan 60 sec
let RSSIT4 = new Array(0); // Array for timespan 300 sec
let RSSIT5 = new Array(0); // Array for timespan 600 sec
let RSSIT6 = new Array(0); // Array for timespan 1800 sec

let MAG16_67Hz = new Array(0);
let MAG16_67HzOUT = new Array(0);

let Matrix = new Array(0);
let MatrixWidth = 1800*3.2;
let SpectMax;
let SpectMin;
let TimeSpan = 60;
let MatrixBinLength = 53;
let MN1,MN2,MN3,MN4,MN5,MN6;
let GeoPsgFifo = new TrFifo(1);

function InitWorkers(){
    
    console.log("TrAgmRTManager: Initializing Workers...");
    
    Geoworker = new GWorker();
    EMworker = new EWorker();
    Specworker = new SpWorker();
    RSSIworker = new RssiWorker();
    NOutput.fill(0,0,5);
    ELFNOutput.fill(0,0,5);

    

    Geoworker.onmessage = function(e){
        let data = e.data;
        switch(data.type){
            case 'outputdata': ReceiveProcessedGeo(data.output); return;
            case 'GeoDetect':  GeoDetectionUpdate(data.output); return;
            case 'GeoDetectPassage': GeophonePassageUpdate(data.output); return;
        }

        console.warn('Traa: Unhandled worker message');
    }

    EMworker.onmessage = function(e){
        let data = e.data;
        switch(data.type){
            case 'outputdata': 
            ReceiveProcessedEM(data.output); 
            NOutput[0] = NOutput[0] + data.N1MAG;
            NOutput[1] = NOutput[1] + data.N2MAG;
            NOutput[2] = NOutput[2] + data.N3MAG;
            NOutput[3] = NOutput[3] + data.N4MAG;
            NOutput[4] = NOutput[4] + data.N5MAG;
            NOutput[5] = NOutput[5] + data.N6MAG;
            ELFNOutput[0] = ELFNOutput[0] + data.N1ELF;
            ELFNOutput[1] = ELFNOutput[1] + data.N2ELF;
            ELFNOutput[2] = ELFNOutput[2] + data.N3ELF;
            ELFNOutput[3] = ELFNOutput[3] + data.N4ELF;
            ELFNOutput[4] = ELFNOutput[4] + data.N5ELF;
            ELFNOutput[5] = ELFNOutput[5] + data.N6ELF;
            return;
        }
        console.warn('Traa: Unhandled worker message');
    }

    Specworker.onmessage = function(e){
        let data = e.data;
        switch(data.type){
            case 'outputdata': ReceiveProcessedSpec(data.output); return;
            //case 'MatrixSize': initMatrix(data.output); return;
        }
        console.warn('Traa: Unhandled worker message');
    }


    RSSIworker.onmessage = function(e){
        let data = e.data;
        switch(data.type){
            case 'outputdata': ReceiveProcessedRSSI(data.output); return;
        }
        console.warn('Traa: Unhandled worker message');
    }

    //Initializations
    Geoworker.postMessage({'type': 'init'});
    EMworker.postMessage({'type': 'init'});
    Specworker.postMessage({'type': 'init'});
    RSSIworker.postMessage({'type': 'init'});
    initMatrix();
}

function initMatrix()
{
    Matrix = [];
    for(let w = 0;w<MatrixBinLength;w++)     // 102 bins between 10 and 30 hz
    {
        var MatrixArray = new Array(0);
        for(let w=0;w<MatrixWidth;w++) //
        {
            MatrixArray.push(45); // -100 is like no data
        }

        Matrix.push(MatrixArray);
    }
}

function PassWorkersRaw(geodata,emdata){
    // Forward Data to the workers 
    Geoworker.postMessage({'type': 'agmdata', 'input': geodata});
    EMworker.postMessage({'type': 'agmdata', 'input': emdata});

    // I may want to decimate here to get 100Hz. then pass to Spect & RSSI
    Specworker.postMessage({'type': 'agmdata', 'input': emdata});
    RSSIworker.postMessage({'type': 'agmdata', 'input': emdata});
}


function SetDetectuseRaw(state){
    // Forward Data to the workers 
    Geoworker.postMessage({'type': 'SetDetectuseRaw', 'input': state});
}

function GetGeoData(input){
    // send out for plotting
    var temp = null;var temp2 = null;var temp3 = null;var temp4 = null;var temp5 = null;var temp6 = null,Geocopy = null;
    var maxarray = new Array(0),minarray = new Array(0);
    var outputarray;

    if(GeoT1.length > 0)
    {
        temp = GeoT1.shift()
        //temp = temp * 100;
    }

    if(GeoT2.length > 0)
    {
        temp2 = GeoT2.shift()
        //temp2 = temp2 * 100;
    }
    if(GeoT3.length > 0)
    {
        temp3 = GeoT3.shift()
        //temp3 = temp3 * 100;
    }
    if(GeoT4.length > 0)
    {
        temp4 = GeoT4.shift()
        //temp4 = temp4 * 100;
    }
    if(GeoT5.length > 0)
    {
        temp5 = GeoT5.shift()
        //temp5 = temp5 * 100;
    }
    if(GeoT6.length > 0)
    {
        temp6 = GeoT6.shift()
        //temp6 = temp6 * 100;
    }

    if(TimeSpan == 5){}
    else if(TimeSpan == 30){temp = temp2}
    else if(TimeSpan == 60){temp = temp3}
    else if(TimeSpan == 300){temp = temp4}
    else if(TimeSpan == 600){temp = temp5}
    else if(TimeSpan == 1800){temp = temp6}



    if(temp == null)
    {
        return null;
    }

    for (let i = 0;i<temp.length;i++)
    {
        maxarray.unshift(temp[i].max);
        minarray.unshift(temp[i].min);
    }

    outputarray = {maxarray,minarray}
    
    return outputarray;
}

function GetFilteredGeoData(input){
    // send out for plotting
    var temp = null;var temp2 = null;var temp3 = null;var temp4 = null;var temp5 = null;var temp6 = null,Geocopy = null;
    var maxarray = new Array(0),minarray = new Array(0);
    var outputarray;

    if(HPGeoT1.length > 0)
    {
        temp = HPGeoT1.shift()
    }

    if(HPGeoT2.length > 0)
    {
        temp2 = HPGeoT2.shift()
    }
    if(HPGeoT3.length > 0)
    {
        temp3 = HPGeoT3.shift()
    }
    if(HPGeoT4.length > 0)
    {
        temp4 = HPGeoT4.shift()
    }
    if(HPGeoT5.length > 0)
    {
        temp5 = HPGeoT5.shift()
    }
    if(HPGeoT6.length > 0)
    {
        temp6 = HPGeoT6.shift()
    }

    if(TimeSpan == 5){}
    else if(TimeSpan == 30){temp = temp2}
    else if(TimeSpan == 60){temp = temp3}
    else if(TimeSpan == 300){temp = temp4}
    else if(TimeSpan == 600){temp = temp5}
    else if(TimeSpan == 1800){temp = temp6}



    if(temp == null)
    {
        return null;
    }

    for (let i = 0;i<temp.length;i++)
    {
        maxarray.unshift(temp[i].max);
        minarray.unshift(temp[i].min);
    }

    outputarray = {maxarray,minarray}
    
    return outputarray;
}





function ReceiveProcessedGeo(data)
{
    if(TimeSpan == 5)
    {
        if(GeoT1.length >= 1)
        {
            GeoT1.shift();
        }
        GeoT1.push(data[0]);
    //////////////////////////
        if(HPGeoT1.length >= 1)
        {
            HPGeoT1.shift();
        }
        HPGeoT1.push(data[6]);
    }
    else if(TimeSpan == 30)
    {
        if(GeoT2.length >= 1)
        {
            GeoT2.shift();
        }
        GeoT2.push(data[1]);
    //////////////////////////
        if(HPGeoT2.length >= 1)
        {
            HPGeoT2.shift();
        }
        HPGeoT2.push(data[7]);
    }
    else if(TimeSpan == 60)
    {
        if(GeoT3.length >= 1)
        {
            GeoT3.shift();
        }
        GeoT3.push(data[2]);
    //////////////////////////
        if(HPGeoT3.length >= 1)
        {
            HPGeoT3.shift();
        }
        HPGeoT3.push(data[8]);
    }    
    else if(TimeSpan == 300)
    {
        if(GeoT4.length >= 1)
        {
            GeoT4.shift();
        }
        GeoT4.push(data[3]);
    //////////////////////////
        if(HPGeoT4.length >= 1)
        {
            HPGeoT4.shift();
        }
        HPGeoT4.push(data[9]);
    }    
    else if(TimeSpan == 600)
    {
        if(GeoT5.length >= 1)
        {
            GeoT5.shift();
        }
        GeoT5.push(data[4]);
//////////////////////////
        if(HPGeoT5.length >= 1)
        {
            HPGeoT5.shift();
        }
        HPGeoT5.push(data[10]);
    }    
    else if(TimeSpan == 1800)
    {
        if(GeoT6.length >= 1)
        {
            GeoT6.shift();
        }
        GeoT6.push(data[5]);
//////////////////////////
        if(HPGeoT6.length >= 1)
        {
            HPGeoT6.shift();
        }
        HPGeoT6.push(data[5]);
    }     
}

function UpdateTimeSpan(input)
{
    TimeSpan = input;
    RSSIworker.postMessage({'type': 'timeupdate', 'input': TimeSpan});
}

function ClearGeoBuffers()
{
    Geoworker.postMessage({'type': 'close'});
    EMworker.postMessage({'type': 'close'});
    Specworker.postMessage({'type': 'close'});
    RSSIworker.postMessage({'type': 'close'});
  
    GeoT1 = [];
    GeoT2 = [];
    GeoT3 = [];
    GeoT4 = [];
    GeoT5 = [];
    GeoT6 = [];

    EMT1 = [];
    EMT2 = [];
    EMT3 = [];
    EMT4 = [];
    EMT5 = [];
    EMT6 = [];

    MAGT1 = [];
    MAGT2 = [];
    MAGT3 = [];
    MAGT4 = [];
    MAGT5 = [];
    MAGT6 = [];
    MAGT3PERSIST = [];

    RSSIT1 = [];
    RSSIT2 = [];
    RSSIT3 = [];
    RSSIT4 = [];
    RSSIT5 = [];
    RSSIT6 = [];
}


function GetMatrix()
{
    if(Matrix == null)
    {
        return;
    }

    if(TimeSpan == 1800)
    {
        return Matrix;
    }
  
    // First we get a copy of Matrix
    
    let temp = new Array(0);
    SpectMax = Matrix[0][0];
    SpectMin = Matrix[0][0]; 
   
    for(var i=0;i<Matrix.length;i++)
    {
        var array = new Array(0)
        for(var ii=0;ii<Matrix[i].length;ii++)
        {
            array.push(Matrix[i][ii])
        }
        array.splice(0,(array.length - (TimeSpan*3.2)));
        temp.push(array)

        for(var w=0;w<temp[i].length;w++)
        {
            if(SpectMax<temp[i][w])
            {
                SpectMax = temp[i][w];
            }
            if(SpectMin>temp[i][w])
            {
                SpectMin = temp[i][w];
            }
        }
    }

    return temp;
}


function GetSpectMax()
{
    if(SpectMax == 0)
    {
        SpectMax = 1
    }
    return SpectMax
}
function GetSpectMin()
{
    if(SpectMin == 0)
    {
        SpectMin = 1
    }
    return SpectMin
}



function ReceiveProcessedEM(data)
{
    if(TimeSpan == 5)//200 Hz sampling
    {
        if(EMT1.length >= 1)
        {
            EMT1.shift();
            MAGT1.shift();
            ELFT1.shift();
            ELFT1imag.shift();
        }
        EMT1.push(data[0]);
        MAGT1.push(data[6]);
        ELFT1.push(data[12]);
        ELFT1imag.push(data[18]);
    }
    else if(TimeSpan == 30)//33.3333 Hz sampling
    {
        if(EMT2.length >= 1)
        {
            EMT2.shift();
            MAGT2.shift();
            ELFT2.shift();
            ELFT2imag.shift();
        }
        EMT2.push(data[1]);
        MAGT2.push(data[7]);
        ELFT2.push(data[13]);
        ELFT2imag.push(data[19]);
    }
    else if(TimeSpan == 60)/// 16.67 hz sampling
    {
        if(EMT3.length >= 1)
        {
            EMT3.shift();
            MAGT3.shift();
            ELFT3.shift();
            ELFT3imag.shift();
        }
        
        EMT3.push(data[2]);
        MAGT3.push(data[8]);
        ELFT3.push(data[14]);
        ELFT3imag.push(data[20]);
    }    
    else if(TimeSpan == 300)//3.33 hz sampling
    {
        if(EMT4.length >= 1)
        {
            EMT4.shift();
            MAGT4.shift();
            ELFT4.shift();
            ELFT4imag.shift();
        }
        EMT4.push(data[3]);
        MAGT4.push(data[9]);
        ELFT4.push(data[15]);
        ELFT4imag.push(data[21]);
    }    
    else if(TimeSpan == 600)//1.667 hz sampling
    {
        if(EMT5.length >= 1)
        {
            EMT5.shift();
            MAGT5.shift();
            ELFT5.shift();
            ELFT5imag.shift();
        }
        EMT5.push(data[4]);
        MAGT5.push(data[10]);
        ELFT5.push(data[16]);
        ELFT5imag.push(data[22]);
    }    
    else if(TimeSpan == 1800)//0.555 hz samping
    {
        if(EMT6.length >= 1)
        {
            EMT6.shift();
            MAGT6.shift();
            ELFT6.shift();
            ELFT6imag.shift();
        }
        EMT6.push(data[5]);
        MAGT6.push(data[11]);
        ELFT6.push(data[17]);
        ELFT6imag.push(data[23]);
    }    

//this gives a copy of the data always at the 16.67 Hz rate
    if(MAGT3PERSIST.length >= 1)
    {
        MAGT3PERSIST.shift();
    }
    MAGT3PERSIST.push(data[8]);
}


function GetEMData(input){
    var temp = null;var temp2 = null;var temp3 = null;var temp4 = null;var temp5 = null;var temp6 = null; 
    var maxarray = new Array(0),minarray = new Array(0);
    var outputarray;


    if(EMT1.length > 0)
    {
        temp = EMT1.shift();
    }

    if(EMT2.length > 0)
    {
        temp2 = EMT2.shift();
    }
    if(EMT3.length > 0)
    {
        temp3 = EMT3.shift();
    }
    if(EMT4.length > 0)
    {
        temp4 = EMT4.shift();
    }
    if(EMT5.length > 0)
    {
        temp5 = EMT5.shift();
    }
    if(EMT6.length > 0)
    {
        temp6 = EMT6.shift();
    }

    if(TimeSpan == 5){}
    else if(TimeSpan == 30){temp = temp2}
    else if(TimeSpan == 60){temp = temp3}
    else if(TimeSpan == 300){temp = temp4}
    else if(TimeSpan == 600){temp = temp5}
    else if(TimeSpan == 1800){temp = temp6}



    if(temp == null)
    {
        return null;
    }

    for (let i = 0;i<temp.length;i++)
    {
        maxarray.unshift(temp[i].max);
        minarray.unshift(temp[i].min);
    }
    

    outputarray = {maxarray,minarray}
    
    return outputarray;
}



function GetMAGData(input){
    var temp = null;var temp2 = null;var temp3 = null;var temp4 = null;var temp5 = null;var temp6 = null;var magtmp = null;
    var maxarray = new Array(0),minarray = new Array(0);

    var outputarray;


    if(MAGT1.length > 0)
    {
        temp = MAGT1.shift();
    }

    if(MAGT2.length > 0)
    {
        temp2 = MAGT2.shift();
    }
    if(MAGT3.length > 0)
    {
        temp3 = MAGT3.shift();
    }
    if(MAGT4.length > 0)
    {
        temp4 = MAGT4.shift();
    }
    if(MAGT5.length > 0)
    {
        temp5 = MAGT5.shift();
    }
    if(MAGT6.length > 0)
    {
        temp6 = MAGT6.shift();
    }

    if(MAGT3PERSIST.length > 0)
    {
        magtmp = MAGT3PERSIST.shift();
    }

    
    if(TimeSpan == 5){}
    else if(TimeSpan == 30){temp = temp2}
    else if(TimeSpan == 60){temp = temp3}
    else if(TimeSpan == 300){temp = temp4}
    else if(TimeSpan == 600){temp = temp5}
    else if(TimeSpan == 1800){temp = temp6}

    

    if(temp == null)
    {
        return null;
    }

    for (let i = 0;i<temp.length;i++)
    {
        maxarray.unshift(temp[i].max);
        minarray.unshift(temp[i].min);
        MAG16_67Hz[i] = magtmp[i].max;
    }
    

    outputarray = {maxarray,minarray}
    
    return outputarray;
}

function GetELFData(input){
    var temp = null;var temp2 = null;var temp3 = null;var temp4 = null;var temp5 = null;var temp6 = null;var magtmp = null;
    var maxarray = new Array(0),minarray = new Array(0);

    var outputarray;


    if(ELFT1.length > 0)
    {
        temp = ELFT1.shift();
    }

    if(ELFT2.length > 0)
    {
        temp2 = ELFT2.shift();
    }
    if(ELFT3.length > 0)
    {
        temp3 = ELFT3.shift();
    }
    if(ELFT4.length > 0)
    {
        temp4 = ELFT4.shift();
    }
    if(ELFT5.length > 0)
    {
        temp5 = ELFT5.shift();
    }
    if(ELFT6.length > 0)
    {
        temp6 = ELFT6.shift();
    }

    // if(MAGT3PERSIST.length > 0)
    // {
    //     magtmp = MAGT3PERSIST.shift();
    // }

    
    if(TimeSpan == 5){}
    else if(TimeSpan == 30){temp = temp2}
    else if(TimeSpan == 60){temp = temp3}
    else if(TimeSpan == 300){temp = temp4}
    else if(TimeSpan == 600){temp = temp5}
    else if(TimeSpan == 1800){temp = temp6}

    

    if(temp == null)
    {
        return null;
    }

    for (let i = 0;i<temp.length;i++)
    {
        maxarray.unshift(temp[i].max);
        minarray.unshift(temp[i].min);
        //MAG16_67Hz[i] = magtmp[i].max;
    }
    

    outputarray = {maxarray,minarray}
    
    return outputarray;
}

function GetELFimagData(input){
    var temp = null;var temp2 = null;var temp3 = null;var temp4 = null;var temp5 = null;var temp6 = null;var magtmp = null;
    var maxarray = new Array(0),minarray = new Array(0);

    var outputarray;


    if(ELFT1imag.length > 0)
    {
        temp = ELFT1imag.shift();
    }

    if(ELFT2imag.length > 0)
    {
        temp2 = ELFT2imag.shift();
    }
    if(ELFT3imag.length > 0)
    {
        temp3 = ELFT3imag.shift();
    }
    if(ELFT4imag.length > 0)
    {
        temp4 = ELFT4imag.shift();
    }
    if(ELFT5imag.length > 0)
    {
        temp5 = ELFT5imag.shift();
    }
    if(ELFT6imag.length > 0)
    {
        temp6 = ELFT6imag.shift();
    }

    // if(MAGT3PERSIST.length > 0)
    // {
    //     magtmp = MAGT3PERSIST.shift();
    // }

    
    if(TimeSpan == 5){}
    else if(TimeSpan == 30){temp = temp2}
    else if(TimeSpan == 60){temp = temp3}
    else if(TimeSpan == 300){temp = temp4}
    else if(TimeSpan == 600){temp = temp5}
    else if(TimeSpan == 1800){temp = temp6}

    

    if(temp == null)
    {
        return null;
    }

    for (let i = 0;i<temp.length;i++)
    {
        maxarray.unshift(temp[i].max);
        minarray.unshift(temp[i].min);
        //MAG16_67Hz[i] = magtmp[i].max;
    }
    

    outputarray = {maxarray,minarray}
    
    return outputarray;
}

function GetMAGData16_67Hz()
{
    let i;
    for(i=0;i<MAG16_67Hz.length;i++)
    {
        MAG16_67HzOUT[i] = MAG16_67Hz[i];
    }
    return(MAG16_67HzOUT);
}

function GetMAGN()
{
    let output = new Array(0);


    output[0] = NOutput[0];
    output[1] = NOutput[1]; 
    output[2] = NOutput[2]; 
    output[3] = NOutput[3]; 
    output[4] = NOutput[4]; 
    output[5] = NOutput[5]; 
    NOutput.fill(0,0,5);

    return(output);
}

function GetELFN()
{
    let output = new Array(0);


    output[0] = ELFNOutput[0];
    output[1] = ELFNOutput[1]; 
    output[2] = ELFNOutput[2]; 
    output[3] = ELFNOutput[3]; 
    output[4] = ELFNOutput[4]; 
    output[5] = ELFNOutput[5]; 
    ELFNOutput.fill(0,0,5);

    return(output);
}

function ReceiveProcessedSpec(input)
{
   //input length and Matrix vertical length must be equal
    for(var w = 0;w<input.length;w++)
    {
        Matrix[w].push(input[w]);
        Matrix[w].shift();    
    }
     
}


function ReceiveProcessedRSSI(data)
{
    if(TimeSpan == 5)
    {
        if(RSSIT1.length >= 1)
        {
            RSSIT1.shift();
        }
        RSSIT1.push(data[0]);
    }
    else if(TimeSpan == 30)
    {
        if(RSSIT2.length >= 1)
        {
            RSSIT2.shift();
        }
        RSSIT2.push(data[0]);
    }
    else if(TimeSpan == 60)
    {
        if(RSSIT3.length >= 1)
        {
            RSSIT3.shift();
        }
        RSSIT3.push(data[0]);
    }    
    else if(TimeSpan == 300)
    {
        if(RSSIT4.length >= 1)
        {
            RSSIT4.shift();
        }
        RSSIT4.push(data[0]);
    }    
    else if(TimeSpan == 600)
    {
        if(RSSIT5.length >= 1)
        {
            RSSIT5.shift();
        }
        RSSIT5.push(data[0]);
    }    
    else if(TimeSpan == 1800)
    {
        if(RSSIT6.length >= 1)
        {
            RSSIT6.shift();
        }
        RSSIT6.push(data[0]);
    }    
}


function GetRSSIData(input)
{
    var temp = null;
    var maxarray = new Array(0),minarray = new Array(0);
    var outputarray;

    temp = new Array(0);

    if(TimeSpan == 5)
    {
        if(RSSIT1.length > 0)
        {
            temp = RSSIT1.shift()
        }
    }
    else if(TimeSpan == 30)
    {
        if(RSSIT2.length > 0)
        {
            temp = RSSIT2.shift()
        }
    }
    else if(TimeSpan == 60)
    {
        if(RSSIT3.length > 0)
        {
            temp = RSSIT3.shift()
        }
    }
    else if(TimeSpan == 300)
    {
        if(RSSIT4.length > 0)
        {
            temp = RSSIT4.shift()
        }
    }
    else if(TimeSpan == 600)
    {
        if(RSSIT5.length > 0)
        {
            temp = RSSIT5.shift()
        }
    }
    else if(TimeSpan == 1800)
    {
        if(RSSIT6.length > 0)
        {
            temp = RSSIT6.shift()
        }
    }





    if(temp == null || temp.length==0)
    {
        return null;
    }

    for (let i = 0;i<temp.length;i++)
    {
        maxarray.unshift(temp[i].max);
        minarray.unshift(temp[i].min);
    }
    

    outputarray = {maxarray,minarray}
    
    return outputarray;
}

function UpdateRSSIFreq(value)
{
    RSSIworker.postMessage({'type': 'frequpdate', 'input':value});
    EMworker.postMessage({'type': 'frequpdate', 'input':value})
    RSSIT1 = [];
    RSSIT2 = [];
    RSSIT3 = [];
    RSSIT4 = [];
    RSSIT5 = [];
    RSSIT6 = [];
}











let Detectout = null; // holds detection count & time values received by GeoWorker
function GeoDetectionUpdate(data)
{
    // Detection Message
    // data is of type {weldcount,TimeBetween,Time5,Time10};
    var temp = data;
    Detectout = temp; 
}

function GetDetectout()
{
    return Detectout;
}

function GeophonePassageUpdate(input)
{
    GeoPsgFifo.Put(input);
}


function SetMaxCnt(input)
{
    //MaxPulseCnt = input;
    Geoworker.postMessage({'type': 'SetMaxCnt', 'input': input});
}
function SetMinCnt(input)
{
    //MinPulseCnt = input;
    Geoworker.postMessage({'type': 'SetMinCnt', 'input': input});
}





function UpdateWeldThresh(input)
{
    Geoworker.postMessage({'type': 'SetThresh', 'input': input});
}

function UpdateGeoAutoState(input)
{
    Geoworker.postMessage({'type': 'EnableDetect', 'input': input});
}

function UpdateGeoPassageThresh(input)
{
    Geoworker.postMessage({'type': 'GeoPsgThresh', 'input': input});
}


function UpdateWeldCount(input)
{
  // post message to worker to update weld count
  Geoworker.postMessage({'type': 'UpdateWeldCnt', 'input': input});
}

function GeoPassageFifoHasData()
{
    var ret;
    if(GeoPsgFifo == null)
    {
        return(false);
    }
    ret = GeoPsgFifo.HasData();
    return(ret);
}

function GeoPassageFifoTakeData()
{
    var ret = new Array(1);

    ret = GeoPsgFifo.Take();
    return(ret);
}