
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import AddDevice from '@/components/AddDevice.vue';
import RemoveDevice from '@/components/RemoveDevice.vue';
import ChangePassword from '@/components/ChangePassword.vue';
import DeviceInfo from '@/components/DeviceInfo.vue';
import DeviceTable from '@/components/DeviceTable.vue';
import QueueCommands from '@/components/QueueCommands.vue';
import QueueIridiumCommands from '@/components/QueueIridiumCommands.vue';
import DeviceHistory from '@/components/DeviceHistory.vue';
import PassageDialog from '@/components/PassageDialog.vue';
import About from '@/components/About.vue';
import PigRunManager from '@/components/PigRunManager.vue';
import PigRunEditor from '@/components/PigRunEditor.vue';
import PigProgressMarkers from '@/components/PigProgressMarkers.vue';
import NotificationSettings from '@/components/NotificationSettings.vue';
import RealTimeStream from '@/components/RealTimeStream.vue';
import UserManagementLocal from '@/components/UserManagementLocal.vue';
import RealTimeOptions from '@/components/RealTimeOptions.vue';
import Map from '@/views/Map.vue';
import ReportManager from '@/components/ReportManager.vue';
import BroadcastCommands from '@/components/BroadcastCommands.vue';
import { openURL } from 'quasar';

@Component(
{
    components:
    {
        AddDevice,
        RemoveDevice,
        ChangePassword,
        DeviceInfo,
        DeviceTable,
        QueueCommands,
        QueueIridiumCommands,
        DeviceHistory,
        PassageDialog,
        About,
        PigRunManager,
        PigRunEditor,
        PigProgressMarkers,
        NotificationSettings,
        RealTimeStream,
        UserManagementLocal,
        RealTimeOptions,
        Map,
        ReportManager,
        BroadcastCommands
    },
})
export default class LayoutDefault extends Vue {
    public leftDrawerOpen = this.$store.state.leftDrawerOpen;
    public devicesReady = false;
    public startupComplete = false;

    // Component method for the tech support q-item-section click handler.
    public onTechSupportClick()
    {
        console.log('Default.vue. onTechSupportClick.');

        openURL('https://support.propipenorthamerica.com');
    }

    // Declared as computed property getter.
    get activePigRuns()
    {
        return this.$store.state.pigRun.activePigRuns;
    }

    // activePigRunsFiltered.
    //
    // Getter used to filter the active pig run id values used to create PigProgressMarkers.vue component instance(s) above. An
    // authenticated user having viewer role membership can only see active pig run(s) that they have a notification subscription
    // for.
    //
    // The list of active pig run id values originates from the web api method GetActivePigRunListForUserWithNotification in the
    // pig run controller.
    //
    // Declared as computed property getter.
    get activePigRunsFiltered()
    {
        let filteredPigRuns: any[] = [];
        console.log('Default.vue:get active filtered')
        if (this.$store.state.user.role == 'viewer')
        {
            if ((this.$store.state.activePigRunArrayWithNotification != undefined) && (this.$store.state.activePigRunArrayWithNotification.length > 0))
            {
                for (var i = 0; i < this.$store.state.pigRun.activePigRuns.length; i++)
                {
                    // console.log('DEFAULT.VUE INSIDE FOR. activePigRunArrayWithNotification.length = ' + this.$store.state.activePigRunArrayWithNotification.length);
                    // console.log('DEFAULT.VUE INSIDE FOR. activePigRunArrayWithNotification.findIndex = ' + this.$store.state.activePigRunArrayWithNotification.findIndex( (element: number) => element == this.$store.state.pigRun.activePigRuns[i].runId ) )

                    if (this.$store.state.activePigRunArrayWithNotification.findIndex( (element: number) => element == this.$store.state.pigRun.activePigRuns[i].runId ) != -1)
                    {
                        filteredPigRuns.push(this.$store.state.pigRun.activePigRuns[i]);
                    }
                }

                return filteredPigRuns;
            }
            else
            {
                return filteredPigRuns;
            }
        }

        return this.$store.state.pigRun.activePigRuns;
    }

    // Declared as computed property getter.
    get devices()
    {
        return this.$store.state.devices;
    }

    // Declared as computed property getter.
    get queryParams()
    {
        return this.$store.state.queryParams;
    }

    // Declared as computed property getter.
    get user()
    {
        return this.$store.state.user;
    }

    public unsubscribe = this.$store.subscribeAction((action, state) =>
    {
        if (action.type === 'notify')
        {
            this.notify(action.payload);
        }

        if (action.type === 'pigRunsReady')
        {
            this.handleQueryParams();
        }
    });

    // Declared as component method.
    public notify(message: any)
    {
        this.$q.notify(message);
    }

    // Declared as component method.
    public handleQueryParams()
    {
        if (!this.startupComplete)
        {
            if (this.$route.query && Object.keys(this.$route.query).length !== 0)
            {
                this.$store.dispatch('handleQueryParams', this.$route.query);
            }
            else if (this.queryParams && Object.keys(this.queryParams).length !== 0)
            {
                // we may have stashed queryParams through the login callback process
                this.$store.dispatch('handleQueryParams', this.queryParams);
            }

            this.startupComplete = true;
        }
    }

    // Declare lifecycle hook.
    public created()
    {
        console.log('LayoutDefault: created()');

        this.$store.dispatch('getDevices');

        // need to make sure all devices are ready before we can show PigProgress
        const unwatch = this.$watch('devices', (newVal) =>
        {
            if (newVal)
            {
                console.log('DEVICES READY');

                setTimeout(() =>
                {
                    this.$store.dispatch('restorePigData');
                    this.devicesReady = true;
                }, 1000);

                unwatch();
            }
        });
    
        this.$store.dispatch('getDefaultNotificationSettings');

        // Dispatch action getActivePigRunListForUserWithNotification to get the list of active pig run id values for the logged in user
        // that has notification configured.
        this.$store.dispatch('getActivePigRunListForUserWithNotification', this.$store.state.user.id);

        // Dispatch action getActivePigRunUidListWithUserNotification to get the list of uid values participating in active pig runs for the logged
        // in user that has notification configured.
        this.$store.dispatch('getActivePigRunUidListForUserWithNotification', this.$store.state.user.id);
    }

    // Declare lifecycle hook.
    public mounted()
    {
    }

    // Declare lifecycle hook.
    public beforeDestroy()
    {
        this.unsubscribe();
    }

    // Declared as component method.
    public onLogout()
    {
        this.$store.dispatch('login', null);
        this.$router.push({name: 'login'});
    }

    // Declared as component method.
    public onActionOne()
    {
        this.$store.dispatch('actionOne');
    }

    // Declared as component method.
    public onActionTwo()
    {
        this.$store.dispatch('actionTwo');
    }

    // Declared as component method.
    public onPigRunManager()
    {
        this.$store.dispatch('showPigRunManager');
        this.$store.dispatch('restorePigData');
    }

    // Declared as component method.
    public onReportManagerClick()
    {
        this.$store.dispatch('showReportManager');
    }

    // Declared as component method.
    public onBroadcastCommandsClick()
    {
        console.log('Default.vue. onBroadcastCommandsClick().');

        this.$store.dispatch('showBroadcastCommands');
    }

    // Declared as component method.
    public onUserManager()
    {
        if (this.user.role === 'superadmin')
        {
            this.$router.push({name: 'userManagement'});
        }
        else
        {
            this.$store.dispatch('localUserManagerShow');
        }
    }

    // Declared as component method.
    public onChangePassword()
    {
        this.$store.dispatch('changePassword');
    }

    // Declared as component method.
    public onNotificationSettings()
    {
        this.$store.dispatch('notificationSettings');
    }

    // Declared as component method.
    public drawerToggle()
    {
        this.$store.dispatch('drawerToggle');
    }
}
