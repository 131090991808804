import { PigPath } from './PigPath';
import { MarkerPassage } from './MarkerPassage';
import { AgmMarker } from './AgmMarker';
import DeviceService from '../services/DeviceService';
import PigRunCommon from '../utils/PigRunCommon';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Passage } from './Passage';
dayjs.extend(utc);

export interface IPigRun {
    runId: number;
    pigPath: PigPath;
    pig: string;
    launchTime?: Date;
    recieveTime?: Date;
    calculatedSpeed?: number;
    passages?: MarkerPassage[];
    markers?: AgmMarker[];
    currentPigLocationLat?:number;
    currentPigLocationLon?:number;
    PigEditTime?:Date;
    EditNextMarkerIndex?:number;
    NewSpeed?: number;
    EditPigLocLat?:number;
    EditPigLocLon?:number;
    IsEdit?:boolean;
    EditNextMarkerIndex2?:number;
    Phase?:number;

}

export class PigRun implements IPigRun {
    constructor(
        public runId: number,
        public pigPath: PigPath,
        public pig: string,
        public launchTime?: Date,
        public recieveTime?: Date,
        public calculatedSpeed?: number,
        public passages?: MarkerPassage[],
        public markers?: AgmMarker[],
        public currentPigLocationLat?:number,
        public currentPigLocationLon?:number,
        public PigEditTime?:Date,
        public EditNextMarkerIndex?:number,
        public NewSpeed?:number,
        public EditPigLocLat?:number,
        public EditPigLocLon?:number,
        public IsEdit?:boolean,
        public EditNextMarkerIndex2?:number,
        public Phase?:number
    ) {
        if (!this.calculatedSpeed) {
            this.calculatedSpeed = 0;
        }
        if (!this.passages) {
            this.passages = [];
        }
    }

    public async buildReport(extension:string)
    {
        if (!this.recieveTime)
        {
            console.error('Pig Run has not been completed');

            return;
        }

        if (!this.markers)
        {
            console.error('Pig Run does not have markers');

            return;
        }

        if (!this.passages)
        {
            console.error('Pig Run does not have passages');

            return;
        }

        const convertedSpeed = this.calculatedSpeed ? Math.round(PigRunCommon.metersToMiles(this.calculatedSpeed) * 100) / 100 : '';
        
        let csv = `Pig Run:,${this.pigPath.pigRunName}\n`;
        csv += `Tool Name (Pig):,${this.pig}\n`;
        csv += `Launch Time:,${dayjs.default(this.launchTime).utc().format('YYYY-MM-DD HH:mm:ss')}\n`;
        csv += `Receive Time:,${dayjs.default(this.recieveTime).utc().format('YYYY-MM-DD HH:mm:ss')}\n`;
        csv += `Total Duration of Pig Run (mins):,${dayjs.default(this.recieveTime).utc().diff(dayjs.default(this.launchTime).utc(), 'minute')}\n`;
        csv += `Average Speed of Pig Run (mph):,${convertedSpeed}\n`;
        csv += `Total Distance (m):,${this.pigPath.getDistance()}\n`;
        csv += `Pig Run Notes:,${this.pigPath.notes ? this.pigPath.notes : ''}\n`;
        csv += `\n`;
        csv += `\nMarker Name,Tracker Name,Marker Lat,Marker Lon,AGM Lat,AGM Lon,AGM Alt (m),Batt,dBm,Passage Time,Frequency,Count,Note,Raw\n`;

        const passageData: any = {};
        const heartbeatData: any = {};
        const linkedUids: number[] = [];

        if (this.passages && this.markers)
        {
            for (const passage of this.passages)
            {
                if (passage.passageId && passage.uid && passage.passageId !== -1)
                {
                    // ELF
                    let type = 0;

                    // Passage Raw Data in Report should be MAG if Passage is MAG/GEO or ELF if Passage is MAG/ELF/GEO
                    if (passage.elfId)
                    {
                        type = 0;
                        passage.passageId = passage.elfId;
                    }
                    else if (passage.magId)
                    {
                        type = 2;
                        passage.passageId = passage.magId;
                    }
                    else if (passage.geoId)
                    {
                        // Should not get here, but use geo if all else fails.
                        type = 1;
                        passage.passageId = passage.geoId;
                    }

                    // collect list of uids for heatbeat data
                    if (passage.uid && linkedUids.indexOf(passage.uid) === -1)
                    {
                        linkedUids.push(passage.uid);
                    }

                    const passageResponse = await DeviceService.getPassageData(passage.uid, passage.passageId, type);
                    
                    if (passageResponse.data)
                    {
                        passageData[passage.passageId] = passageResponse.data;

                        if (passageData[passage.passageId].data)
                        {
                            // First 4 ints are not relevant.
                            passageData[passage.passageId].data = passageData[passage.passageId].data.slice(4);
                        }
                    }
                }
            }

            // Get heartbeat data from linkedUids that had passages.
            for (const linkedUid of linkedUids)
            {
                const heartbeatResponse = await DeviceService.getDeviceHistory(linkedUid, 'heatbeat');

                if (heartbeatResponse.data)
                {
                    heartbeatData[linkedUid] = heartbeatResponse.data;
                }
            }

            for (const marker of this.markers) {
                const linkedUidText = marker.linkedUid ? marker.linkedUid : '';

                // Mitch - test
                console.log('Marker note = ' + marker.note);

                // @ts-ignore - replaceAll works in modern browsers
                let noteText = marker.note ? marker.note.replaceAll(',', '') : '';

                csv += '';
                let markerInfo = `${marker.name},${linkedUidText},${marker.lat},${marker.lon}`;
                let heartbeatInfo = ',,,'; // batt, dBm will be calculated if it can be found

                if (this.passages)
                {
                    let passageFound = false;

                    for (const passage of this.passages)
                    {
                        if (passage.markerName === marker.name)
                        {
                            if (passage.passageId && passage.passageId !== -1 && Object.keys(passageData).indexOf(passage.passageId.toString()) !== -1)
                            {
                                // Passage time may have been edited, passageData response will have any changes plus a better precision.
                                passage.passageTimeStamp = passageData[passage.passageId].passageTimeStamp;

                                if (passageData[passage.passageId].isVerified || passage.isVerified)
                                {
                                    if (passage.uid && Object.keys(heartbeatData).indexOf(passage.uid.toString()) !== -1)
                                    {
                                        for (const heartbeat of heartbeatData[passage.uid])
                                        {
                                            if (dayjs.default(passage.passageTimeStamp).diff(dayjs.default(heartbeat.heartbeatTimeStamp), 'minute') < 10)
                                            {
                                                let alt;

                                                if(heartbeat.altitude != null)
                                                {
                                                    alt = (heartbeat.altitude / 1000)
                                                }
                                                else
                                                {
                                                    alt = ''
                                                }
                                                
                                                heartbeatInfo = `,${heartbeat.lat},${heartbeat.lon},${alt},${heartbeat.battery},-${heartbeat.signalStrength},`;

                                                break;
                                            }
                                        }
                                    }

                                    passage.passageTimeStamp = " " + passage.passageTimeStamp;

                                    // linkedUID may be reused in a pig run so use the verified marker name from the pasaage.
                                    markerInfo = `${marker.name},${passage.uid},${marker.lat},${marker.lon}`;

                                    csv += markerInfo;
                                    csv += heartbeatInfo;
                                    csv += `${passage.passageTimeStamp},`;
                                    csv += `${passage.passageDetectedFrequency},`;
                                    csv += passage.detectionCount ? `${passage.detectionCount},` : ',';

                                    if (noteText != undefined && noteText.length > 0)
                                    {
                                        // noteText - search and replace each double quote character with two double quote characters.
                                        noteText = noteText.replace(/["]+/g, '""');

                                        // noteText - search and replace each \n character with an empty character.
                                        noteText = noteText.replace(/[\n]+/g, ' ');

                                        // noteText - search and replace each # character with the word number.
                                        noteText = noteText.replaceAll('#', 'number');
                                    }
                                    
                                    csv += "\"" + noteText + "\"" + ",";
                                    csv += passageData[passage.passageId].data ? `${passageData[passage.passageId].data},` : ',';

                                    passageFound = true;
                                    
                                    break;
                                }
                            }
                            else
                            {
                                if (passage.uid && passage.isVerified)
                                {
                                    for (const heartbeat of heartbeatData[passage.uid])
                                    {
                                        if (dayjs.default(passage.passageTimeStamp).diff(dayjs.default(heartbeat.heartbeatTimeStamp), 'minute') < 10)
                                        {
                                            heartbeatInfo = `,${heartbeat.battery},-${heartbeat.signalStrength},`;

                                            break;
                                        }
                                    }
                                }

                                if (passage.isVerified)
                                {
                                    passage.passageTimeStamp = " " + `${dayjs.default(passage.passageTimeStamp).format('YYYY-MM-DD HH:mm:ss.SSS')},`;

                                    csv += markerInfo;
                                    csv += heartbeatInfo;
                                    csv += ',,,'+passage.passageTimeStamp +`,`;
                                    csv += ',' + noteText + ',Manual Passage';

                                    passageFound = true;
                                    break;
                                }
                            }
                        }
                    }

                    if (!passageFound)
                    {
                        csv += markerInfo;
                        csv += ',,,,,,,,,' + 'Skipped Marker - ' + noteText;
                    }
                }

                csv += '\n';
            }

            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            hiddenElement.download = `${this.pigPath.pigRunName}_${this.runId}_report`;

            if (extension == 'csv')
            {
                hiddenElement.download = hiddenElement.download + `.csv`;
            }
            else if (extension == 'pro')
            {
                hiddenElement.download = hiddenElement.download + `.pro`;
            }

            hiddenElement.click();
        }
    }

    public async buildReport_V2(extension:string) {
        
        if (!this.recieveTime) {
            console.error('Pig Run has not been completed');
            return;
        }

        if (!this.markers) {
            console.error('Pig Run does not have markers');
            return;
        }

        if (!this.passages) {
            console.error('Pig Run does not have passages');
            return;
        }
  
        let csv =   "AGM Survey Marker,"+
                    "AGM UID,"+
                    "AGM FW," +
                    "Survey Marker Latitude,"+
                    "Survey Marker Longitude,"+
                    "AGM Latitude,"+
                    "AGM Longitude,"+
                    "AGM Altitude (m),"+
                    "Passage Type,"+
                    "Time Stamp (UTC),"+
                    "Notes," +
                    "\n"

    // Get list of verified passages
    const verified_passages = this.passages.filter((passage: MarkerPassage) => passage.isVerified == true);
    // Get list of manual passages
    const manual_passages = verified_passages.filter((passage: MarkerPassage) => passage.uid == null);
    
    // now we have verified_passages array and markers_psg_verified array
    var Historic_psg_array: any[] = [];
    for (const passage of verified_passages)
    {
        let type = 0;
        if(passage.uid != null)
        {
            if (passage.elfId) {
                type = 0;
                passage.passageId = passage.elfId;
            } else if (passage.magId) {
                type = 2;
                passage.passageId = passage.magId;
            } else if (passage.geoId) {
                // We shouln't get here but use geo if all else fails
                type = 1;
                passage.passageId = passage.geoId;
            }
            const passageResponse = await DeviceService.getPassageData(passage.uid as number, passage.passageId as number, type);
            Historic_psg_array.push(passageResponse.data);
        }
    }

    // We need to get firmware version of all AGMs
    const devicesInfo = await DeviceService.getDevices();

//debugger
        if(this.markers)
        {
            this.markers.forEach((marker: AgmMarker) => {
                if(marker.state == "TRACK")
                {
                    let index_verifiedpsg = Historic_psg_array.findIndex((x: Passage) => x.MarkerName == marker.name);
                    let index_manual = manual_passages.findIndex((x: MarkerPassage) => x.markerName == marker.name);
    
                    if(index_verifiedpsg > -1)
                    {
                        // Verified Passage exist. We need to get passage information from Historic_psg_array
                        let passage = Historic_psg_array[index_verifiedpsg];
                        if(passage != null)
                        {
                            let fmVersion = '';
                            if(passage.uid != null && passage.uid != undefined)
                            {
                                var idx = devicesInfo.data.findIndex((x: any) => x.uId == passage.uid);
                                if(idx>-1)
                                {
                                    fmVersion = devicesInfo.data[idx].firmwareVersion;
                                }
                            }


                            let uid = passage.uid??"";
                            let lat = passage.lat??"";
                            let lon = passage.lon??"";
                            //let alt = (passage.alt/1000)??"";
                            let alt;
                            
                            if(passage.alt != null)
                            {
                                alt = (passage.alt/1000)
                            }
                            else
                            {
                                alt = "";
                            }

                            let TimeStamp = ' ' + passage.passageTimeStamp??"";
                            let note = marker.note??"";
                            let passageType = "";
                            if(passage.passageDetectedFrequency == 0)
                            {
                                passageType = 'MAG'
                            }
                            else
                            {
                                passageType = 'ELF-' + passage.passageDetectedFrequency
                            }

                            csv += marker.name + "," + uid + "," + fmVersion + "," + marker.lat + "," + marker.lon + "," + lat + "," + lon + "," + alt + "," + passageType + ","+ TimeStamp + "," + note + "\n";
                        }
                    }
    
                    if(index_manual > -1)
                    {
                        // Manual Passage exist
                        let passage = manual_passages[index_manual];
                        let TimeStamp = ' ' + passage.passageTimeStamp??"";
                        let note = '';

                        let uid = "";
                        let lat = "";
                        let lon = "";
                        let alt = "";
                        let passageType = "";
                        let fmVersion = '';

                        if(marker.note != null)
                        {
                            note = " - " + marker.note;
                        }

                        if(passage != null)
                        {
                           // csv += marker.name + "," + "" + "," + ""      + "," + marker.lat + "," + marker.lon + "," + "" + "," + "" + "," + "" + ","+ "" +  "," + TimeStamp  + "," + "Manual Passage" + note + "\n";
                            csv += marker.name + "," + uid + "," + fmVersion + "," + marker.lat + "," + marker.lon + "," + lat + "," + lon + "," + alt + "," + passageType + ","+ TimeStamp + "," +"Manual Passage" +note + "\n";
                        }
                    }
    
                    if(index_verifiedpsg == -1 && index_manual == -1)
                    {
                        //Missed Passage
                        let note = '';
                        if(marker.note != null)
                        {
                            note = " - " + marker.note;
                        }

                        let TimeStamp = "";
                        let uid = "";
                        let lat = "";
                        let lon = "";
                        let alt = "";
                        let passageType = "";
                        let fmVersion = '';



                        //csv += marker.name + "," + "" + "," + marker.lat + "," + marker.lon + "," + "" + "," + "" + "," + + "" + "," + "" + "," + "" + "," + "Missed Passage - Check AGM Offline Passages"  + note + "\n";
                        csv += marker.name + "," + uid + "," + fmVersion + "," + marker.lat + "," + marker.lon + "," + lat + "," + lon + "," + alt + "," + passageType + ","+ TimeStamp + "," +"Missed Passage - Check AGM Offline Passages" +note + "\n";

                    }
                }
                else
                {
                    // Marker Set to SKIP
                    let note = '';
                    if(marker.note != null)
                    {
                        note = " - " + marker.note;
                    }
                    let TimeStamp = "";
                    let uid = "";
                    let lat = "";
                    let lon = "";
                    let alt = "";
                    let passageType = "";
                    let fmVersion = '';
                    //csv += marker.name + "," + "" + "," + marker.lat + "," + marker.lon  + "," + "" + "," + "" + "," + "" + "," + "" + "," + "" + "," + "SKIPPED"  + note + "\n";
                    csv += marker.name + "," + uid + "," + fmVersion + "," + marker.lat + "," + marker.lon + "," + lat + "," + lon + "," + alt + "," + passageType + ","+ TimeStamp + "," +"SKIPPED" +note + "\n";

                }
            });
        }
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        
        hiddenElement.download = `${this.pigPath.pigRunName}_${this.runId}_report`;
        if(extension == 'csv')
        {
            hiddenElement.download = hiddenElement.download + `.csv`;
        }
        else if(extension == 'pro')
        {
            hiddenElement.download = hiddenElement.download + `.pro`;
        }
        
        //hiddenElement.download = `${this.pigPath.pigRunName}_${this.runId}_report.csv`;



        hiddenElement.click();
    }
}
