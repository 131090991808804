
import { PigRun } from '../models/PigRun';
import { MarkerPassage } from '../models/MarkerPassage';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AgmMarker } from '../models/AgmMarker';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Passage } from '@/models/Passage';
import PigRunCommon from '../utils/PigRunCommon';
dayjs.extend(utc);

@Component
export default class MarkerActions extends Vue {

public pigRun: PigRun | undefined;
public UserRole:String = '';
public RunId:Number|null = null;

public ViewPassage_Dialog :boolean = false;

public closeViewPsgs()
{
    this.ViewPassage_Dialog = false;
}
public passagePagination = {
        sortBy: 'passageTimeStamp',
        descending: true,
        rowsPerPage: -1,
    };
public visibleColumns = ['passageTimeStamp', 'uid', 'graph-data', 'verify-data'];
public getuser()
{
    //use this method to get user role. So that certain things get displayed/hidden based on role.
    this.UserRole = this.$store.state.user.role
    //console.log(this.$store.state.user)
    return this.UserRole;
}





public passageColumns = [
    { name: 'passageTimeStamp', label: 'Timestamp', field: 'passageTimeStamp', sortable: true, required: true, align: 'left' },
    { name: 'markerName', label: 'Marker Name', field: 'markerName', sortable: true, required: true },
    { name: 'uid', label: 'UID', field: 'uid', sortable: true },
    { name: 'detectionCount', label: 'Detection Count', field: 'detectionCount', sortable: true},
    { name: 'graph-data', field: 'passageId', label: 'Data'}, // changed to graph btn in slot
    { name: 'verify-data', field: 'passageId', label: 'Verified'}, // changed to check btn in slot
];


get filteredPassages(): MarkerPassage[] 
{
    if (this.RunId  && this.$store.state.selectedMarkerEditor.name !== '') {
        var selectedRun = this.$store.state.pigRun.completedPigRuns.filter((PigRun: PigRun) => PigRun.runId === this.RunId);
        if(selectedRun == null || selectedRun == undefined || selectedRun.length == 0 || selectedRun[0].passages == undefined || selectedRun[0].passages == null)
        {
            return [];
        }

        var passages = selectedRun[0].passages.filter((passage: MarkerPassage) => passage.markerName === this.$store.state.selectedMarkerEditor.name);
        if(passages == undefined || passages == null || passages.length == 0)
        {
            return [];
        }
        return passages     
    }
    return [];
}


public onShowChart(props: any, type: string) {
    console.log('PigProgressMarkers: showChart()');
    this.$store.dispatch('setPassageDialogSource', 'verify_passage');
    this.$store.dispatch('addUIDToUpdate', props.row.uid);
    const passageIdsToUpdate = [];
    if (props.row.hasOwnProperty('geoId')) {
        if (props.row.geoId != null) {
            passageIdsToUpdate.push(props.row.geoId);
        }
    }
    if (props.row.hasOwnProperty('elfId')) {
        if (props.row.elfId != null) {
            passageIdsToUpdate.push(props.row.elfId);
        }
    }
    if (props.row.hasOwnProperty('magId')) {
        if (props.row.magId != null) {
            passageIdsToUpdate.push(props.row.magId);
        }
    }
    this.$store.dispatch('addPassageIdsToUpdate', passageIdsToUpdate);

    if (type === 'ELF') {
        const passageCopy =  {...props.row};
        passageCopy.passageId = props.row.elfId;
        console.log(passageCopy);
        this.$store.dispatch('passageChartShow', passageCopy);
    }
    if (type === 'GEO') {
        const passageCopy =  {...props.row};
        passageCopy.passageId = props.row.geoId;
        console.log(passageCopy);
        this.$store.dispatch('passageChartShow', passageCopy);
    }
    if (type === 'MAG') {
        const passageCopy =  {...props.row};
        passageCopy.passageId = props.row.magId;
        console.log(passageCopy);
        this.$store.dispatch('passageChartShow', passageCopy);
    }
}



get marker(): AgmMarker {
    return this.$store.state.selectedMarkerEditor;
}


public unsubscribe = this.$store.subscribeAction((action: any, state: any) => { 
    if(action.type === 'passRunId')
    {
        //this.SetRunId(action.payload);
    }
    else if(action.type === 'passEditorRunId')
    {
        this.SetRunId(action.payload);
    }
    else if(action.type == 'refreshcompPigRun')
    {
        this.onRefreshRequest(action.payload);
    }
    else if(action.type == 'updateCompRun')
    {
        this.onRefreshcompRun(action.payload);
    }
});


public onRefreshRequest(payload: number) 
{
    if (this.pigRun) 
    {
        console.log('EditorMarkerActions: onRefreshRequest');
        if (payload === this.pigRun.runId) 
        {
            console.log('EditorMarkerActions: restoreCompletedPigRun at Run Id = ' + this.pigRun.runId);
            this.$store.dispatch('restoreCompletedPigRun', payload);
        }
    }
}

    public onRefreshcompRun(payload: PigRun) 
    {
        if (this.pigRun) 
        {
            console.log('EditorMarkerActions: refreshPigRun - DO UPDATE');
            if (payload.runId === this.pigRun.runId) {
                var selectedRun = this.$store.state.pigRun.completedPigRuns.filter((PigRun: PigRun) => PigRun.runId === this.RunId);
                console.log('EditorMarkerActions: Run Updated at Run Id = ' + this.pigRun.runId);
                if(selectedRun.length == 1)
                {
                
                    this.pigRun = selectedRun[0];
                    var selectedmarker = selectedRun[0].markers.filter((Marker: AgmMarker) => Marker.name === this.marker.name);
                    if(selectedmarker.length == 1)
                    {
                        this.$store.state.selectedMarkerEditor.note = selectedmarker[0].note;
                        this.$store.state.selectedMarkerEditor.state = selectedmarker[0].state;
                        this.$store.state.selectedMarkerEditor.description = selectedmarker[0].description;
                        var run_idx;
                        //////////////////
                        // Need to check if icon need to be TRACK or TRACK_NotVerified
                        if(this.pigRun && this.pigRun.passages)
                        {
                            run_idx = this.$store.state.ActiveRuns.findIndex((obj: any) => (obj.pigrunid === this.pigRun?.runId));
                            if(run_idx>-1)
                            {
                                const mrkerpsg_Idx = this.pigRun.passages.findIndex((passage: MarkerPassage) => (passage.markerName === selectedmarker[0].name && passage.isVerified == true));
                                if(mrkerpsg_Idx>-1)
                                {
                                    this.$store.state.ActiveRuns[run_idx].localmarkerLayers[selectedmarker[0].name].setIcon(PigRunCommon.getIcon('TRACK'))
                                }
                                else
                                {
                                    this.$store.state.ActiveRuns[run_idx].localmarkerLayers[selectedmarker[0].name].setIcon(PigRunCommon.getIcon('TRACK_NotVerified'))

                                }
                            }
                        }
                        /////////////////
                    }
                }

            }
        }
    }

    public SetRunId(id: number)
    {
        console.log('EditorMarkerActions: SetRunId called at RunId = id');
        this.RunId = id;
    }

    public mounted() {
        console.log('MarkerActions: mounted()');
    }

    public beforeDestroy() {
        this.unsubscribe();
    }


    public onAddNote(currentNote: any) {
        console.log('onAddNote');
        this.addNoteDialog = true;
        if(this.marker && this.marker.note)
        {
            this.noteText = this.marker.note;
        }
    }


    public addNoteDialog:boolean = false;
    public noteText: string = '';


    public onSaveNote() {
        console.log('EditorMarkerActions: onSaveNote()');
        
        var PRun = this.$store.state.pigRun.completedPigRuns.filter((PigRun: PigRun) => PigRun.runId === this.RunId);
        if(PRun[0].passages == null || PRun[0].passages == undefined)
        {
            return;
        }
        
        this.pigRun = PRun[0];

        if (this.pigRun && this.pigRun.markers) {
            this.pigRun.markers.forEach( (marker: AgmMarker) => {
                if (marker.name === this.marker.name) {
                    if (this.noteText.length > 200) {
                        this.noteText = this.noteText.substring(0, 199);
                    }
                    marker.note = this.noteText;
                    //logger.log(`${this.userInfo} onSaveNote=>updatePigRun`);
                    this.updatePigRun2();
                }
            });
        }
        this.noteText = '';
    }


public ManualpassageTimestamp = '';
    public onAddManualPassageDialog()
    {
        this.addPassageDialog = true;
        this.ManualpassageTimestamp = '';
        this.showErrorMsg = false;
    }
    public onAddManualPassage()
    {
        // We need to verify this.passageTimestamp is properly formatted
        var PRun = this.$store.state.pigRun.completedPigRuns.filter((PigRun: PigRun) => PigRun.runId === this.RunId);
        this.pigRun = PRun[0];
        console.log(this.ManualpassageTimestamp);
        var psgtime = new Date(this.ManualpassageTimestamp + ' GMT');

        var isIos= /iPad|iPhone|iPod/i.test (navigator.userAgent);
        
        if(isIos == true)
        {            
            var acttime,hour,min,sec,year,month,day;
            var TestDate:Date;
            acttime = this.ManualpassageTimestamp;
                //UTC Time//
            year = acttime[0]+acttime[1]+acttime[2]+acttime[3];
            month = acttime[5]+acttime[6];
            day = acttime[8]+acttime[9];
            hour = acttime[11]+acttime[12];
            min = acttime[14]+acttime[15];
            sec = acttime[17]+acttime[18];
         
                //UTC Time//
            psgtime = new Date();
            psgtime.setUTCFullYear(parseInt(year));
            psgtime.setUTCMonth((parseInt(month)-1));
            psgtime.setUTCDate(parseInt(day));
            psgtime.setUTCHours(parseInt(hour));
            psgtime.setUTCMinutes(parseInt(min));
            psgtime.setUTCSeconds(parseInt(sec));
            psgtime.setUTCMilliseconds(0);
            if(isNaN(psgtime.getTime()))
            {
                this.ManualPsg_ErrorMsg = 'Please enter valid Passage Time {YYYY-MM-DD HH:MM:SS}';
                this.showErrorMsg = true;
                this.addPassageDialog = true;
                return;
            }
        }
        else
        {
            if(isNaN(psgtime.getTime()))
            {
                this.ManualPsg_ErrorMsg = 'Please enter valid Passage Time {YYYY-MM-DD HH:MM:SS}';
                this.showErrorMsg = true;
                this.addPassageDialog = true;
                return;
            }
        }


        // Check 1: Check to see if this marker has previously verified passage
        
        if(this.CheckifMarkerHasPsg(this.marker.name) == true)
        {
            // Error
            console.log('EditorMarkerActions: This marker has previously verified passage')
            this.ManualPsg_ErrorMsg = 'This marker has previously verified passage';
            this.showErrorMsg = true;
            return;
        }

        // Check 2: Check to see if this.passageTimestamp is before launch time
        if(this.CheckifBeforeLaunch(this.ManualpassageTimestamp+ ' GMT') == true)
        {
            // Error
            console.log('EditorMarkerActions: Manual passage Time is before Launch Time')
            this.ManualPsg_ErrorMsg = 'Manual passage Time is before Launch Time';
            this.showErrorMsg = true;
            return;
        }

        // Check 2: Check to see if this.passageTimestamp is before launch time
        if(this.CheckifBeforeReceive(this.ManualpassageTimestamp+ ' GMT') == true)
        {
            // Error
            console.log('EditorMarkerActions: Manual passage Time is after Receive Time')
            this.ManualPsg_ErrorMsg = 'Manual passage Time is after Receive Time';
            this.showErrorMsg = true;
            return;
        }
        

        // Check 3: Check to see if this.passageTimestamp is in the future
        if(this.CheckifinFuture(this.ManualpassageTimestamp + ' GMT') == true)
        {
            // Error
            console.log('EditorMarkerActions: Manual passage Time is in the future')
            this.ManualPsg_ErrorMsg = 'Entered Time is in the Future';
            this.showErrorMsg = true;
            return;
        }

        // Check 4: Check to see if this.passageTimestamp is a time that occured after the next verified passage location

        if(this.CheckifBeforePreviousVerifiedPsg(this.ManualpassageTimestamp+ ' GMT',this.marker.name) == true)
        {
            // Error
            console.log('EditorMarkerActions: Manual passage Time is Invalid')
            this.ManualPsg_ErrorMsg = 'Entered Time is before or equals to a previously verified psg';
            this.showErrorMsg = true;
            return;
        }

        // Check 5: Check to see if this.passageTimestamp is a time that occured after the next verified passage location
        if(this.CheckifAfterNextVerifiedPsg(this.ManualpassageTimestamp+ ' GMT',this.marker.name) == true)
        {
            // Error
            console.log('EditorMarkerActions: Entered Time is ahead of a further Verified Psg')
            this.ManualPsg_ErrorMsg = 'Entered Time is ahead of a further Verified Psg';
            this.showErrorMsg = true;
            return;
        }


        // Check 6: Check to see if this.passageTimestamp is a time that occured after the next verified passage location

        if(this.CheckifVerifiedExist() == true)
        {
            // Error
            console.log('EditorMarkerActions: Verified passage Already Exist at this location')
            this.ManualPsg_ErrorMsg = 'Verified passage Already Exist at this location';
            this.showErrorMsg = true;
            return;
        }


        if(this.pigRun != null && this.pigRun != undefined && this.pigRun.passages!= null && this.pigRun.passages!= undefined)
        {
            var newpsg:MarkerPassage = new MarkerPassage(
                this.marker.name,
                // this.ManualpassageTimestamp + ' GMT',
                this.ManualpassageTimestamp,
                undefined,
                true);


            this.pigRun.passages.push(newpsg);
            console.log(this.pigRun);
            this.$store.dispatch('upsertFinishedPigRun', this.pigRun);
        }



        this.addPassageDialog = false;
        this.showErrorMsg = false;  

        console.log('EditorMarkerActions: onAddPassageInfo()');
        console.log('EditorMarkerActions. this.passageMarker = [' + this.marker.name + [']']);
    }

    public CheckifVerifiedExist()
    {
        if (this.marker && this.pigRun && this.pigRun.passages) {
            var index = -1;
            //var psgtime_GMT  = this.EditManualPsgTimestamp + ' GMT';
            index = this.pigRun.passages.findIndex(x =>
            {
                if(x.isVerified === true && x.markerName === this.marker.name)
                {   
                    return true;
                }
            });
        }
        return false;
    }



    public onViewPassages() {
        console.log('EditorMarkerActions: onViewPassages');
        this.ViewPassage_Dialog = true;
        this.ShowVerifyPsg_ErrorMsg = false;
        this.showErrorMsg = false;
        this.showErrorMsg2 = false;
        this.EditManual_ErrorMsg = false;
        this.ManualPsg_ErrorMsg = ''; 
        this.VerifyPsg_ErrorMsg = '';
    }
    public onVerifyPassage(selectedpassage :Passage)
    {
        // We need to update the PigRun and upsert it back to database and inform all other users
        var PRun = this.$store.state.pigRun.completedPigRuns.filter((PigRun: PigRun) => PigRun.runId === this.RunId);
        this.pigRun = PRun[0]; 
        let psgtosend: any = {}
    
        if(this.pigRun)
        {
            if(this.pigRun.passages == null || this.pigRun.passages == undefined)
            {
                return;
            }
            // Need to do some checkes here.
            
            if(selectedpassage.isVerified == false)
            {
                if(this.CheckifMarkerHasPsg(this.marker.name) == true)
                {
                    // Error
                    console.log('EditorMarkerActions:  This marker has previously verified passage')
                    this.VerifyPsg_ErrorMsg = 'This marker has previously verified passage';
                    this.ShowVerifyPsg_ErrorMsg = true;
                    return;
                }
                // Check 2: Check to see if this.EditManualPsgTimestamp is before launch time
                if(this.CheckifBeforeLaunch(selectedpassage.passageTimeStamp + ' GMT') == true)
                {
                    // Error
                    console.log('EditorMarkerActions: Verify passage Time is before Launch Time')
                    this.VerifyPsg_ErrorMsg = 'Entered Time is before Launch Time';
                    this.ShowVerifyPsg_ErrorMsg = true;
                    return;
                }

                // Check 3: Check to see if this.EditManualPsgTimestamp is in the future
                if(this.CheckifinFuture(selectedpassage.passageTimeStamp + ' GMT') == true)
                {
                    // Error
                    console.log('EditorMarkerActions: Verify passage Time is in the future')
                    this.VerifyPsg_ErrorMsg = 'Entered Time is in the Future';
                    this.ShowVerifyPsg_ErrorMsg = true;
                    return;
                }

                // Check 4: Check to see if this.EditManualPsgTimestamp is a time that occured before any previous verified passages

                if(this.CheckifBeforePreviousVerifiedPsg(selectedpassage.passageTimeStamp + ' GMT',this.marker.name) == true)
                {
                    // Error
                    console.log('EditorMarkerActions: Manual passage Time is Invalid')
                    this.VerifyPsg_ErrorMsg = 'Entered Time is before of a previous Verified Psg';
                    this.ShowVerifyPsg_ErrorMsg = true;
                    return;
                }
                
                
                // Check 5: Check to see if this.EditManualPsgTimestamp is a time that occured after the next verified passage location
                if(this.CheckifAfterNextVerifiedPsg(selectedpassage.passageTimeStamp + ' GMT',this.marker.name) == true)
                {
                    // Error
                    console.log('EditorMarkerActions: Manual passage Time is Invalid')
                    this.VerifyPsg_ErrorMsg = 'Entered Time is ahead of a further Verified Psg';
                    this.ShowVerifyPsg_ErrorMsg = true;
                    return;
                }

            }
            
            this.pigRun.passages.forEach( (passage: MarkerPassage|null) => {
                if(passage != null || passage != undefined)
                {
                    if(passage.passageId != null || passage.passageId != undefined)
                    {
                        if(passage.passageId == selectedpassage.passageId)
                        {
                            passage.isVerified = !passage.isVerified;
                            psgtosend = passage;
                            
                        }
                    }
                }
            });
        }
        else
        {
            return;
        }

        // Now PRun should have the new information
        // We need to send it to backend to update
            //this.$store.dispatch('verifyPassage', passage); 
            
                
        this.ShowVerifyPsg_ErrorMsg = false;
        this.VerifyPsg_ErrorMsg = '';

        this.updatePigRun();
           
           
        if(psgtosend != null)
        {
            psgtosend.isVerified = !psgtosend.isVerified;  
            this.$store.dispatch('verifyPassage', psgtosend);
        }
        // We also need to update front end
    }

    public updatePigRun() {
        console.log('EditorMarkerActions: updatePigRun()');
        if (this.pigRun) {
            console.log(this.pigRun);
            this.$store.dispatch('upsertFinishedPigRun', this.pigRun);
        }
    }

    public updatePigRun2()
    {  
        //this.$store.dispatch('ignoreNextPigRunUpdate', true);
        this.$store.dispatch('upsertFinishedPigRun', this.pigRun);
    }

 






    public selectedMarkerPassage: MarkerPassage | null = null;
    public EditManualPsgTimestamp:any = "2021-06-27 01:02:03";
    public editConfirmDialog: boolean = false
    public addPassageDialog = false;
    public showErrorMsg:boolean = false;
    public showErrorMsg2:boolean = false;
    public EditManual_ErrorMsg:boolean = false;
    public ShowVerifyPsg_ErrorMsg:boolean = false;
    public ManualPsg_ErrorMsg: string = '';
    public VerifyPsg_ErrorMsg: string  = '';
    public onConfirmEditManualPassage(passage: MarkerPassage) {
        this.selectedMarkerPassage = passage;
        this.EditManualPsgTimestamp = passage.passageTimeStamp;
        this.editConfirmDialog = true;
    }

    public closeManualPsg()
    {
        this.addPassageDialog = false;
        this.showErrorMsg = false;
        this.showErrorMsg2 = false;
        this.EditManual_ErrorMsg = false;
        this.ShowVerifyPsg_ErrorMsg = false; 
        this.ManualPsg_ErrorMsg = ''; 
        this.VerifyPsg_ErrorMsg = '';
    }   

    public onEditManualPsg() 
    {
        console.log('EditorMarkerAction: onEditManualPsg');
        var psgtime_GMT = this.EditManualPsgTimestamp + ' GMT';
        console.log('EditorMarkerAction: New Manual Passage Time = ' + this.EditManualPsgTimestamp);

        var PRun = this.$store.state.pigRun.completedPigRuns.filter((PigRun: PigRun) => PigRun.runId === this.RunId);
        if(PRun[0].passages == null || PRun[0].passages == undefined)
        {
            return;
        }

   
        this.pigRun = PRun[0];



        var psgtime = new Date(psgtime_GMT);

        var isIos= /iPad|iPhone|iPod/i.test (navigator.userAgent);
        if(isIos == true)
        {
            var acttime,hour,min,sec,year,month,day,milisec;
            acttime = this.EditManualPsgTimestamp;
                //UTC Time//
            year = acttime[0]+acttime[1]+acttime[2]+acttime[3];
            month = acttime[5]+acttime[6];
            day = acttime[8]+acttime[9];
            hour = acttime[11]+acttime[12];
            min = acttime[14]+acttime[15];
            sec = acttime[17]+acttime[18];
            milisec = "000";
                //UTC Time//
            psgtime = new Date();
            psgtime.setUTCFullYear(parseInt(year));
            psgtime.setUTCMonth((parseInt(month)-1));
            psgtime.setUTCDate(parseInt(day));
            psgtime.setUTCHours(parseInt(hour));
            psgtime.setUTCMinutes(parseInt(min));
            psgtime.setUTCSeconds(parseInt(sec));
            psgtime.setUTCMilliseconds(0);
            if(isNaN(psgtime.getTime()))
            {
                this.ManualPsg_ErrorMsg = 'Please enter valid Passage Time {YYYY-MM-DD HH:MM:SS}';
                this.showErrorMsg = true;
                this.addPassageDialog = true;
                return;
            }
        }
        else
        {
            if(isNaN(psgtime.getTime()))
            {
                //InValid Date
                this.ManualPsg_ErrorMsg = 'Detected invalid characters';
                this.EditManual_ErrorMsg = true;
                return;
            }
        }


        if (this.selectedMarkerPassage) {
            // marker passages are verified
            this.addEditMarkerPassage(this.selectedMarkerPassage);
        }
    }

    public addEditMarkerPassage(passageMarker: MarkerPassage) 
    {
        console.log('PigProgressMarkers: addEditMarkerPassage()');


       // Check 2: Check to see if this.EditManualPsgTimestamp is before launch time
        if(this.CheckifBeforeLaunch(this.EditManualPsgTimestamp + ' GMT') == true)
        {
            // Error
            console.log('PigProgressMarker: Manual passage Time is before Launch Time')
            this.ManualPsg_ErrorMsg = 'Entered Time is before Launch Time';
            this. EditManual_ErrorMsg = true;
            return;
        }

        // Check 3: Check to see if this.EditManualPsgTimestamp is in the future
        if(this.CheckifinFuture(this.EditManualPsgTimestamp + ' GMT') == true)
        {
            // Error
            console.log('PigProgressMarker: Manual passage Time is in the future')
            this.ManualPsg_ErrorMsg = 'Entered Time is in the Future';
            this. EditManual_ErrorMsg = true;
            return;
        }

        // Check 4: Check to see if this.EditManualPsgTimestamp is a time that occured before any previous verified passages

        if(this.CheckifBeforePreviousVerifiedPsg(this.EditManualPsgTimestamp + ' GMT',this.marker.name) == true)
        {
            // Error
            console.log('PigProgressMarker: Manual passage Time is Invalid')
            this.ManualPsg_ErrorMsg = 'Entered Time is before of a previous Verified Psg';
            this. EditManual_ErrorMsg = true;
            return;
        }
        
        
        // Check 5: Check to see if this.EditManualPsgTimestamp is a time that occured after the next verified passage location
        if(this.CheckifAfterNextVerifiedPsg(this.EditManualPsgTimestamp + ' GMT',this.marker.name) == true)
        {
            // Error
            console.log('PigProgressMarker: Manual passage Time is Invalid')
            this.ManualPsg_ErrorMsg = 'Entered Time is ahead of a further Verified Psg';
            this. EditManual_ErrorMsg = true;
            return;
        }


        

        this.EditManual_ErrorMsg = false;  

        if (this.pigRun && this.pigRun.passages && passageMarker) {
            var index = this.pigRun.passages.findIndex(x => x.markerName === passageMarker.markerName && x.passageTimeStamp == passageMarker.passageTimeStamp);
            var value = this.pigRun.passages.splice(index,1);
            if(value.length == 1)
            {
                value[0].passageTimeStamp = this.EditManualPsgTimestamp;
                this.pigRun.passages.push(passageMarker);
                //this.markerLayers[passageMarker.markerName].closePopup();
                this.updatePigRun();
                this.editConfirmDialog = false;
            }
        }
    }

    public CheckifBeforeLaunch(psgtime_GMT:string)
    {
        if(this.pigRun && this.pigRun.launchTime)
        {
            //var psgtime_GMT  = this.EditManualPsgTimestamp + ' GMT';
            var launchTime = new Date(this.pigRun.launchTime.toUTCString());
            var NewPsgTime = new Date(psgtime_GMT);
            var diff = NewPsgTime.getTime() - launchTime.getTime();
            if(diff <= 0)
            {
                // Error Passage Time is before Launch Time
                console.log('Manual passage Time is before Launch Time')
                return true;
            }
        }
        return false;
    }

    public CheckifBeforeReceive(psgtime_GMT:string)
    {
        if(this.pigRun && this.pigRun.recieveTime)
        {
            //var psgtime_GMT  = this.EditManualPsgTimestamp + ' GMT';
            var receiveTime = new Date(this.pigRun.recieveTime.toUTCString());
            var NewPsgTime = new Date(psgtime_GMT);
            var diff = NewPsgTime.getTime() - receiveTime.getTime();
            if(diff >= 0)
            {
                // Error Passage Time is before Launch Time
                console.log('Manual passage Time is after Receive Time')
                return true;
            }
        }
        return false;
    }
    

    public CheckifinFuture(psgtime_GMT:string)
    {
        if(this.pigRun)
        {
            //var psgtime_GMT  = this.EditManualPsgTimestamp + ' GMT';
            var NowDate:Date;
            NowDate = new Date();
            var NewPsgTime = new Date(psgtime_GMT);
            var diff = NewPsgTime.getTime() - NowDate.getTime();
            if(diff > 0)
            {
                // Error Passage Time is before Launch Time
                console.log('Time entered is in future')
                return true;
            }
        }
        return false;
    }


    public CheckifBeforePreviousVerifiedPsg(psgtime_GMT:string, selectedmarkerName:string|null)
    {
        if(this.pigRun && this.pigRun.passages)
        {
            // In this case we should only look at passages that correspond to locations between launch and current location (no future points)
                var current_marker_index = -1;

                if(this.pigRun.markers)
                {
                    current_marker_index = this.pigRun.markers?.findIndex(x =>
                    {
                        if(x.name === selectedmarkerName)
                        {   
                            return true;
                        }
                    });

                    if(current_marker_index<0)
                    {
                        return false; // selected marker name does not exist in the list
                                    // We are not going to proceed checking
                    }
                    else
                    {
                        // It is in the list and we know the index => current_marker_index
                        for(var i = 0;i<current_marker_index;i++ )
                        {
                            if(this.pigRun.markers[i].state == "TRACK")
                            {
                                for(var j = 0;j<this.pigRun.passages.length;j++)
                                {
                                    if(this.pigRun.markers[i].name == this.pigRun.passages[j].markerName)
                                    {
                                        // We have a match between TRACK marker name and a passage in list
                                        if(this.pigRun.passages[j].isVerified == true)
                                        {
                                            // Now we make the comparison
                                            var psgTime = new Date(this.pigRun.passages[j].passageTimeStamp + ' GMT');
                                            var EditManualPsgTime = new Date(psgtime_GMT);
                                            var diff = EditManualPsgTime.getTime() - psgTime.getTime();

                                            if(diff <= 0) // if edited manual psg time is less than a verieifed psg time then error
                                            {
                                                return true; /// An error case exist
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
        }

        return false;
    }

    

    public CheckifAfterNextVerifiedPsg(psgtime_GMT:string,selectedmarkerName:string|null)
    {
        if(this.pigRun && this.pigRun.passages)
        {
            var index = -1;var current_marker_index = -1;
            console.log(selectedmarkerName);
            // First step we need to find where this passage in the list
            if(this.pigRun.markers)
            {
                current_marker_index = this.pigRun.markers?.findIndex(x =>
                {
                    if(x.name === selectedmarkerName)
                    {   
                        return true;
                    }
                });
                if(current_marker_index<0)
                {
                    return false; // selected marker name does not exist in the list
                                  // We are not going to proceed checking
                }
                else
                {
                    // It is in the list and we know the index => current_marker_index
                    for(var i = current_marker_index + 1;i<this.pigRun.markers.length;i++ )
                    {
                        if(this.pigRun.markers[i].state == "TRACK")
                        {
                            for(var j = 0;j<this.pigRun.passages.length;j++)
                            {
                                if(this.pigRun.markers[i].name == this.pigRun.passages[j].markerName)
                                {
                                    // We have a match between TRACK marker name and a passage in list
                                    if(this.pigRun.passages[j].isVerified == true)
                                    {
                                        // Now we make the comparison
                                        var psgTime = new Date(this.pigRun.passages[j].passageTimeStamp + ' GMT');
                                        var EditManualPsgTime = new Date(psgtime_GMT);
                                        var diff = psgTime.getTime() - EditManualPsgTime.getTime();
                                        if(diff <= 0) // if edited manual psg time is greater than a verieifed psg time then error
                                        {
                                            return true; /// An error case exist
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }
            }
        }

        return false;
    }

    public deleteConfirmDialog: boolean = false;
    public onConfirmDeleteManualPassage(passage: MarkerPassage) {
        this.selectedMarkerPassage = passage;
        this.deleteConfirmDialog = true;
    }

    public onDeleteManualPassage() {
        console.log('delete me');
        var PRun = this.$store.state.pigRun.completedPigRuns.filter((PigRun: PigRun) => PigRun.runId === this.RunId);
        if(PRun[0].passages == null || PRun[0].passages == undefined)
        {
            return;
        }
       
        this.pigRun = PRun[0];
        
        console.log(this.selectedMarkerPassage);
        if (this.pigRun && this.pigRun.passages && this.selectedMarkerPassage) {
            this.pigRun.passages = this.pigRun.passages.filter( (el: any) => el.passageTimeStamp !== (this.selectedMarkerPassage as MarkerPassage).passageTimeStamp);
            this.updatePigRun();
        }
    }

    public CheckifMarkerHasPsg(selectedmarkerName:string|null)
    {
        var index = -1;
        if(this.pigRun && this.pigRun.passages)
        {
            index = this.pigRun.passages.findIndex( (passage: MarkerPassage) => {
                if (passage.markerName == selectedmarkerName) {
                    if(passage.isVerified == true)
                    {
                        return true;
                    }
                }
            });

            if(index <0)
            {
                return false;
            }
            else
            {
                return true;
            }

        }
        return false;
    }

}

