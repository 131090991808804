export class PowerMode {
    public static Active = 'Active';
    public static LowPower = 'Low Power';
    public static Hibernate = 'Hibernate';
    // Logger is a valid option, just not availible to the user
    // public static Logger = 'Logger';
    public static getModes() {
        return [
            this.Hibernate, // 0
            this.Active, // 1
            this.LowPower, // 2
            // this.Logger, // 3
        ];
    }

    public static getModesLimited() {
        return [
            this.Hibernate, // 0
            this.Active, // 1
            //this.LowPower, // 2
            // this.Logger, // 3
        ];
    }

    public static byIdx(i: number) {
        return [
            this.Hibernate, // 0
            this.Active, // 1
            this.LowPower, // 2
            // this.Logger, // 3
        ][i];
    }
    public static toType(i: number) {
        return [
            'hibernate', // 0
            'active', // 1
            'lowPower', // 2
            // 'logger', // 3
        ][i];
    }
    public static toString(s: string) {
        const idx = [
            'hibernate', // 0
            'active', // 1
            'lowPower', // 2
            // 'logger', // 3
        ].indexOf(s);
        if (idx !== -1) {
            return this.byIdx(idx);
        } else {
            return '';
        }
    }
}
