
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserManagement from '@/components/UserManagement.vue';

@Component({
    components: {
        UserManagement,
    },
})
export default class UserManagementView extends Vue {
}

