
import DateCommon from '../utils/DateCommon';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
// import am4themes_animated from '@amcharts/amcharts4/themes/animated';
// am4core.useTheme(am4themes_animated);
// import * as dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// dayjs.extend(utc);

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class PassageDialog extends Vue
{
    public editUserDialog: boolean = false;
    public newPassageTime: any = null;
    public newPassageTimeDisplay: any = null;
    public millisecondsExtra: string = '';
    public isPassageTimeEditSessionActive = false;
    public passageDialog = false;
    public maximizedToggle = false;
    public chart: any = null;
    public cursorEvt: any = null;

    // getters
    get detectionCount()
    {
        return 'Detection Count ' + this.$store.state.selectedPassageDetectionCount;
    }

    get passageTimeStamp()
    {
        return this.$store.state.selectedPassageTimestamp;
    }

    get passageData()
    {
        return this.$store.state.selectedPassageData;
    }

    get selectedPassages()
    {
        return this.$store.state.selectedPassages;
    }

    public UserRole:String = '';
    public getuser()
    {
        // Use this method to get user role.
        this.UserRole = this.$store.state.user.role

        return this.UserRole;
    }

    public unsubscribe = this.$store.subscribeAction((action, state) =>
    {
        if (action.type === 'passageChartShow')
        {
            this.showDialog();
        }
    });

    // The target passage time stamp value to use for the edit operation.
    public updatePassageTimeStampTargetValue: Date | undefined;

    // Mitch. June 28, 2023.
    //
    // Updated implementation of the onSavePassageTime component method where the PassageDialog.vue component is also invoked from the ReportManager.vue component.
    public onSavePassageTime()
    {
        console.log('PassageDialog.vue. onSavePassageTime. Enter method.');
        console.log('PassageDialog.vue. onSavePassageTime. this.$store.state.passageDialogSource = [' + this.$store.state.passageDialogSource + '].');
        console.log('PassageDialog.vue. onSavePassageTime. Before this.$store.state.passageIdsToUpdate');
        console.log(this.$store.state.passageIdsToUpdate);

        // If this component (i.e., PassageDialog.vue) has been invoked from either ReportManager.vue or DeviceInfo.vue.
        if (this.$store.state.passageDialogSource == 'report_manager' || this.$store.state.passageDialogSource == 'selected_passage')
        {
            // Get at least one Passage.Aid value stored in the Vuex store state member passageIdsToUpdate.
            if (this.$store.state.passageIdsToUpdate != undefined && this.$store.state.passageIdsToUpdate.length > 0)
            {
                let passageId: number = -1;

                for (let i = 0; i < this.$store.state.passageIdsToUpdate.length; i++)
                {
                    passageId = this.$store.state.passageIdsToUpdate[i];

                    if (passageId != -1)
                    {
                        break;
                    }
                }

                // If a Passage.Aid value was not found, return.
                if (passageId == -1)
                {
                    return;
                }

                console.log('PassageDialog.vue. onSavePassageTime. Passage.Aid = [' + passageId + '].');

                this.$store.dispatch('updatePassageTimeStampFromPassageDialogComponent', { passageId: passageId, updatePassageTimeStampTargetValue: this.updatePassageTimeStampTargetValue });
                this.editUserDialog = false;
            }
        }
        // else if (this.$store.state.passageDialogSource == 'selected_passage')
        // {
        //     console.log('PassageDialog.vue. onSavePassageTime. selected_passage section. Before logging this.$store.state.passageIdsToUpdate.');
        //     console.log(this.$store.state.passageIdsToUpdate);

        //     // Get at least one Passage.Aid value stored in the Vuex store state member passageIdsToUpdate.
        //     if (this.$store.state.passageIdsToUpdate != undefined && this.$store.state.passageIdsToUpdate.length > 0)
        //     {
        //         let passageId: number = -1;

        //         for (let i = 0; i < this.$store.state.passageIdsToUpdate.length; i++)
        //         {
        //             passageId = this.$store.state.passageIdsToUpdate[i];

        //             if (passageId != -1)
        //             {
        //                 break;
        //             }
        //         }

        //         // If a Passage.Aid value was not found, return.
        //         if (passageId == -1)
        //         {
        //             return;
        //         }

        //         console.log('PassageDialog.vue. onSavePassageTime. Passage.Aid = [' + passageId + '].');

        //         this.$store.dispatch('updatePassageTimeStampFromReportManager', { passageId: passageId, updatePassageTimeStampTargetValue: this.updatePassageTimeStampTargetValue });
        //         this.editUserDialog = false;
        //     }
        // }
        else
        {
            const data = {
                newPassageTime: this.newPassageTime,
                newPassageTimeDisplay: this.newPassageTimeDisplay,
            };

            this.$store.dispatch('updatePassageTimeStamps', data);
            this.editUserDialog = false;
        }

        // const data = {
        //     newPassageTime: this.newPassageTime,
        //     newPassageTimeDisplay: this.newPassageTimeDisplay,
        // };

        // console.log('PassageDialog.vue. onSavePassageTime. Before printing data.');
        // console.log(data);
        // console.log('PassageDialog.vue. onSavePassageTime. After printing data.');


        // this.$store.dispatch('updatePassageTimeStamps', data);
        // this.editUserDialog = false;
    }

    // Mitch. June 28, 2023.
    //
    // Previous implementation of the onSavePassageTime component method prior to invoking the PassageDialog.vue component
    // from the ReportManager.vue component.
    // public onSavePassageTime() {
    //     console.log('run: onSavePassageTime');
    //     const data = {
    //         newPassageTime: this.newPassageTime,
    //         newPassageTimeDisplay: this.newPassageTimeDisplay,
    //     };
    //     this.$store.dispatch('updatePassageTimeStamps', data);
    //     this.editUserDialog = false;
    // }

    public showDialog() {
        this.passageDialog = true;
    }

    public toggleMaxMin() {
        this.maximizedToggle = !this.maximizedToggle;
        // if we remove the width style the chartCard the chart will auto expand when maxed
        if (this.maximizedToggle) {
            // @ts-ignore
            this.$refs.chartCard.$el.style = '';
        } else {
            // @ts-ignore
            this.$refs.chartCard.$el.style = 'width: 700px; max-width: 80vw;';
        }
    }

    public beforeHideDialog() {
        // dispose the chart first!
        console.log('run: beforeHideDialog');
        if (this.chart) {
            this.chart.dispose();
        }
        // disable passage time edit session
        this.isPassageTimeEditSessionActive = false;
    }

    public beforeHideConfirmDialog() {
        console.log('run: beforeHideConfirmDialog');
        // disable passage time edit session
        this.isPassageTimeEditSessionActive = false;
    }

    @Watch('passageData', { immediate: false, deep: true })
    public passageDataChanged(value: any, oldValue: any) {
        // html div element doesn't exist when dialog not shown, it is needed to create the chart!
        if (this.passageDialog) {
            this.createChart();
        }
    }

    public passageEditSessionChanged()
    {
        console.log('PassageDialog.vue. passageEditSessionChanged. Enter method.');
        console.log('PassageDialog.vue. passageEditSessionChanged. isPassageTimeEditSessionActive = [' + this.isPassageTimeEditSessionActive + ']');

        if (this.isPassageTimeEditSessionActive)
        {
            const cursorPosition = {
                x: null as unknown as Date,
                tooltip: '',
            };

            this.cursorEvt = this.chart.cursor.events.on('cursorpositionchanged', (ev: any) => {
                const xAxis = ev.target.chart.xAxes.getIndex(0);
                cursorPosition.x = xAxis.positionToDate(xAxis.toAxisPosition(ev.target.xPosition));
                cursorPosition.tooltip = ev.target.chart.xAxes.values[0].tooltip.label.currentText;
            });

            const self = this;

            // add zero before single digit minutes for newPassageTimeDisplay
            const pad = (n: number) => {
                return (n < 10) ? ('0' + n) : n;
            };

            this.chart.plotContainer.events.once('hit', (ev: any) =>
            {
                console.log('PassageDialog.vue. passageEditSessionChanged. In hit event handler.');

                const chartValue = cursorPosition.x;
                const millisecondsFromLabel = cursorPosition.tooltip.substr(cursorPosition.tooltip.lastIndexOf('.') + 1, cursorPosition.tooltip.length);
                const milliseconds = millisecondsFromLabel.replace(this.millisecondsExtra, '');
                const utcDate = new Date(Date.UTC(chartValue.getFullYear(),
                    chartValue.getMonth(),
                    chartValue.getDate(),
                    chartValue.getHours(),
                    chartValue.getMinutes(),
                    chartValue.getSeconds(),
                    parseInt(milliseconds, 10),
                ));
                
                self.newPassageTimeDisplay = chartValue.getFullYear() + '-' + pad((chartValue.getMonth() + 1)) + '-' +
                    pad(chartValue.getDate()) + ' ' + pad(chartValue.getHours()) + ':' + pad(chartValue.getMinutes()) + ':' +
                    pad(chartValue.getSeconds()) + '.' + milliseconds + this.millisecondsExtra;
                self.newPassageTime = utcDate.toISOString().replace('Z', `${this.millisecondsExtra}Z`);
                self.editUserDialog = true;

                // Mitch - test (start)
                console.log('PassageDialog.vue. passageEditSessionChanged. Year = [' + utcDate.getUTCFullYear() + '].');
                console.log('PassageDialog.vue. passageEditSessionChanged. Month = [' + utcDate.getUTCMonth() + '].');
                console.log('PassageDialog.vue. passageEditSessionChanged. Day = [' + utcDate.getUTCDate() + '].');
                console.log('PassageDialog.vue. passageEditSessionChanged. Hour = [' + utcDate.getUTCHours() + '].');
                console.log('PassageDialog.vue. passageEditSessionChanged. Minute = [' + utcDate.getUTCMinutes() + '].');
                console.log('PassageDialog.vue. passageEditSessionChanged. Second = [' + utcDate.getUTCSeconds() + '].');
                console.log('PassageDialog.vue. passageEditSessionChanged. Milliseconds = [' + utcDate.getUTCMilliseconds() + '].');

                this.updatePassageTimeStampTargetValue = utcDate;
                // Mitch - test (end)
            });
        }
        else
        {
            if (this.cursorEvt !== null)
            {
                this.cursorEvt.dispose();
            }
        }
    }

    public createChart() {
        const chart = am4core.create('chartdiv', am4charts.XYChart);
        
        //debugger
        /*let numberOfSamples = 500; // a standard 32 s passage will have 500 samples

        // determine if an iridium passage state based on padding of 0's
        const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
        const tenSecSum = this.passageData.slice(160, 500).reduce(reducer);
        const twentySecSum = this.passageData.slice(320, 500).reduce(reducer);
        const thirtySecSum = this.passageData.slice(480, 500).reduce(reducer);
        if (tenSecSum === 0) {
            numberOfSamples = 160;
        } else if (twentySecSum === 0) {
            numberOfSamples = 320;
        } else if (thirtySecSum === 0) {
            numberOfSamples = 480;
        }*/
        let numberOfSamples = this.passageData.length;
        // A web passage has the sampling rate stored as part of the Passage table record in the database. If the sampling rate
        // exists, it is used. Otherwise, the default sampling rate of 15.625 is used.
        let samplingRate = this.$store.state.selectedPassageSamplingRate;

        if (samplingRate === undefined)
        {            
            if(numberOfSamples < 5000)
            {
                samplingRate = 15.625;
            }
            else
            {
                samplingRate = 1000.0;
            }
        }
        else
        {
            samplingRate = samplingRate / 1000;
        }

        // Calculate the dateOffset.
        let dateOffset = (1 / samplingRate) * 1000 * numberOfSamples;
        let datedelta = 1000/samplingRate;

        const data = [];
        // let dateOffset = 64 * numberOfSamples;    // Mitch - commented this out.

        const passageEndTime = this.$store.state.selectedPassageEndTimestamp;

        this.millisecondsExtra = passageEndTime.slice(-3);

        const endDate = DateCommon.sqlServerToDate(passageEndTime);

        if (endDate) {
            for (let i = 0; i < numberOfSamples; i++) {
                data.push({ date: DateCommon.utcTime(endDate) - dateOffset, name: 'name' + i, value: this.passageData[i] });
                dateOffset -= datedelta;//64;
            }
        }
        chart.data = data;
        chart.numberFormatter.numberFormat = '#';

        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        // @ts-ignore
        dateAxis.tooltip.disabled = false;
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.baseInterval = {timeUnit: 'millisecond', count: datedelta};
        dateAxis.dateFormats.setKey('millisecond', 'HH:mm:ss.SSS' + this.millisecondsExtra);

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // @ts-ignore
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        const series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = 'date';
        series.dataFields.valueY = 'value';

        series.tooltipText = '{valueY.value}';
        chart.cursor = new am4charts.XYCursor();

        const scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        chart.scrollbarX = scrollbarX;

        this.chart = chart;
    }

    public mounted() {
        console.log('PassageDialog: mounted()');
    }

    public beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}
