export interface IMarkerPassage {
    markerName: string;
    passageTimeStamp: string; // TS does not support the precision we need
    uid?: number;
    isVerified?: boolean;
    passageId?: number;
    passageDetectedFrequency?: number;
    detectionCount?: number;
    packetComplexInformation?: number;
    passageTimeEndOfSnip?: string; // TS does not support the precision we need
    data?: number[];
    elfId?: number;
    geoId?: number;
    magId?: number;
}

export class MarkerPassage implements IMarkerPassage {
    constructor(
        public markerName: string,
        public passageTimeStamp: string,
        public uid?: number,
        public isVerified?: boolean,
        public passageId?: number,
        public passageDetectedFrequency?: number,
        public detectionCount?: number,
        public packetComplexInformation?: number,
        public passageTimeEndOfSnip?: string,
        public data?: number[],
        public elfId?: number,
        public geoId?: number,
        public magId?: number,
    ) { }
}
