import { Device } from './Device';
import { AgmMarker } from './AgmMarker';
import { UserNotification } from './UserNotification';
import L from 'leaflet';
import * as turf from '@turf/turf';
import * as turfHelpers from '@turf/helpers';

export interface IPigPath {
    pathId: number;
    pigRunName: string;
    launchSiteLat: number;
    launchSiteLon: number;
    recieveSiteLat: number;
    recieveSiteLon: number;
    agms: number[]; // array of uids that may be included in the pig run
    offlineAgms: object;
    pigs: string[];
    expectedSpeed: number;
    pigLineGenerated: number[][]; // array of lat/lon

    // Mitch - updated implementation related to broadcast commands.
    // broadcastCommandNow: string;
    // broadcastCommandAtLaunch: string;
    // broadcastCommandAtReceive: string;

    // Note: All optional interface members must be declared after the required members above.
    pigLineKml?: number[][]; // array of lat/lon
    notes?: string;
    markers?: AgmMarker[];
    csvFileName?: string;
    notifications?: UserNotification[];
}

export class PigPath implements IPigPath {
    constructor(
        public pathId: number,
        public pigRunName: string,
        public launchSiteLat: number,
        public launchSiteLon: number,
        public recieveSiteLat: number,
        public recieveSiteLon: number,
        public agms: number[],
        public offlineAgms: object,
        public pigs: string[],
        public expectedSpeed: number,
        public pigLineGenerated: number[][],

        // Mitch - updated implementation related to broadcast commands.
        // public broadcastCommandNow: string,
        // public broadcastCommandAtLaunch: string,
        // public broadcastCommandAtReceive: string,

        public pigLineKml?: number[][],
        public notes?: string,
        public markers?: AgmMarker[],
        public csvFileName?: string,
        public notifications?: UserNotification[],
    ) {}

    public generatedLineToLatLngs(): L.LatLng[] {
        const latLngs: L.LatLng[] = [];
        this.pigLineGenerated.forEach( (ll: number[]) => {
            latLngs.push(L.latLng(ll[1], ll[0])); // how did these get reversed??
        });
        return latLngs;
    }

    public kmlLineToLatLngs(): L.LatLng[] {
        const latLngs: L.LatLng[] = [];
        if (this.pigLineKml) {
            this.pigLineKml.forEach( (ll: number[]) => {
                latLngs.push(L.latLng(ll[1], ll[0]));
            });
        }
        return latLngs;
    }

    public launchSiteToLatLng(): L.LatLng {
        return L.latLng(this.launchSiteLat, this.launchSiteLon);
    }

    public recieveSiteToLatLng(): L.LatLng {
        return L.latLng(this.recieveSiteLat, this.recieveSiteLon);
    }

    // public getPigByName(name: string): Pig | null {
    //     for (const p of this.pigs) {
    //         if (p.pigName === name) {
    //             return p;
    //         }
    //     }
    //     return null;
    // }

    public getDistance(): number {
        let distance = 0;
        let coords = null;
        const coordsArray = this.generatedLineToLatLngs();
        for (let i = 0; i < coordsArray.length - 1; i++) {
            coords = coordsArray[i];
            distance += coords.distanceTo(coordsArray[i + 1]);
        }
        return distance;
    }

    public getDistanceTo(agm: number): number {
        let distance = 0;
        if (this.agms.indexOf(agm) !== -1) {
            let coords = null;
            const coordsArray = this.generatedLineToLatLngs();
            for (let i = 0; i < this.agms.indexOf(agm) + 1; i++) {
                coords = coordsArray[i];
                distance += coords.distanceTo(coordsArray[i + 1]);
            }
        }
        return distance;
    }

    public getDistanceToMarker(marker: AgmMarker): number {
        let distance = 0;
        if (this.markers && this.markers.indexOf(marker) !== -1) {
            let coords = null;
            const coordsArray = this.generatedLineToLatLngs();
            for (let i = 0; i < this.markers.indexOf(marker) + 1; i++) {
                coords = coordsArray[i];
                distance += coords.distanceTo(coordsArray[i + 1]);
            }
        }
        return distance;
    }

    public getMarkerInfo(): any {
        const markerInfo: any = {};
        const linestring = turfHelpers.lineString(this.pigLineGenerated);
        if (this.markers) {
            // calculate details with track markers only because nextMarker should always be the next TRACK marker
            const trackMarkers = this.markers.filter((m: AgmMarker) => m.state === 'TRACK');
            for (let i = 0; i < trackMarkers.length; i++) {
                const marker = trackMarkers[i];
                const snapped = turf.nearestPointOnLine(linestring, turf.point([marker.lon, marker.lat]), {units: 'meters'});
                if (snapped && snapped.geometry && snapped.properties) {
                    markerInfo[marker.name] = {
                        latLng: L.latLng(snapped.geometry.coordinates[1], snapped.geometry.coordinates[0]),
                        nextMarker: trackMarkers.length > i + 1 ? trackMarkers[i + 1].name : null,
                        distanceFromStart: snapped.properties.location,
                        state: marker.state,
                        // speedMultiplier: 1,
                    };
                    // if (marker.distanceMetres) {
                    //     markerInfo[marker.name].speedMultiplier = markerInfo[marker.name].distanceFromStart / marker.distanceMetres;
                    //     markerInfo[marker.name].distanceFromStart = marker.distanceMetres;
                    // }
                }
            }

            // calculate SKIP markers just for use in ETA calculations
            for (let i = 0; i < this.markers.length; i++) {
                const marker = this.markers[i];
                if (marker.state === 'SKIP') {
                    const snapped = turf.nearestPointOnLine(linestring, turf.point([marker.lon, marker.lat]), {units: 'meters'});
                    if (snapped && snapped.geometry && snapped.properties) {
                        markerInfo[marker.name] = {
                            latLng: L.latLng(snapped.geometry.coordinates[1], snapped.geometry.coordinates[0]),
                            nextMarker: this.markers.length > i + 1 ? this.markers[i + 1].name : null,
                            distanceFromStart: snapped.properties.location,
                            state: marker.state,
                            // speedMultiplier: 1,
                        };
                        // if (marker.distanceMetres) {
                        //     markerInfo[marker.name].speedMultiplier = markerInfo[marker.name].distanceFromStart / marker.distanceMetres;
                        //     markerInfo[marker.name].distanceFromStart = marker.distanceMetres;
                        // }
                    }
                }
            }

            // calulate the distance from start to the first TRACK marker
            let distanceToFirstTrackMarker: number = this.generatedLineToLatLngs()[0].distanceTo(this.generatedLineToLatLngs()[this.pigLineGenerated.length - 1]);
            if (trackMarkers.length) {
                // it may be a user generated distance...
                // if (trackMarkers[0].distanceMetres) {
                //     distanceToFirstTrackMarker = trackMarkers[0].distanceMetres;
                // } else {
                const firstTrackMarker = turf.nearestPointOnLine(linestring, turf.point([trackMarkers[0].lon, trackMarkers[0].lat]), {units: 'meters'});
                if (firstTrackMarker && firstTrackMarker.geometry && firstTrackMarker.properties) {
                    distanceToFirstTrackMarker = firstTrackMarker.properties.location as number;
                }
                // }
            }

            markerInfo.start = {
                latLng: this.generatedLineToLatLngs()[0],
                nextMarker: trackMarkers.length ? trackMarkers[0].name : null,
                distanceToNext: distanceToFirstTrackMarker,
                distanceFromStart: 0,
                // speedMultiplier: 1,
                state: 'TRACK',
            };
            markerInfo.end = {
                latLng: this.generatedLineToLatLngs()[this.markers.length + 1],
                nextMarker: null,
                distanceToNext: null,
                distanceFromStart: this.getDistance(),
                // speedMultiplier: 1,
                state: 'TRACK',
            };
        }

        // we need to go back and adjust distanceToNext based on all distanceFromStart values
        for (const currentMarker in markerInfo) {
            if (markerInfo[currentMarker].nextMarker) {
                markerInfo[currentMarker].distanceToNext = markerInfo[markerInfo[currentMarker].nextMarker].distanceFromStart - markerInfo[currentMarker].distanceFromStart;
            } else {
                markerInfo[currentMarker].distanceToNext = null;
            }
        }

        return markerInfo;
    }

    public getAgmInfo(): any {
        const agmInfo: any = {};
        for (let i = 0; i < this.agms.length; i++) {
            const agm = this.agms[i];
            const latLng = this.generatedLineToLatLngs()[i + 1]; // first coord is recieve site
            agmInfo[agm] = {
                latLng,
                nextAgm: this.agms.length > i + 1 ? this.agms[i + 1] : null,
                distanceToNext: latLng.distanceTo(this.generatedLineToLatLngs()[i + 2]),
                distanceFromStart: this.getDistanceTo(agm),
            };
        }
        agmInfo.start = {
            latLng: this.generatedLineToLatLngs()[0],
            nextAgm: this.agms[0],
            distanceToNext: this.generatedLineToLatLngs()[0].distanceTo(this.generatedLineToLatLngs()[1]),
            distanceFromStart: 0,
        };
        agmInfo.end = {
            latLng: this.generatedLineToLatLngs()[this.agms.length + 1],
            nextAgm: null,
            distanceToNext: null,
            distanceFromStart: this.getDistance(),
        };
        return agmInfo;
    }

    public getKmlAgmInfo(devices: Device[]): any {
        let agmInfo: any = {};
        if (this.pigLineKml && this.pigLineKml.length > 0) {
            let agmCount = 0;
            const linestring = turfHelpers.lineString(this.pigLineKml);
            if (this.launchSiteLat) {
                const launchPt = turf.point([this.launchSiteLon, this.launchSiteLat]);
                const snapped = turf.nearestPointOnLine(linestring, launchPt, {units: 'meters'});
                if (snapped && snapped.geometry && snapped.properties) {
                    // console.log(snapped.properties.location); // === distance from start of line
                    agmInfo.start = {
                        latLng: L.latLng(snapped.geometry.coordinates[1], snapped.geometry.coordinates[0]),
                        nextAgm: this.agms.length > agmCount ? this.agms[agmCount] : null,
                        distanceFromStart: snapped.properties.location,
                    };
                }
                agmCount++;
            }
            for (const agm of this.agms) {
                let deviceLat;
                let deviceLon;
                if (this.offlineAgms.hasOwnProperty(agm)) {
                    deviceLat = this.offlineAgms[agm][0];
                    deviceLon = this.offlineAgms[agm][1];
                } else {
                    const deviceInfo = devices.filter( (d: Device) => agm === d.uid)[0];
                    if (deviceInfo && deviceInfo.lat && deviceInfo.lon) {
                        deviceLat = deviceInfo.lat;
                        deviceLon = deviceInfo.lon;
                    }
                }
                const agmPt = turf.point([deviceLon, deviceLat]);
                const snapped = turf.nearestPointOnLine(linestring, agmPt, {units: 'meters'});
                if (snapped && snapped.geometry && snapped.properties) {
                    agmInfo[agm] = {
                        latLng: L.latLng(snapped.geometry.coordinates[1], snapped.geometry.coordinates[0]),
                        nextAgm: this.agms.length > agmCount ? this.agms[agmCount] : null,
                        distanceFromStart: snapped.properties.location,
                    };
                }
                agmCount++;
            }
            if (this.recieveSiteLat) {
                const recievePt = turf.point([this.recieveSiteLon, this.recieveSiteLat]);
                const snapped = turf.nearestPointOnLine(linestring, recievePt, {units: 'meters'});
                if (snapped && snapped.geometry && snapped.properties) {
                    agmInfo.end = {
                        latLng: L.latLng(snapped.geometry.coordinates[1], snapped.geometry.coordinates[0]),
                        nextAgm: this.agms.length > agmCount ? this.agms[agmCount] : null,
                        distanceFromStart: snapped.properties.location,
                    };
                }
                agmCount++;
            }

            // we need to go back and add distanceToNext now that starting distances have been calulated
            for (const currentAgm in agmInfo) {
                if (agmInfo[currentAgm].nextAgm) {
                    agmInfo[currentAgm].distanceToNext = agmInfo[agmInfo[currentAgm].nextAgm].distanceFromStart - agmInfo[currentAgm].distanceFromStart;
                } else {
                    agmInfo[currentAgm].distanceToNext = null;
                }
            }

            // if the recieve is closer than the launch we need to revese the kml path and recalculate everything
            if (agmInfo.end.distanceFromStart < agmInfo.start.distanceFromStart) {
                console.log('REVERSE KML LINESTRING');
                this.pigLineKml = this.pigLineKml.reverse();
                agmInfo = this.getKmlAgmInfo(devices);
            }
        }
        return agmInfo;
    }
}
