export class RecordMode {
    public static Off = 'Off'; // 0
    // public static Snippet = 'Snippet'; // 1
    public static On = 'On'; // 2
    public static Downloading = 'Download';
    public static getModes() {
        return [
            this.Off, // 0
            this.On, // 2
            //this.Downloading,//3
        ];
    }
    public static byIdx(i: number) {
        return [
            this.Off, // 0
            this.On, // 2
            this.Downloading,//3
        ][i];
    }
    public static toType(i: number) {
        return [
            'off', // 0
            'on', // 2
            'download'//3
        ][i];
    }
    public static toString(s: string) {
        const idx = [
            'off', // 0
            'on', // 2
            'download'//3
        ].indexOf(s);
        if (idx !== -1) {
            return this.byIdx(idx);
        } else {
            return '';
        }
    }
    public static intToString(mode: number) {
        if (mode === 0) {
            return this.Off;
        }
        if (mode === 2) {
            return this.On;
        }
        if (mode === 3) {
            return this.Downloading;
        }
        return '';
    }
}
