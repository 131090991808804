
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class About extends Vue {

    public mounted() {
        console.log('About: mounted()');
    }

    public beforeDestroy() {
        // this.unsubscribe();
    }
}

