import axios from 'axios';
import store from '../store';
import { PigRun } from '../models/PigRun';
import { PigPath } from '../models/PigPath';
import { WebPassage } from '../models/WebPassage';
import { PigPathBroadcastCommand } from '@/models/PigPathBroadcastCommand';
import { CompletedPigRunBroadcastCommand } from '@/models/CompletedPigRunBroadcastCommand';
import { ReportDataForCompletedPigRunRequest } from '@/models/ReportManager/ReportDataForCompletedPigRunRequest';
import { ReportDataForDevicesRequest } from '@/models/ReportManager/ReportDataForDevicesRequest';
import { PassageVerificationRequest } from '@/models/ReportManager/PassageVerificationRequest';
import { DeleteManualPassageRequest } from '@/models/ReportManager/DeleteManualPassageRequest';
import { BroadcastCommandsRequest } from '@/models/BroadcastCommands/BroadcastCommandsRequest';
import { UpdatePassageTimeStampRequest } from '@/models/ReportManager/UpdatePassageTimeStampRequest';
import { DownloadRecordedPassageDataRequest } from '@/models/ReportManager/DownloadRecordedPassageDataRequest';
import { DownloadRecordedRawDataRequest } from '@/models/DownloadRecordedRawData/DownloadRecordedRawDataRequest';
import { DownloadRecordedPassageDataFirmwareValidationRequest } from '@/models/ReportManager/DownloadRecordedPassageDataFirmwareValidationRequest';
import { UpdateA2PMessagingStateRequest } from '@/models/Notification/UpdateA2PMessagingStateRequest';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_ROOT,
    withCredentials: false, // This is the default
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

let token = '';

export default {
    // ----------------------------------------------------------------------------------------------------------------------------------------------
    // Mitch - async/await Vue.js client action dispatch test.

    // async executeSlowCall(age: number)
    // {
    //     token = store.getters.token;
    //
    //     return await apiClient.get('/pigrun/slowcall/' + age, { headers: {Authorization: `Bearer ${token}`} });
    // },

    // async executeFastCall(age: number)
    // {
    //     token = store.getters.token;
    //
    //     return await apiClient.get('/pigrun/fastcall/' + age, { headers: {Authorization: `Bearer ${token}`} });
    // },
    
    // Web api service call used to create broadcast commands (main menu item call).
    async createBroadcastCommands(broadcastCommandsRequest: BroadcastCommandsRequest)
    {
        token = store.getters.token;
        
        return await apiClient.post('/pigrun/CreateBroadcastCommands', broadcastCommandsRequest, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to delete a manual passage for the specified PigRun.RunId.
    async deleteManualPassage(deleteManualPassageRequest: DeleteManualPassageRequest)
    {
        token = store.getters.token;

        return await apiClient.post('/pigrun/DeleteManualPassage', deleteManualPassageRequest, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to get report data for a completed pig run.
    async getReportDataForCompletedPigRun(reportDataForCompletedPigRunRequest: ReportDataForCompletedPigRunRequest)
    {
        token = store.getters.token;
        
        return await apiClient.post('/pigrun/GetReportDataForCompletedPigRun', reportDataForCompletedPigRunRequest, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to get report data for device(s).
    async getReportDataForDevices(reportDataForDevicesRequest: ReportDataForDevicesRequest)
    {
        token = store.getters.token;

        return await apiClient.post('/pigrun/GetReportDataForDevices', reportDataForDevicesRequest, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to create completed pig run broadcast commands.
    async createCompletedPigRunBroadcastCommands(completedPigRunBroadcastCommand: CompletedPigRunBroadcastCommand)
    {
        token = store.getters.token;
        
        return await apiClient.post('/pigrun/CreateCompletedPigRunBroadcastCommands', completedPigRunBroadcastCommand, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to create any required broadcast commands for the now event.
    async createBroadcastCommandsForNowEventForPathId(pathId: number)
    {
        token = store.getters.token;
        
        return await apiClient.get('/pigpath/CreateBroadcastCommandsForNowEvent/' + pathId, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to get the broadcast command data for the specified pathId.
    async getBroadcastCommandDataForPathId(pathId: number)
    {
        token = store.getters.token;

        return await apiClient.get('/pigpath/GetBroadcastCommandData/' + pathId, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to set passage verification.
    async setPassageVerification(passageVerificationRequest: PassageVerificationRequest)
    {
        token = store.getters.token;

        return await apiClient.post('pigrun/SetPassageVerification', passageVerificationRequest, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to update the broadcast command data for the specified pathId.
    //
    // Note: The pathId is a property of the PigPathBroadcastCommand class.
    //
    // Note: This web api call will update the PigPathBroadcastCommand SQL Server table records for the specified pathId.
    async updatePigPathBroadcastCommandData(pigPathBroadcastCommand: PigPathBroadcastCommand)
    {
        token = store.getters.token;

        return await apiClient.post('/pigpath/UpdateBroadcastCommandData', pigPathBroadcastCommand, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to update a passage time stamp value.
    async updatePassageTimeStamp(updatePassageTimeStamp: UpdatePassageTimeStampRequest)
    {
        token = store.getters.token;

        return await apiClient.post('/pigrun/UpdatePassageTimeStamp', updatePassageTimeStamp, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to create a download recorded passage data request.
    async downloadRecordedPassageDataRequest(downloadRecordedPassageDataRequest: DownloadRecordedPassageDataRequest)
    {
        token = store.getters.token;

        return await apiClient.post('/pigrun/DownloadRecordedPassageDataRequest', downloadRecordedPassageDataRequest, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to validate a download recorded passage data request.
    async validateDownloadRecordedPassageDataRequest(downloadRecordedPassageDataRequest: DownloadRecordedPassageDataRequest)
    {
        token = store.getters.token;

        return await apiClient.post('/pigrun/ValidateDownloadRecordedPassageDataRequest', downloadRecordedPassageDataRequest, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to end a download recorded passage data session.
    async endDownloadRecordedPassageDataSession(downloadRecordedPassageDataSessionId: number)
    {
        token = store.getters.token;

        return await apiClient.get('/pigrun/EndDownloadRecordedPassageDataSession/' + downloadRecordedPassageDataSessionId, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to create a download recorded raw data request.
    async downloadRecordedRawDataRequest(downloadRecordedRawDataRequest: DownloadRecordedRawDataRequest)
    {
        token = store.getters.token;

        return await apiClient.post('/pigrun/DownloadRecordedRawDataRequest', downloadRecordedRawDataRequest, { headers: {Authorization: `Bearer ${token}`} });
    },

    // Web api service call used to get detail for an individual download recorded passage data request.
    async getDownloadRecordedPassageDataRequestDetail(downloadRecordedPassageDataSessionId: number)
    {
        token = store.getters.token;

        return await apiClient.get('/pigrun/GetDownloadRecordedPassageDataRequestDetail/' + downloadRecordedPassageDataSessionId, { headers: { Authorization: `Bearer ${token}` } });
    },

    // Web api service call used to get CommandQueue detail for the specified cid value.
    async getCommandQueueDetail(cid: number)
    {
        token = store.getters.token;

        return await apiClient.get('/pigrun/GetCommandQueueDetail/' + cid, { headers: { Authorization: `Bearer ${token}` } });
    },

    // Web api service call used to perform a download recorded passage data firmware validation for the specified list of uid values.
    async validateDownloadRecordedPassageDataRequestFirmware(downloadRecordedPassageDataFirmwareValidationRequest: DownloadRecordedPassageDataFirmwareValidationRequest)
    {
        token = store.getters.token;

        return await apiClient.post('/pigrun/ValidateDownloadRecordedPassageDataRequestFirmware', downloadRecordedPassageDataFirmwareValidationRequest, { headers: { Authorization: `Bearer ${token}` } });
    },

    // Web api service call used to cancel an in progress download recorded passage data session.
    async cancelDownloadRecordedPassageDataRequest(downloadRecordedPassageDataSessionId: number)
    {
        token = store.getters.token;

        return await apiClient.get('/pigrun/CancelDownloadRecordedPassageDataRequest/' + downloadRecordedPassageDataSessionId, { headers: { Authorization: `Bearer ${token}` } });
    },

    // Web api service call used to get user detail.
    async getUser()
    {
        token = store.getters.token;

        return await apiClient.get('/pigrun/user/get-user', { headers: { Authorization: `Bearer ${token}` } });
    },

    // Web api service call used to update application-to-person messaging state.
    async updateA2PMessagingState(updateA2PMessagingStateRequest: UpdateA2PMessagingStateRequest)
    {
        token = store.getters.token;
        
        return await apiClient.post('/pigrun/user/update-a2p-messaging-state', updateA2PMessagingStateRequest, { headers: { Authorization: `Bearer ${token}` } });
    },





    







    
    // ----------------------------------------------------------------------------------------------------------------------------------------------

    addWebPassage(webPassage: WebPassage) {
        token = store.getters.token;

        let stringifiedPassage: any;

        stringifiedPassage = JSON.stringify(webPassage);

        return apiClient.post('/pigrun/addwebpassage', stringifiedPassage, { headers: {Authorization: `Bearer ${token}`} });
    },

    // getActivePigRunListForUserWithNotification.
    //
    // This service method is used to get the list of active pig run id values for which the specified user has a notification subscription.
    //
    // From a functional perspective, this is part of the implementation where an authenticated user, with viewer role membership, is only
    // permitted to view the active pig runs they have a notification subscription for.
    getActivePigRunListForUserWithNotification(userId: string) {
        token = store.getters.token;

        return apiClient.get('/pigrun/ActivePigRunListWithUserNotification/' + userId, { headers: {Authorization: `Bearer ${token}`} });
    },

    // getActivePigRunUidListForUserWithNotification.
    //
    // This service method is used to get the list of uid values participating in active pig run(s) for which the specified user has a 
    // notification subscription.
    //
    // From a functional perspective, this is part of the implementation where an authenticated user, with viewer role membership, is only
    // permitted to view the agm icons and corresponding data participating in active pig run(s) they have a notification subscription for.
    getActivePigRunUidListForUserWithNotification(userId: string) {
        token = store.getters.token;

        return apiClient.get('/pigrun/ActivePigRunUidListWithUserNotification/' + userId, { headers: {Authorization: `Bearer ${token}`} });
    },

    getPigRuns() {
        token = store.getters.token;
        return apiClient.get('/pigrun', { headers: {Authorization: `Bearer ${token}`} });
    },
    getDatabaseServerUtcDate() {
        token = store.getters.token;
        return apiClient.get('/getdatabaseserverutcdate', { headers: {Authorization: `Bearer ${token}`} });
    },
    getPigRun(runId: number) {
        token = store.getters.token;
        return apiClient.get('/pigrun/' + runId, { headers: {Authorization: `Bearer ${token}`} });
    },
    deletePigRun(runId: number) {
        token = store.getters.token;
        return apiClient.delete('/pigrun/' + runId, { headers: {Authorization: `Bearer ${token}`} });
    },
    addPigRun(pigRun: PigRun) {
        token = store.getters.token;
        const postBody: any = JSON.parse(JSON.stringify(pigRun));
        if (postBody.runId === -1) {
            delete postBody['runId'];
        }
        // any arrays/objects needs to be stringified and are strings in the DB
        postBody.path = JSON.stringify(JSON.parse(JSON.stringify(postBody.pigPath)));
        postBody.markers = JSON.stringify(postBody.markers);
        postBody.passages = JSON.stringify(postBody.passages);
        return apiClient.post('/pigrun/add/', postBody, { headers: {Authorization: `Bearer ${token}`} });
    },    
    updateFinishedPigRun(pigRun: PigRun) {
        token = store.getters.token;
        const postBody: any = JSON.parse(JSON.stringify(pigRun));
        if (postBody.runId === -1) {
            delete postBody['runId'];
        }
        // any arrays/objects needs to be stringified and are strings in the DB
        postBody.path = JSON.stringify(JSON.parse(JSON.stringify(postBody.pigPath)));
        postBody.markers = JSON.stringify(postBody.markers);
        postBody.passages = JSON.stringify(postBody.passages);
        return apiClient.post('/finished/pigrun/add/', postBody, { headers: {Authorization: `Bearer ${token}`} });
    },
    synchFinishedPigRun(RunId: number) {
        token = store.getters.token;
        //const postBody: any = {RunId };
        const postBody: any = JSON.parse(JSON.stringify(RunId));
        return apiClient.post('/finished/pigrun/synch/',postBody, { headers: {Authorization: `Bearer ${token}`} });
    },
    getPigPaths() {
        token = store.getters.token;
        return apiClient.get('/pigrun/pigpath', { headers: {Authorization: `Bearer ${token}`} });
    },
    getPigPath(pathId: number) {
        // not currently used/untested
        token = store.getters.token;
        return apiClient.get('/pigrun/pigpath/' + pathId, { headers: {Authorization: `Bearer ${token}`} });
    },
    deletePigPath(pathId: number) {
        token = store.getters.token;
        return apiClient.delete('/pigrun/pigpath/' + pathId, { headers: {Authorization: `Bearer ${token}`} });
    },
    addPigPath(pigPath: PigPath) {
        token = store.getters.token;
        const postBody: any = JSON.parse(JSON.stringify(pigPath));
        if (postBody.pathId === -1) {
            delete postBody['pathId'];
        }
        // any arrays/objects needs to be stringified and are strings in the DB
        postBody.markers = JSON.stringify(postBody.markers);
        postBody.pigs = JSON.stringify(postBody.pigs);
        postBody.agms = JSON.stringify(postBody.agms);
        postBody.offlineAgms =  JSON.stringify(postBody.offlineAgms);
        postBody.pigLineGenerated = JSON.stringify(postBody.pigLineGenerated);
        postBody.notifications = JSON.stringify(postBody.notifications);
        return apiClient.post('/pigrun/pigpath/add', postBody, { headers: {Authorization: `Bearer ${token}`} });
    },
    triggerManualAlert(markerName: string, pigRunId: number) {
        token = store.getters.token;
        const postBody: any = { markerName, pigRunId };
        return apiClient.post('/pigrun/triggermanualalert', postBody, {headers: {Authorization: `Bearer ${token}`} });
    },
    addMarkerPassage() {
        token = store.getters.token;
        const postBody: any = {};
        // MarkerPassage
        return apiClient.post('/pigrun/markerpassage/add', postBody, {headers: {Authorization: `Bearer ${token}`} });
    },
    verifyMarkerPassage() {
        token = store.getters.token;
        const postBody: any = {};
        // MarkerPassage
        return apiClient.post('/pigrun/markerpassage/verify', postBody, {headers: {Authorization: `Bearer ${token}`} });
    },
    deleteMarkerPassage(runId: number, passageTimeStamp: string) {
        token = store.getters.token;
        // MarkerPassage
        return apiClient.delete('/pigrun/markerpassage/{runId}/{passageTimestamp}', {headers: {Authorization: `Bearer ${token}`} });
    },
    endPigRun() {
        token = store.getters.token;
        const postBody: any = {};
        // PigRunDate
        return apiClient.post('/pigrun/endRun', postBody, {headers: {Authorization: `Bearer ${token}`} });
    },
    updateAgms(pigRun: PigRun) {
        token = store.getters.token;
        const postBody: any = JSON.parse(JSON.stringify(pigRun));
        console.log(postBody);
        const allowed = ['runId', 'path'];
        const filteredBody = Object.keys(postBody)
            .filter((key) => allowed.includes(key))
            .reduce((obj, key) => {
                obj[key] = postBody[key];
                return obj;
            }, {});
        console.log(filteredBody);
        // PigRunAgms
        return apiClient.post('/pigrun/updateAgms', filteredBody, {headers: {Authorization: `Bearer ${token}`} });
    },
    updateNotifications(pigRun: PigRun) {
        token = store.getters.token;
        const postBody: any = JSON.parse(JSON.stringify(pigRun));
        // any arrays/objects needs to be stringified and are strings in the DB
        // if (postBody.runId === -1) {
        //     delete postBody['runId'];
        // }
        // any arrays/objects needs to be stringified and are strings in the DB
        // postBody.path = JSON.stringify(JSON.parse(JSON.stringify(postBody.pigPath)));
        // delete postBody['markers'];
        // delete postBody['passages'];

        // filter the object to only post what we want to update
        const allowed = ['runId', 'path'];
        const filteredBody = Object.keys(postBody)
            .filter((key) => allowed.includes(key))
            .reduce((obj, key) => {
                obj[key] = postBody[key];
                return obj;
        }, {});

        // PigRunNotifications
        return apiClient.post('/pigrun/updateNotifications', filteredBody, {headers: {Authorization: `Bearer ${token}`} });
    },

    updateMarker() {
        token = store.getters.token;
        const postBody: any = {};
        // PigRunMarker
        return apiClient.post('/pigrun/updateMarker', postBody, {headers: {Authorization: `Bearer ${token}`} });
    },

    updateMarkers() {
        token = store.getters.token;
        const postBody: any = {};
        // PigRunMarkers
        return apiClient.post('/pigrun/updateMarkers', postBody, {headers: {Authorization: `Bearer ${token}`} });
    },
};
