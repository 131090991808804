export class PassageSize {
    public static TenSeconds = '10 sec';
    public static TwentySeconds = '20 sec';
    public static ThirtySeconds = '30 sec';

    public static byIdx(i: number) {
        return [
            this.TenSeconds, // 0
            this.TwentySeconds, // 1
            this.ThirtySeconds, // 2
        ][i];
    }
}
