export interface IAgmMarker {
    name: string;
    lat: number;
    lon: number;
    state: string;
    note?: string;
    linkedUid?: number;
    description?: string;
    distanceMetres?: number;
    autoLink?: boolean;
    autoVerify?: boolean;
    autoLinkedUids?: number[];
}

export class AgmMarker implements IAgmMarker {
    constructor(
        public name: string,
        public lat: number,
        public lon: number,
        public state: string,
        public note?: string,
        public linkedUid?: number,
        public description?: string,
        public distanceMetres?: number,
        public autoLink?: boolean,
        public autoVerify?: boolean,
        public autoLinkedUids?: number[],
        public ETA?: string,
        public DistanceFromLaunch?: number,
    ) { }
}
