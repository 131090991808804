

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import StreamingHub from '../services/StreamingHub';
import {SciChartSurface} from "scichart/Charting/Visuals/SciChartSurface";
import {NumericAxis} from "scichart/Charting/Visuals/Axis/NumericAxis";
import { XyyDataSeries } from "scichart/Charting/Model/XyyDataSeries";
import { FastBandRenderableSeries } from "scichart/Charting/Visuals/RenderableSeries/FastBandRenderableSeries";
import { NumberRange } from "scichart/Core/NumberRange";
import { EAxisAlignment } from "scichart/types/AxisAlignment";
import {EAutoRange} from "scichart/types/AutoRange";
import { HorizontalLineAnnotation } from "scichart/Charting/Visuals/Annotations/HorizontalLineAnnotation";
import { VerticalLineAnnotation } from "scichart/Charting/Visuals/Annotations/VerticalLineAnnotation";
import { LineAnnotation } from "scichart/Charting/Visuals/Annotations/LineAnnotation";
import { UniformHeatmapDataSeries } from "scichart/Charting/Model/UniformHeatmapDataSeries";
import { UniformHeatmapRenderableSeries } from "scichart/Charting/Visuals/RenderableSeries/UniformHeatmapRenderableSeries";
import { HeatmapColorMap } from "scichart/Charting/Visuals/RenderableSeries/HeatmapColorMap";
import { ELabelPlacement } from "scichart/types/LabelPlacement";
import {EXyDirection} from "scichart/types/XyDirection";
import { TextAnnotation } from "scichart/Charting/Visuals/Annotations/TextAnnotation";
import { EHorizontalAnchorPoint, EVerticalAnchorPoint } from "scichart/types/AnchorPoint";
import {MouseWheelZoomModifier} from "scichart/Charting/ChartModifiers/MouseWheelZoomModifier";
import { LegendModifier } from 'scichart/Charting/ChartModifiers/LegendModifier';
import { CursorModifier } from "scichart/Charting/ChartModifiers/CursorModifier";
import html2canvas from 'html2canvas';

import {
    InitWorkers,
    PassWorkersRaw, /* AGM Data Input. This is expected to be an array of numbers in the range of -32768 to 32767 with a data rate of 1KSPS. */
    GetGeoData,
    GetFilteredGeoData,
    GetEMData,
    GetMAGData,
    GetMAGData16_67Hz,
    GetMAGN,
    GetELFData,
    GetELFN,
    GetELFimagData,
    GetRSSIData,
    UpdateTimeSpan,
    ClearGeoBuffers,
    GetMatrix,
    GetSpectMax,
    GetSpectMin,
    UpdateRSSIFreq,
    UpdateWeldThresh,
    UpdateGeoAutoState,
    UpdateWeldCount,
    UpdateGeoPassageThresh,
    SetMaxCnt,
    SetMinCnt,
    GetDetectout,
    GeoPassageFifoTakeData,
    GeoPassageFifoHasData,
    SetDetectuseRaw
// @ts-ignore - no type defs!
} from '../realtimeplotworkers/TrAgmRTManager.js';


import {
    TraaInit,
    TraaEnable, /* Enable AGM Audio processing and streaming. */
    TraaDisable, /* Disable AGM Audio. Input data is ignored when disabled. */
    TraaAgmData, /* AGM Data Input. This is expected to be an array of numbers in the range of -32768 to 32767 with a data rate of 1KSPS. */
    TraaSetGaindB, /* User setable gain in dB. This can be called in realtime. */
    TraaIsEnabled,
    TraaIsPlaying,
    MuteAudio,
    TerminateAudio
// @ts-ignore - no type defs!
} from '../workers/TrAgmAudio.js';
import { Command } from '../models/Command';
import { CommandType } from '../models/CommandType';
import { Device } from '../models/Device';

// Mitch - start
import { WebPassage } from '../models/WebPassage';
import PigRunService from '../services/PigRunService';
// Mitch - end

@Component
export default class RealTimeStream extends Vue {

    public showRealTimeStream = false;
    public uid: number | null = null;
    public streamingConnection: any = null;
    public weldSpace: number | null = null;
    public calcBeat: number | null = null;
    public calcSpeed: number | null = null;
    public timerId: number = -1; // Timer responsible for plot updates
    public timerId2: number = -2; // Timer responsible for plot updates
    public timerId3: number = -3; // Timer responsible for Weld Detection Time Update
    public refreshFrequency = 150;//150// Timer Update Speed in ms
    public refreshFrequency2 = 2000; // refresh Weld Count 
    public TimeSpan: number = 60;
    public OldTimeSpan: number = 60;
    public isPaused: boolean = false;
    public isPSG: boolean = false;
    public isClosed: boolean = false;
    public dataSeries: any = null;
    public GeoSurface: any = null;
    public GeoContext: any = null;
    public EMSurface: any = null;
    public EMContext: any = null;
    public SpectSurface: any = null;
    public SpectContext: any = null;
    public RSSISurface: any = null;
    public RSSIContext: any = null;
    public VerticalAnnotation1:any = null;
    public VerticalAnnotation2:any = null;
    public DeltaLine : any; // Horizontal line annotation drawn between two verical annotations (Delta Time)
    public RssiHorizontalAnnotation :any; 
    public GeoDelta: number | null = null;
    public NowDate: Date = new Date('');
    public TimeString: string = '';
    public TimeHour: number = 0;
    public TimeMin: number = 0;
    public TimeSec: number = 0;
    public TimeMili: number = 0;
    public Spect_Xaxis_Start = 0
    public Spect_Yaxis_Start = 9
    public MatrixCopy = new Array(0);
    public HeatmapDataSeries: any = null;
    public heatmapSeries: any = null;
    public heatmapColor: any=null;
    public RSSIString: String = "RSSI @ 22 Hz";
    public TimeSpanString: String = "Time: 1 Min";
    public minimizeDialog: boolean = false;
    public maximizeDialog: boolean = false;
    public WeldCount: number = 0;
    public OldWeldCount: number = 0;
    public GeoAutoAvgSelect: String = "2 Welds Avg";
    public WeldDesc: String = "Weld Space (m)"
    public SpeedDesc:String = "Speed (m/s)";
    public checkboxstate:boolean = false;
    public StepSize5 = 0.005;//0.0049;//5/1016;
    public StepSize30 =0.03;// 0.0295;//30/1016;
    public StepSize60 = 0.06;//0.05905;//60/1016;
    public StepSize300 = 0.3//0.295;//300/1016;
    public StepSize600 = 0.6;//0.590;//600/1016;
    public StepSize1800 = 1.8;//1.771;//1800/1016;
    public xarray5 = new Array(0);
    public xarray30 = new Array(0);
    public xarray60 = new Array(0);
    public xarray300 = new Array(0);
    public xarray600 = new Array(0);
    public xarray1800 = new Array(0);
    public xarray5_200hz = new Array(0);
    public xarray30_200hz = new Array(0);
    public xarray60_200hz = new Array(0);
    public xarray300_200hz = new Array(0);
    public xarray600_200hz = new Array(0);
    public xarray1800_200hz = new Array(0);
    public psglog = false;
    public psglogidx = 0;
    public psglogelf = false;
    public psglogidxelf = 0;
    public PassageArrayMag = new Array(500);
    public numinputsamples = new Array(6);

    public GeoDetect = false;
    public isDialogOpen = false;
    public UserRole:String = '';
    public isGeoAutoClicked: boolean = false;
    public AutoGeoThreshLine:any = null;
    public AutoGeoThreshLineN:any = null;
   
    public volume: number = 20;
    public playBtn = true;
    public Enable_Audio:boolean = true;
    public GeoAvgOption:number = 1;
    public SystemUnits:String = "Metric";
    public isOnline: boolean = true;
    public GeoSpeedString:string = "X";
    public GeoWeldTimeString: string = "X";
    public ShowRAW:boolean = false;
    public ShowMAG:boolean = true;
    public ShowELF:boolean = true;
    public EMExtents:string = "extents";
    public RSSIExtents:string = "extents";
    public GeoExtents:string = "extents";

    public EMrendSeries:any;
    public MAGrendSeries:any;
    public ELFrendSeriesELFimag:any;
    public ELFrendSeriesELF:any;

    public MagThresh:number = 0.8;
    public ElfThresh:number = -40;
    public GeoThresh:number = 0.9;
    public WeldThresh:number = 0.9;
    public MaxMag = 0;
    public MinMag = 0;
    public MaxMagArray = new Array(125);
    public MaxElfI = 0;
    public MaxElfR = 0;
    public MinElfI = 0;
    public MinElfR = 0;
    public MaxElfRArray = new Array(125);
    public MaxElfIArray = new Array(125);

    public MaxRSSI = -1000;
    public MaxRSSIArray = new Array(125);

    public MaxGeo = 0;
    public MinGeo = 0;

    public MaxGeoArray = new Array(125);
    public MaxFilteredGeoArray = new Array(125);

    public RSSI_FrqLabel = this.$store.state.RealTimeSettings.Freq1;

    public EMVerticalLineAnnotation = 
    new VerticalLineAnnotation({
        stroke: "green",
        strokeThickness: 4,
        x1: -20,
        showLabel: true,
        resizeDirections: 0,
        isEditable: true,
        labelPlacement: ELabelPlacement.Right,
        onDragEnded: (() => this.EMDragEnd()) });

    public RSSIVerticalLineAnnotation = 
    new VerticalLineAnnotation({
        stroke: "green",
        strokeThickness: 4,
        x1: -20,
        showLabel: true,
        resizeDirections: 0,
        isEditable: true,
        labelPlacement: ELabelPlacement.Right,
        onDragEnded: (() => this.RSSIDragEnd()) });

    public SPectVerticalLineAnnotation = 
    new VerticalLineAnnotation({
        stroke: "green",
        strokeThickness: 4,
        x1: -20,
        showLabel: true,
        resizeDirections: 0,
        isEditable: true,
        labelPlacement: ELabelPlacement.Right,
        onDragEnded: (() => this.SpectDragEnd()) });    
    
    public EMHorizontalAnnotationPositive =  new HorizontalLineAnnotation({
    isEditable: true,
    labelPlacement: ELabelPlacement.Top,
    labelValue: "Mag Detection Threshold",
    showLabel: true,
    stroke: "Green",
    strokeThickness: 2,
    y1: 0.8, // The Y-value of the HorizontalLineAnnotation
    onDragEnded: (() => this.EMDragEndPositive()) });
  

    public EMHorizontalAnnotationNegative =  new HorizontalLineAnnotation({
    isEditable: true,
    labelPlacement: ELabelPlacement.Top,
    showLabel: false,
    stroke: "Green",
    strokeThickness: 2,
    y1: -0.8, // The Y-value of the HorizontalLineAnnotation
    onDragEnded: (() => this.EMDragEndNegative()) });

    public GeophoneDetectionThresholdLine =  new HorizontalLineAnnotation({
    isEditable: true,
    labelPlacement: ELabelPlacement.TopLeft,
    labelValue: "Geophone Detection Threshold",
    showLabel: true,
    stroke: "Blue",
    strokeThickness: 2,
    y1: 0.9, // The Y-value of the HorizontalLineAnnotation
    onDragEnded: (() => this.GeoDetectionThresholdDragEnd()) });

    public GeophoneDetectionThresholdLineN =  new HorizontalLineAnnotation({
    isEditable: true,
    labelPlacement: ELabelPlacement.TopLeft,
    labelValue: "Geophone Detection Threshold",
    showLabel: false,
    stroke: "Blue",
    strokeThickness: 2,
    y1: -0.9, // The Y-value of the HorizontalLineAnnotation
    onDragEnded: (() => this.GeoDetectionThresholdDragEndN()) });
     
        // Horizontal line stretched horizontally
    /*public AudioVerticalLine = new VerticalLineAnnotation({
        labelPlacement: ELabelPlacement.TopRight,
        labelValue: "audio",
        showLabel: false,
        stroke: "Cyan",
        strokeThickness: 2,
        x1: -10,
        axisLabelFill: "Cyan"
    })*/


    get device(): Device {
        return this.$store.state.selectedDevice;
    }

    public initannotation()
    {
        //debugger
        this.AutoGeoThreshLine =  new HorizontalLineAnnotation({
            isEditable: true,
            labelPlacement: ELabelPlacement.Top,
            showLabel: true,
            labelValue: "Weld Threshold",
            stroke: "Green",
            strokeThickness: 2,
            y1: 0.8,
            onDragEnded: (() => this.GeoAutoDragEnd())
        })

        this.AutoGeoThreshLineN =  new HorizontalLineAnnotation({
            isEditable: true,
            labelPlacement: ELabelPlacement.Top,
            showLabel: false,
            labelValue: "Weld Threshold",
            stroke: "Green",
            strokeThickness: 2,
            y1: -0.8,
            onDragEnded: (() => this.GeoAutoDragEndN())
        })

        this.VerticalAnnotation1 = 
        new VerticalLineAnnotation({
            stroke: "green",
            strokeThickness: 4,
            x1: -10,
            showLabel: true,
            resizeDirections: 0,
            isEditable: true,
            labelPlacement: ELabelPlacement.Left,
            onDragEnded: (() => this.DragEnd()) });


        this.VerticalAnnotation2 = new VerticalLineAnnotation({
        stroke: "green",
        strokeThickness: 4,
        x1: -20,
        showLabel: true,
        resizeDirections: 0,
        isEditable: true,
        labelPlacement: ELabelPlacement.Right,
        onDragEnded: (() => this.DragEnd()) });
    
        if(this.GeoSurface)
        {
            this.GeoSurface.annotations.remove(this.AutoGeoThreshLine);
            this.GeoSurface.annotations.remove(this.AutoGeoThreshLineN);
            this.GeoSurface.annotations.remove(this.VerticalAnnotation1);
            this.GeoSurface.annotations.remove(this.VerticalAnnotation2);
        }
    }

    public UpdateEMAxis()
    {
        var emyaxis;

        emyaxis = this.EMSurface.getYAxisById("DefaultAxisId");

        if(this.checkboxstate == true)
        {
            let maxval =0;
            let elfmax = 0;
            maxval = 0;

            if(this.MinElfI < 0)
            {
                this.MinElfI = -this.MinElfI;
            }
            if(this.MinElfI < 0)
            {
                this.MinElfR = -this.MinElfR;
            }

            elfmax = Math.max( ...[this.MaxElfI, this.MaxElfR, this.MinElfI, this.MinElfR] );

            maxval = 0;
            for(let i=0;i<30;i++)
            {
                if(this.MaxMagArray[i] > maxval )
                {
                    maxval = this.MaxMagArray[i];
                }
            }

            emyaxis = this.EMSurface.getYAxisById("DefaultAxisId");
            if(this.EMExtents == "extents")
            {
                emyaxis.autoRange = EAutoRange.Always;
            }
            else
            {
                emyaxis.autoRange = EAutoRange.Never;
                emyaxis.visibleRange = new NumberRange(1.1*this.EMHorizontalAnnotationNegative.y1, 1.1*this.EMHorizontalAnnotationPositive.y1);
                
            }
        }
        else
        {
                emyaxis.autoRange = EAutoRange.Never;
                //emyaxis.visibleRange = new NumberRange(1.1*this.EMHorizontalAnnotationNegative.y1, 1.1*this.EMHorizontalAnnotationPositive.y1);
        }
    }

    public UpdateGeoAxis()
    {
        var yaxis;

        yaxis = this.GeoSurface.getYAxisById("DefaultAxisId");

        if(this.checkboxstate == true)
        {

            /*let maxval =0;
            for(let i=0;i<30;i++)
            {
                if(this.MaxGeoArray[i] > maxval )
                {
                    maxval = this.MaxGeoArray[i];
                }
            }*/

            if(this.GeoExtents == "extents")
            {
                yaxis.autoRange = EAutoRange.Always;
            }
            else
            {
                yaxis.autoRange = EAutoRange.Never;
                yaxis.visibleRange = new NumberRange(1.1*this.AutoGeoThreshLineN.y1, 1.1*this.AutoGeoThreshLine.y1);
            }
        }
        else
        {
            yaxis.autoRange = EAutoRange.Never;
            //yaxis.visibleRange = new NumberRange(1.1*this.AutoGeoThreshLineN.y1, 1.1*this.AutoGeoThreshLine.y1);
        }
    }

    public AutoScaleCheckboxClicked()
    {
        var geoyaxis,rssiyaxis,magyaxis;

        geoyaxis = this.GeoSurface.getYAxisById("DefaultAxisId");
        rssiyaxis = this.RSSISurface.getYAxisById("DefaultAxisId");
        magyaxis = this.EMSurface.getYAxisById("DefaultAxisId");

        if(this.checkboxstate == true)
        {
            // Enable Auto Scale
            if(this.EMExtents == "extents")
            {
                magyaxis.autoRange = EAutoRange.Always;
            }
            else
            {
                magyaxis.autoRange = EAutoRange.Never;
                magyaxis.visibleRange = new NumberRange(1.05*this.EMHorizontalAnnotationNegative.y1, 1.05*this.EMHorizontalAnnotationPositive.y1);
            }

            if(this.RSSIExtents == "extents")
            {
                //rssiyaxis.autoRange = EAutoRange.Always;
            }
            else
            {
                rssiyaxis.autoRange = EAutoRange.Never;
                rssiyaxis.visibleRange = new NumberRange(-100, 10 + this.RssiHorizontalAnnotation.y1);
            }

            if(this.GeoExtents == "extents")
            {
                geoyaxis.autoRange = EAutoRange.Always;
            }
            else
            {
                geoyaxis.autoRange = EAutoRange.Never;
                if(this.GeophoneDetectionThresholdLine.y1 < this.AutoGeoThreshLine.y1 )
                {
                    geoyaxis.visibleRange = new NumberRange(-1.1*this.GeophoneDetectionThresholdLine.y1, 1.05*this.GeophoneDetectionThresholdLine.y1);
                }
                else
                {
                    geoyaxis.visibleRange = new NumberRange(1.1*this.AutoGeoThreshLineN.y1, 1.1*this.AutoGeoThreshLine.y1);
                }
               
                
            }  
            
        }
        else
        {   
            // Disable Auto Scale
            geoyaxis.autoRange = EAutoRange.Never;
            geoyaxis.visibleRange = new NumberRange(-1, 1);

            rssiyaxis.autoRange = EAutoRange.Never;
            rssiyaxis.visibleRange = new NumberRange(-95, -6);


            magyaxis.autoRange = EAutoRange.Never;
            magyaxis.visibleRange = new NumberRange(-1.5, 1.5);
        }
    }
    

    public UpdateWeldCount2()
    {
        var temp,tempTime;
        temp = GetDetectout();
        if(temp != null)
        {
            if(temp.weld != null)
            {
                this.WeldCount = temp.weld;
            }
        
            tempTime = temp.TimeBetween;

            if(this.GeoAvgOption == 2)
            {
                if(temp.Time5 != 0)
                {
                    tempTime = temp.Time5;
                }
            }
            else if(this.GeoAvgOption == 3)
            {
                if(temp.Time10 != 0)
                {
                    tempTime = temp.Time10;
                }
            }

            this.calcBeat = tempTime;
            if(this.calcBeat == null)
            {
                this.GeoWeldTimeString = "X";
            }
            else
            {
                this.GeoWeldTimeString = "Weld Time:"+this.calcBeat.toFixed(1)+" s";
            }

            if(this.weldSpace != null && this.calcBeat != null)
            {
                if(this.SystemUnits == "Imperial")
                {
                    this.calcSpeed = this.weldSpace/this.calcBeat;// Weld Space is already converted to ft.
                    this.calcSpeed = Math.round(this.calcSpeed *0.681818* 1000)/1000; // ft per sec to miles per hour
                    this.GeoSpeedString = "Pig Speed:"+this.calcSpeed.toFixed(1)+" mph";
                }
                else
                {
                    this.calcSpeed = this.weldSpace/this.calcBeat;
                    this.calcSpeed = Math.round(this.calcSpeed * 1000)/1000;
                    this.GeoSpeedString = "Pig Speed:"+this.calcSpeed.toFixed(1)+" m/s"; 
                }
            }

        }
    }

    
    public onGeoAutoSelect(input:number)
    {
        if(input == 1)
        {
            this.GeoAutoAvgSelect = "2 Welds Avg";
        }
        else if(input == 2)
        {
            this.GeoAutoAvgSelect = "5 Welds Avg";
        }
        else if(input == 3)
        {
            this.GeoAutoAvgSelect = "10 Welds Avg";
        }
        this.GeoAvgOption = input;
    }


    
    public onSystemUnitsSelect(input:number)
    {
        if(input == 1)
        {
            if(this.SystemUnits != "Metric")
            {
                if(this.calcSpeed != null)
                {
                    this.calcSpeed = Math.round((this.calcSpeed / 2.23694)*1000)/1000;
                }
                if(this.weldSpace != null)
                {
                    this.weldSpace = Math.round(this.weldSpace * 0.3048 * 1000)/1000;
                }
                
            }
            this.SystemUnits = "Metric";
            this.WeldDesc = "Weld Space (m)";
            this.SpeedDesc = "Speed (m/s)";
        }
        else if(input == 2)
        {
            if(this.SystemUnits != "Imperial")
            {
                if(this.calcSpeed != null)
                {
                    this.calcSpeed = Math.round(this.calcSpeed * 2.23694*1000)/1000;// convert from meters per second to miles per hour
                }
                if(this.weldSpace != null)
                {
                    this.weldSpace = Math.round((this.weldSpace / 0.3048) * 1000)/1000;
                }
            }
            this.SystemUnits = "Imperial";
            this.WeldDesc = "Weld Space (ft)";
            this.SpeedDesc = "Speed (mph)";
        }
    }
    
 
    
    public unsubscribe = this.$store.subscribeAction((action, state) => 
    {
            if (action.type === 'realtimeStreamerShow') {
                //window.open("yourURL", '_blank');
                this.showDialog(action.payload);
            }

            if (action.type === 'UpdateGeoSpeedParams') 
            {
                this.SetGeophoneParams(this.$store.state.GeoSpeedCalcSettings);
            }

            if(action.type === "UpdateGraphing")
            {
                this.SetGraphing(this.$store.state.GraphingSettings)
            }

    });

    public SetGeophoneParams(geoparams: any)
    {
        //debugger
        this.weldSpace = geoparams.WeldSpace;
        if(this.weldSpace == null)
        {
            console.log("spacing is null");
        }
        else
        {                 
            console.log(this.weldSpace.toString());
        }

        if( geoparams.Metric == true)
        {
            this.SystemUnits = "Metric";
        }
        else
        {
            this.SystemUnits = "Imperial";
        }


        SetDetectuseRaw(geoparams.SourceRaw);
        
        console.log(this.SystemUnits);

        this.GeoAvgOption = geoparams.NumAvg;
        if(this.GeoAvgOption == null)
        {
            console.log("avg is null");
        }
        else
        {                 
            console.log(this.GeoAvgOption.toString());
        }

        if(geoparams.Enable == true)
        {
            this.GeoAutoClicked();
        }
        else
        {
            this.GeoAutoUnClicked();
        }
    }

    public SetGraphing(graphparams: any)
    {
        //debugger;
        this.ShowRAW = graphparams.ShowRaw;
        this.ShowMAG = graphparams.ShowMAG;
        this.ShowELF = graphparams.ShowELF;
        this.EMExtents = graphparams.EMExtents;
        this.RSSIExtents = graphparams.RSSIExtents;
        this.GeoExtents = graphparams.GeoExtents;
        if(this.checkboxstate == true)
        {
            this.AutoScaleCheckboxClicked();
        }
        console.log(graphparams);
        
    }

    public getuser()
    {
        return this.$store.state.user.role;
    }


    @Watch('RTStreamDialog', { immediate: false, deep: true })
    public UpdateDialogState(value: boolean, oldValue: boolean)
    {
        this.isDialogOpen = value;
    }



    

    public showDialog(uid: number) {
        //debugger
        this.isDialogOpen = this.RTStreamDialog;
        if(this.isDialogOpen == true)
        {
            // TDO Need to check if UID has changed.
            // TDO if UID changed close previous streaming session and open a new one.
            return;
        }
        this.$store.dispatch('update_RTStreamDialog', true);


        // let us test the fifo class
        // Initialize the manager & the workers
        this.RegesterAudioListener();
        this.UserRole = this.getuser();
        if(this.UserRole == 'viewer')
        {

        }
        else
        {
            this.uid = uid;
            console.log(this.uid);
            console.log('START COMMAND ' + this.uid);
            this.submitStreamMode(this.uid, true);
        }


        InitWorkers();
        this.uid = uid;
        this.showRealTimeStream = true;
        this.isClosed = false;
        this.UseSciChartKey();
        // Initialize the charts after 1 second & start the plotting timer
        this.initannotation();
                
        setTimeout(() => {
            this.initxarrays();
            this.initSciChartGeo();
            this.initSciChartEM();
            this.initSciChartSpect();
            this.initSciChartRSSI();
            this.StartPlotTimer();
            this.streamingConnection = StreamingHub.getConnection();
            this.startStream();
        }, 1000);

        this.checkboxstate = true;
        /*setTimeout(() => {
            //this.checkboxstate = false;
            this.onClickAutoThresh();
        }, 20000);*/
    }

    public onClose() {
        console.log('AudioStream: onClose()');
        this.$store.dispatch('update_RTStreamDialog', false);

        setTimeout(() => {            
            this.RssiHorizontalAnnotation.isSelected = false;
            this.RssiHorizontalAnnotation.delete;
            this.RSSISurface.annotations.remove(this.RssiHorizontalAnnotation)

            this.EMVerticalLineAnnotation.isSelected = false;
            this.EMVerticalLineAnnotation.delete;
            this.EMSurface.annotations.remove(this.EMVerticalLineAnnotation)

            this.EMHorizontalAnnotationPositive.isSelected = false;
            this.EMHorizontalAnnotationPositive.delete;
            this.EMSurface.annotations.remove(this.EMHorizontalAnnotationPositive);

            this.EMHorizontalAnnotationNegative.isSelected = false;
            this.EMHorizontalAnnotationNegative.delete;
            this.EMSurface.annotations.remove(this.EMHorizontalAnnotationNegative);

            //this.AudioVerticalLine.isSelected = false;
            //this.AudioVerticalLine.delete;
            //this.GeoSurface.annotations.remove(this.AudioVerticalLine);

            this.RSSIVerticalLineAnnotation.isSelected = false;
            this.RSSIVerticalLineAnnotation.delete;
            this.RSSISurface.annotations.remove(this.RSSIVerticalLineAnnotation)


            this.SPectVerticalLineAnnotation.isSelected = false;
            this.SPectVerticalLineAnnotation.delete;
            this.SpectSurface.annotations.remove(this.SPectVerticalLineAnnotation)

            this.AutoGeoThreshLine.isSelected = false;
            this.AutoGeoThreshLine.delete;
            this.GeoSurface.annotations.remove(this.AutoGeoThreshLine);

            this.AutoGeoThreshLineN.isSelected = false;
            this.AutoGeoThreshLineN.delete;
            this.GeoSurface.annotations.remove(this.AutoGeoThreshLineN);

            this.GeophoneDetectionThresholdLine.isSelected = false;
            this.GeophoneDetectionThresholdLine.delete;
            this.GeoSurface.annotations.remove(this.GeophoneDetectionThresholdLine);

            this.GeophoneDetectionThresholdLineN.isSelected = false;
            this.GeophoneDetectionThresholdLineN.delete;
            this.GeoSurface.annotations.remove(this.GeophoneDetectionThresholdLineN);

            this.VerticalAnnotation1.isSelected = false;
            this.VerticalAnnotation1.delete;
            this.GeoSurface.annotations.remove(this.VerticalAnnotation1);

            this.VerticalAnnotation2.isSelected = false;
            this.VerticalAnnotation2.delete;
            this.GeoSurface.annotations.remove(this.VerticalAnnotation2);

            this.EMSurface.delete();
            this.RSSISurface.delete();
            this.SpectSurface.delete();
            this.GeoSurface.delete();
            
        }, 100);
        
        
        this.StopPlotTimer();
        ClearGeoBuffers();
        this.isClosed = true;

        this.EMSurface.annotations.remove(this.EMVerticalLineAnnotation);
        this.RSSISurface.annotations.remove(this.RSSIVerticalLineAnnotation);
      
        this.streamingConnection.invoke('ShutupToAgm', this.uid)
        .then((result: any) => {
            console.log('ShutupToAgm....');
        })
        .catch((err: any) => {
            return console.error('ShutupToAgm' + err.toString());
        });

        this.streamingConnection.off('ReceiveStream');

        this.shutdownAudio();
    }


    public onClickSendPsg()
    {
        if(this.latwebPassage)
        {
            this.$store.dispatch('notify', 'Adding Web Passage');
            PigRunService.addWebPassage(this.latwebPassage);
        }
    }


    public onClickAutoThresh()
    {
        let maxval =0;
        var rssiy = this.RSSISurface.getYAxisById("DefaultAxisId");

//debugger;
        maxval = 0;
        for(let i=0;i<this.MaxMagArray.length;i++)
        {
            if(this.MaxMagArray[i] > maxval )
            {
                maxval = this.MaxMagArray[i];
            }
        }
        if(maxval == 0)
        {
            maxval = 0.8/3;
        }

        if(maxval > (0.8/3))
        {
            maxval = 0.8/3;
        }
        
        this.EMHorizontalAnnotationPositive.y1 = maxval*3;
        this.EMHorizontalAnnotationNegative.y1 = -maxval*3;
        this.EMExtents = 'thresh';
        var emyaxis;

        emyaxis = this.EMSurface.getYAxisById("DefaultAxisId");
        emyaxis.autoRange = EAutoRange.Never;
        emyaxis.visibleRange = new NumberRange(1.1*this.EMHorizontalAnnotationNegative.y1, 1.1*this.EMHorizontalAnnotationPositive.y1);


        maxval = -1000;
        for(let i=0;i<this.MaxRSSIArray.length;i++)
        {
            if(this.MaxRSSIArray[i] > maxval )
            {
                maxval = this.MaxRSSIArray[i];
            }
        }

        if(maxval > -20)
        {
            maxval = -30;
        }

        this.RssiHorizontalAnnotation.y1 = maxval + 10;
        this.RSSIExtents = 'thresh';
        rssiy.autoRange = EAutoRange.Never;
        rssiy.visibleRange = new NumberRange(-100, this.RssiHorizontalAnnotation.y1+10);


        maxval = 0;
        for(let i=0;i<this.MaxGeoArray.length;i++)
        {
            if(this.MaxGeoArray[i] > maxval )
            {
                maxval = this.MaxGeoArray[i];
            }
        }
        if(maxval == 0)
        {
            maxval = 0.6/3;
        }

        if(maxval*1.5 > 0.6)
        {
            maxval = 0.6*(1/1.5);
        }

        this.AutoGeoThreshLine.y1 = maxval*1.5;
        this.AutoGeoThreshLineN.y1 = -maxval*1.5;

        UpdateWeldThresh(this.AutoGeoThreshLine.y1);

        if(maxval*6 > 0.6)
        {
            maxval = 0.6*(1/6);
        }

        this.GeophoneDetectionThresholdLine.y1 = maxval*6
        this.GeophoneDetectionThresholdLineN.y1 = -maxval*6
        this.GeoThresh = this.GeophoneDetectionThresholdLine.y1;
        UpdateGeoPassageThresh(this.GeoThresh);
        this.GeoExtents = 'thresh';
        var yaxis;
        yaxis = this.GeoSurface.getYAxisById("DefaultAxisId");
        yaxis.autoRange = EAutoRange.Never;
        yaxis.visibleRange = new NumberRange(1.1*this.GeophoneDetectionThresholdLineN.y1, 1.1*this.GeophoneDetectionThresholdLine.y1);
 
    }

    public onMinimize() 
    {
        this.minimizeDialog = true;
        this.maximizeDialog = false;
        //this.GeoSurface.annotations.remove(this.AutoGeoThreshLine);
        //this.GeoSurface.annotations.remove(this.AutoGeoThreshLineN);
        //this.GeoSurface.annotations.remove(this.VerticalAnnotation1);
        //this.GeoSurface.annotations.remove(this.VerticalAnnotation2);
        //this.EMSurface.annotations.remove(this.EMHorizontalAnnotationPositive);
        //this.EMSurface.annotations.remove(this.EMHorizontalAnnotationNegative);
        console.log('Minimizing Start')
        this.ClearHorizontalAnnotation(this.EMHorizontalAnnotationPositive,this.EMSurface);
        this.ClearHorizontalAnnotation(this.EMHorizontalAnnotationNegative,this.EMSurface);
        this.ClearHorizontalAnnotation(this.RssiHorizontalAnnotation,this.RSSISurface);
        this.ClearHorizontalAnnotation(this.GeophoneDetectionThresholdLine,this.GeoSurface);
        this.ClearHorizontalAnnotation(this.GeophoneDetectionThresholdLineN,this.GeoSurface);
        this.ClearHorizontalAnnotation(this.AutoGeoThreshLine,this.GeoSurface);
        this.ClearHorizontalAnnotation(this.AutoGeoThreshLineN,this.GeoSurface);
        //this.ClearVerticalAnnotation(this.AudioVerticalLine,this.GeoSurface);
        this.ClearVerticalAnnotation(this.VerticalAnnotation1,this.GeoSurface);
        this.ClearVerticalAnnotation(this.VerticalAnnotation2,this.GeoSurface);
        this.ClearVerticalAnnotation(this.EMVerticalLineAnnotation,this.EMSurface);
        this.ClearVerticalAnnotation(this.RSSIVerticalLineAnnotation,this.RSSISurface);   
        console.log('Minimizing End')      
    }

    public onMaximize()
    {
        this.maximizeDialog = true;
        this.minimizeDialog = false;
    }

    public onRestore()
    {
        this.maximizeDialog = false;
    }
 
    public MagGlobal: any;
    public GeoGlobal: any
    public RSSIGlobal: any;

    public StartPlotTimer() 
    {
        clearInterval(this.timerId);
        this.timerId = setInterval(() => {

            this.MagGlobal = GetMAGData();
            this.MagDetection(this.MagGlobal);
            this.GeoGlobal = GetGeoData();
            //this.GeophoneDetection(this.GeoGlobal);
            this.RSSIGlobal = GetRSSIData();
            this.RSSIDetect(this.RSSIGlobal);


            if(this.isPaused == false)
            {
                if(this.isClosed == false && this.minimizeDialog == false)
                {
                    //console.log("plotting")
                    //console.log(this.minimizeDialog);
                    this.PlotGeoSeries(this.GeoGlobal,GetFilteredGeoData());
                    this.PlotEMSeries(GetEMData(), this.MagGlobal,GetELFData(),GetELFimagData());
                    this.UpdateSpect();
                    this.PlotRSSISeries(this.RSSIGlobal);
                    this.updateClock();
                }
                else
                {
                    //GetGeoData();
                    GetEMData();
                    //GetMAGData();
                    GetELFData();
                    GetELFimagData();
                    //GetRSSIData();
                    GetMatrix();
                }
            }
            else
            {
                //GetGeoData();
                GetEMData();
                //GetMAGData();
                GetELFData();
                GetELFimagData();
                //GetRSSIData();
                GetMatrix();
            }
            
            

        }, this.refreshFrequency) as any;

        clearInterval(this.timerId2);
        this.timerId2 = setInterval(() => {
            if(this.isPaused == false)
            {
                if(this.isClosed == false)
                {
                    if(this.isGeoAutoClicked)
                    {
                        this.UpdateWeldCount2();
                    }
                }
            }
        }, this.refreshFrequency2) as any;
    }



    public async updateClock()
    {
        this.NowDate = new Date();
        this.TimeHour = this.NowDate.getUTCHours();
        this.TimeMin = this.NowDate.getUTCMinutes();
        this.TimeSec = this.NowDate.getUTCSeconds();
        this.TimeMili = this.NowDate.getUTCMilliseconds();
        this.TimeString = this.TimeHour.toString() +':' + this.TimeMin.toString() + ':' + this.TimeSec.toString() + '.' + this.TimeMili.toString();
    }


    public StopPlotTimer()
    {
        clearInterval(this.timerId);
    }


    public startStream() 
    {
        console.log('Start Real Time Plots...');
       // Play Button Pressed so we start listening from SignalR

        this.streamingConnection.invoke('ListenToAgm', this.uid)
        .then((result: any) => {
            console.log('ListenToAgm (RealTime)....');
        })
        .catch((err: any) => {
            return console.error('ListenToAgm (RealTime)....' + err.toString());
        });

        this.streamingConnection.on('ReceiveStream', (msg: any) => {
            //console.log("RealTime: Data Received")
            let streamMessage = JSON.parse(msg);
            
            if(this.isClosed == false)
            {
                // The message received from the SignalR streaming hub must be deserialized from JSON form
                // back to an object.
                // The deserialized object will have the following properties:

                //let streamMessage = JSON.parse(msg);
    
                if (streamMessage.geodata.length === 0) {
                    console.log('No Stream  (RealTime)');
                }
                else
                {
                   PassWorkersRaw(streamMessage.geodata,streamMessage.emdata);  
                }
            }
            else
            {
                console.log('Receiving Data  (isClose True)');
            }

            if (this.Enable_Audio) {
                TraaAgmData(streamMessage.geodata);
            }
        });
    }

    public onItemClick(value:number){
        // We need to inform other tasks that timespan has changed
        console.log('TimeSpan Changed')
        this.OldTimeSpan = this.TimeSpan;
        this.TimeSpan = value;
        if(value == 5 || value == 30 || value == 60 || value == 300 || value == 600 || value == 1800)
        {
            UpdateTimeSpan(value)
            // Need to update the axis

            if(value == 5 || value == 30)
            {
                this.TimeSpanString = "Time: " + value.toString() +" "+ "Sec";
            }
            else
            {
                var min = value/60;
                this.TimeSpanString = "Time: " + min.toString() +" "+ "Min";
            }
            
        
        }
    }


    public onFrequencyClicked(value:number){
        // We need to inform other tasks that timespan has changed
        var word = "RSSI @ ";        
        word =  word + value.toString() + " Hz";
        this.RSSIString = word; 
        UpdateRSSIFreq(value);
    }


    public ClearVerticalAnnotation(Annotation: VerticalLineAnnotation, Surf:SciChartSurface)
    {
        if(Annotation != null)
        {
            Annotation.isSelected = false;
            setTimeout(() => {
            Annotation.isSelected = false;
            Annotation.delete;
            Surf.annotations.remove(Annotation);
            }, 100);// this is a workaround. when annotation is selected and trying to hide does not fully hide. Calling hide second time after some time make it work
        }
    }

    public ClearHorizontalAnnotation(Annotation: HorizontalLineAnnotation, Surf:SciChartSurface)
    {
        if(Annotation != null)
        {
            Annotation.isSelected = false;
            setTimeout(() => {
            Annotation.isSelected = false;
            Annotation.delete;
            Surf.annotations.remove(Annotation);
            }, 100);// this is a workaround. when annotation is selected and trying to hide does not fully hide. Calling hide second time after some time make it work
        }
    }

    public ClearAnnotations1()
    {
        if(this.VerticalAnnotation1 != null)
        {
            this.VerticalAnnotation1.isSelected = false;
            setTimeout(() => {
            this.VerticalAnnotation1.isSelected = false;
            this.VerticalAnnotation1.isHidden = true;
            }, 100);// this is a workaround. when annotation is selected and trying to hide does not fully hide. Calling hide second time after some time make it work

        }
        if(this.VerticalAnnotation2 != null)
        {
            this.VerticalAnnotation2.isSelected = false;
            setTimeout(() => {
            this.VerticalAnnotation2.isSelected = false;
            this.VerticalAnnotation2.isHidden = true;
            }, 100);
        }
    }

    public ClearAnnotations2()
    {
        if(this.DeltaLine != null)
        {
            this.GeoSurface.annotations.remove(this.DeltaLine)
        }
    }


    public DragEnd()
    {
        var numtest:number;
        var delta:number;

        this.ClearAnnotations2()
        if(this.VerticalAnnotation1.x1 > this.VerticalAnnotation2.x1)
        {
            var num = this.VerticalAnnotation1.x1 - this.VerticalAnnotation2.x1;
            this.GeoDelta = Math.round(num * 1000)/1000;
            if(this.GeoDelta > 2)
            {
                numtest = this.VerticalAnnotation2.x1 + (this.GeoDelta/2)
            }
            else
            {
                numtest = this.VerticalAnnotation2.x1
            }
            
        }
        else
        {
            var num = this.VerticalAnnotation2.x1 - this.VerticalAnnotation1.x1;
            this.GeoDelta = Math.round(num * 1000)/1000;
    
            if(this.GeoDelta > 2)
            {
                numtest = this.VerticalAnnotation1.x1 + (this.GeoDelta/2)
            }
            else
            {
                numtest = this.VerticalAnnotation1.x1
            }
            
        }
        console.log('Delta: '+ this.GeoDelta);

        var aza:string = this.GeoDelta.toString()
 
         this.DeltaLine= new LineAnnotation({
            stroke: "green",//#B73225
            strokeThickness: 5,
            x1:  this.VerticalAnnotation1.x1,
            x2:  this.VerticalAnnotation2.x1,
            y1: 5.0,
            y2: 5.0,
            isEditable: false
        })
        
        if(this.VerticalAnnotation1.x1 > this.VerticalAnnotation2.x1)
        {
            delta = (this.VerticalAnnotation1.x1)- (this.VerticalAnnotation2.x1)
        }
        else
        {
            delta = (this.VerticalAnnotation2.x1)- (this.VerticalAnnotation1.x1)
        }
        this.calcBeat = delta;
        this.GeoWeldTimeString ="Weld Time:"+delta.toFixed(1)+" s";

        if(this.weldSpace != null)
        {
            if(this.SystemUnits == "Imperial")
            {
                this.calcSpeed = this.weldSpace/this.calcBeat;// Weld Space is already converted to ft.
                this.calcSpeed = Math.round(this.calcSpeed *0.681818* 1000)/1000; // ft per sec to miles per hour
                this.GeoSpeedString = "Pig Speed:"+this.calcSpeed.toFixed(1)+" mph";
            }
            else
            {
                this.calcSpeed = this.weldSpace/this.calcBeat;
                this.calcSpeed = Math.round(this.calcSpeed * 1000)/1000;
                this.GeoSpeedString = "Pig Speed:"+this.calcSpeed.toFixed(1)+" m/s"; 
            }
        }
        else
        {
            this.GeoSpeedString = "Weld Spacing Not Set"; 
        }
        
        this.GeoSurface.annotations.add(this.DeltaLine);
        
        
         this.calcBeat = this.GeoDelta 
        if(this.weldSpace != null)
        {
            this.calcSpeed = this.weldSpace /this.GeoDelta ;
            if(this.SystemUnits == "Imperial")
            {
                this.calcSpeed = Math.round(this.calcSpeed * 2.23694*1000)/1000;
            }
        }
        else
        {
            this.calcSpeed = null;
        }


        this.GetTimeLabels();

    }

    public EMDragEnd()
    {
        this.GetTimeLabelAnnotation(this.EMVerticalLineAnnotation);
    }

    public RSSIDragEnd()
    {
        this.GetTimeLabelAnnotation(this.RSSIVerticalLineAnnotation);
    }

    public SpectDragEnd()
    {
        this.GetTimeLabelAnnotation(this.SPectVerticalLineAnnotation);
    }

    public GetTimeLabelAnnotation(Annotation: VerticalLineAnnotation)
    {
        let x = -Annotation.x1;
        let hr,min,sec,mili, markkertime;

        markkertime = this.NowDate.getTime() - x*1000;

        var markerdate = new Date(markkertime);
        hr = markerdate.getUTCHours();
        min = markerdate.getUTCMinutes();
        sec = markerdate.getUTCSeconds();
        mili = markerdate.getUTCMilliseconds();

        let TimeString: string = '';
        
        if(hr<10)
        {
            TimeString = "0" + hr.toString() +':';
        }
        else
        {
            TimeString = hr.toString() +':';
        }

        if(min<10)
        {
            TimeString = TimeString + "0" + min.toString() +':';
        }
        else
        {
            TimeString = TimeString + min.toString() +':';
        }

        if(sec<10)
        {
            TimeString = TimeString + "0" + sec.toString() +'.';
        }
        else
        {
            TimeString = TimeString + sec.toString() +'.';
        }

        
        TimeString =  TimeString + mili.toString();
        //console.log('TimeString = ' + TimeString);
        Annotation.labelValue = TimeString;

        Annotation.labelPlacement = 3;
        return(markerdate);
    }

    public GetTimeLabels()
    {
        // Need to calculate time for each line
        let x = -this.VerticalAnnotation2.x1; // this is in seconds 
        let hr,min,sec,mili,markkertime;
        

        markkertime = this.NowDate.getTime() - x*1000;

        var markerdate = new Date(markkertime);
        hr = markerdate.getUTCHours();
        min = markerdate.getUTCMinutes();
        sec = markerdate.getUTCSeconds();
        mili = markerdate.getUTCMilliseconds();

          let TimeString: string = '';
        
        if(hr<10)
        {
            TimeString = "0" + hr.toString() +':';
        }
        else
        {
            TimeString = hr.toString() +':';
        }

        if(min<10)
        {
            TimeString = TimeString + "0" + min.toString() +':';
        }
        else
        {
            TimeString = TimeString + min.toString() +':';
        }

        if(sec<10)
        {
            TimeString = TimeString + "0" + sec.toString() +'.';
        }
        else
        {
            TimeString = TimeString + sec.toString() +'.';
        }

        
        TimeString =  TimeString + mili.toString();
        //console.log('TimeString = ' + TimeString);
        this.VerticalAnnotation2.labelValue = TimeString;


        x = -this.VerticalAnnotation1.x1;

        markkertime = this.NowDate.getTime() - x*1000;

        var markerdate = new Date(markkertime);
        hr = markerdate.getUTCHours();
        min = markerdate.getUTCMinutes();
        sec = markerdate.getUTCSeconds();
        mili = markerdate.getUTCMilliseconds();

        // hr = this.TimeHour
        // min = this.TimeMin
        // sec = this.TimeSec
        // mili = this.TimeMili 



        // // Need to get the whole number in val and the decimal number in val
        // whole = Math.trunc(x)
        // decimal = x - whole;
        // decimal = decimal * 1000;
        // decimal = Math.trunc(decimal)

        // mili = mili - decimal;
        
        // if(mili < 0)
        // {
        //     mili = 0 - mili;
        //     sec = sec - 1;
        // }


        // // How many seconds/min in whole
        // testmin = whole/60;
        // testmin1 = Math.trunc(testmin);

        // testsec = whole - testmin1 * 60;


        // sec = sec - testsec;
        // min = min - testmin1;

        // if(sec<0){sec = 0 - sec; min = min - 1;}
        // if(min<0){min = 0 - min; hr = hr - 1;}
        // if(hr >24){hr = hr - 24;}

        

        TimeString = '';
        
        if(hr<10)
        {
            TimeString = "0" + hr.toString() +':';
        }
        else
        {
            TimeString = hr.toString() +':';
        }

        if(min<10)
        {
            TimeString = TimeString + "0" + min.toString() +':';
        }
        else
        {
            TimeString = TimeString + min.toString() +':';
        }

        if(sec<10)
        {
            TimeString = TimeString + "0" + sec.toString() +'.';
        }
        else
        {
            TimeString = TimeString + sec.toString() +'.';
        }

        
        TimeString =  TimeString + mili.toString();
        this.VerticalAnnotation1.labelValue = TimeString;

        this.VerticalAnnotation1.labelPlacement = 3;
        this.VerticalAnnotation2.labelPlacement = 3;

    }
    public DragStart()
    {
        console.log('Drag Started');
    }

    public ResumedClicked()
    {
        this.isPSG = false;
        this.latwebPassage = null;
        if(this.isPaused == true)
        {
            
            this.ClearAnnotations1();
            this.ClearAnnotations2();
            this.ClearVerticalAnnotation(this.EMVerticalLineAnnotation,this.EMSurface);
            this.ClearVerticalAnnotation(this.RSSIVerticalLineAnnotation,this.RSSISurface);
            this.ClearVerticalAnnotation(this.SPectVerticalLineAnnotation,this.SpectSurface);
            this.isPaused = false;
        }
    }

    public PauseClicked()
    {
        // Mitch - start

        // Get the selected device from the state store.
/*         let selectedDevice: Device;

        selectedDevice = this.$store.state.selectedDevice;

        // Construct a WebPassage object.
        let webPassage: WebPassage;

        webPassage = new WebPassage(selectedDevice.uid);
        webPassage.lat = selectedDevice.lat;
        // webPassage.lon = selectedDevice.lon;
        webPassage.lon = undefined;
        webPassage.timeEndOfSnippet = new Date();
        webPassage.data = new Array(0);

        for (let i = 0; i < 500; i++)
        {
            webPassage.data?.push(i);
        }

        console.log(webPassage.data);
           
        // passage.data?.push(17);
        // passage.data?.push(18);

        // Call the addPassage method of the pig run service.
        PigRunService.addWebPassage(webPassage); */

        // Mitch - end

        //debugger
        if(this.isPaused == false)
        {
            this.EMVerticalLineAnnotation.labelPlacement = ELabelPlacement.Axis;
            this.EMVerticalLineAnnotation.axisLabelFill = "white";
            this.RSSIVerticalLineAnnotation.labelPlacement = ELabelPlacement.Axis;
            this.RSSIVerticalLineAnnotation.axisLabelFill = "white";
            this.SPectVerticalLineAnnotation.labelPlacement = ELabelPlacement.Axis;
            this.SPectVerticalLineAnnotation.axisLabelFill = "white";
            
            this.VerticalAnnotation1.axisLabelFill = "white";
            this.VerticalAnnotation2.axisLabelFill = "white";
            
            this.VerticalAnnotation1.isHidden = false;
            this.VerticalAnnotation2.isHidden = false;
            
            
            this.VerticalAnnotation1.x1 = ((this.TimeSpan)/2) -this.TimeSpan;
            this.VerticalAnnotation2.x1 = ((this.TimeSpan)/3) -this.TimeSpan;
            
            this.EMSurface.annotations.remove(this.RSSIVerticalLineAnnotation);
            this.RSSISurface.annotations.remove(this.RSSIVerticalLineAnnotation);

            this.RSSISurface.annotations.add(this.RSSIVerticalLineAnnotation);
            this.EMSurface.annotations.add(this.EMVerticalLineAnnotation);
            ;

            this.GetTimeLabelAnnotation(this.EMVerticalLineAnnotation);
            this.GetTimeLabelAnnotation(this.RSSIVerticalLineAnnotation);
            this.GetTimeLabels();
            this.isPaused = true;
        }
    }

    public UpdateSpeed()
    {
        if(this.isGeoAutoClicked == false)
        {
            this.calcBeat = this.GeoDelta


            if(this.weldSpace != null && this.GeoDelta != null)
            {
                this.calcSpeed = this.weldSpace /this.GeoDelta; 
                if(this.SystemUnits == "Imperial")
                {
                    this.calcSpeed = Math.round(this.calcSpeed * 2.23694*1000)/1000;
                }
            }
            else
            {
                this.calcSpeed = null;
            }
        }
    }



    public UpdateWeldCnt()
    {
        UpdateWeldCount(this.WeldCount);
    }

    public MaxPulseCnt:number = 100;
    public MinPulseCnt:number = 1;
    public SetMaxCnt()
    {
        SetMaxCnt(this.MaxPulseCnt)
    }
    public SetMinCnt()
    {
        SetMinCnt(this.MinPulseCnt)
    }


    public GeoThreshval:string = '0.2';
    public SetGeoThresh()
    {
        var temp:number = parseFloat(this.GeoThreshval);
        temp = Math.round(temp * 100)/100;
        if(temp >1)
        {
            temp = 1;
            this.GeoThreshval = '1';
        }

        if(temp<0)
        {
            temp = 0.2;
            this.GeoThreshval = '0.2';
        }

        if(temp==0)
        {
            temp = 0.1;
            this.GeoThreshval = '0.1';
        }

        this.AutoGeoThreshLine.y1 = temp;
        UpdateWeldThresh(this.AutoGeoThreshLine.y1);
    }

    public UseSciChartKey()
    {
        SciChartSurface.setRuntimeLicenseKey((process.env.VUE_APP_SCICHART_KEY || ''));
    }
    
    public async initSciChartGeo() 
    {
        const {sciChartSurface, wasmContext} = await SciChartSurface.create("scichart-Geo");
        const xAxis = new NumericAxis(wasmContext);
        xAxis.axisAlignment = EAxisAlignment.Bottom;
        xAxis.autoRange = EAutoRange.Always; //Auto Grow X axis when data is available
        const yAxis = new NumericAxis(wasmContext);
        yAxis.axisAlignment = EAxisAlignment.Right;
        yAxis.growBy = new NumberRange(0.2, 0.3);
        yAxis.visibleRangeLimit = new NumberRange(-1, 1);
        yAxis.axisTitle = 'Geo';
        yAxis.axisTitleStyle.fontSize = 12;

        if(this.checkboxstate == true)
        { 
            yAxis.autoRange = EAutoRange.Always;
        }
        else
        {   
            // Disable Auto Scale
            yAxis.autoRange = EAutoRange.Never;
            yAxis.visibleRange = new NumberRange(-1, 1);
        }


        sciChartSurface.xAxes.add(xAxis);
        sciChartSurface.yAxes.add(yAxis);
                

        this.GeoSurface = sciChartSurface;
        this.GeoContext = wasmContext;
        this.VerticalAnnotation1.isHidden = true;
        this.VerticalAnnotation2.isHidden = true;
        this.AutoGeoThreshLine.isHidden = false;
        this.AutoGeoThreshLine.y1 = 0.8;
        this.AutoGeoThreshLine.isHidden = false;
        this.AutoGeoThreshLineN.y1 = -0.8;

        // raw geo series
        this.GeodataSeries = new XyyDataSeries(this.GeoContext);
        const rendSeries = new FastBandRenderableSeries(this.GeoContext);
        rendSeries.dataSeries = this.GeodataSeries;
        rendSeries.dataSeries.dataSeriesName = "raw";
        rendSeries.strokeThickness = 2;//StrokeThickness = 1 is Faster
        rendSeries.fill = "#279B2733";
        rendSeries.fillY1 = "#279B2733";
        rendSeries.stroke = "#FF1919FF";
        rendSeries.strokeY1 = "#FF1919FF";
        this.GeoSurface.renderableSeries.add(rendSeries);


        // filtered geo series
        this.GeoFiltereddataSeries = new XyyDataSeries(this.GeoContext);
        const rendSeries2 = new FastBandRenderableSeries(this.GeoContext);
        rendSeries2.dataSeries = this.GeoFiltereddataSeries;
        rendSeries2.dataSeries.dataSeriesName = "filtered";
        rendSeries2.dataSeries.dataSeriesName = 'filtered';
        rendSeries2.strokeThickness = 2;//StrokeThickness = 1 is Faster
        rendSeries2.fill = "#279B2733";
        rendSeries2.fillY1 = "#279B2733";
        rendSeries2.stroke = "#00FFFF";
        rendSeries2.strokeY1 = "#00FFFF";
        this.GeoSurface.renderableSeries.add(rendSeries2);



        
    

        const GeoMouseWheelZoomModifier = new MouseWheelZoomModifier();
        GeoMouseWheelZoomModifier.isEnabled = true;
        GeoMouseWheelZoomModifier.xyDirection = EXyDirection.YDirection;
        

        this.AutoGeoThreshLine.y1 = this.WeldThresh;
        this.AutoGeoThreshLineN.y1 = -this.WeldThresh;
        this.GeophoneDetectionThresholdLine.y1 = this.GeoThresh;
        this.GeophoneDetectionThresholdLineN.y1 = -this.GeoThresh;

        let GeoLegend = new LegendModifier();
        GeoLegend.sciChartLegend.showCheckboxes = true;
        this.GeoSurface.chartModifiers.add(GeoLegend);


        //this.GeoSurface.renderableSeries.add(rendSeries);
        this.GeoSurface.chartModifiers.add(GeoMouseWheelZoomModifier);
        this.GeoSurface.annotations.add(this.AutoGeoThreshLine);
        this.GeoSurface.annotations.add(this.AutoGeoThreshLineN);
        this.GeoSurface.annotations.add(this.VerticalAnnotation1);
        this.GeoSurface.annotations.add(this.VerticalAnnotation2);
        this.GeoSurface.annotations.add(this.GeophoneDetectionThresholdLine);
        this.GeoSurface.annotations.add(this.GeophoneDetectionThresholdLineN);



        // Add CursorModifier behavior
        const cursorModifier = new CursorModifier({
            crosshairStroke: "#ff6600",
            crosshairStrokeThickness: 1,
            tooltipContainerBackground: "#000",
            tooltipTextStroke: "#ff6600",
            showTooltip: false,
            axisLabelFill: "#b36200",
            axisLabelStroke: "#fff"
        });
        this.GeoSurface.chartModifiers.add(cursorModifier);
    }

    public initxarrays()
    {
        //debugger;
        this.xarray5 = [];
        this.xarray30 = [];
        this.xarray60 = [];
        this.xarray300 = [];
        this.xarray600 = [];
        this.xarray1800 = [];


        for(let i=0;i<1016;i++)
        {
            this.xarray5.unshift(-i*this.StepSize5); 
            this.xarray30.unshift(-i*this.StepSize30); 
            this.xarray60.unshift(-i*this.StepSize60); 
            this.xarray300.unshift(-i*this.StepSize300); 
            this.xarray600.unshift(-i*this.StepSize600); 
            this.xarray1800.unshift(-i*this.StepSize1800);
        }
    }

    public geostarted:boolean = false;
    public GeodataSeries:any = null;
    public GeoFiltereddataSeries:any = null;
    public RSSIdataSeries:any = null;
    public EMdataSeries:any = null;
    public MAGdataSeries:any = null;
    public ELFdataSeries:any = null;
    public ELFImagdataSeries:any = null;
    public geopsg = false;
    public GeoPsgWave = new Array(1016);


    public GeophoneDetection( GeoData:any)
    {
        let GeoPsgData = new Array(1);

        if(GeoData == null)
        {
            return;
        }

        this.GeoPsgWave = [...GeoData.maxarray];

        if(this.psglog == false)
        {
            while(GeoPassageFifoHasData())
            {
                GeoPsgData = GeoPassageFifoTakeData();
                if(this.$store.state.RealTimeSettings.GeoEn == true)
                {
                    if(GeoPsgData[0] == true)
                    {
                        this.GeoDetect = true;
                        let psgDateTime = new Date();
                        let EndTime = new Date();
                        let webPassage: WebPassage;
                        let selectedDevice: Device;
                        
                        var uid = 0;
                        if(this.uid)
                        {
                            uid = this.uid;
                        }
                        webPassage = new WebPassage(uid);
                        //debugger;
                        
                        psgDateTime.setTime(Date.now());
                        var root = this;

                        setTimeout(() => {
                           
                            //let maxidx = 0;
                            //maxidx = root.GeoPsgWave.indexOf(Math.max.apply(Math, this.GeoPsgWave));
                            webPassage.lat = selectedDevice.lat;
                            webPassage.lon = selectedDevice.lon;
                            webPassage.passageTimeStamp = psgDateTime;
                            webPassage.passageDetectedFrequency = 0;
                            webPassage.samplingRate = 16.66667;
                            webPassage.packetComplexInformation = 1;

                            EndTime.setTime(webPassage.passageTimeStamp.getTime() + 15000);
                            webPassage.timeEndOfSnippet = EndTime;
                            console.log(webPassage.passageTimeStamp);
                            console.log(webPassage.timeEndOfSnippet);
                            webPassage.data = new Array(0);                            
                            for (let i = (root.GeoPsgWave.length-1); i > (root.GeoPsgWave.length-1)-500; i--)
                            {                                
                                webPassage.data?.unshift(Math.floor((root.GeoPsgWave[i]/2) * 32767));
                            }
                            
                            console.log(webPassage.data);
                            
                            // passage.data?.push(17);
                            // passage.data?.push(18);

                            // Call the addPassage method of the pig run service.

                            if(this.$store.state.RealTimeSettings.SendToDB == true)
                            {
                                this.latwebPassage = null;
                                PigRunService.addWebPassage(webPassage);
                            }
                            else
                            {
                                this.latwebPassage = webPassage;
                                if((this.getuser() == 'admin') || (this.getuser() == 'superadmin'))
                                {
                                    this.isPSG = true;
                                }     
                            }

                            if(this.$store.state.RealTimeSettings.SoundEn)
                            {
                                new Audio(this.soundFiles[3]).play();
                            }
                            
                           
                            //test code//
                            if(this.isPaused == false)
                            {
                                this.PauseClicked();
                            }
                            setTimeout(() => 
                            {
                                this.psglogelf = false;
                                if(this.isPaused == true)
                                {
                                    this.ResumedClicked();
                                }                          
                            
                            }, 1000*this.$store.state.RealTimeSettings.PauseTime);
                                            



                            this.GeoDetect = false;

                        }, 15000);
                    }
                }
            }
        }
    }

    public async PlotGeoSeries(GeoData: any,FilteredGeoData: any)
    {
        //let GeoPsgData = new Array(1);
        var geoyaxis;

        
        if(GeoData == null)
        {
            return
        }
        if(this.GeoSurface != null)
        {
            geoyaxis= this.GeoSurface.getYAxisById("DefaultAxisId");
        }
        else
        {
            return;
        }

        this.MaxGeo = 0;
        this.MinGeo = 0;
        for(let i = 0; i< GeoData.maxarray.length; i++)
        {
            if(GeoData.maxarray[i] > this.MaxGeo)
            {
               this.MaxGeo = GeoData.maxarray[i];
            }

            if(GeoData.minarray[i] < 0)
            {
                if(-GeoData.minarray[i] > this.MinGeo)
                {
                    this.MinGeo = -GeoData.minarray[i];    
                }
            }
        }

        if(this.MinGeo > this.MaxGeo)
        {
            this.MaxGeo = this.MinGeo;
        }

        this.MaxGeoArray.unshift(this.MaxGeo);
        this.MaxGeoArray.pop();

        this.GeodataSeries.clear();
        if(this.TimeSpan == 5){this.GeodataSeries.appendRange(this.xarray5, GeoData.maxarray,GeoData.minarray);}
        else if(this.TimeSpan == 30){this.GeodataSeries.appendRange(this.xarray30, GeoData.maxarray,GeoData.minarray);}
        else if(this.TimeSpan == 60){this.GeodataSeries.appendRange(this.xarray60, GeoData.maxarray,GeoData.minarray);}
        else if(this.TimeSpan == 300){this.GeodataSeries.appendRange(this.xarray300, GeoData.maxarray,GeoData.minarray);}
        else if(this.TimeSpan == 600){this.GeodataSeries.appendRange( this.xarray600, GeoData.maxarray,GeoData.minarray);}
        else if(this.TimeSpan == 1800){this.GeodataSeries.appendRange(this.xarray1800, GeoData.maxarray,GeoData.minarray);}








        if(FilteredGeoData == null)
        {
            return
        }
        if(this.GeoSurface != null)
        {
            geoyaxis= this.GeoSurface.getYAxisById("DefaultAxisId");
        }
        else
        {
            return;
        }

        this.MaxGeo = 0;
        this.MinGeo = 0;
        for(let i = 0; i< FilteredGeoData.maxarray.length; i++)
        {
            if(FilteredGeoData.maxarray[i] > this.MaxGeo)
            {
               this.MaxGeo = FilteredGeoData.maxarray[i];
            }

            if(FilteredGeoData.minarray[i] < 0)
            {
                if(-FilteredGeoData.minarray[i] > this.MinGeo)
                {
                    this.MinGeo = -FilteredGeoData.minarray[i];    
                }
            }
        }

        if(this.MinGeo > this.MaxGeo)
        {
            this.MaxGeo = this.MinGeo;
        }

        this.MaxFilteredGeoArray.unshift(this.MaxGeo);
        this.MaxFilteredGeoArray.pop();



        this.GeoFiltereddataSeries.clear();
        if(this.TimeSpan == 5){this.GeoFiltereddataSeries.appendRange(this.xarray5, FilteredGeoData.maxarray,FilteredGeoData.minarray);}
        else if(this.TimeSpan == 30){this.GeoFiltereddataSeries.appendRange(this.xarray30, FilteredGeoData.maxarray,FilteredGeoData.minarray);}
        else if(this.TimeSpan == 60){this.GeoFiltereddataSeries.appendRange(this.xarray60, FilteredGeoData.maxarray,FilteredGeoData.minarray);}
        else if(this.TimeSpan == 300){this.GeoFiltereddataSeries.appendRange(this.xarray300, FilteredGeoData.maxarray,FilteredGeoData.minarray);}
        else if(this.TimeSpan == 600){this.GeoFiltereddataSeries.appendRange( this.xarray600, FilteredGeoData.maxarray,FilteredGeoData.minarray);}
        else if(this.TimeSpan == 1800){this.GeoFiltereddataSeries.appendRange(this.xarray1800, FilteredGeoData.maxarray,FilteredGeoData.minarray);}
        this.UpdateGeoAxis();
    }


    public async initSciChartRSSI()
    {
        //SciChartSurface.setRuntimeLicenseKey("sq/V+8nPiXr88co0bvRAMJr4JOD9T29CKIWSsfdfogbP/P1mKG8j/+LD8dhfzyE2oYGlXMNMhBY1tufYErB4zE2s6RSzbpskfuN9tVCEqVK2pyRgkLCcQyX64U0I8U/Pz3fHbfuc5zXwiucwFZmHMWyboAa9lvoYoGfi/W2gSWTeDTUR35y+iPmJ1wK6Kp7yGhp0V8jNZp3TGCjt0TvXfkaXPEKwJVD963W1aPjt0d2tt+85Jf7uWGfSxkFGKbhQtXVKr4ijOb6EMG230a7+uY431tr6HjHk9GA3LU6bio7Jt7p26kT/+qay04jm8SSV2ht9glZXblFFG0j3kZZLFZYPPGjvtCZhs7GxTdZ9gDfdTBzeJBK9d3RS/mu1qexBDo1dPKsZ7hUEHV+UWSMF6kiO9rZUYauDRYdSdmdCEfF1w4g9zlVgZoFS5xCmjRcB0shBej43vmi0QVq15kYz/p3WVigbBzE8Tji+X5kSv1VqB6NwYvrhZD7w+A65Pc8flXg/vFhoZOHtwWaV9D9NtM7XVcZBGa5BcUtuUYkC+Q9XwhnTQycJU5fMJc+aKeCQZait7J0hTiztspZGrXT8tGVIgxRVeTmzjhGsf51MHzTKA83hdgMPNb7GG+s=");
        const {sciChartSurface, wasmContext} = await SciChartSurface.create("scichart-RSSI");
        const xAxis = new NumericAxis(wasmContext);
        xAxis.axisAlignment = EAxisAlignment.Bottom;
        xAxis.autoRange = EAutoRange.Always; //Auto Grow X axis when data is available

        const yAxis = new NumericAxis(wasmContext);
        yAxis.axisAlignment = EAxisAlignment.Right;
        yAxis.growBy = new NumberRange(0.2, 0.3);
        yAxis.autoRange = EAutoRange.Never;
        yAxis.axisTitle = 'RSSI';
        yAxis.axisTitleStyle.fontSize = 12;

        yAxis.visibleRange= new NumberRange(-95, -6);
        

        sciChartSurface.xAxes.add(xAxis);
        sciChartSurface.yAxes.add(yAxis);

        this.RSSISurface = sciChartSurface;
        this.RSSIContext = wasmContext;

        this.RssiHorizontalAnnotation =  new HorizontalLineAnnotation({
            isEditable: true,
            labelPlacement: ELabelPlacement.Top,
            labelValue: "ELF Detection Threshold",
            showLabel: true,
            stroke: "Blue",
            strokeThickness: 2,
            y1: -40 // The Y-value of the HorizontalLineAnnotation
        });
        

        this.RSSISurface.annotations.add(this.RssiHorizontalAnnotation);
        this.RSSIdataSeries = new XyyDataSeries(this.RSSIContext);
        const rendSeries = new FastBandRenderableSeries(this.RSSIContext);
        
        rendSeries.dataSeries= this.RSSIdataSeries;
        rendSeries.strokeThickness = 2;
        rendSeries.fill = "#279B2733";
        rendSeries.fillY1 = "#279B2733";
        rendSeries.stroke = "#FF1919FF";
        rendSeries.strokeY1 = "#FF1919FF";
        this.RSSISurface.renderableSeries.add(rendSeries);

        this.RssiHorizontalAnnotation.y1 = this.ElfThresh;

                // Add CursorModifier behavior
        const cursorModifier = new CursorModifier({
            crosshairStroke: "#ff6600",
            crosshairStrokeThickness: 1,
            tooltipContainerBackground: "#000",
            tooltipTextStroke: "#ff6600",
            showTooltip: false,
            axisLabelFill: "#b36200",
            axisLabelStroke: "#fff"
        });
        this.RSSISurface.chartModifiers.add(cursorModifier);
    }

    public UpdateRSSIAxis()
    {
        var rssiy;

        rssiy = this.RSSISurface.getYAxisById("DefaultAxisId");

        if(this.checkboxstate == true)
        {
            let maxval;
            maxval = -1000;

            for(let i=0;i<30;i++)
            {
                if(this.MaxRSSIArray[i] > maxval )
                {
                    maxval = this.MaxRSSIArray[i];
                }
            }
            
           /* rssiy = this.RSSISurface.getYAxisById("DefaultAxisId");
            if(maxval > (10+this.RssiHorizontalAnnotation.y1))
            {
                rssiy.autoRange = EAutoRange.Never;
                rssiy.visibleRange = new NumberRange(-100, 10+maxval);
            }
            else
            {
                rssiy.autoRange = EAutoRange.Never;
                rssiy.visibleRange = new NumberRange(-100, 10+this.RssiHorizontalAnnotation.y1);
            }*/
            if(this.RSSIExtents == "extents")
            {
                //rssiy.autoRange = EAutoRange.Always;
                rssiy.visibleRange = new NumberRange(-100, 10+maxval);
            }
            else
            {
                console.log("RSSI Thresh");
                rssiy.autoRange = EAutoRange.Never;
                rssiy.visibleRange = new NumberRange(-100, 10+this.RssiHorizontalAnnotation.y1);
            }
        }
        else
        {
            console.log("RSSI Auto OFF");
                rssiy.autoRange = EAutoRange.Never;
                rssiy.visibleRange = new NumberRange(-100, 10+this.RssiHorizontalAnnotation.y1);
        }

    }

    get soundFiles(): any {
        return this.$store.state.soundFiles;
    }

    public ELFTime = new Date();
    public RSSIDetect(RSSIData:any)
    {
        let Ns, newsamples;
        let maxrssi = -1000;
        let idxmax = 0;
        let psgDateTime = new Date();
        let EndTime = new Date();
        let selectedDevice: Device;
        let webPassage: WebPassage;

        if(RSSIData == null)
        {
            return;
        }
        
        Ns = GetELFN();// get the number of new samples that have arrived
       
        if(this.TimeSpan == 5)
        {
            newsamples = Ns[0];
        }
        else if(this.TimeSpan == 30)
        {
            newsamples = Ns[1];
        }
        else if(this.TimeSpan == 60)
        {
            newsamples = Ns[2];
        }
        else if(this.TimeSpan == 300)
        {
            newsamples = Ns[3];
        }
        else if(this.TimeSpan == 600)
        {
            newsamples = Ns[4]; 
        }
        else if(this.TimeSpan == 1800)
        {            
            newsamples = Ns[5];
        }

        if(newsamples > 1016)
        {
            newsamples = 1016;
        }

        if(this.$store.state.RealTimeSettings.ELFEn == true)
        {
            this.ElfThresh = this.RssiHorizontalAnnotation.y1;
            for(let i = 0; i<newsamples; i++)
            {
                if( (RSSIData.maxarray[i + 1016-newsamples] > this.ElfThresh) && (this.psglogelf == false) )
                {
                    //simple compare to threshold - will catch a lot fo things but thats ok for now
                    
                    console.log("elf detect 1");
                    this.psglogelf = true;
                    this.psglogidxelf = 0;
                    this.ELFTime.setTime(Date.now());
                    console.log(psgDateTime);
                    console.log("elf detect 2");
                }

                if(this.psglogelf == true)
                {
                    //detected something so accumulate some data
                    if(this.psglogidxelf < ((16.66667*15)))//goes until 15 seconds after passage
                    {
                        this.psglogidxelf++
                    }
                    else
                    {   
                        maxrssi = -1000;                 
                        for(let j =0; j < RSSIData.maxarray.length; j++)
                        {                        
                            if(RSSIData.maxarray[j] > maxrssi)
                            {
                                maxrssi = RSSIData.maxarray[j];
                                idxmax = 1016-j;
                            }                        
                        }

                        this.psglogidxelf = 0;                      
                                    


                        selectedDevice = this.$store.state.selectedDevice;

                        // Construct a WebPassage object.
                        var uid = 0;
                        if(this.uid)
                        {
                            uid = this.uid;
                        }

                        webPassage = new WebPassage(uid);
                        webPassage.lat = selectedDevice.lat;
                        webPassage.lon = selectedDevice.lon;
                        webPassage.passageTimeStamp = this.ELFTime;
                        webPassage.passageDetectedFrequency = 22;
                        webPassage.samplingRate = 16.66667;
                        webPassage.packetComplexInformation = 0;
                        //debugger;

                        EndTime.setTime(webPassage.passageTimeStamp.getTime() + (idxmax*this.StepSize60*1000));
                        webPassage.timeEndOfSnippet = EndTime;
                        console.log(webPassage.passageTimeStamp);
                        console.log(webPassage.timeEndOfSnippet);
                        webPassage.data = new Array(0);

                        let pwrr =0;
                        let pwri = 0;

                        for (let k = 0; k < 500; k++)
                        {
                            pwrr += (this.ElfWaveR[this.ElfWaveR.length -k - 1])*(this.ElfWaveR[this.ElfWaveR.length -k - 1]);
                            pwri += (this.ElfWaveI[this.ElfWaveR.length -k - 1])*(this.ElfWaveI[this.ElfWaveI.length -k - 1]);
                        }

                        if(pwrr >= pwri)
                        {
                            for (let ii = 0; ii < 500; ii++)
                            {
                                webPassage.data?.unshift(Math.floor((this.ElfWaveR[this.ElfWaveR.length -ii - 1]*2) * 32767));
                            }
                        }
                        else
                        {
                            for (let ii = 0; ii < 500; ii++)
                            {
                                webPassage.data?.unshift(Math.floor((this.ElfWaveI[this.ElfWaveI.length -ii - 1]*2) * 32767));
                            }
                        }

                        console.log(webPassage.data);



                        // Call the addPassage method of the pig run service.
                        if(this.$store.state.RealTimeSettings.SendToDB == true)
                        {
                            this.latwebPassage = null;
                            PigRunService.addWebPassage(webPassage);
                        }
                        else
                        {
                            this.latwebPassage = webPassage;
                            if((this.getuser() == 'admin') || (this.getuser() == 'superadmin'))
                            {
                                this.isPSG = true;
                            }   
                        }
          
                        if(this.$store.state.RealTimeSettings.SoundEn)
                        {
                            new Audio(this.soundFiles[5]).play();
                        }
                        
                        
                        this.psglogelf = false;
                        //test code//
                        if(this.isPaused == false)
                        {
                            this.PauseClicked();
                        }
                        setTimeout(() => 
                        {
                            this.psglogelf = false;
                            if(this.isPaused == true)
                            {
                                this.ResumedClicked();
                            }                          
                        
                        }, 1000*this.$store.state.RealTimeSettings.PauseTime);
                                             
                        
                    }
                }
            }
        }
    }

    public async PlotRSSISeries(RSSIData: any)
    {
        var N;
        var newsamples=0;
        var idxmax =0;
        let psgDateTime = new Date();
        let EndTime = new Date();
        var maxrssi = -200;

        if(RSSIData == null)
        {
            return
        }
        if(this.RSSISurface == null)
        {
            return;
        }

        // if((this.checkboxstate == true)&&(this.RSSIExtents == "extents"))
        // {
        //     var rssiyaxis = this.RSSISurface.getYAxisById("DefaultAxisId");
        //     var max,min;
        //     max = RSSIData.maxarray[1015];
        //     min = RSSIData.minarray[1015];
        
        //     for(let i=0;i<RSSIData.maxarray.length;i++)
        //     {
        //         if(max<RSSIData.maxarray[i])
        //         {
        //             max = RSSIData.maxarray[i]
        //         }
        //     }

        //     for(let i=0;i<RSSIData.maxarray.length;i++)
        //     {
        //         if(min>RSSIData.minarray[i])
        //         {
        //             min = RSSIData.minarray[i]
        //         }
        //     }

        //     rssiyaxis.visibleRange = new NumberRange(min, max);
        // }

        
        //N = GetELFN();

        if(this.TimeSpan == 5){newsamples = Math.floor(this.numinputsamples[0]*0.5);}
        else if(this.TimeSpan == 30){newsamples = Math.floor(this.numinputsamples[1]*0.5);}
        else if(this.TimeSpan == 60){newsamples = Math.floor(this.numinputsamples[2]*0.5);}
        else if(this.TimeSpan == 300){newsamples = Math.floor(this.numinputsamples[3]*0.5);}
        else if(this.TimeSpan == 600){newsamples = Math.floor(this.numinputsamples[4]*0.5);}
        else if(this.TimeSpan == 1800){newsamples = Math.floor(this.numinputsamples[5]*0.5);}

        // if(this.$store.state.RealTimeSettings.ELFEn == true)
        // {
        //     this.ElfThresh = this.RssiHorizontalAnnotation.y1;
        //     for(let i = 0; i<newsamples; i++)
        //     {
        //         if( (RSSIData.maxarray[i + 1016-newsamples] > this.ElfThresh) && (this.psglogelf == false) )
        //         {
        //             //simple compare to threshold - will catch a lot fo things but thats ok for now
                    
        //             console.log("elf detect");
        //             this.psglogelf = true;
        //             this.psglogidxelf = 0;
        //         }

        //         if(this.psglogelf == true)
        //         {
        //             //detected something so accumulate some data
        //             if(this.psglogidxelf < (1016*(0.5*15/this.TimeSpan)))//goes until 15 seconds after passage
        //             {
        //                 this.psglogidxelf++
        //             }
        //             else
        //             {                    
        //                 for(let j =0; j < RSSIData.maxarray.length; j++)
        //                 {                        
        //                     if(RSSIData.maxarray[j] > maxrssi)
        //                     {
        //                         maxrssi = RSSIData.maxarray[j];
        //                         idxmax = 1016-j;
        //                     }                        
        //                 }

        //                 this.psglogidxelf = 0;


                        

        //                 if(this.TimeSpan == 5)
        //                 {
        //                     this.RSSIVerticalLineAnnotation.x1 = -idxmax*this.StepSize5; //set the graph marker to where we believe the detection is
        //                 }
        //                 else if((this.TimeSpan == 30))
        //                 {
        //                     this.RSSIVerticalLineAnnotation.x1 = -idxmax*this.StepSize30; //set the graph marker to where we believe the detection is
        //                 }
        //                 else if((this.TimeSpan == 60))
        //                 {
        //                     this.RSSIVerticalLineAnnotation.x1 = -idxmax*this.StepSize60; //set the graph marker to where we believe the detection is
        //                 }
        //                 else if((this.TimeSpan == 300))
        //                 {
        //                     this.RSSIVerticalLineAnnotation.x1 = -idxmax*this.StepSize300; //set the graph marker to where we believe the detection is
        //                 }
        //                 else if((this.TimeSpan == 600))
        //                 {
        //                     this.RSSIVerticalLineAnnotation.x1 = -idxmax*this.StepSize600; //set the graph marker to where we believe the detection is
        //                 }
        //                 else if((this.TimeSpan == 1800))
        //                 {
        //                     this.RSSIVerticalLineAnnotation.x1 = -idxmax*this.StepSize1800; //set the graph marker to where we believe the detection is
        //                 }
                        
        //                 psgDateTime = this.GetTimeLabelAnnotation(this.RSSIVerticalLineAnnotation);// get the passage time
        //                 if(this.isPaused == false)
        //                 {
        //                     this.PauseClicked();
        //                 }

        //                 let selectedDevice: Device;

        //                 selectedDevice = this.$store.state.selectedDevice;

        //                 // Construct a WebPassage object.
        //                 let webPassage: WebPassage;

        //                 webPassage = new WebPassage(selectedDevice.uid);
        //                 webPassage.lat = selectedDevice.lat;
        //                 webPassage.lon = selectedDevice.lon;
        //                 webPassage.passageTimeStamp = psgDateTime;
        //                 webPassage.passageDetectedFrequency = 0;
        //                 webPassage.samplingRate = 16.66667;
        //                 webPassage.packetComplexInformation = 0;
        //                 //debugger;

        //                 EndTime.setTime(webPassage.passageTimeStamp.getTime() + (idxmax*this.StepSize60*1000));
        //                 webPassage.timeEndOfSnippet = EndTime;
        //                 console.log(webPassage.passageTimeStamp);
        //                 console.log(webPassage.timeEndOfSnippet);
        //                 webPassage.data = new Array(0);

        //                 let pwrr =0;
        //                 let pwri = 0;

        //                 for (let i = 0; i < 500; i++)
        //                 {
        //                     pwrr += (this.ElfWaveR[this.ElfWaveR.length -i - 1])*(this.ElfWaveR[this.ElfWaveR.length -i - 1]);
        //                     pwri += (this.ElfWaveI[this.ElfWaveR.length -i - 1])*(this.ElfWaveI[this.ElfWaveI.length -i - 1]);
        //                 }

        //                 if(pwrr >= pwri)
        //                 {
        //                     for (let i = 0; i < 500; i++)
        //                     {
        //                         webPassage.data?.unshift(Math.floor((this.ElfWaveR[this.ElfWaveR.length -i - 1]*2) * 32767));
        //                     }
        //                 }
        //                 else
        //                 {
        //                     for (let i = 0; i < 500; i++)
        //                     {
        //                         webPassage.data?.unshift(Math.floor((this.ElfWaveI[this.ElfWaveI.length -i - 1]*2) * 32767));
        //                     }
        //                 }
        //                 //debugger
        //                 console.log(webPassage.data);
                        
        //                 // passage.data?.push(17);
        //                 // passage.data?.push(18);

        //                 // Call the addPassage method of the pig run service.
        //                 if(this.$store.state.RealTimeSettings.SendToDB == true)
        //                 {
        //                     PigRunService.addWebPassage(webPassage);
        //                 }

        //                 setTimeout(() => {
        //                     this.psglogelf = false;
        //                     if(this.isPaused == true)
        //                     {
        //                         this.ResumedClicked();
        //                     }     
                            
        //                 }, 1000*this.$store.state.RealTimeSettings.PauseTime);
        //             }
        //         }
        //     }
        // }

        

        this.RSSIdataSeries.clear();

                
        this.MaxRSSI = -1000;
        for(let i = 0; i< RSSIData.maxarray.length; i++)
        {
            if(RSSIData.maxarray[i] > this.MaxRSSI)
            {
               this.MaxRSSI = RSSIData.maxarray[i];
            } 
        }
        this.MaxRSSIArray.unshift(this.MaxRSSI);
        this.MaxRSSIArray.pop();

        if(this.TimeSpan == 5){this.RSSIdataSeries.appendRange(this.xarray5, RSSIData.maxarray,RSSIData.minarray );}
        else if(this.TimeSpan == 30){this.RSSIdataSeries.appendRange(this.xarray30, RSSIData.maxarray,RSSIData.minarray );}
        else if(this.TimeSpan == 60){this.RSSIdataSeries.appendRange(this.xarray60, RSSIData.maxarray,RSSIData.minarray );}
        else if(this.TimeSpan == 300){this.RSSIdataSeries.appendRange(this.xarray300, RSSIData.maxarray,RSSIData.minarray );}
        else if(this.TimeSpan == 600){this.RSSIdataSeries.appendRange(this.xarray600, RSSIData.maxarray,RSSIData.minarray );}
        else if(this.TimeSpan == 1800){this.RSSIdataSeries.appendRange(this.xarray1800, RSSIData.maxarray,RSSIData.minarray );}

        this.UpdateRSSIAxis();
    }




    public async EMDragEndPositive()
    {
        if(this.EMHorizontalAnnotationPositive.y1 < 0.001)
        {
            this.EMHorizontalAnnotationPositive.y1 = 0.001;
        }
        
        this.EMHorizontalAnnotationNegative.y1 = -1*(this.EMHorizontalAnnotationPositive.y1);
        this.UpdateEMAxis();
    }

    public async EMDragEndNegative()
    {
        if(this.EMHorizontalAnnotationNegative.y1 > -0.001)
        {
            this.EMHorizontalAnnotationNegative.y1 = -0.001;
        }
        
        this.EMHorizontalAnnotationPositive.y1 = -1*(this.EMHorizontalAnnotationNegative.y1);
        this.UpdateEMAxis();
    }

    public async initSciChartEM() 
    {

        let EMLegend = new LegendModifier();

        this.MaxMagArray.fill(0);
        this.MaxGeoArray.fill(0);
        this.MaxRSSIArray.fill(-1000);
        this.MaxElfIArray.fill(0);
        this.MaxElfRArray.fill(0);

        const {sciChartSurface, wasmContext} = await SciChartSurface.create("scichart-EM");
        const xAxis = new NumericAxis(wasmContext);
        xAxis.axisAlignment = EAxisAlignment.Bottom;
        xAxis.autoRange = EAutoRange.Always; //Auto Grow X axis when data is available
        
        const yAxis = new NumericAxis(wasmContext);
        yAxis.axisAlignment = EAxisAlignment.Right;
        yAxis.growBy = new NumberRange(0.2, 0.3);
        yAxis.autoRange = EAutoRange.Always;//EAutoRange.Always;
        yAxis.axisTitle = 'EM';
        yAxis.axisTitleStyle.fontSize = 12;
        yAxis.visibleRangeLimit = new NumberRange(-1.5, 1.5);

        sciChartSurface.xAxes.add(xAxis);
        sciChartSurface.yAxes.add(yAxis);

        this.EMSurface = sciChartSurface;
        this.EMContext = wasmContext;

        // raw em series
        this.EMdataSeries = new XyyDataSeries(this.EMContext);
        this.EMrendSeries = new FastBandRenderableSeries(this.EMContext);
        this.EMrendSeries.dataSeries= this.EMdataSeries;
        this.EMrendSeries.dataSeries.dataSeriesName = 'raw';
        this.EMrendSeries.strokeThickness = 2;
        this.EMrendSeries.fill = "#279B2733";
        this.EMrendSeries.fillY1 = "#279B2733";
        this.EMrendSeries.stroke = "#00FFFF";
        this.EMrendSeries.strokeY1 = "#00FFFF";
        this.EMrendSeries.isVisible = false;
        this.EMSurface.renderableSeries.add(this.EMrendSeries);
        //magnetic series
        //debugger;
        this.MAGdataSeries = new XyyDataSeries(this.EMContext);
        this.MAGrendSeries = new FastBandRenderableSeries(this.EMContext);
        this.MAGrendSeries.dataSeries= this.MAGdataSeries;
        this.MAGrendSeries.dataSeries.dataSeriesName = 'mag';
        this.MAGrendSeries.strokeThickness = 2;
        this.MAGrendSeries.fill = "#fa0202";
        this.MAGrendSeries.fillY1 = "#fa0202";
        this.MAGrendSeries.stroke = "#fa02d1";
        this.MAGrendSeries.strokeY1 = "#fa02d1";
        this.EMSurface.renderableSeries.add(this.MAGrendSeries);


        //ELF series
        this.ELFdataSeries = new XyyDataSeries(this.EMContext);
        this.ELFrendSeriesELF = new FastBandRenderableSeries(this.EMContext);
        this.ELFrendSeriesELF.dataSeries= this.ELFdataSeries;
        this.ELFrendSeriesELF.dataSeries.dataSeriesName = 'elf';
        this.ELFrendSeriesELF.strokeThickness = 2;
        this.ELFrendSeriesELF.fill = "#279B2733";
        this.ELFrendSeriesELF.fillY1 = "#279B2733";
        this.ELFrendSeriesELF.stroke = "#33F8FF";
        this.ELFrendSeriesELF.strokeY1 = "#33F8FF";//
        this.ELFrendSeriesELF.isVisible = false;
        //this.EMSurface.renderableSeries.add(this.ELFrendSeriesELF);

        EMLegend.sciChartLegend.showCheckboxes = true;
        this.EMSurface.chartModifiers.add(EMLegend);

        //ELF series
        this.ELFImagdataSeries = new XyyDataSeries(this.EMContext);
        this.ELFrendSeriesELFimag = new FastBandRenderableSeries(this.EMContext);
        this.ELFrendSeriesELFimag.dataSeries= this.ELFImagdataSeries;
        this.ELFrendSeriesELFimag.dataSeries.dataSeriesName = 'elf';
        this.ELFrendSeriesELFimag.strokeThickness = 2;
        this.ELFrendSeriesELFimag.fill = "#279B2733";
        this.ELFrendSeriesELFimag.fillY1 = "#279B2733";
        this.ELFrendSeriesELFimag.stroke = "#FEFF33";
        this.ELFrendSeriesELFimag.strokeY1 = "#FEFF33";
        
        this.EMSurface.renderableSeries.add(this.ELFrendSeriesELFimag);

        const mouseWheelZoomModifier = new MouseWheelZoomModifier();
        
        
        mouseWheelZoomModifier.isEnabled = true;
        mouseWheelZoomModifier.xyDirection = EXyDirection.YDirection;


        this.EMSurface.chartModifiers.add(mouseWheelZoomModifier); 


        
        this.EMSurface.annotations.add(this.EMHorizontalAnnotationPositive);
        this.EMSurface.annotations.add(this.EMHorizontalAnnotationNegative);

        //this.GeoSurface.annotations.add(this.AudioVerticalLine);

        this.EMHorizontalAnnotationPositive.y1 = this.MagThresh;
        this.EMHorizontalAnnotationNegative.y1 = -this.MagThresh;


                        // Add CursorModifier behavior
        const cursorModifier = new CursorModifier({
            crosshairStroke: "#ff6600",
            crosshairStrokeThickness: 1,
            tooltipContainerBackground: "#000",
            tooltipTextStroke: "#ff6600",
            showTooltip: false,
            axisLabelFill: "#b36200",
            axisLabelStroke: "#fff"
        });
        this.EMSurface.chartModifiers.add(cursorModifier);

    }


public emb:boolean = false;
public ElfWaveR = new Array(500);
public ElfWaveI = new Array(500);
public MAGpsgData = new Array(0);


    public MagDetection( MAGData:any)
    {
        let Ns, max, idxmax, min, idxmin,idxmid;
        let newsamples;
        let StartTime = new Date();
        let psgDateTime = new Date();
        let UtcTimeServer;
        let EndTime = new Date();
        let emyaxis;

        Ns = GetMAGN();// get the number of new samples that have arrived


       
        if(this.TimeSpan == 5)
        {
            newsamples = Ns[0];
        }
        else if(this.TimeSpan == 30)
        {
            newsamples = Ns[1];
        }
        else if(this.TimeSpan == 60)
        {
            newsamples = Ns[2];
        }
        else if(this.TimeSpan == 300)
        {
            newsamples = Ns[3];
        }
        else if(this.TimeSpan == 600)
        {
            newsamples = Ns[4]; 
        }
        else if(this.TimeSpan == 1800)
        {            
            newsamples = Ns[5];
        }

        if(newsamples > 1016)
        {
            newsamples = 1016;
        }

        if(this.EMSurface != null)
        {
            emyaxis= this.EMSurface.getYAxisById("DefaultAxisId");
        }
        else
        {
            return;
        }

                /************MAG DETECTION OF PIG FROM STREAM*********** */
        this.MAGpsgData = GetMAGData16_67Hz();// gets a persistent (1000Hz -> 200 Hz -> 16.67Hz) 16.67 Hz sampled data stream from the raw data
        if(this.MAGpsgData == null)
        {
            return;
        }

        if(this.$store.state.RealTimeSettings.MagEn == true)
        {
            this.MagThresh = this.EMHorizontalAnnotationPositive.y1;

            for(let i = 0; i<newsamples; i++)
            {
                if(((MAGData.maxarray[i + 1016-newsamples] > this.MagThresh) || (MAGData.minarray[i + 1016-newsamples] < -this.MagThresh)) &&( this.psglog == false))
                {
                    //simple compare to threshold - will catch a lot fo things but thats ok for now
                    console.log("mag detect");
                    this.psglog = true;
                    this.psglogidx = 0;
                   
                }

                if(this.psglog == true)
                {
                    
                    //detected something so accumulate some data
                    if(this.psglogidx < (1016*(15/this.TimeSpan)))//goes until 15 seconds after passage
                    {
                        this.psglogidx++
                    }
                    else
                    {
                        StartTime.setTime( Date.now());
                        this.psglogidx++    
                        max = -1e9;
                        min = 1e9;
                        idxmax = 0;
                        idxmin = 0;
                        idxmid = 0;
                        // PigRunService.getDatabaseServerUtcDate().then((response) => {
                        //     UtcTimeServer = response.data;
                        // }).catch((error)=>console.log('time error'));    
                        

                        for(let j =0; j < 500; j++)// store 500 samples to be sent as a passage
                        {
                            this.PassageArrayMag[j] = this.MAGpsgData[j];
                            if(this.PassageArrayMag[j] > max)
                            {
                                max = this.PassageArrayMag[j];
                                idxmax = j;
                            }
                            if(this.PassageArrayMag[j] <min)
                            {
                                min = this.PassageArrayMag[j];
                                idxmin = j;
                            }

                            idxmid = Math.round((idxmax + idxmin)/2);// calulated for determing the graph marker
                        }
                        psgDateTime.setTime(  StartTime.getTime() - idxmid*this.StepSize60*1000  );
                        this.psglogidx = 0;
                        
                        //this.EMVerticalLineAnnotation.x1 = -idxmid*this.StepSize60; //set the graph marker to where we believe the detection is
                        //emyaxis.autoRange = EAutoRange.Once;
                        //this.GetTimeLabelAnnotation(this.EMVerticalLineAnnotation);// get the passage time

                        let selectedDevice: Device;
                        selectedDevice = this.$store.state.selectedDevice;
                        
                        var uid = 0;
                        if(this.uid)
                        {
                            uid = this.uid;
                        }

                        // Construct a WebPassage object.
                        let webPassage: WebPassage;
                        webPassage = new WebPassage(uid);
                        webPassage.lat = selectedDevice.lat;
                        webPassage.lon = selectedDevice.lon;
                        webPassage.passageTimeStamp = psgDateTime;
                        webPassage.passageDetectedFrequency = 0;
                        webPassage.samplingRate = 16.66667;
                        webPassage.packetComplexInformation = 2;
                        //debugger;
                        console.log((idxmid*this.StepSize60*1000))
                        EndTime.setTime(webPassage.passageTimeStamp.getTime() + (idxmid*this.StepSize60*1000));
                        webPassage.timeEndOfSnippet = EndTime;
                        console.log(webPassage.passageTimeStamp);
                        console.log(webPassage.timeEndOfSnippet);
                        webPassage.data = new Array(0);

                        for (let i = 0; i < 500; i++)
                        {
                            webPassage.data?.unshift(Math.floor((this.PassageArrayMag[i]/1.5) * 32767));
                        }

                        console.log(webPassage.data);
                        
                        // passage.data?.push(17);
                        // passage.data?.push(18);


                        
                        
                        // Call the addPassage method of the pig run service.
                        if(this.$store.state.RealTimeSettings.SendToDB == true)
                        {
                            this.latwebPassage = null;
                            PigRunService.addWebPassage(webPassage);
                        }
                        else
                        {
                            this.latwebPassage = webPassage;
                            if((this.getuser() == 'admin') || (this.getuser() == 'superadmin'))
                            {
                                this.isPSG = true;
                            }   
                        }
                        if(this.$store.state.RealTimeSettings.SoundEn)
                        {
                            new Audio(this.soundFiles[4]).play();
                        }
                        
                        this.psglog = false;
                        //test code//
                        if(this.isPaused == false)
                        {
                            this.PauseClicked();
                        }
                        setTimeout(() => 
                        {
                            this.psglog = false;
                            if(this.isPaused == true)
                            {
                                this.ResumedClicked();
                            }                          
                        
                        }, 1000*this.$store.state.RealTimeSettings.PauseTime);
                         //test code//
                    }
                }
            }
        }
    }

    public latwebPassage: WebPassage | null = null;



    public async PlotEMSeries(EMData: any, MAGData:any, ELFData:any, ELFDataImag:any)
    {
        let Ns = new Array(0);
        let Nself = new Array(0);
        
        let newsamples;
        //let newsampleself;
        var emyaxis;
        var max = -1e9;
        var min = 1e9;
        var idxmax = 1;
        var idxmin = 1;
        var idxmid = 1;
        let psgDateTime = new Date();
        let EndTime = new Date();

        if(EMData == null)
        {
            return;
        }

        if( this.EMSurface == null)
        {
            return;
        }

        if(this.EMrendSeries != null)
        {
            //this.EMrendSeries.isVisible = this.ShowRAW;
        }
        else
        {
            return;
        }
        
        if(this.MAGrendSeries != null)
        {
        //this.MAGrendSeries.isVisible = this.ShowMAG;
        }
        else
        {
            return;
        }

        if(this.ELFrendSeriesELFimag != null)
        {
            //this.ELFrendSeriesELFimag.isVisible = this.ShowELF;
        }
        else
        {
            return;
        }
        
        


        emyaxis= this.EMSurface.getYAxisById("DefaultAxisId");

        //Ns = GetMAGN();// get the number of new samples that have arrived
        //Nself = GetELFN();
        //this.numinputsamples = Nself;
        this.EMdataSeries.clear();
        if(this.TimeSpan == 5)
        {
            this.EMdataSeries.appendRange(this.xarray5, EMData.maxarray,EMData.minarray );
            //newsamples = Ns[0];
            //newsampleself = Nself[0];
        }
        else if(this.TimeSpan == 30)
        {
            this.EMdataSeries.appendRange(this.xarray30, EMData.maxarray,EMData.minarray );
            //newsamples = Ns[1];
            //newsampleself = Nself[1];
        }
        else if(this.TimeSpan == 60)
        {
            this.EMdataSeries.appendRange(this.xarray60, EMData.maxarray,EMData.minarray );
            //newsamples = Ns[2];
            //newsampleself = Nself[2];
        }
        else if(this.TimeSpan == 300)
        {
            this.EMdataSeries.appendRange(this.xarray300, EMData.maxarray,EMData.minarray );
            //newsamples = Ns[3];
            //newsampleself = Nself[3];
        }
        else if(this.TimeSpan == 600)
        {
            this.EMdataSeries.appendRange(this.xarray600, EMData.maxarray,EMData.minarray );
            //newsamples = Ns[4];
            //newsampleself = Nself[4];
        }
        else if(this.TimeSpan == 1800)
        {
            this.EMdataSeries.appendRange(this.xarray1800, EMData.maxarray,EMData.minarray );
            //newsamples = Ns[5];
            //newsampleself = Nself[5];
        }

        if(MAGData == null)
        {
            return;
        }

        // if(newsamples > 1016)
        // {
        //     newsamples = 1016;
        // }

        /************MAG DETECTION OF PIG FROM STREAM*********** */
        // this.MAGpsgData = GetMAGData16_67Hz();// gets a persistent (1000Hz -> 200 Hz -> 16.67Hz) 16.67 Hz sampled data stream from the raw data
        // if(this.$store.state.RealTimeSettings.MagEn == true)
        // {

        //     this.MagThresh = this.EMHorizontalAnnotationPositive.y1;

        //     for(let i = 0; i<newsamples; i++)
        //     {
        //         if(((MAGData.maxarray[i + 1016-newsamples] > this.MagThresh) || (MAGData.minarray[i + 1016-newsamples] < -this.MagThresh)) &&( this.psglog == false))
        //         {
        //             //simple compare to threshold - will catch a lot fo things but thats ok for now
        //             console.log("mag detect");
        //             this.psglog = true;
        //             this.psglogidx = 0;
        //         }

        //         if(this.psglog == true)
        //         {
        //             //detected something so accumulate some data
        //             if(this.psglogidx < (1016*(15/this.TimeSpan)))//goes until 15 seconds after passage
        //             {
        //                 this.psglogidx++
        //             }
        //             else
        //             {                    
        //                 for(let j =0; j < 500; j++)// store 500 samples to be sent as a passage
        //                 {
        //                     this.PassageArrayMag[j] = this.MAGpsgData[j];
        //                     if(this.PassageArrayMag[j] > max)
        //                     {
        //                         max = this.PassageArrayMag[j];
        //                         idxmax = j;
        //                     }
        //                     if(this.PassageArrayMag[j] <min)
        //                     {
        //                         min = this.PassageArrayMag[j];
        //                         idxmin = j;
        //                     }

        //                     idxmid = Math.round((idxmax + idxmin)/2);// calulated for determing the graph marker
        //                 }

        //                 this.psglogidx = 0;
                        
        //                 this.EMVerticalLineAnnotation.x1 = -idxmid*this.StepSize60; //set the graph marker to where we believe the detection is
        //                 emyaxis.autoRange = EAutoRange.Once;
        //                 psgDateTime = this.GetTimeLabelAnnotation(this.EMVerticalLineAnnotation);// get the passage time

        //                 let selectedDevice: Device;

        //                 selectedDevice = this.$store.state.selectedDevice;

        //                 // Construct a WebPassage object.
        //                 let webPassage: WebPassage;

        //                 webPassage = new WebPassage(selectedDevice.uid);
        //                 webPassage.lat = selectedDevice.lat;
        //                 webPassage.lon = selectedDevice.lon;
        //                 webPassage.passageTimeStamp = psgDateTime;
        //                 webPassage.passageDetectedFrequency = 0;
        //                 webPassage.samplingRate = 16.66667;
        //                 webPassage.packetComplexInformation = 2;
        //                 //debugger;
        //                 console.log((idxmid*this.StepSize60*1000))
        //                 EndTime.setTime(webPassage.passageTimeStamp.getTime() + (idxmid*this.StepSize60*1000));
        //                 webPassage.timeEndOfSnippet = EndTime;
        //                 console.log(webPassage.passageTimeStamp);
        //                 console.log(webPassage.timeEndOfSnippet);
        //                 webPassage.data = new Array(0);

        //                 for (let i = 0; i < 500; i++)
        //                 {
        //                     webPassage.data?.unshift(Math.floor((this.PassageArrayMag[i]/1.5) * 32767));
        //                 }

        //                 console.log(webPassage.data);
                        
        //                 // passage.data?.push(17);
        //                 // passage.data?.push(18);

        //                 // Call the addPassage method of the pig run service.
        //                 if(this.$store.state.RealTimeSettings.SendToDB == true)
        //                 {
        //                     PigRunService.addWebPassage(webPassage);
        //                 }
                        
        //                     if(this.isPaused == false)
        //                     {
        //                         this.PauseClicked();
        //                     }
        //                     setTimeout(() => 
        //                     {
        //                         this.psglog = false;
        //                         if(this.isPaused == true)
        //                         {
        //                             this.ResumedClicked();
        //                         }                          
                            
        //                     }, 1000*this.$store.state.RealTimeSettings.PauseTime);
        //             }
        //         }
        //     }
        // }


        this.MAGdataSeries.clear();
        this.MaxMag = 0;
        this.MinMag = 0;
        for(let i = 0; i< MAGData.maxarray.length; i++)
        {
            if(MAGData.maxarray[i] > this.MaxMag)
            {
               this.MaxMag = MAGData.maxarray[i];
            }

            if(MAGData.minarray[i] < 0)
            {
                if(-MAGData.minarray[i] > this.MinMag)
                {
                    this.MinMag = -MAGData.minarray[i];    
                }
            }
        }

        if(this.MinMag > this.MaxMag)
        {
            this.MaxMag = this.MinMag;
        }

        this.MaxMagArray.unshift(this.MaxMag);
        this.MaxMagArray.pop();

        if(this.TimeSpan == 5){this.MAGdataSeries.appendRange(this.xarray5, MAGData.maxarray,MAGData.minarray );}
        else if(this.TimeSpan == 30){this.MAGdataSeries.appendRange(this.xarray30, MAGData.maxarray,MAGData.minarray );}
        else if(this.TimeSpan == 60){this.MAGdataSeries.appendRange(this.xarray60, MAGData.maxarray,MAGData.minarray );}
        else if(this.TimeSpan == 300){this.MAGdataSeries.appendRange(this.xarray300, MAGData.maxarray,MAGData.minarray );}
        else if(this.TimeSpan == 600){this.MAGdataSeries.appendRange(this.xarray600, MAGData.maxarray,MAGData.minarray );}
        else if(this.TimeSpan == 1800){this.MAGdataSeries.appendRange(this.xarray1800, MAGData.maxarray,MAGData.minarray );}

        if(ELFData == null)
        {
            return;
        }        

        // if(newsampleself > 1016)
        // {
        //     newsampleself = 1016;
        // }

        // this.ELFdataSeries.clear();
        // if(this.TimeSpan == 5){this.ELFdataSeries.appendRange(this.xarray5, ELFData.maxarray,ELFData.minarray );}
        // else if(this.TimeSpan == 30){this.ELFdataSeries.appendRange(this.xarray30, ELFData.maxarray,ELFData.minarray );}
        // else if(this.TimeSpan == 60){this.ELFdataSeries.appendRange(this.xarray60, ELFData.maxarray,ELFData.minarray );}
        // else if(this.TimeSpan == 300){this.ELFdataSeries.appendRange(this.xarray300, ELFData.maxarray,ELFData.minarray );}
        // else if(this.TimeSpan == 600){this.ELFdataSeries.appendRange(this.xarray600, ELFData.maxarray,ELFData.minarray );}
        // else if(this.TimeSpan == 1800){this.ELFdataSeries.appendRange(this.xarray1800, ELFData.maxarray,ELFData.minarray );}

        this.ELFImagdataSeries.clear();

        this.MaxElfI = 0;
        this.MinElfI = 0;
        for(let i = 0; i< ELFDataImag.maxarray.length; i++)
        {
            if(ELFDataImag.maxarray[i] > this.MaxElfI)
            {
               this.MaxElfI = ELFDataImag.maxarray[i];
            }

            if(ELFDataImag.minarray[i] < 0)
            {
                if(-ELFDataImag.minarray[i] > this.MinElfI)
                {
                    this.MinElfI = -ELFDataImag.minarray[i];    
                }
            }
        }

        

        this.MaxElfI = 0;
        this.MinElfI = 0;

        this.MaxElfI = Math.max(...ELFDataImag.maxarray);
        this.MinElfI = Math.min(...ELFDataImag.minarray);
        this.MaxElfR =  Math.max(...ELFData.maxarray);
        this.MinElfR =  Math.min(...ELFData.minarray);


        

        if(this.TimeSpan == 5){this.ELFImagdataSeries.appendRange(this.xarray5, ELFDataImag.maxarray,ELFData.minarray );}
        else if(this.TimeSpan == 30){this.ELFImagdataSeries.appendRange(this.xarray30, ELFDataImag.maxarray,ELFData.minarray );}
        else if(this.TimeSpan == 60){this.ELFImagdataSeries.appendRange(this.xarray60, ELFDataImag.maxarray,ELFData.minarray );}
        else if(this.TimeSpan == 300){this.ELFImagdataSeries.appendRange(this.xarray300, ELFDataImag.maxarray,ELFData.minarray );}
        else if(this.TimeSpan == 600){this.ELFImagdataSeries.appendRange(this.xarray600, ELFDataImag.maxarray,ELFData.minarray );}
        else if(this.TimeSpan == 1800){this.ELFImagdataSeries.appendRange(this.xarray1800, ELFDataImag.maxarray,ELFData.minarray );}
        this.ElfWaveR = ELFData.maxarray;
        this.ElfWaveI = ELFDataImag.maxarray;
        
        this.UpdateEMAxis();

    }

    public async initSciChartSpect()
    {

        const { sciChartSurface, wasmContext } = await SciChartSurface.create("scichart-Spect");
        sciChartSurface.xAxes.add(new NumericAxis(wasmContext));
        sciChartSurface.yAxes.add(new NumericAxis(wasmContext));
        //step size on y axis is the resolution = (n*fs)/N = (1 * 100)/512
        // where n is the bin number ... fs is sampling freq which in our case 100Hz after Decimation & 512 is our FFT Size  //0.1993125
        const heatmapDataSeries = new UniformHeatmapDataSeries(wasmContext, -this.TimeSpan, 0.3125, this.Spect_Yaxis_Start, 0.4,GetMatrix());
    
        this.heatmapColor = new HeatmapColorMap({
            minimum: GetSpectMin(),//40,//Change this parameter to be the max in the matrix
            maximum: GetSpectMax(),//110,
            gradientStops: [
                { offset: 0, color: "#00008B" },//DarkBlue // => Closer to -100
                { offset: 0.2, color: "#6495ED" },//CornflowerBlue 
                { offset: 0.4, color: "#006400" },//DarkGreen
                { offset: 0.6, color: "#7FFF00" },//Chartreuse 
                { offset: 0.8, color: "#FFFF00" },//Yellow
                { offset: 1.0, color: "#FF0000" },// Red // => Closer to 0
            ]
        });
        
        
        
        const heatmapSeries = new UniformHeatmapRenderableSeries(wasmContext, {
        dataSeries: heatmapDataSeries,
        colorMap: this.heatmapColor
        });
        
        // Add heatmap to the chart
        sciChartSurface.renderableSeries.add(heatmapSeries);

        this.SpectSurface = sciChartSurface;
        this.SpectContext = wasmContext;
        this.HeatmapDataSeries = heatmapDataSeries;
        this.heatmapSeries = heatmapSeries;
      }



    public async UpdateSpect()
    {
        if(this.TimeSpan != this.OldTimeSpan)
        {
        // TimeSpan has changed we need to recreate the plot
            this.OldTimeSpan = this.TimeSpan
            this.SpectSurface.delete;
            this.SpectContext.delete;
            this.HeatmapDataSeries.delete;
            this.heatmapSeries.delete;
            this.initSciChartSpect();
        }
        else
        {
            var min = GetSpectMin();
            var max = GetSpectMax();

            if(this.heatmapColor != null)
            {
                this.heatmapColor.minimum = min;
                this.heatmapColor.maximum= max;
                this.HeatmapDataSeries.setZValues(GetMatrix());
            }
            else
            {
                this.initSciChartSpect();
            }
        }
    }
    
   
    get positionStyle() {
        console.log('postion Style called !')
        var is_mobile = /mobile|android/i.test (navigator.userAgent);

        if(is_mobile)
        {
            if (this.minimizeDialog == true && this.maximizeDialog == false) {
                console.log('Returning Minimize Dimensions')
                return "max-width: 600px;width:420px;height:305px";
            } 
            else if(this.minimizeDialog == false && this.maximizeDialog == true) 
            {
                console.log('Returning Maximize Dimensions')
                return "max-width: 600px;width:420px;height:400px";
            }
            else 
            {
                console.log('Returning Normal Dimensions')
                return "max-width: 600px;width:420px;height:305px";
            }
        }
        else
        {
            if (this.minimizeDialog == true && this.maximizeDialog == false) {
                console.log('Returning Minimize Dimensions')
                return "max-width: 1200px;width:840px;height:610px";
            } 
            else if(this.minimizeDialog == false && this.maximizeDialog == true) 
            {
                console.log('Returning Maximize Dimensions')
                //return "max-width: 1200px;width:840px;height:800px";
                return "max-width: 1200px;width:840px;height:880px";
            }
            else 
            {
                console.log('Returning Normal Dimensions')
                return "max-width: 1200px;width:840px;height:610px";
            }
        }
    }


    get plotStyle()
    {
        if (this.maximizeDialog == true) {
            return "width: 800px; height: 180px";
        } 
        else if(this.maximizeDialog == false) 
        {
            return "width: 800px; height: 100px";
        }
    }

    

    public ShowPlotsClicked()
    {
        console.log('Clicked !')
        this.minimizeDialog = false;
        this.maximizeDialog = false;

        setTimeout(() => {
                this.initSciChartGeo();
                this.initSciChartEM();
                this.initSciChartSpect();
                this.initSciChartRSSI();
                //this.StartPlotTimer();
        }, 300);
    }



    public GeoAutoDragEnd()
    {   // Do not allow Horizontal line to go negative


            if(this.AutoGeoThreshLine.y1<0)
            {
                //this.AutoGeoThreshLine.y1 = 0.2;
                this.AutoGeoThreshLine.y1 = -1*this.AutoGeoThreshLine.y1;
            }

            if(this.AutoGeoThreshLine.y1>1)
            {
                this.AutoGeoThreshLine.y1 = 1;
            }
            this.AutoGeoThreshLineN.y1 = -1*this.AutoGeoThreshLine.y1;
            this.WeldThresh = this.AutoGeoThreshLine.y1;     
            UpdateWeldThresh(this.AutoGeoThreshLine.y1);
            this.UpdateGeoAxis();
    }

    public GeoAutoDragEndN()
    {
            if(this.AutoGeoThreshLineN.y1>0)
            {
                //this.AutoGeoThreshLine.y1 = 0.2;
                this.AutoGeoThreshLineN.y1 = -1*this.AutoGeoThreshLineN.y1;
            }

            if(this.AutoGeoThreshLineN.y1<-1)
            {
                this.AutoGeoThreshLineN.y1 = -1;
            }
            this.AutoGeoThreshLine.y1 = -1*this.AutoGeoThreshLineN.y1;
            this.WeldThresh = this.AutoGeoThreshLine.y1;
            UpdateWeldThresh(this.AutoGeoThreshLine.y1);
            this.UpdateGeoAxis(); 
    }

    public GeoDetectionThresholdDragEnd()
    {

        if(this.GeophoneDetectionThresholdLine.y1<=0)
        {
            //this.AutoGeoThreshLine.y1 = 0.2;
            this.GeophoneDetectionThresholdLine.y1 = 0.01;
        }

        if(this.GeophoneDetectionThresholdLine.y1>0.6)
        {
            this.GeophoneDetectionThresholdLine.y1 = 0.6;
        }

        this.GeophoneDetectionThresholdLineN.y1 = -1*this.GeophoneDetectionThresholdLine.y1;  

        this.GeoThresh = this.GeophoneDetectionThresholdLine.y1;
        UpdateGeoPassageThresh(this.GeoThresh)
    }

    public GeoDetectionThresholdDragEndN()
    {

        if(this.GeophoneDetectionThresholdLineN.y1>=0)
        {
            //this.AutoGeoThreshLine.y1 = 0.2;
            this.GeophoneDetectionThresholdLineN.y1 = -0.01;
        }

        if(this.GeophoneDetectionThresholdLineN.y1<-0.6)
        {
            this.GeophoneDetectionThresholdLineN.y1 = -0.6;
        }

        this.GeophoneDetectionThresholdLine.y1 = -1*this.GeophoneDetectionThresholdLineN.y1;  
        this.GeoThresh = this.GeophoneDetectionThresholdLine.y1;
        UpdateGeoPassageThresh(this.GeoThresh)
    }
    
    public GeoAutoClicked()
    {
        this.isGeoAutoClicked = true;
        this.AutoGeoThreshLine.isHidden = false;
        this.AutoGeoThreshLineN.isHidden = false;
        UpdateGeoAutoState(this.isGeoAutoClicked);
    }

    public GeoAutoUnClicked()
    {
        console.log('GeoAutoUNClicked!');
        this.isGeoAutoClicked = false;
        this.AutoGeoThreshLine.isSelected = false;
        this.AutoGeoThreshLineN.isSelected = false;
        setTimeout(() => {
            this.AutoGeoThreshLine.isSelected = false;
            this.AutoGeoThreshLine.isHidden = false;
            }, 100);
    }


    public mounted()
    {
        // Initialize parameters
        this.isClosed = false;
        this.minimizeDialog = false;
        this.maximizeDialog = false;
        this.watchOnlineStatus();

    }

    public watchOnlineStatus() {
        document.addEventListener('visibilitychange', this.visibilityChange, false);
    }


    public visibilityChange() {
        
        let hidden = '';
        let visibilityChange = '';



        if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
            hidden = 'hidden';
            visibilityChange = 'visibilitychange';
        // @ts-ignore
        } else if (typeof document.msHidden !== 'undefined') {
            hidden = 'msHidden';
            visibilityChange = 'msvisibilitychange';
        // @ts-ignore
        } else if (typeof document.webkitHidden !== 'undefined') {
            hidden = 'webkitHidden';
            visibilityChange = 'webkitvisibilitychange';
        }
        if (document[hidden]) {
            // User is offline
            
            // Here we should do same as if user pressed minimize button
            // If dialog is open
            if(this.isDialogOpen )
            {
                this.onMinimize();
            }
             
            //this.setOffline();
        } else {

        }




    }
    public resumeHandler() {
        //debugger
    }
    public freezeHandler() {
        //debugger
    }
    public pageshowHandler() {
        //debugger
    }





    public RegesterAudioListener()
    {
        console.log('Test: start Audio Stream...');
        this.playBtn = true;
        this.Enable_Audio = true;
        TraaInit();
        
        setTimeout(() => {
            TraaEnable();
        }, 100);
    }




    public onVolumeChange() {
        TraaSetGaindB(this.volume);
    }


    public onToggleAudio() {
        if (this.playBtn) {
            this.playBtn = false;
            this.Enable_Audio = true;
            console.log("Play Pressed");
            //TraaEnable();
            TraaSetGaindB(this.volume);
            MuteAudio(false);
        } else {
            this.playBtn = true;
            console.log("Stop Pressed");
            this.Enable_Audio = false;
            //TraaDisable();
            MuteAudio(true);
        }
    }


    public submitStreamMode(uid: number, enableStream: boolean) {
        console.log('AudioStream: submitStreamMode() ' + enableStream);
        if (this.uid) {
            this.$store.dispatch('postCommand', new Command(uid, CommandType.StreamEnable, new Date(), {
                streamEnabled: enableStream,
            }));
        }
    }

    public shutdownAudio()
    {
        this.playBtn = true;
        this.UserRole = this.getuser();
        
        if (this.uid) {
            if(this.UserRole == 'viewer')
            {
                this.uid = null;
            }
            else
            {
                console.log('STOP COMMAND ' + this.uid);
                this.submitStreamMode(this.uid, false);
                this.uid = null;
            }
        }

        TraaDisable();
        setTimeout(() => {
            TerminateAudio();
        }, 200);
        
    }

    public onClickOptions() 
    {
        this.$store.dispatch('RealTimeOptionsShow',[this.EMExtents,this.RSSIExtents,this.GeoExtents]);
        console.log("options clcked");
    }

    get RTStreamDialog():boolean{
        return this.$store.state.RTStreamDialog;
    }


    public startX:number = 0;
    public startY:number = 0;
    public croppedImageWidth:number = 100;
    public croppedImageHeight:number = 100
    public imageUrl:any = null
    public TakeScreenshot()
    {
        //var bodyRect = document.body.getBoundingClientRect();
        const el = document.querySelector('#foobar');
        const dim = el?.getBoundingClientRect()
        var width = 1;
        var hight = 1
        if(dim != null)
        {
            this.startX = dim.x;
            this.startY = dim.y;
            width = dim.bottom;
            hight = dim.height;

            this.croppedImageWidth = width;
            this.croppedImageHeight = hight
        }

        
        var a = document.querySelector('body');

        if(a != null)
        {
            html2canvas(a).then(canvas => {
                let croppedCanvas = document.createElement('canvas');
                let croppedCanvasContext = croppedCanvas.getContext('2d');
                croppedCanvas.width  = width;
                croppedCanvas.height = hight;
                if(croppedCanvasContext != null)
                {
                    croppedCanvasContext.drawImage(canvas, this.startX, this.startY, this.croppedImageWidth, this.croppedImageHeight, 0, 0, this.croppedImageWidth, this.croppedImageHeight);
                    this.imageUrl = croppedCanvas.toDataURL('image/png');
                    console.log(this.imageUrl)
                    debugger
                    var filename = this.uid + new Date().toString() + '.png';
                    const hiddenElement = document.createElement('a');
                    hiddenElement.href =  encodeURI(this.imageUrl);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = (this.uid + '')
                    hiddenElement.download = filename;
                    hiddenElement.click();
                    
                }
            })
        }
    }
}


