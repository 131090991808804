
import { Component, Prop, Vue } from 'vue-property-decorator';
import LeafletMap from '@/components/LeafletMap.vue';

@Component({
  components: {
      LeafletMap,
    },
})
export default class Map extends Vue {}

