// UpdateA2PMessagingStateRequest.
//
// Class representing an update application-to-person messaging state request.
export class UpdateA2PMessagingStateRequest
{
    // Application-to-person messaging opt-in state.
    public a2pMessagingOptInState: boolean;

    // Phone number.
    public phone: string;

    // Constructor.
    public constructor(a2pMessagingOptInState: boolean, phone: string)
    {
        this.a2pMessagingOptInState = a2pMessagingOptInState;
        this.phone = phone;
    }
}
