
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AgmMarker } from '@/models/AgmMarker';

@Component
export default class AutoSettings extends Vue {

    @Prop() public readonly markers: AgmMarker[] | undefined;

    public tmpMarkerEdits: AgmMarker[] = [];
    public autoLinkToggleSetting = false;

    public onToggleAllAutoLink() {
      if (this.tmpMarkerEdits.length) {
          console.log('run: onToggleAllAutoLink');
          this.autoLinkToggleSetting = !this.autoLinkToggleSetting;
          this.tmpMarkerEdits.forEach((marker: AgmMarker) => {
              if (marker.linkedUid || marker.state !== 'TRACK') {
                  // ignore toggle if manual linked
              } else {
                  marker.autoLink = this.autoLinkToggleSetting;
              }
          });
          console.log(this.tmpMarkerEdits);
      }
    }

    public onToggleAllAutoVerify() {
      if (this.tmpMarkerEdits.length) {
          console.log('run: onToggleAllAutoVerify');
          console.log(this.tmpMarkerEdits);
          const newSetting = !this.tmpMarkerEdits[0].autoVerify;
          this.tmpMarkerEdits.forEach((marker: AgmMarker) => {
              marker.autoVerify = newSetting;
          });
      }
    }

    public show() {
      // @ts-ignore
      this.$refs.dialog.show();
      this.tmpMarkerEdits = JSON.parse(JSON.stringify(this.markers));
    }

    public hide() {
      // @ts-ignore
      this.$refs.dialog.hide();
    }

    public onDialogHide() {
      this.$emit('hide');
    }

    public onOKClick() {
      this.$emit('ok', this.tmpMarkerEdits);
      this.hide();
    }

    public onCancelClick() {
      this.hide();
    }

}
