export interface IUserMgmt {
    id: string | null;
    group: number;
    name: string;
    email: string;
    phone: string;
    role: string;
    password?: string;
}

export class UserMgmt implements IUserMgmt {
    constructor(
        public id: string | null,
        public group: number,
        public name: string,
        public email: string,
        public phone: string,
        public role: string,
        public password?: string,
    ) {}
}
