import { render, staticRenderFns } from "./DeviceActions.vue?vue&type=template&id=1fd66a24&scoped=true&"
import script from "./DeviceActions.vue?vue&type=script&lang=ts&"
export * from "./DeviceActions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd66a24",
  null
  
)

export default component.exports