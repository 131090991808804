//added by Ihab//
export class SDCommands {
    public static ClearOFFLINE = 'Delete OFFLINE PSGs via Cell';
    public static SDClearIROFFLINE = 'Delete OFFLINE PSGs via Sat';
    public static SDClearOnline = 'Delete ONLINE PSGs';
    public static SDFormat = 'Format SD card';
    public static SDDwnOFF = 'Download OFFLINE PSGs via Cell';
    public static SDDwnIROFF = 'Download OFFLINE PSGs via Sat';
    public static SDDwnON = 'Download ONLINE PSGs';
    public static SDExit = 'Exit Download Session';
    public static SDDeleteRaw = 'Delete Raw Files';
   
    
    public static getModes() {
        return [
            this.ClearOFFLINE, // 0
            this.SDClearIROFFLINE,//1
            this.SDClearOnline,//2
            this.SDFormat, // 3
            this.SDDwnOFF,//4
            this.SDDwnIROFF,//5 
            this.SDDwnON,//6
            this.SDExit,//7
            
        ];
    }

    public static getLimitedModes() {
        return [
            this.SDFormat//, // 0
            //this.SDDeleteRaw          
        ];
    }

    public static byIdx(i: number) {
        return [
            this.ClearOFFLINE, // 0
            this.SDClearIROFFLINE,//1
            this.SDClearOnline,//2
            this.SDFormat, // 3
            this.SDDwnOFF,//4
            this.SDDwnIROFF,//5 
            this.SDDwnON,//6
            this.SDExit,//7
        ][i];
    }
    public static toString(s: string) {
        const idx = [
            'Clear Offline', // 0
            'Clear Sat Offline',// 1
            'Clear Online',// 2
            'SD Format', // 3
            'Download Offline', // 4
            'Download Sat Offline', // 5
            'Download Online',//6
            'Exit Download'//7
        ].indexOf(s);
        if (idx !== -1) {
            return this.byIdx(idx);
        } else {
            return '';
        }
    }
}
